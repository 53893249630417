import React from 'react';
import { useTranslation } from 'react-i18next';

// Import utilities
import { DrawerRPC, ToggleRPC } from 'components/utilities';

// Import icons
import { ReactComponent as FavoriteIcon } from 'assets/icons/ic_favorite.svg';

// Import components
import { Button, AuthModal } from 'components/elements';
import Favourite from './Favourite';
import { IconWrapper } from '../styles';

// Import logic from hook
import { useFavouritesActions } from './useFavouritesActions';

export const Favourites = () => {
	const { t } = useTranslation();
	const { deleteFavourite, favourites } = useFavouritesActions();

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<AuthModal>
						<Button modifiers={['navIcon']} onClick={toggle}>
							<IconWrapper width="3.2rem" height="3.2rem">
								<FavoriteIcon />
							</IconWrapper>
						</Button>
					</AuthModal>
					<DrawerRPC
						isOpen={on}
						onClose={toggle}
						title={t('common_header_nav_watchlist')}
					>
						{favourites.map((item) => (
							<Favourite
								toggle={toggle}
								key={item.uuid}
								item={item}
								deleteFavourite={deleteFavourite}
							/>
						))}
					</DrawerRPC>
				</>
			)}
		</ToggleRPC>
	);
};
