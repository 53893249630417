import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTvPrograms } from 'store/actions';

// Import variables
import { REFRESH_PROGRAMS_INTERVAL } from 'helpers/variables';

const useRefreshPrograms = () => {
	const {
		date: { till }
	} = useSelector((state) => state.channels);
	const dispatch = useDispatch();

	const handleFetchResources = (isLoaded) =>
		// Dispatch an fetchTvPrograms action in channels folder
		fetchTvPrograms({ isLoaded })(dispatch);

	const tomorrowTime = (isLoaded = true) => {
		const currentTime = new Date().getTime();
		const tillTime = new Date(till).getTime();

		if (currentTime >= tillTime) {
			handleFetchResources(isLoaded);
		}
	};

	/*eslint-disable */
	React.useEffect(() => {
		tomorrowTime(false);
	}, []);
	/*eslint-enable */

	/*eslint-disable */
	React.useEffect(() => {
		const intervalTime = REFRESH_PROGRAMS_INTERVAL;

		const refreshInterval = setInterval(() => {
			if (till) {
				// Check if it is tomorrow
				tomorrowTime();
			}
		}, intervalTime);

		return () => clearInterval(refreshInterval);
	}, [till]);
	/*eslint-enable */
};
export default useRefreshPrograms;
