import React from 'react';
import { string } from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import components
import GeoblockingErrorModal from 'components/elements/molecules/common_modal/GeoblockingErrorModal';

// Import translations
import { ERRORS_CODES_TRANSLATIONS } from 'translations/error_codes_translations';

// Import helpers
import {
	APP_SUB_PATHS_KEY,
	getNavigationSubPaths
} from 'helpers/navigationSubPaths';

const { PROGRAM_DETAILS } = APP_SUB_PATHS_KEY;

const GeoblockingError = ({ code }) => {
	const { pathname } = useLocation();

	const { en, ar } = ERRORS_CODES_TRANSLATIONS.find(({ key }) => key === code);

	const programDetailsSubPath = getNavigationSubPaths(PROGRAM_DETAILS);

	const isProgramDetails = pathname.includes(programDetailsSubPath);

	return (
		<GeoblockingErrorModal
			title={en}
			secondTitle={ar}
			isProgramDetails={isProgramDetails}
		/>
	);
};

GeoblockingError.propTypes = {
	code: string.isRequired
};

export default GeoblockingError;
