import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const base = css`
	color: ${({ theme }) => theme.white};
	margin-bottom: 1.8rem;
	line-height: 1.4;
	font-weight: ${({ theme }) => theme.font.medium};

	${({ isUppercase }) =>
		isUppercase &&
		css`
			text-transform: uppercase;
		`}
`;

// Heading First
const HEADING_FIRST_MODIFIERS = {
	primary: ({ theme: { heading } }) => css`
		color: ${heading.first.primary};
	`,

	details: ({ theme }) => css`
		margin-bottom: 2.2rem;
		color: ${theme.grayMedium};
	`,

	normal: () => css`
		font-size: 2.4rem;
	`,

	big: () => css`
		font-size: 3.5rem;
	`
};

const First = styled.h1`
	${base}
	margin-top: 2.5rem;
	color: ${({ theme: { heading } }) => heading.first.white};
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 1.2;
	${applyStyleModifiers(HEADING_FIRST_MODIFIERS)};
`;

// Heading Second
const HEADING_SECOND_MODIFIERS = {
	small: ({ theme }) => css`
		font-size: 1.4rem;
		color: ${theme.primary};
	`
};

const Second = styled.h2`
	${base}

	${applyStyleModifiers(HEADING_SECOND_MODIFIERS)};
`;

// Heading Third
const HEADING_THIRD_MODIFIERS = {
	big: () => css`
		font-size: 2.4rem;
	`
};

const Third = styled.h3`
	${base}
	font-weight:${({ theme }) => theme.font.normal};
	${applyStyleModifiers(HEADING_THIRD_MODIFIERS)};
`;

export { Second, Third, First };
