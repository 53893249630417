import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH DVR DEVICES ********************
export const fetchDevices = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_DEVICES_LOADING,
			payload: true
		});

		// Get tv categories
		const { data } = await services.get(
			'/recordings/dvr/devices?platform=BROWSER'
		);

		// filter non recording devices RemoteDvrFlag
		const filteredDevices = data.filter(({ RemoteDvrFlag }) => RemoteDvrFlag);

		// Dispatch an action
		dispatch({
			type: types.FETCH_DEVICES_SUCCESS,
			payload: filteredDevices
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_DEVICES_ERROR,
			payload: true
		});
	}
};

// ******************** SELECT DEVICES ********************
export const selectDevice = (id) => (dispatch) => {
	dispatch({
		type: types.SELECT_DEVICE,
		payload: id
	});
};
