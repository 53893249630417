import styled, { css } from 'styled-components';

// Import styles
import { sectionBackground } from 'components/styles';

export const Root = styled.div`
	height: ${({ height }) => height}px;
	width: 100%;
	display: grid;
	grid-template-rows: ${({ header }) => `${header}px 1fr`};
	align-items: center;
	justify-items: flex-end;
	grid-gap: ${({ gridGap }) => gridGap}px;

	${({ backgroundImage }) =>
		backgroundImage && sectionBackground(backgroundImage)}
`;

export const SliderContainer = styled.div`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	grid-row-start: 2;
	grid-row-end: 3;

	${({ isVODRecommendation }) =>
		isVODRecommendation &&
		css`
			position: absolute;
			left: 0;
			right: 0;

			width: 90%;
			margin: 0 auto;

			${ItemContainer} {
				display: block;
			}
		`};

	&:hover {
		.slick-slider {
			z-index: 10 !important;
		}
	}

	.slick-list {
		overflow: visible;

		${({ isVODRecommendation, spaceBetweenItems }) =>
			isVODRecommendation &&
			spaceBetweenItems &&
			css`
				margin-right: -${spaceBetweenItems / 2}px;
			`};
	}

	.slick-track {
		margin: 0;
	}

	.slick-slider {
		position: relative;
	}

	.slick-next {
		position: absolute;
		top: 50%;
		right: 2rem !important;
		z-index: 999;

		svg {
			height: 58px;
			width: 58px;
		}
	}

	.slick-prev {
		position: absolute;
		top: 50%;
		left: 2rem !important;
		z-index: 999;

		svg {
			height: 58px;
			width: 58px;
		}
	}
`;

export const ItemContainer = styled.div`
	display: flex;
	justify-content: center;
`;
