// Localstorage
export const LOCALSTORAGE_USER = '_wk_u';
export const LOCALSTORAGE_PLAYER = '_wk_sh_p';
export const LOCALSTORAGE_TRACKING = '_wk_sh_p_t';
export const LOCAL_STORAGE_LANGUAGE = '_wk_lng';
export const LOCAL_STORAGE_PLAYER_VOLUME = '_wk_p_v';

// Sessionstorage
export const SESSIONSTORAGE_OS_SUPPORT = '_wk_o_s';

// Cookie
export const COOKIE_VIDEO_SESSION = '_wk_s';
export const COOKIE_EXPIRES = 3650;
export const COOKIE_TRANSACTION_PATH = '_wk_t_p';

// Refresh programs interval
export const REFRESH_PROGRAMS_INTERVAL = 300000;

// Update live program interval
export const UPDATE_LIVE_PROGRAM_INTERVAL = 60000;

// Browser wake up interval
export const BROWSER_WAKE_UP_INTERVAL = 6000;

// Refetch transaction status timeout
export const RECHECK_TRANSACTION_INTERVAL = 5000;

// Login
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
export const DEVICES_LIMIT_EXCEEDED =
	'SUBSCRIBER_ASSIGNED_DEVICES_LIMIT_EXCEEDED';
export const DEVICE_NOT_EXISTS = 'DEVICE_NOT_EXISTS';

export const NO_PROGRAM = 'NO_PROGRAM';

export const CHANNEL_OFFSET_LIMIT = 'offset=0&limit=300';

export const RESUME_PLAYBACK_OFFSET_TIME = 5;

// SectionsGroup names
export const SECTIONS = {
	MAIN_WEB: 'MAIN_WEB',
	VOD_WEB: 'VOD_WEB',
	SERIES_WEB: 'SERIES_WEB',
	KIDS_WEB: 'KIDS_WEB',
	START_WEB: 'START_WEB'
};

// Home
export const SECTIONS_TYPES = {
	LIVE_NOW: 'live_epg',
	BANNER: 'banner',
	VIRTUAL: 'virtual',
	CAROUSEL: 'carousel',
	PROMOTED: 'promoted',
	NORMAL: 'normal',
	NORMAL_COVER: 'normal_cover',
	LARGE: 'large'
};

export const VIRTUAL_SECTIONS_TYPES = {
	FAVOURITE_CHANNELS: 'favourite_channels',
	FAVOURITE_MOVIES: 'favourite_movies',
	FAVOURITE_SERIES: 'favourite_series'
};

// VOD
export const VOD_SECTIONS_TYPES = {
	WEB_MOVIES_CONTINUE_WATCHING: 'web_filmy_kontynuujoglądanie'
};

// EPG
export const EPG_SELECTORS = {
	EPG_CLASS: 'epg'
};

// Player Types
export const PLAYER_TYPES = {
	CATCHUP: 'CATCHUP',
	LIVE: 'LIVE'
};

export const PLAYER_VOD_LIVE_DELAY = 40;

// Program details
export const PROGRAM_RECOMMENDATIONS = {
	MAX_RESULTS: 8
};

// Player selectros
export const PLAYER_SELECTORS = {
	PLAYER: 'player',
	DETAILS: 'player_details',
	VOD: 'player_vod',
	ATDS_PLAYER_CONTAINER_CLASS: 'atdsplayer-container',
	ATDS_PLAYER_HIDE_CURSOR_CLASS: 'hide-cursor',
	SHAKA_CONTROLS_CONTAINER_CLASS: 'shaka-controls-container'
};

export const PRODUCT_TYPES = ['program', 'vod', 'episode'];

export const VOD_PERMITTED_SECTION_LABELS = [
	'virtual',
	'carousel',
	'normal',
	'promoted',
	'banner'
];

// url platforms
export const PLATFORM_BROWSER = 'platform=BROWSER';

// OS NOT SUPPORTED
export const OS_NOT_SUPPORTED = 'OS_NOT_SUPPORTED';

// Product image types
export const PRODUCT_IMAGES_TYPES = {
	COVER: 'cover',
	POSTER: 'poster',
	LOGO: 'logo',
	COVER_LARGE: 'cover_large',
	COVER_DETAIL: 'cover_detail',
	COVER_SMALL: 'cover_small'
};

// query subtypes translation to filter category items
export const QUERY_SUBTYPES = {
	vod: 'vod',
	series: 'seriale',
	seriale: 'series'
};

// HOME PAGE LABEL
export const HOME_LABEL = 'MAIN_WEB';

// react app projects
export const APP_PROJECT_TYPES = {
	TV_SMART: 'TV_SMART',
	TVO: 'TVO',
	TV_ONLINE: 'TV_ONLINE'
};

// request url system types
export const SYSTEM_TYPES = {
	OTT: 'ott',
	TVONLINE: 'tvonline'
};

// base links to apps
export const BASE_APPS_URLS = {
	ANDROID_BASE_URL: 'https://play.google.com/store/apps/details?id=',
	APPLE_BASE_URL: 'https://itunes.apple.com/pl/app/',
	LG_BASE_URL: 'https://ae.lgappstv.com/main/tvapp/'
};

// android apps ids
export const ANDROID_APPS_IDS = {
	ANDROID_TV_SMART_ID: 'com.ims.widekhaliji',
	ANDROID_TV_ONLINE_ID: 'com.ims.widekhaliji'
};

// apple apps ids
export const APPLE_APPS_IDS = {
	APPLE_TV_SMART_ID: '/widekaliji/id1124366719?mt=8 ',
	APPLE_TV_ONLINE_ID: 'widekaliji/id1124366719?mt=8 '
};

// LG apps ids
export const LG_APPS_IDS = {
	LG_TV_SMART_ID:
		'detail?appId=1142456&catCode1=&moreYn=N&cateYn=N&orderType=0&headerName=&appRankCode=&sellrUsrNo=',
	LG_TV_ONLINE_ID:
		'detail?appId=1142456&catCode1=&moreYn=N&cateYn=N&orderType=0&headerName=&appRankCode=&sellrUsrNo='
};

// Client external links
export const CLIENT_EXTERNAL_LINKS = {
	ABOUT_US: 'https://www.instagram.com/widekhaliji',
	CONTACT: 'https://contact.widekhaliji.com',
	CONTACT_AR: 'https://contact.widekhaliji.com/ar',
	FACEBOOK_GROUP: 'https://www.facebook.com/widekhaliji',
	TV_SMART_FAQ: 'https://widekhaliji.com',
	TV_ONLINE_FAQ: 'https://widekhaliji.com',
	INSTAGRAM: 'https://www.instagram.com/widekhaliji/',
	TWITTER: 'https://twitter.com/widekhaliji'
};

// meta tags login page links
export const META_LOGIN_LINKS = {
	TV_ONLINE_LOGIN: 'https://widekhaliji.com/login',
	TV_SMART_LOGIN: 'https://tvsmart.vectra.pl/logowanie'
};

//-------------- MOVIE PREVIEW CONSTANTS ---------------
// preview stripe url
export const STRIPES_URL =
	'https://r.dcs.redcdn.pl/file/o2/Vectra/cdn/public/assets/prod/vod';
// numbers of frame in single stripe
export const FRAMES_PER_STRIPE = 32;
// time for single fame
export const TIME_PER_FRAME = 30;

// tracking interval time
export const TRACKING_INTERVAL_TIME = 30000;

// tracking events
export const TRACKING_EVENTS = {
	PLAYING: 'PLAYING',
	BUFFERING: 'BUFFERING',
	PAUSED: 'PAUSED',
	SEEKING: 'SEEKING',
	TRACK_CHANGED: 'TRACK_CHANGED',
	COMPLETE: 'COMPLETE',
	STOPPED: 'STOPPED',
	CLOSED: 'CLOSED',
	ERROR: 'ERROR'
};

// purchase query string values - from blue media url
export const PURCHASE_QUERY_STRING_PARAMS = {
	ORDER_ID: 'OrderID',
	STATUS: 'Status'
};

// status types (mainly used for tracking request status in Redux)
export const STATUS_TYPES = {
	idle: 'idle',
	loading: 'loading',
	success: 'success',
	error: 'error',
	pending: 'pending'
};

// status types for transacion status (response from bluemedia)
export const TRANSACTION_STATUS_TYPES = {
	init: 'init',
	pending: 'pending',
	succeed: 'succeed',
	failed: 'failed' // - also when user cancels transaction
};

export const STATUS_FROM_URL_FAILED = '-1';

export const CONFIRMATION_STATUS_WAITING = 'waiting';

// names of login tabs
export const LOGIN_TABS_NAMES = { nextGen: 'nextGen', vectra: 'vectra' };

// login system types
export const LOGIN_SYSTEM_TYPES = { pureott: 'pureott', vectra: 'vectra' };

// portal languages
export const LANGUAGES_TYPES = {
	ENG: 'eng',
	PL: 'pl',
	AR: 'ar'
};

export const LANG_PARAMS_TYPES = {
	EN: 'en',
	AR: 'ar'
};

export const THEME_NAMES = {
	PURE_OTT: 'PURE_OTT',
	OTT: 'OTT',
	TV_ONLINE: 'TV_ONLINE',
	WIDE_KHALIJI: 'WIDE_KHALIJI'
};
// CSS Positions
export const CSS_POSITIONS = {
	ABSOLUTE: 'absolute',
	STATIC: 'static',
	RELATIVE: 'relative',
	FIXED: 'fixed',
	STICKY: 'sticky'
};

export const VECTRA_LOGIN_BACKGROUND =
	'https://r.dcs.redcdn.pl/scale/o2/Vectra/cdn/images/static/start.jpg?srcmode=4&srcw=5114&srch=2806&dstw=3061&dsth=1680&type=1&quality=80';

export const DEFAULT_PLAYER = process.env.REACT_APP_DEFAULT_PLAYER;

export const THEO_PLAYER = 'theoplayer';

export const THEO_LIBRARY_URL = `${process.env.REACT_APP_WEBSITE_URL}/theoplayer`;

// POSTAL CODES
export const POSTAL_CODES_REGEX = {
	POLAND: /^([0-9]{2})(-[0-9]{3})?$/i
};

// INVOICE
export const INVOICE_TYPES = {
	BUSINESS: 'business',
	INDIVIDUAL: 'individual'
};

export const DIRECTION_TYPES = {
	LTR: 'ltr',
	RTL: 'rtl'
};

export const DIRECTION_WAY_TYPES = {
	RIGHT: 'right',
	LEFT: 'left'
};

//COUNTRY CODE
export const DEFAULT_COUNTRY_CODE = 'sa';

export const LINK_TYPES = {
	CHANNEL: 'channel',
	VOD: 'vod',
	SERIES: 'series',
	SEASON: 'season',
	EPISODE: 'episode',
	PROVISION: 'provision',
	COLLECTION: 'collection',
	MAIN: 'main'
};

//Default language of project
export const DEFAULT_PROJECT_LANGUAGE = {
	AR: 'ar',
	ENG: 'en',
	PL: 'pl'
};

export const PACKET_MAIN = 'main';

export const PACKET_TYPE = 'packet';

export const FONTS = {
	NOTO_KUFI_ARABIC: 'Noto Kufi Arabic',
	NOTO_SANS_REGULAR: 'Noto Sans'
};

// Images type
export const IMAGES_TYPES = {
	POSTER: 'poster',
	LOGO: 'logo'
};

export const ASSIGNED_SUPSCRIPTION_TYPES = {
	PACKET: 'packet',
	PAYMENT_METHOD_FREE: 'free'
};

// TRACKING MANAGER
export const TRACKING_GTM_MANAGER = {
	PAGE_VIEW: 'pageView',
	PAGE_VIEW_PIXEL: 'PageView'
};

export const TRACKING_GTM_MANAGER_EVENTS = {
	LOGIN: 'Login',
	LOGIN_OUT: 'LogOut',
	ADD_TO_FAVOURITES: 'AddToFavourites',
	REMOVE_FROM_FAVOURITES: 'RemoveFromFavourites',
	REMOVE_DEVICE: 'RemoveDevice',
	ADD_DEVICE: 'AddDevice',
	GO_TO_LOGIN_PAGE: 'GoToLogInPage',
	REGISTER: 'Register',
	ACCEPT_AGREEMENTS: 'AcceptAgreements'
};

export const ARABIC_SLUGS = {
	MOVIES: 'أفلام',
	MOVIE: 'فيلم',
	SERIES: 'مسلسلات',
	CHANNELS: 'القنوات'
};

export const ENGLISH_SLUGS = {
	MOVIES: 'movies',
	MOVIE: 'movie',
	SERIES: 'series',
	CHANNELS: 'channels'
};

export const ITEM = 'item';
export const PERIODS = {
	HOUR: 'hour',
	DAY: 'day',
	MONTH: 'month',
	YEAR: 'year'
};

export const STATIC_DOCUMENT_TYPES = {
	REGULATION: 'regulation',
	PRIVACY_POLICY: 'privacy-policy',
	ABOUT_US: 'about-us'
};

export const LOGIN_PAGE_IMAGE = {
	MOBILE: `${process.env.REACT_APP_API_URL}/assets/model/landingPageBrowserRwd/cover`,
	DESKTOP: `${process.env.REACT_APP_API_URL}/assets/model/landingPageBrowser/cover?params=w1920`
};

export const NON_SUBSCRIBER_PAGE_IMAGE = {
	ar: `${process.env.REACT_APP_API_URL}/assets/model/loginPageNoSubscriptionAr/cover?params=w1920`,
	en: `${process.env.REACT_APP_API_URL}/assets/model/loginPageNoSubscriptionEn/cover?params=w1920`
};

export const USER_INFORMATION = {
	LOGIN: 'id',
	EMAIL: 'email',
	PHONE_NUMBER: 'phone_number',
	CREATED_AT: 'created_at',
	LAST_LOGGED_AT: 'last_logged_at'
};

export const PLATFORMS = {
	BROWSER: 'BROWSER',
	IOS: 'IOS',
	ANDROID: 'ANDROID',
	ANDROID_TV: 'ANDROID_TV',
	APPLE_TV: 'APPLE_TV',
	TIZEN: 'TIZEN',
	WEBOS: 'WEBOS'
};

export const PERIOD_UNITS = {
	DAY: 'day',
	HOUR: 'hour',
	MONTH: 'month',
	YEAR: 'year'
};

export const INTL_STYLES = {
	LONG: 'long',
	SHORT: 'short',
	NARROW: 'narrow'
};

// SECURITY TYPES
export const SECURITY_TYPES = {
	DRM: 'DRM',
	AKAMAI: 'AKAMAI'
};

// VOLUME
export const VOLUME = {
	MIN: 0,
	MAX: 100
};

// FAVOURITES / WATCHLIST AVAILABLE PRODUCT TYPES
export const FAVOURITES_AVAILABLE_TYPES = ['vod', 'channel', 'series'];

// PLAYER SEGMENTS TYPES
export const INTRO = 'intro';
export const NEXT_EPISODE = 'next_episode';

// PLAYER NEXT EPISODE
export const NEXT_EPISODE_SHOW_TIME = 10;

export const EVENTS_NAMES = {
	PLAY: 'play',
	PAUSE: 'pause',
	ERROR: 'error',
	WAITING: 'waiting',
	PLAYING: 'playing',
	BEFORE_UNLOAD: 'beforeunload',
	LOADED_DATA: 'loadeddata',
	LOADED_METADATA: 'loadedmetadata',
	FULLSCREEN_CHANGE: 'fullscreenchange',
	KEYDOWN: 'keydown',
	TIME_UPDATE: 'timeupdate',
	CAST_STATUS_CHANGED: 'caststatuschanged',
	ENDED: 'ended',
	BUFFERING: 'buffering',
	ADAPTATION: 'adaptation',
	MOUSEMOVE: 'mousemove',
	MOUSEUP: 'mouseup',
	RESIZE: 'resize'
};

// PRODUCT TYPE
export const PRODUCT_TYPE = {
	VOD: 'vod',
	SERIES: 'series',
	CHANNEL: 'channel',
	EPISODE: 'episode',
	PROGRAM: 'program'
};

export const AUTH_PHONE_NUMBER_MIN_LENGTH = 8;

// CODE QUERY PARAMETER
export const CODE_PARAMETER = 'c';

export const MOBILE_DEVICES_WEBSITE_URL = 'http://m.wide.net/';

// WATERMARK URL
export const GLOBAL_WATERMARK_URL = `${process.env.REACT_APP_API_URL}/assets/model/watermark/logo`;
export const VOD_WATERMARK_URL = `${process.env.REACT_APP_API_URL}/assets/model/vodLogo/logo`;

export const ADS_DISPLAYING_INTERVAL_TIME = 600000; // 10 minutes

export const SPECIAL_PRICE_COUNTRIES_CODES = [
	'IQ',
	'PS',
	'IL',
	'LB',
	'JO',
	'EG'
];
