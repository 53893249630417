import React from 'react';
import { array } from 'prop-types';

// Import components
import { Basic } from '../Basic';
import Packet from './Packet';

export function Packets({ packets }) {
	return (
		<Basic
			itemResolutionWidth={1876}
			itemResolutionHeight={783}
			itemsList={packets}
			itemsToShow={3}
			spaceBetweenItems={15}
			renderItemComponent={({
				data: { title, short_description, uuid, type, images },
				height,
				width
			}) => (
				<Packet
					width={width}
					height={height}
					title={title}
					uuid={uuid}
					description={short_description}
					type={type}
					images={images}
				/>
			)}
		/>
	);
}

Packets.propTypes = {
	packets: array.isRequired
};
