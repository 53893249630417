import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Content, Text, ButtonStyled } from './styles';

const ModalContent = ({ close }) => {
	const { t } = useTranslation();

	return (
		<Content>
			<Text>{t('common_subscription_verifying_your_payment')}</Text>
			<Text description>{t('common_subscription_processing_please_wait')}</Text>
			<Text description>{t('common_subscription_try_again')}</Text>

			<ButtonStyled modifiers={['primary']} onClick={close}>
				{t('common_general_ok')}
			</ButtonStyled>
		</Content>
	);
};

ModalContent.propTypes = {
	close: func.isRequired
};

export default ModalContent;
