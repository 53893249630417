import styled, { css } from 'styled-components';

// Import styles
import { customResponsive } from 'components/styles';

export const Wrapper = styled.div`
	height: 7rem;
	background-color: ${({ isInverse, theme }) =>
		isInverse ? theme.black : 'transparent'};
`;

export const Content = styled.div`
	padding: 0rem 2rem;
	display: grid;
	grid-template-columns: ${({ columns }) => columns};

	${customResponsive(
		1200,
		css`
			padding-bottom: 0.6rem;
		`
	)}
`;
