import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Info } from './styles';

// Import helpers
import { getGenreName } from 'helpers/languages';

const VodSerialInfo = ({ product: { year, genres } }) => {
	const { t } = useTranslation();

	const genre = getGenreName({ genres });

	return (
		<>
			<Info>
				{!!year && (
					<>
						{t('search_year')}: {year}
					</>
				)}
			</Info>
			<br />
			<Info>
				{genre && (
					<>
						{t('search_genres')}: {genre}
					</>
				)}
			</Info>
		</>
	);
};

VodSerialInfo.propTypes = {
	product: PropTypes.shape({
		year: PropTypes.number,
		context: PropTypes.shape({
			duration: PropTypes.number.isRequired
		}),
		genres: PropTypes.array.isRequired
	})
};

export default VodSerialInfo;
