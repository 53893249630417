import React from 'react';
import styled from 'styled-components';

// Import utilities
import useUserAgent from 'components/utilities/hooks/user_agent/useUserAgent';

// Routes
import AppRoutes from 'components/routes/app_routes/AppRoutes';

// Templates
import { AppTemplate } from 'components/templates';

// Layout
import Header from 'components/layout/header/Header';
import Footer from 'components/layout/footer/Footer';

const App = () => {
	const { isMobile } = useUserAgent();

	return (
		<AppTemplate>
			{!isMobile && <Header />}
			<Wrapper>
				<AppRoutes />
			</Wrapper>
			{!isMobile && <Footer />}
		</AppTemplate>
	);
};

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: ${({ theme }) => theme.nav.height}rem;
	min-height: calc(100vh - ${({ theme }) => theme.nav.height}rem);
	z-index: 1;
`;

export default App;
