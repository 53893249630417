import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { Link } from 'react-router-dom';

// Import icons
import { ReactComponent as IconPlay } from 'assets/icons/ic_play.svg';

// Import styles
import { flex } from 'components/styles';

// Import utilities
import { useAuth } from 'components/utilities';

// Import components
import { Button, AuthModal } from 'components/elements';
import Share from './Share';
import Reminder from './Reminder';

const PopoverToolbox = ({
	reminderId,
	linkDetails,
	programId,
	isUpcoming = false,
	isCatchup = false,
	theme,
	startDate
}) => {
	const { isAuth } = useAuth();

	const showReminderButton = false;

	const reminderProps = { reminderId, theme, programId, startDate };
	return (
		<ToolBox>
			{isAuth && (
				<>
					{showReminderButton && isUpcoming && (
						<Box>
							<Reminder {...reminderProps} />
						</Box>
					)}
					{!isUpcoming && (
						<Box isCatchup={isCatchup}>
							<AuthModal>
								<Button as={Link} to={linkDetails}>
									<IconPlayStyled />
								</Button>
							</AuthModal>
						</Box>
					)}
				</>
			)}
			<Box>
				<Share linkDetails={linkDetails} />
			</Box>
		</ToolBox>
	);
};

const ToolBox = styled.div`
	height: 7rem;
	border-top: 1px solid ${({ theme: { popover } }) => popover.border};
	display: flex;
	justify-content: space-between;
`;

const Box = styled.div`
	flex: 1;
	${flex()}

	&:nth-child(2) {
		border-left: 1px solid ${({ theme: { popover } }) => popover.border};
		border-right: 1px solid ${({ theme: { popover } }) => popover.border};
	}

	svg {
		height: 3rem;
		width: 3rem;
	}

	${({ isCatchup }) =>
		isCatchup &&
		css`
			svg {
				g,
				path {
					fill: ${({ theme }) => theme.popover.buttonCatchup};
				}
			}
		`}
`;

const IconPlayStyled = styled(IconPlay)`
	g,
	path {
		fill: ${({ theme: { popover } }) => popover.icon};
	}
`;

PopoverToolbox.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isCatchup: PropTypes.bool,
	startDate: PropTypes.string
};

export default withTheme(PopoverToolbox);
