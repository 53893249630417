import React from 'react';
import { bool, func, number } from 'prop-types';

// COMPONENTS
import PlayController from './PlayController';
import PlayerSeekController from './PlayerSeekController';
import PlayerSkipControllers from './PlayerSkipControllers';
import PlayerTimer from './PlayerTimer';
import ShakaSlider from 'components/views/vod/components/PlayerWrapper/components/ShakaSlider';
import VolumeController from 'components/views/vod/components/PlayerWrapper/player_controllers/VolumeController';
import SettingsController from 'components/views/vod/components/PlayerWrapper/player_controllers/settings_controller/SettingsController';

// Import styles
import {
	Wrapper,
	ControlsContainer,
	SettingsContainer,
	Container
} from 'components/views/vod/components/PlayerWrapper/player_controllers/styles';

export const PlayerControllers = React.memo(
	({
		isFocus,
		isEpisode,
		isNextEpisodeAvailable,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable
	}) => {
		return (
			<Wrapper isFocus={isFocus}>
				<ShakaSlider
					duration={duration}
					updateTime={updateTime}
					currentTimeProgress={currentTimeProgress}
					isPreviewAvailable={isPreviewAvailable}
				/>

				<Container>
					<PlayerTimer />
					<ControlsContainer>
						<PlayerSkipControllers
							isEpisode={isEpisode}
							isNextEpisodeAvailable={isNextEpisodeAvailable}
						>
							<PlayerSeekController>
								<PlayController />
							</PlayerSeekController>
						</PlayerSkipControllers>
					</ControlsContainer>
					<SettingsContainer>
						<VolumeController />
						<SettingsController />
					</SettingsContainer>
				</Container>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerControllers.propTypes = {
	isFocus: bool.isRequired,
	isEpisode: bool.isRequired,
	isNextEpisodeAvailable: bool.isRequired,
	isPreviewAvailable: bool,
	duration: number,
	updateTime: func,
	currentTimeProgress: func
};

export default PlayerControllers;
