import * as types from 'store/actions/types';
import produce from 'immer';

// Import variables
import { STATUS_TYPES } from 'helpers/variables';

const INITIAL_STATE = {
	requestStatus: STATUS_TYPES.loading,
	error: {
		message: '',
		name: '',
		isError: false,
		errorCode: null,
		status: 200
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.CHECK_EMAIL_CONFIRMATION_HASH_LOADING:
				draft.requestStatus = STATUS_TYPES.loading;
				draft.error = INITIAL_STATE.error;
				break;
			case types.CHECK_EMAIL_CONFIRMATION_HASH_SUCCESS:
				draft.requestStatus = STATUS_TYPES.success;
				break;
			case types.CHECK_EMAIL_CONFIRMATION_HASH_ERROR:
				draft.requestStatus = STATUS_TYPES.error;
				draft.error.isError = true;
				draft.error.name = action.payload.data.name || '';
				draft.error.message = action.payload.data.message || '';
				draft.error.status = action.payload.status;
				break;

			default:
				return state;
		}
	});
