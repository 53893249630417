import React from 'react';
import { bool, string } from 'prop-types';

// Import components
import { Available } from 'components/elements';

// Import utilities
import { useAuth } from 'components/utilities';

const AvailableMarks = ({
	isAvailable,
	modifiers = 'default',
	isContrast = false
}) => {
	const { isAuth } = useAuth();

	if (isAvailable) return null;
	return (
		<>
			{isAuth && (
				<Available.Box isAvailable={isAvailable} modifiers={modifiers}>
					{isContrast ? (
						<Available.IconUnavailableContrast modifiers={modifiers} />
					) : (
						<Available.IconUnavailable modifiers={modifiers} />
					)}
				</Available.Box>
			)}
		</>
	);
};

AvailableMarks.propTypes = {
	isAvailable: bool.isRequired,
	modifiers: string,
	isContrast: bool
};

export default AvailableMarks;
