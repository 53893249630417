import styled, { css } from 'styled-components';

// Import assets
import PortalLogo from 'assets/images/logo_big.png';

// Import components
import { Heading } from 'components/elements';

export const Preview = styled.div`
	width: 26rem;
	background: url(${PortalLogo}) 50% /40% auto ${({ theme }) =>
			theme.popover.preview.bg} no-repeat;
`;

export const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
`;

export const PopoverWrapper = styled.div`
	height: 26rem;
	display: flex;
	color: ${({ theme }) => theme.grayMedium};
	overflow: hidden;
	background-color: ${({ theme: { popover } }) => popover.bg};

	${({ dragged }) =>
		dragged &&
		css`
			display: none;
		`};
`;

export const Container = styled.div`
	width: 28rem;
	display: flex;
	flex-direction: column;
`;

export const PlaceholderContainer = styled.div`
	flex: 1;
	padding: 1.5rem;
`;

export const PlaceholderText = styled(Heading.Third)`
	color: ${({ theme }) => theme.grayMedium};
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
