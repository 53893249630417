import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

const PlayerSeekController = ({ children }) => {
	const { updateSeekTime, resetFocus } = useContextProvider();

	const handleSeekTime = (value = true) => () => {
		resetFocus();
		updateSeekTime(value);
	};

	return (
		<>
			<Button
				modifiers={['icon', 'playerIconHover']}
				onClick={handleSeekTime(false)}
			>
				<Player.IconSeekPrev />
			</Button>

			{children}

			<Button
				modifiers={['icon', 'playerIconHover']}
				onClick={handleSeekTime()}
			>
				<Player.IconSeekNext />
			</Button>
		</>
	);
};

PlayerSeekController.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

export default PlayerSeekController;
