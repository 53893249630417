import styled from 'styled-components';

export const TheoPlayerWrapper = styled.div`
	height: 100% !important;
	width: 100% !important;

	.fa-forward,
	.fa-backward {
		&::before {
			font-size: 1.7rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.fa-forward,
	.fa-backward {
		order: -100;
		cursor: pointer;
	}
`;
