import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import { langResources, pathnameLngDetector } from './translations/helpers';

// Import variables
import { LOCAL_STORAGE_LANGUAGE } from 'helpers/variables';
import { projectlanguages } from 'helpers/languages';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(pathnameLngDetector);

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		resources: langResources,
		fallbackLng: 'ar',
		debug: false,
		detection: {
			order: ['querystring', 'pathname', 'localStorage', 'htmlTag'],
			caches: ['localStorage'],
			lookupQuerystring: 'lang',
			lookupFromPathIndex: 0,
			lookupLocalStorage: LOCAL_STORAGE_LANGUAGE,
			supported: projectlanguages
		},
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
