import { useState } from 'react';

/**
 * Small utility hook to toggle UI elements
 * @param {bool} defaultValue - set default visibility = ON / OFF
 * @returns {array} isModalOpened - state property, toggle - toggle ON / OFF function
 */
const useToggle = (initial = false) => {
	const [isModalOpened, setIsModalOpened] = useState(initial);
	const toggle = () => setIsModalOpened((prev) => !prev);

	return [isModalOpened, toggle];
};

export default useToggle;
