import React from 'react';

const ImageOnLoadRPC = ({ children }) => {
	const [isLoadError, setLoadError] = React.useState(false);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const handleSetLoadError = () => setLoadError(true);
	const handleSetLoaded = () => setIsLoaded(true);
	return (
		!isLoadError &&
		children({
			setError: handleSetLoadError,
			isLoaded,
			setLoaded: handleSetLoaded,
			isError: isLoadError
		})
	);
};

export default ImageOnLoadRPC;
