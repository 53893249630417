import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Paragraph } from 'components/elements';

// Import styles
import { transitionMain, rtl } from 'components/styles';
import { Info, Clock } from '../info/styles';

export const SearchItemStyled = styled.li`
	height: 9rem;
	border-top: 1px solid ${({ theme }) => theme.line};
`;

export const Preview = styled.div`
	height: 7rem;
	width: 10rem;
	overflow: hidden;
`;

export const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

export const ChannelImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
`;

export const Content = styled.div`
	flex: 1;
	overflow: hidden;
	${rtl`
    padding-left: 2rem;
  `}
`;

export const ParagraphStyled = styled(Paragraph.Ellipsis)`
	color: ${({ theme }) => theme.grayMedium};
	font-size: 1.5rem;
	${transitionMain}
`;

export const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 1rem 1.5rem;
		${transitionMain}
	}

	&:hover,
	&:active {
		background: ${({ theme: { searchBox } }) => searchBox.itemHoverBg};

		${ParagraphStyled} {
			color: ${({ theme: { searchBox } }) => searchBox.itemTitleHover};
		}

		${Info} {
			color: ${({ theme: { searchBox } }) => searchBox.itemInfoHover};
		}

		${Clock} {
			g {
				fill: ${({ theme: { searchBox } }) => searchBox.itemInfoHover};
				${transitionMain}
			}
			path {
				fill: ${({ theme: { searchBox } }) => searchBox.itemInfoHover};
				${transitionMain}
			}
		}
	}
`;
