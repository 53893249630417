import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const INPUT_MODIFIERS = {};

const baseStyle = css`
	width: 100%;
	height: 4rem;
	padding: 10px 12px;
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.border};
	border-radius: 4px;

	font-size: 14px;
	line-height: 1.28;
	color: ${({ theme }) => theme.white};

	&:focus {
		outline: none;
		border-color: ${({ theme }) => theme.primary};
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) => theme.white};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.red};
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	${applyStyleModifiers(INPUT_MODIFIERS)};
`;

const Input = styled.input`
	${baseStyle}
`;

const Select = styled.select`
	${baseStyle}
`;

export { Input, Select };
