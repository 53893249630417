import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { default as SlickSlider } from 'react-slick';

// Import components
import SliderController from './SliderController';

const Slider = ({ settings, defaultBtn = true, children }) => {
	const options = {
		...settings,
		nextArrow: <SliderController defaultBtn={defaultBtn} type="next" />,
		prevArrow: <SliderController defaultBtn={defaultBtn} type="prev" />
	};

	return <SliderStyled {...options}>{children}</SliderStyled>;
};

const SliderStyled = styled(SlickSlider)`
	position: relative;
`;

Slider.propTypes = {
	settings: PropTypes.object.isRequired,
	defaultBtn: PropTypes.bool,
	children: PropTypes.node.isRequired
};

export default Slider;
