import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

// Import helpers
import { getTVLiveSectionTitle } from '../helpers';
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

// Import utilities
import { useNavList } from 'components/utilities';

// Import components
import { SectionHeader } from 'components/elements';
import TvOnlineItem from './TvOnlineItem';

const { CHANNELS, HOME } = APP_SUB_PATHS_KEY;

const TvOnline = ({ categories, title, id }) => {
	const navList = useNavList(categories);
	const sectionTitle = getTVLiveSectionTitle({ title });

	return (
		<section>
			<SectionHeader
				title={sectionTitle}
				linkToAll={`/tv/${getNavigationSubPaths(CHANNELS)}`}
				path={`/tv/${getNavigationSubPaths(HOME)}`}
				navLinks={navList}
				sectionId={id}
			/>
			<Switch>
				{navList.map((nav) => (
					<Route
						exact
						key={nav.id}
						path={`/tv/${getNavigationSubPaths(HOME)}/${nav.slug}`}
						component={TvOnlineItem}
					/>
				))}
				<Route
					render={() => <Redirect to={`/tv/${getNavigationSubPaths(HOME)}`} />}
				/>
			</Switch>
		</section>
	);
};

TvOnline.propTypes = {
	categories: PropTypes.array,
	title: PropTypes.string.isRequired,
	id: PropTypes.string,
	direction: PropTypes.string.isRequired
};

const mapStateToProps = ({ category: { data } }) => ({
	categories: data
});

export default connect(mapStateToProps)(TvOnline);
