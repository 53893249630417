export const colors = {
	black: '#000',
	white: '#fff',
	red: '#ef2052',
	redSecondary: '#FF0000',
	primaryLight: '#2da7df',
	primary: '#21409a',
	primaryDark: '#182f70',
	blueLightSemi: '#5f76b5',
	blueLight: '#66afe9',
	blueDark: '#072740',
	blueBlack: '#0a101b',
	blueDarkExtra: '#071325',
	blueOcean: '#66b5d6',
	bluePelorus: '#2a89b5',
	blueAzure: '#4197CA',
	blueAzureDark: '#2786BF',
	brown: '#a79d91',
	purpleLight: '#dce3f8',
	turquoiseDark: '#12473f',
	green: '#75aea2',
	grayDarkSemi: '#433e3a',
	blueGray: '#678398',
	grayMain: '#666666',
	grayBorder: '#64686e5e',
	grayLightExtra: '#e5e2e1',
	grayLight: '#ccc',
	graySoft: '#dadada',
	gray: '#79899A',
	grayMediumWhiteTheme: '#697287',
	grayMediumDarkTheme: '#9D9FA3',
	grayDark: '#2a2d39',
	grayBlack: '#433e3b',
	purpleDark: '#403448',
	orange: '#e26745',
	orangeNormal: '#fb7b0e',
	orangeMain: '#EF493C',
	pinkGray: '#926d8c',
	blackLight: 'rgba(0,0,0,0.74)',
	blackSemiTranslucent: 'rgba(0, 0, 0, 0.5)',
	blackPearl: '#0A101A',
	blackGray: '#2d343d',
	whiteSemiTranslucent: 'rgba(255, 255, 255, 0.5)',
	whiteTransparent07: 'rgba(255, 255, 255, 0.7)',
	transparent: 'transparent',
	tvsmart_primary: '#9A6800',
	tvsmart_light: '#FFF369',
	loader: {
		medium: '#2773bc',
		light: '#2da7df'
	},
	alert: {
		border: '#ebccd1',
		bg: '#f2dede'
	},
	footer: {
		height: 6,
		link: '#697287'
	},
	channels: {
		progressbar_width: 20.2
	},
	program_details: {
		row: '#f4f4f6'
	},
	progressbar: {
		grayLight: '#e5e6ea',
		default: '#b1b1b1'
	},
	vod: {
		black: 'rgb(12,18,25)',
		blue: 'rgb(45, 104, 144)',
		btnGlow: {
			blueLight: 'rgb(20, 159, 197)',
			blue: 'rgb(39, 66, 136)'
		},
		font: {
			white: 'rgb(255,255,255)',
			whiteGray: '#ebebeb',
			whiteGrayDark: '#afafaf',
			whiteGrayLight: '#e6e3df'
		}
	},
	player: {
		grayLight: '#aeaeae'
	},
	calendarSelectedDay: '#1890ff',
	promotionPrice: '#F2C919',
	productDetails: {
		noCover: '#2a3155'
	},
	placeholder: '#202027',
	tvsmart: {
		loader: {
			basic: '#9A6800',
			medium: '#EAB036',
			light: '#FFF369'
		},
		epg_progressbar: '#EAB036'
	},
	firebase_auth: {
		facebook: '#4267b2',
		phone: 'rgba(0,0,0,0.4)'
	}
};
