import styled from 'styled-components';

const Catchup = styled.span`
	color: ${({ theme }) => theme.catchup.color};
	background-color: ${({ theme }) => theme.catchup.bg};
	padding: 0.2rem 0.6rem 0.3rem;
	font-size: 1rem;
	line-height: 1;
	font-weight: ${({ theme }) => theme.font.medium};
	border-radius: 3px;
`;

export default Catchup;
