import React from 'react';
import { string, number, oneOfType, array } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as PreviewIcon } from 'assets/icons/ic_vod_preview.svg';

// Import components
import { ButtonRounded } from 'components/elements';

export function PreviewButton({ watchPreview, width, height, modifiers }) {
	const { t } = useTranslation();

	return (
		<ButtonRounded
			type="button"
			onClick={watchPreview}
			width={width}
			height={height}
			title={t('vod_buttons_preview')}
			modifiers={modifiers}
		>
			<PreviewIcon />
		</ButtonRounded>
	);
}

PreviewButton.defaultProps = {
	width: 80,
	height: 80,
	subtype: '',
	redirect: () => {}
};

PreviewButton.propTypes = {
	width: number,
	height: number,
	subtype: string,
	modifiers: oneOfType([string, array])
};
