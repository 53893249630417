import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { checkAppType } from 'helpers';
import { STATIC_DOCUMENT_TYPES } from 'helpers/variables';
import { APP_SUB_PATHS_KEY } from 'helpers/navigationSubPaths';

const { PRIVACY_POLICY, ABOUT_US } = APP_SUB_PATHS_KEY;
const { REGULATION } = STATIC_DOCUMENT_TYPES;

// ******************** FETCH USER'S DEVICES ********************
export const fetchStaticContent = (type, language) => async (dispatch) => {
	try {
		// Dispatch an action
		dispatch({
			type: types.STATIC_CONTENT_LOADING,
			payload: true
		});

		const { isTvSmart } = checkAppType();

		if (isTvSmart && type === REGULATION) {
			type += '_ott';
		}

		if (type === STATIC_DOCUMENT_TYPES.PRIVACY_POLICY) {
			type = PRIVACY_POLICY;
		}

		if (type === STATIC_DOCUMENT_TYPES.ABOUT_US) {
			type = ABOUT_US;
		}

		const url = `/documents/byAlias/${type}`;

		// Get documents
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.STATIC_CONTENT_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.STATIC_CONTENT_ERROR,
			payload: true
		});
	}
};
