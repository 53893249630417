import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import { rtl } from 'components/styles';

export const Item = styled(Link).attrs(({ width, height }) => ({
	style: {
		width,
		height
	}
}))`
	position: relative;
	background: url(${({ image }) => image}) no-repeat center center;
	background-size: cover;
	text-decoration: none;
	${rtl`
		text-align: left;
	`}
	color: ${({ theme }) => theme.banner.color};
	overflow: hidden;

	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 60%;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${({ theme }) => theme.banner.bg};
	}
`;
