import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { linearGradient, linearGradientGray } from 'components/styles';

const PROGRESS_BAR_MODIFIERS = {
	primary: () => css`
		${linearGradient({ deg: 'to left' })}
	`,
	lightGray: ({ theme }) => css`
		background-color: ${theme.progressbar.grayLight};
	`,
	easeGray: () => css`
		background-color: rgba(240, 244, 247, 0.2);
	`,
	white: ({ theme }) => css`
		background-color: ${theme.white};
	`,
	default: () => css`
		${linearGradientGray({ deg: 'to left' })}
	`,
	tvsmart: () => css`
		${linearGradient({
			deg: 'to left',
			mainTheme: 'tvsmart_light',
			secondTheme: 'tvsmart_primary'
		})}
	`
};

const Bar = styled.div`
	display: flex;
	background-color: gray;
	height: 0.2rem;
	width: 100%;
	margin: 1rem 0;

	${applyStyleModifiers(PROGRESS_BAR_MODIFIERS)};
`;
const Current = styled.div`
	flex-basis: ${({ progress }) => progress}%;
	height: 100%;
	transition: all 0.3s ease-in-out;

	${applyStyleModifiers(PROGRESS_BAR_MODIFIERS)};
`;

export { Bar, Current };
