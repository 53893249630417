import * as types from 'store/actions/types';
import services from 'services/services';

export const fetchPacket = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_PACKET_LOADING,
			payload: true
		});

		const { data } = await services.get(`/products/packet/${id}`);

		dispatch({
			type: types.FETCH_PACKET_SUCCESS,
			payload: data
		});
	} catch {
		dispatch({
			type: types.FETCH_PACKET_ERROR,
			payload: true
		});
	}
};
