import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import redux actions
import {
	fetchActiveSubscriptionsList,
	fetchAssignedSubscriptionsList
} from 'store/actions';

// Import variables
import { TRANSACTION_STATUS_TYPES } from 'helpers/variables';

const { pending } = TRANSACTION_STATUS_TYPES;

const useSubscriptions = ({ isLogged }) => {
	const dispatch = useDispatch();

	const active = useSelector(({ activeSubscriptions }) => activeSubscriptions);
	const assigned = useSelector(
		({ assignedSubscriptions }) => assignedSubscriptions
	);

	const isSubscriptionsLoaded = active.isLoaded && assigned.isLoaded;

	useEffect(() => {
		if (isLogged && !isSubscriptionsLoaded) {
			fetchActiveSubscriptionsList()(dispatch);
			fetchAssignedSubscriptionsList()(dispatch);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged]);

	const isSubscriptionPending =
		!assigned.data.length &&
		active.data.find((subscription) => subscription.status === pending);

	const isSubscriptionsLoading = !active.isLoaded || !assigned.isLoaded;

	const subscriptionsData = active.data.concat(assigned.data);

	const isSubscriptions = !!subscriptionsData.length;

	return {
		isSubscriptionsLoading,
		isSubscriptions,
		isSubscriptionPending,
		subscriptionsData
	};
};

export default useSubscriptions;
