import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Heading First
const ELLIPSIS_MODIFIERS = {
	info: ({ theme }) => css`
		color: ${theme.grayMedium};
		min-width: 25rem;
	`
};

const Ellipsis = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};

	${applyStyleModifiers(ELLIPSIS_MODIFIERS)};
`;

const Type = styled.span`
	display: inline-block;
	margin-bottom: 0.6rem;
	font-size: 1.4rem;
	font-weight: ${({ theme, fontWeight = 'normal' }) => theme.font[fontWeight]};
	color: ${({ theme }) => theme.grayMedium};
`;

export { Ellipsis, Type };
