import React from 'react';
import { array, func, number, object, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

//Import hooks
import useSliderItem from 'components/views/vod/components/sliders/components/SliderItem/useSliderItem';

//Import styles
import {
	StyledImage,
	StyledLink
} from 'components/views/vod/components/sliders/components/SliderItem/styles';
import {
	Content,
	Wrap,
	Title
} from 'components/views/vod/components/PlayerWrapper/recommendations/styles';

const RecommendationsItem = ({
	data,
	width,
	height,
	sliderId,
	imageType,
	onClick
}) => {
	const { linkParams, backgroundSize, imgSrc, title } = useSliderItem({
		data,
		sliderId,
		imageType
	});

	const renderAs = onClick ? 'button' : Link;

	return (
		<Content width={width} height={height}>
			<Wrap backgroundSize={backgroundSize}>
				<StyledLink onClick={onClick} to={linkParams} as={renderAs}>
					<StyledImage
						width={width}
						height={height}
						src={imgSrc}
						effect="blur"
						alt={title}
					/>
				</StyledLink>
			</Wrap>

			<Link to={linkParams}>
				<Title>{title}</Title>
			</Link>
		</Content>
	);
};

RecommendationsItem.propTypes = {
	width: number.isRequired,
	height: number.isRequired,
	sliderId: string,
	imageType: string,
	onClick: func,
	data: shape({
		uuid: string.isRequired,
		images: object,
		subtype: string.isRequired,
		context: object,
		title: string,
		year: number,
		genres: array,
		rating: number,
		country: string
	})
};

export default RecommendationsItem;
