import React from 'react';
import PropTypes from 'prop-types';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import VolumeController from './VolumeController';
import SettingsController from './SettingsController';
import PlayController from './PlayController';
import ShakaSlider from 'components/views/vod/components/PlayerWrapper/components/ShakaSlider';
import LiveIndicator from 'components/elements/organisms/player/player_overlay/player_controllers/LiveIndicator';
import CastButton from 'components/elements/organisms/player/player_cast/CastButton';
import { Strap } from 'components/elements';

// Import styles
import { PlayerControllersWrapper } from 'components/elements/organisms/player/player_overlay/player_controllers/styles';
import {
	Container,
	InnerContainer,
	SettingsContainer
} from 'components/views/vod/components/PlayerWrapper/player_controllers/styles';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';

const PlayerControllers = ({ isFocus, isCatchup }) => {
	// Get player methods from Player context
	const {
		isReady,
		isFullScreen,
		seekAvailable,
		duration,
		updateTime,
		currentTimeProgress,
		playerType,
		isPaused,
		isLive
	} = usePlayerTvContext();

	const isMainPlayer = playerType === PLAYER_SELECTORS.PLAYER;

	return (
		isReady && (
			<PlayerControllersWrapper
				isCatchupControllers={isCatchup}
				isFullScreen={isFullScreen}
				isMainPlayer={isMainPlayer}
				isFocus={isFocus}
			>
				<Strap />

				<Container>
					<InnerContainer>
						<LiveIndicator
							isLive={isLive}
							isPaused={isPaused}
							isCatchup={isCatchup}
						/>

						<CastButton />
					</InnerContainer>

					<PlayController />

					{seekAvailable && (
						<ShakaSlider
							duration={duration}
							updateTime={updateTime}
							currentTimeProgress={currentTimeProgress}
							seekAvailable={seekAvailable}
							isCatchup={isCatchup}
						/>
					)}

					<SettingsContainer>
						<VolumeController />
						<SettingsController />
					</SettingsContainer>
				</Container>
			</PlayerControllersWrapper>
		)
	);
};

PlayerControllers.propTypes = {
	isFocus: PropTypes.bool.isRequired,
	isCatchup: PropTypes.bool.isRequired
};

export default PlayerControllers;
