import React from 'react';
import PropTypes from 'prop-types';

const INITIAL_COUNTER_NUMBER = 10;

const CountdownRPC = ({ children, time, isPaused, endFunction }) => {
	const [counter, setCounter] = React.useState(INITIAL_COUNTER_NUMBER);

	React.useEffect(() => {
		setCounter(time);
	}, [time]);

	React.useEffect(() => {
		let countdownTimeout = null;
		if (counter > 0) {
			countdownTimeout = setTimeout(() => setCounter(counter - 1), 1000);
		}

		if (isPaused) {
			clearTimeout(countdownTimeout);
		}

		if (counter === 0 && endFunction) {
			endFunction();
		}

		return () => clearTimeout(countdownTimeout);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counter, isPaused]);

	return children({ counter });
};

CountdownRPC.propTypes = {
	children: PropTypes.func.isRequired,
	time: PropTypes.number.isRequired,
	isPaused: PropTypes.bool.isRequired,
	endFunction: PropTypes.func
};

export default CountdownRPC;
