import { css } from 'styled-components';
import { transform } from 'cssjanus';

// Import variables
import { DIRECTION_TYPES } from 'helpers/variables';

// function that convert given css with janus transform
// rest and spread operator are used to properly pass data (becasue rtl function
// is called by tagged template literals)
export const rtl = (...styles) => (styledComponentsProps) => {
	const cssStyles = css(...styles);
	const direction =
		styledComponentsProps.direction || styledComponentsProps.theme?.direction;
	return direction === DIRECTION_TYPES.RTL
		? transform(cssToString(cssStyles, styledComponentsProps))
		: cssStyles;
};

// Helper function that takes all css styles from styled-components and turns them into string
// It takes functions (if present) written in styled-components and call them and then converts result to string.
// Cssjanus lib needs all css arguments to be type of string
function cssToString(cssStyles, styledComponentsProps) {
	return cssStyles.reduce((result, currentValue) => {
		let addition = currentValue;
		if (typeof currentValue === 'function') {
			const propsFunctionResult = currentValue(styledComponentsProps);
			addition =
				typeof propsFunctionResult === 'string'
					? propsFunctionResult
					: cssToString(propsFunctionResult, styledComponentsProps);
		}
		return result + addition;
	}, '');
}
