import React from 'react';
import { useTranslation } from 'react-i18next';

// Import helpers
import { getUserAgent } from 'helpers';
// Import variables
import { OS_NOT_SUPPORTED, SESSIONSTORAGE_OS_SUPPORT } from 'helpers/variables';

const useSystemSupport = () => {
	const { t } = useTranslation();
	const [isOsSupported, setOsSupported] = React.useState(true);
	const [isMessageAccepted, setSupportMessageAccepted] = React.useState(false);
	const [supportError, setSupportError] = React.useState({
		message: '',
		codeError: ''
	});
	const {
		os: { name: osName }
	} = getUserAgent();

	React.useEffect(() => {
		const osNameLowerCase = osName.toLowerCase();
		const supportStorage = JSON.parse(
			sessionStorage.getItem(SESSIONSTORAGE_OS_SUPPORT)
		);

		const isSupported =
			osNameLowerCase === 'mac os' || osNameLowerCase === 'windows';

		if (!isSupported) {
			const message = `${t(
				'common_os_support_message_beginning'
			)} (${osName}). ${t('common_os_support_message_end')}`;

			setOsSupported(false);
			setSupportError({ message, errorCode: OS_NOT_SUPPORTED });

			if (supportStorage && supportStorage.isMessageAccepted) {
				setSupportMessageAccepted(supportStorage.isMessageAccepted);
			} else {
				sessionStorage.setItem(
					SESSIONSTORAGE_OS_SUPPORT,
					JSON.stringify({
						...supportStorage,
						isMessageAccepted: false
					})
				);
			}
		}

		// eslint-disable-next-line
	}, [isMessageAccepted]);

	const showSupportMessage = !isOsSupported && !isMessageAccepted;

	return {
		showSupportMessage,
		supportError,
		setSupportAccept: setSupportMessageAccepted
	};
};

export default useSystemSupport;
