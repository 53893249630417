import styled from 'styled-components';
import { Button } from 'components/elements';
import { flex } from 'components/styles';
import { getFontFamily } from 'helpers/rtl';

export const StyledWrapper = styled.div`
	position: relative;
	margin: 0 4rem;
	padding: 5rem 0;

	${flex()}
`;

export const AbsoluteWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	z-index: 99;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in-out;
`;

export const Heading = styled(Button)`
	color: ${({ theme }) => theme.player.topHeadline.color};
	font-weight: ${({ theme }) => theme.font.medium};
	font-family: ${({ isRtl }) => getFontFamily(isRtl)} !important;
	font-size: 3rem !important;
	padding: 0;
	right: 50%;
	left: 50%;
`;

export const ButtonsWrapper = styled.div`
	position: absolute;
	right: 0;
	direction: ltr;

	${Button} {
		margin-right: 4rem;

		&:last-child {
			margin-right: 0;
		}
	}

	& svg {
		height: 3rem;
		width: 3rem;
	}
`;
