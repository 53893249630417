import React from 'react';
import { number } from 'prop-types';

// Import styles
import { ContinueWatchingData, Strap } from './styles';

const ProgressBar = ({ continuePercent = 0 }) => (
	<ContinueWatchingData>
		<Strap percent={continuePercent} />
	</ContinueWatchingData>
);

ProgressBar.propTypes = {
	continuePercent: number
};

export default ProgressBar;
