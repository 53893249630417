import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Import helpers
import {
	APP_SUB_PATHS_KEY,
	getNavigationSubPaths
} from 'helpers/navigationSubPaths';

// Import styles
import { Wrapper } from './styles';

// Import components
import { ButtonTransparent } from 'components/elements';

const { PACKETS } = APP_SUB_PATHS_KEY;

const Packets = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleClick = () => {
		history.push(`/${getNavigationSubPaths(PACKETS)}`);
	};

	return (
		<Wrapper>
			<ButtonTransparent
				onClick={handleClick}
				modifiers="primary"
				width={189}
				height={59}
			>
				{t('vod_buttons_check_availability_vod')}
			</ButtonTransparent>
		</Wrapper>
	);
};

export default Packets;
