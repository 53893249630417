import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

//Import assets
import SmallLogo from 'assets/icons/ic_logo_small.svg';

// Import styles
import {
	borderGradientTransparent,
	customMinResponsive,
	flex,
	rtl
} from 'components/styles';

export const CouponInfo = styled.div`
	width: 100%;
	height: 7.1rem;
	border-radius: ${({ theme }) => theme.packets.couponActivation.roundSize}rem;
	${flex()}
	background-image: ${`url(${SmallLogo})`};
	background-repeat: no-repeat;
	background-position: center;

	${({ theme }) =>
		borderGradientTransparent({
			width: 1,
			gradient: theme.packets.couponActivation.border
		})}

	${customMinResponsive(
		1250,
		css`
			grid-column: span 2;
		`
	)}


`;

export const CouponText = styled.p`
	font-size: 1.5rem;
	font-weight: ${({ theme }) => theme.font.semiMedium};
	color: ${({ theme }) => theme.packets.couponActivation.text};
`;

export const CouponButton = styled(Link)`
	cursor: pointer;
	background: none;
	border: none;
	font-size: 1.5rem;
	font-weight: ${({ theme }) => theme.font.semiMedium};
	color: ${({ theme }) => theme.packets.couponActivation.buttonText};
	text-decoration: underline;
	${rtl`margin-left: 1.3rem`};
`;
