// VARIABLES
export const OTHER_PAYMENT_METHODS = 'other_payment_methods';

export const ONE_TIME = 'one_time';

export const RECURRING = 'recurring';

export const GATEWAY_TYPE = 'gateway_type';

export const RECURRING_GATEWAY_TYPE = 'recurring_gateway_type';

export const defaultPriceValues = {
	period: null,
	price: null,
	promotions: null,
	paymentMethod: null
};

export const TRANSACTION_TYPES = {
	PURCHASE: 'purchase',
	RENT: 'rent',
	SUBSCRIPTION: 'subscription'
};

export const SUBSCRIPTION_STATUS_TYPES = {
	ACTIVE: 'active',
	CANCELED: 'canceled',
	PENDING: 'pending'
};

export const GATEWAY_TYPES = {
	CARD: 'card',
	CARD_RECURRING: 'card_recurring',
	BLIK: 'blik',
	BLIK_RECURRING: 'blik_recurring',
	GOOGLE_PAY: 'google_pay',
	GOOGLE_PAY_RECURRING: 'google_pay_recurring',
	APPLE_PAY: 'apple_pay',
	APPLE_PAYRECURRING: 'apple_pay_recurring',
	OTHER_BLUEMEDIA: 'other_bluemedia',
	UNKNOWN: 'unknown'
};

export const PAYMENTS_CURRENCY = {
	USD: 'USD',
	AED: 'AED'
};

export const PAYMENT_METHODS = {
	INVOICE: 'invoice',
	CARD: 'card',
	EXTERNAL: 'external',
	BLUEMEDIA_ONE_TIME: 'bluemedia',
	BLUEMEDIA_SUBSCRIPTION: 'subscription_bluemedia',
	PAYPAL: 'paypal',
	PAYTABS: 'paytabs',
	SUBSCRIPTION_PAYPAL: 'subscription_paypal',
	SUBSCRIPTION_CHECKOUT: 'subscription_checkout',
	SUBSCRIPTION_PAYTABS: 'subscription_paytabs',
	FREE: 'free'
};
const {
	PAYPAL,
	PAYTABS,
	SUBSCRIPTION_PAYPAL,
	SUBSCRIPTION_PAYTABS
} = PAYMENT_METHODS;

export const USED_PAYMENT_METHODS = {
	[SUBSCRIPTION_PAYPAL]: {
		method: SUBSCRIPTION_PAYPAL,
		currency: PAYMENTS_CURRENCY.USD
	},
	[PAYPAL]: {
		method: PAYPAL,
		currency: PAYMENTS_CURRENCY.USD
	},
	[SUBSCRIPTION_PAYTABS]: {
		method: SUBSCRIPTION_PAYTABS,
		currency: PAYMENTS_CURRENCY.AED
	},
	[PAYTABS]: {
		method: PAYTABS,
		currency: PAYMENTS_CURRENCY.AED
	}
};

export const isPaymentIncludeInUsedPaymentMethods = (paymentMethod) =>
	!!USED_PAYMENT_METHODS[paymentMethod];

// HELPERS FUNCTIONS
export const getPrices = ({
	prices: { purchase, rent, subscription },
	method,
	subscriptionMethod
}) => {
	const purchasePrices = purchase && purchase[method];
	const rentPrices = rent && rent[method];
	const subscriptionPrices = subscription && subscription[subscriptionMethod];

	return {
		purchase: purchasePrices ?? defaultPriceValues,
		rent: rentPrices ?? defaultPriceValues,
		subscription: subscriptionPrices ?? defaultPriceValues
	};
};

const getPaymentSubscriptionMethod = ({ subscription, ...rest }) => {
	// return paypal as default
	if (!!subscription?.[SUBSCRIPTION_PAYPAL]) {
		return SUBSCRIPTION_PAYPAL;
	}

	let paymentMethod;

	subscription &&
		Object.keys(subscription).forEach((key) => {
			if (key && isPaymentIncludeInUsedPaymentMethods(key)) {
				paymentMethod = key;
			}
		});

	return paymentMethod;
};

export const filterProductPrices = ({ prices_by_payment_methods }) => {
	const paymentMethod = getPaymentSubscriptionMethod(prices_by_payment_methods);
	const prices = getPrices({
		prices: prices_by_payment_methods,
		method: PAYPAL,
		subscriptionMethod: paymentMethod
	});

	return { ...prices, currency: getPaymentCurrency(paymentMethod) };
};

export const getPaymentCurrency = (paymentMethod = null) => {
	const currency =
		USED_PAYMENT_METHODS?.[paymentMethod]?.currency ?? PAYMENTS_CURRENCY.USD;

	return currency.toLowerCase();
};
