import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive } from 'components/styles';

const GradientBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	${({ isPacket }) =>
		!isPacket &&
		css`
			z-index: 2;
		`}

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		opacity: 0.8;
		background: linear-gradient(
			${({ isRtl }) => (isRtl ? '-45deg' : '45deg')},
			rgba(12, 12, 12, 1) 20%,
			rgba(0, 0, 0, 0) 80%,
			rgba(0, 0, 0, 0) 100%
		);

		${customMinResponsive(
			1271,
			css`
				background: linear-gradient(
					${({ isRtl }) => (isRtl ? '-45deg' : '45deg')},
					rgba(12, 12, 12, 1) 20%,
					rgba(0, 0, 0, 0) 60%,
					rgba(0, 0, 0, 0) 100%
				);
			`
		)}
	}

	&:after {
		background: linear-gradient(
			${({ isRtl }) => (isRtl ? '-1deg' : '1deg')},
			rgba(12, 12, 12, 1) 0%,
			rgba(12, 12, 12, 1) 8%,
			rgba(0, 0, 0, 0) 30%,
			rgba(0, 0, 0, 0) 100%
		);
	}
`;

export default GradientBg;
