import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';
import { Tooltip } from 'antd';

const RegisterFormField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'true',
	form: { touched, errors },
	placeholder,
	tooltipHintText
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';
	return (
		<RegisterFormFieldStyled>
			<Tooltip
				title={tooltipHintText}
				trigger="focus"
				placement="bottomRight"
				overlayClassName="antd-customRegisterTooltip"
			>
				<InputStyled
					className={`${
						touched[field.name] && errors[field.name] ? 'errors' : ''
					}`}
					type={type}
					name={name}
					autoComplete={autoComplete}
					placeholder={placeholder}
					{...field}
				/>
			</Tooltip>
			<FormError isErrorVisible={isErrorVisible}>{errorText}</FormError>
		</RegisterFormFieldStyled>
	);
};

const RegisterFormFieldStyled = styled.div`
	margin-bottom: 1.9rem;
	position: relative;
`;

const InputStyled = styled(Input)`
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};

	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.redSecondary};
		color: ${({ theme }) => theme.redSecondary};

		&:focus {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
				0 0 8px rgba(255, 0, 0, 0.6);
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		-webkit-box-shadow: 0 0 0px 1000px
			${({ theme }) => theme.authentication.contentBg} inset;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const FormError = styled.span`
	position: absolute;
	top: 4rem;
	left: 0;
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: 1rem;
	color: ${({ theme }) => theme.redSecondary};
	text-align: left;
`;

RegisterFormField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	tooltipHintText: PropTypes.string
};

export default RegisterFormField;
