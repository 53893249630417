import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, flex, iconSearch, rtl } from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

// Import components
import { Button } from 'components/elements';

export const FormStyled = styled.form`
	position: relative;
	${flex({ jc: 'space-between', ai: 'normal' })};
	width: 100%;
	padding: 0 1rem;
`;

export const ButtonStyled = styled(Button)`
	padding: 0rem 0.6rem;
`;

export const SearchIconStyled = styled(SearchIcon)`
	${iconSearch(2.4, 2.4, 'white')}
	${rtl`
		margin-right: 0.5rem;
	`}
`;

export const CloseIconStyled = styled(CloseIcon)`
	${iconSearch(1.5, 1.5, 'white')}
`;

export const SearchInputStyled = styled.div`
	flex: 1;
	${rtl`
		border-left: 1px solid ${({ theme }) => theme.navSearch.dashedLine};
		padding-left: 1.5rem;
	`}
`;

export const InputStyled = styled.input`
	width: 100%;
	border: 0;
	border-bottom: 1px solid ${({ theme }) => theme.navSearch.inputBorder};
	padding: 0.7rem 1rem;
	color: ${({ theme }) => theme.navSearch.inputColor};
	font-size: 1.4rem;
	background-color: ${({ theme }) => theme.navSearch.inputBg};

	${customMinResponsive(
		1200,
		css`
			width: 47rem;
		`
	)}

	${customMinResponsive(
		1600,
		css`
			width: 54rem;
		`
	)}

	&:focus {
		outline: none;
		border-bottom: 1px solid ${({ theme }) => theme.navSearch.inputBorderFocus};
	}

	&::placeholder {
		color: ${({ theme }) => theme.navSearch.inputColor};
		font-size: 1.4rem;
	}
`;
