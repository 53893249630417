import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFavourite, addFavourite } from 'store/actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';

// Import icons
import AddToFavourites from 'assets/icons/ic_add_to_favourites.png';
import RemoveFromFavourites from 'assets/icons/ic_remove_from_favourites.png';

// Import components
import { ButtonStyled } from './styles';

const FavouriteButton = ({ channelID }) => {
	const dispatch = useDispatch();
	const favourites = useSelector((store) => store.favourites.data);

	const isFavourite = checkIsFavourite(favourites, channelID);

	return isFavourite ? (
		<ButtonStyled
			onClick={() => deleteFavourite(channelID)(dispatch)}
			isFavourite
		>
			<LazyLoadImage src={RemoveFromFavourites} width={35} />
		</ButtonStyled>
	) : (
		<ButtonStyled onClick={() => addFavourite(channelID)(dispatch)}>
			<LazyLoadImage src={AddToFavourites} width={35} />
		</ButtonStyled>
	);
};

FavouriteButton.propTypes = {
	channelID: PropTypes.string.isRequired
};

export default FavouriteButton;
