import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { favouritesSorter } from './helpers';

export function useLogic(label) {
	const [dataArray, setDataArray] = useState([]);
	const favourites = useSelector(({ favourites }) => favourites);
	const { data: list } = favourites;

	useEffect(() => {
		const sortedData = favouritesSorter(list, label);
		setDataArray(sortedData);

		// eslint-disable-next-line
	}, [JSON.stringify(list)]);

	return { dataArray };
}
