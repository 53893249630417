import React from 'react';
import { string, number, object, array, shape, func, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Import utilities
import useSliderItem from './useSliderItem';

// Import components
import { AvailableMarks } from 'components/elements';
import { DetailsButton } from 'components/views/vod/components/buttons/DetailsButton';
import Preview from 'components/views/vod/components/sliders/Carousel/components/Info/preview/Preview';
import WatchlistButton from 'components/views/vod/components/buttons/WatchlistButton';

// Import styles
import {
	Wrap,
	StyledLink,
	Information,
	Grid,
	MetaItem,
	StyledImage
} from './styles';

const SliderItem = ({
	data,
	sliderId,
	imageType,
	width,
	height,
	onClick,
	isCarousel = false,
	saveSectionId = () => {}
}) => {
	const { isRtl, direction } = useSelector(({ direction }) => direction);

	const {
		isAvailable,
		isCollectionPage,
		linkParams,
		backgroundSize,
		imgSrc,
		channelBackgroundImageSrc,
		isChannel,
		title,
		uuid,
		subtype,
		metadata,
		videoAssetId,
		showWatchlistButton
	} = useSliderItem({ data, sliderId, imageType });

	const renderAs = onClick ? 'button' : Link;

	const handleItemClick = () => (onClick ? onClick() : saveSectionId());

	const imageWidth = isChannel ? '60%' : width;
	const imageHeight = isChannel ? '' : height;

	return (
		<Wrap
			backgroundSize={backgroundSize}
			isChannel={isChannel}
			channelBackgroundImageSrc={channelBackgroundImageSrc}
			showInformation
			width={width}
			height={height}
		>
			<StyledLink onClick={handleItemClick} to={linkParams} as={renderAs}>
				<StyledImage
					width={imageWidth}
					height={imageHeight}
					src={imgSrc}
					effect="blur"
					alt={title}
				/>

				{!isCollectionPage && <AvailableMarks isAvailable={isAvailable} />}
			</StyledLink>
			{!isChannel && (
				<Information className="information" width={width}>
					{isCarousel ? (
						<Grid gap={2.7} dir={direction}>
							{metadata.map((info) => (
								<MetaItem key={info} isRtl={isRtl}>
									{info}
								</MetaItem>
							))}
						</Grid>
					) : (
						<Grid gap={3} dir={direction}>
							<DetailsButton
								title={title}
								uuid={uuid}
								subtype={subtype}
								modifiers="slider"
							/>
							{!!videoAssetId && (
								<Preview
									uuid={uuid}
									videoAssetId={videoAssetId}
									modifiers="slider"
								/>
							)}
							{showWatchlistButton && (
								<WatchlistButton
									uuid={uuid}
									modifiers={['slider', 'sliderWatchList']}
								/>
							)}
						</Grid>
					)}
				</Information>
			)}
		</Wrap>
	);
};

SliderItem.propTypes = {
	width: number.isRequired,
	height: number.isRequired,
	sliderId: string,
	imageType: string,
	onClick: func,
	isCarousel: bool,
	data: shape({
		uuid: string.isRequired,
		images: object,
		subtype: string.isRequired,
		context: object,
		title: string,
		year: number,
		genres: array,
		rating: number,
		country: string
	}),
	saveSectionId: func
};

export default SliderItem;
