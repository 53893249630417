import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import helpers
import { NAV_LINKS } from './nav_helpers';

// Import components
import { NavDropdown } from 'components/elements';
import NavLinks from './NavLinks';
import NavSearch from './nav_search/NavSearch';

const Nav = ({ isMainNavigation, on, toggle }) => {
	return (
		<NavStyled>
			<NavLinks isMainNavigation={isMainNavigation} />
			{on && <NavSearch toggle={toggle} />}
			<NavDropdown isMainNav={true} links={NAV_LINKS} />
		</NavStyled>
	);
};

const NavStyled = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

Nav.propTypes = {
	isMainNavigation: PropTypes.bool
};

Nav.defaultProps = {
	isMainNavigation: false
};

export default Nav;
