import React from 'react';
import PropTypes from 'prop-types';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';

// Import components
import { LanguageDropdown } from 'components/elements';
import NavDropdown from './NavDropdown/NavDropdown';
import ParentalControl from './parental_control/ParentalControl';
import { Favourites } from './favourites/Favourites';
import { Reminders } from './reminders/Reminders';
import Pip from './pip/Pip';
import { NavToolbarStyled, Item, SearchButtonStyled } from './styles';

const showParentalControlButton = false;
const showNotificationButton = false;

const NavToolbar = ({ toggle }) => (
	<NavToolbarStyled>
		<Item>
			<SearchButtonStyled modifiers={['navIcon']} onClick={toggle}>
				<SearchIcon />
			</SearchButtonStyled>
		</Item>

		{showParentalControlButton && (
			<Item>
				<ParentalControl />
			</Item>
		)}

		<Item>
			<Favourites />
		</Item>

		{showNotificationButton && (
			<Item>
				<Reminders />
			</Item>
		)}

		<Item>
			<Pip />
		</Item>

		<Item>
			<LanguageDropdown className="nav-select" />
		</Item>

		<Item>
			<NavDropdown />
		</Item>
	</NavToolbarStyled>
);

NavToolbar.propTypes = {
	toggle: PropTypes.func.isRequired
};

export default NavToolbar;
