import { useSelector } from 'react-redux';

// Import variables
import { LOGIN_SYSTEM_TYPES } from 'helpers/variables';

/**
 * Custom hook that checks what is user source/type (vectra | pureott)
 * This information is crutial when selecting prices and payments methods in transaction
 */
const useUserSourceType = () => {
	const { source } = useSelector(({ auth }) => auth.authenticate);

	const { vectra, pureott } = LOGIN_SYSTEM_TYPES;

	const userSourceType = source;
	const isVectraUser = source === vectra;
	const isNexGenUser = source === pureott;

	return { userSourceType, isVectraUser, isNexGenUser };
};

export default useUserSourceType;
