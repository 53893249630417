export const getVideoEndStatus = ({
	isEpisode,
	isPreview,
	isNextEpisodeAvailable
}) => {
	const isPreviewEnded = !!isPreview;

	const isNextEpisode = isEpisode && isNextEpisodeAvailable;

	const isLastEpisodeEnded = isEpisode && !isNextEpisodeAvailable;

	const isMovieEnded = !isEpisode;

	return { isPreviewEnded, isNextEpisode, isLastEpisodeEnded, isMovieEnded };
};
