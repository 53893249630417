import React from 'react';
import { bool, func } from 'prop-types';

// Import components
import ModalContent from './ModalContent';

// Import styles
import { ModalStyled } from './styles';

const SubscriptionPendingModal = ({ close, on }) => {
	return (
		<ModalStyled
			maskClosable={false}
			width={600}
			closable={true}
			onCancel={close}
			visible={on}
			bodyStyle={{ padding: '56px 20px  40px' }}
		>
			<ModalContent close={close} />
		</ModalStyled>
	);
};

SubscriptionPendingModal.propTypes = {
	close: func.isRequired,
	on: bool.isRequired
};

export default SubscriptionPendingModal;
