import * as types from 'store/actions/types';
import servicesVod from 'services/services';
import { openNotification } from 'components/utilities/notification/notification';
import i18next from 'i18next';

// Import helpers
import { checkErrorStatus } from '../../helpers/error_status';

/**
 * @param {object} filter
 * @param {number} [filter.genre]
 * @param {string} [filter.order]
 * @param {string} [filter.orderDir]
 * @param {function} dispatch
 * @param {string} [sortName]
 */
export function updateMoviesFilter(filter, dispatch, sortName) {
	if (sortName) {
		dispatch({ type: types.UPDATE_SORTING_NAME, payload: sortName });
	}

	dispatch({ type: types.UPDATE_MOVIES_FILTER, payload: filter });
}

/**
 * function responsible for fetching movies and series list and write it to store
 * @param {object} filter
 * @param {number} filter.offset
 * @param {number} filter.limit
 * @param {"title"|"title_org"|"rating"} filter.order
 * @param {"asc"|"desc"} filter.orderDir
 * @param {number|null} filter.genre
 * @param {function} dispatch
 * @param {boolean} clear
 */
export async function fetchMoviesList(
	sectionId = null,
	filter,
	dispatch,
	clear = false,
	isPacket = false,
	subtype = 'vod'
) {
	const { offset, limit, order, orderDir, genre } = filter;
	try {
		dispatch({ type: types.MOVIES_LIST_LOADING });

		if (clear) dispatch({ type: types.MOVIES_LIST_CLEAR });

		const url = isPacket
			? `/products/packet/${sectionId}/products`
			: sectionId
			? `/sections/${sectionId}/content`
			: `/products/vod`;

		let queries =
			order && orderDir
				? `subtype=${subtype}&order=${order}&orderDir=${orderDir}&limit=${limit}&offset=${offset}`
				: `subtype=${subtype}&limit=${limit}&offset=${offset}`;

		if (genre && genre !== 'all') queries = queries + `&genre=${genre}`;

		const {
			data: { data: moviesSeriesList }
		} = await servicesVod.get(url + '?' + queries);

		dispatch({
			type: types.MOVIES_LIST_SUCCESS,
			payload: moviesSeriesList,
			total: moviesSeriesList.length > 0 && moviesSeriesList.length >= 99
		});
	} catch (error) {
		dispatch({ type: types.MOVIES_LIST_ERROR });

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('vod_root_error_title'),
				description: i18next.t('vod_root_error_description')
			});
	}
}

export function clearMoviesListData(dispatch) {
	dispatch({ type: types.MOVIES_LIST_CLEAR_DATA });
}
