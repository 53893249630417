import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import { rtl } from 'components/styles';

export const ButtonStyled = styled(Button)`
	position: absolute;
	${rtl`
    top: 0;
    left: auto;
    right: 0;
  `};

	& > svg {
		height: 2.8rem;
		width: 2.8rem;

		& > path {
			fill: ${({ theme: { sidebar }, isFavourite }) =>
				isFavourite
					? sidebar.favouriteButton.colorActive
					: sidebar.favouriteButton.color};
		}
	}
`;
