import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH USER VOD ACCOUNTS ********************
export const fetchUserVodAccounts = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_USER_VOD_ACCOUNTS_LOADING,
			payload: true
		});

		const { data } = await services.get('/subscriber/addresses');

		dispatch({
			type: types.FETCH_USER_VOD_ACCOUNTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_USER_VOD_ACCOUNTS_ERROR,
			payload: true
		});
	}
};

// ******************** SET USER VOD ACCOUNTS ********************
export const setUserVodAccount = (key) => async (dispatch) => {
	try {
		await services.put('/subscriber/addresses/default', { key });
	} catch (error) {
		dispatch({
			type: types.FETCH_USER_VOD_ACCOUNTS_ERROR,
			payload: true
		});
	}
};
