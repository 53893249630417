// Import helpers
import {
	getImgUrl,
	checkPosterUrl,
	setChannelInverseLogo
} from 'helpers/index';

export const getProductImage = ({
	screenshots,
	images,
	type,
	product,
	channels,
	channelInverse = false,
	isUserProfile = false,
	isSearchItem = false
}) => {
	let isChannelLogo = false;

	// Check image url, check if img's url exists
	let { imgUrl, isImgUrlValid } =
		!isPosterImg(type, isUserProfile) && !isSearchItem
			? getImgUrl({
					screenshots,
					images
			  })
			: checkPosterUrl({ images });

	if (!isImgUrlValid && (type === 'program' || type === 'channel')) {
		const channel = channels.find((item) => item.uuid === product.parent_uuid);

		if (channel) {
			if (channelInverse) {
				const {
					imgUrl: channelImgUrl,
					isImgUrlValid: isChannelImgUrlValid
				} = setChannelInverseLogo(channel.images);
				imgUrl = channelImgUrl;
				isChannelLogo = true;
				isImgUrlValid = isChannelImgUrlValid;
			} else {
				const {
					imgUrl: channelImgUrl,
					isImgUrlValid: isChannelImgUrlValid
				} = getImgUrl({
					images: channel.images
				});
				imgUrl = channelImgUrl;
				isChannelLogo = true;
				isImgUrlValid = isChannelImgUrlValid;
			}
		} else {
			isImgUrlValid = false;
		}
	}

	return { imgUrl, isImgUrlValid, isChannelLogo };
};

const isPosterImg = (type, isUserProfile) => {
	switch (type) {
		case 'vod':
			return !isUserProfile ? false : true;
		case 'program':
		case 'packet':
		case 'channel':
			return false;
		default:
			return true;
	}
};
