import React from 'react';
import { arrayOf, bool, node, oneOfType } from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import redux actions
import { deleteVideoSession } from 'store/actions';

// Import context
import { useContextProvider } from '../useContextProvider';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from 'components/views/vod/helpers/player';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

const playerSelector = PLAYER_SELECTORS.VOD;

const PlayerSkipControllers = ({
	isEpisode,
	isNextEpisodeAvailable,
	children
}) => {
	const { updateTime, duration } = useContextProvider();

	const { getState } = useStore();
	const { episodes } = useSelector(({ movieDetails }) => movieDetails);

	const prev = episodes?.data?.prev || episodes?.prev;
	const next = episodes?.data?.next || episodes?.next;

	const dispatch = useDispatch();
	const { type } = useParams();

	const handlePlayEpisode = async (episode) => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(dispatch, getState);
		const id = episode.uuid;
		// Fetch movie resources
		await fetchMovieResources({ id, type, dispatch, getState });
	};

	const setStartTime = () => updateTime(0);

	const setEndTime = () => updateTime(duration - 10);

	const handleSkipDown = () =>
		isEpisode ? handlePlayEpisode(prev) : setStartTime();

	const handleSkipUp = () =>
		isEpisode ? handlePlayEpisode(next) : setEndTime();

	return (
		<>
			<Button
				disabled={isEpisode && !prev}
				onClick={handleSkipDown}
				modifiers={['icon', 'playerIconHover']}
			>
				<Player.IconPrevious />
			</Button>

			{children}

			<Button
				disabled={isEpisode && (!next || !isNextEpisodeAvailable)}
				onClick={handleSkipUp}
				modifiers={['icon', 'playerIconHover']}
			>
				<Player.IconNext />
			</Button>
		</>
	);
};

PlayerSkipControllers.propTypes = {
	isEpisode: bool.isRequired,
	isNextEpisodeAvailable: bool.isRequired,
	children: oneOfType([arrayOf(node), node])
};

export default PlayerSkipControllers;
