import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

// Import components
import { Alert } from 'components/elements';

const ModalForm = ({
	children,
	closeModal,
	submitAction,
	isError,
	translation,
	isOpen,
	validation,
	initialValues,
	isEnabled,
	errorMessage
}) => {
	const { t } = useTranslation();
	// Create form ref
	const form = React.useRef();

	/*eslint-disable */
	React.useEffect(() => {
		const { resetForm, setSubmitting } = form.current;
		// Reset form if modal is closed
		!isOpen && resetForm();
		// Reset form if modal is closed
		isError && setSubmitting(false);
	}, [isOpen, isError]);
	/*eslint-enable */

	const handleCloseModal = () => {
		// Close modal
		closeModal();
		// Reset form
		form.current.resetForm();
	};

	const handleOnSubmit = async (
		values // Dispatch a submitAction action
	) =>
		await submitAction({
			values,
			isEnabled,
			callback: handleCloseModal
		});

	return (
		<Formik
			ref={form}
			initialValues={initialValues}
			validate={validation}
			onSubmit={handleOnSubmit}
		>
			{({ handleSubmit, isSubmitting }) => (
				<form data-testid="login-form" onSubmit={handleSubmit}>
					{isError && (
						<Alert
							closeBtn={false}
							testid="static-error"
							text={errorMessage || t(`${translation}`)}
						/>
					)}
					{children({ isSubmitting })}
				</form>
			)}
		</Formik>
	);
};

ModalForm.propTypes = {
	closeModal: PropTypes.func.isRequired,
	submitAction: PropTypes.func.isRequired,
	isError: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string,
	isEnabled: PropTypes.bool,
	isOpen: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	validation: PropTypes.func,
	translation: PropTypes.string.isRequired,
	children: PropTypes.func.isRequired
};

const mapStateToProps = (state, { type }) => {
	const { isError, errorMessage } = state[type];
	return {
		isError,
		errorMessage
	};
};

export default connect(mapStateToProps)(ModalForm);
