import axios from 'axios';

// Import helpers
import { getHeaders } from 'helpers/headers';

const myAxiosFactory = () => (appCheckToken) => {
	const headers = getHeaders(appCheckToken);

	return axios.create({
		baseURL: `${process.env.REACT_APP_API_URL}`,
		headers
	});
};

const myAxios = myAxiosFactory();
// Export `myAxios`
export default myAxios;
