import React from 'react';
import { string, object, shape, oneOfType, arrayOf, node } from 'prop-types';
import { Select } from 'antd';

// Import styles
import { FormError, FormFieldStyled } from './styles';

// Import components
import CountryFlag from './CountryFlag';

const SelectField = ({
	field,
	name = 'rating',
	autoComplete = 'true',
	form: { touched, errors },
	children,
	countryCode,
	...rest
}) => (
	<FormFieldStyled isDialCodeField={countryCode}>
		{countryCode && <CountryFlag countryCode={countryCode} />}
		<Select
			style={{ width: '100%' }}
			className={`${touched[field.name] && errors[field.name] ? 'errors' : ''}`}
			autoComplete={autoComplete}
			type="select"
			{...field}
			name={name}
			onChange={(event) => field.onChange(field.name)(event)}
			{...rest}
		>
			{children}
		</Select>
		{touched[field.name] && errors[field.name] && (
			<FormError>{errors[field.name]}</FormError>
		)}
	</FormFieldStyled>
);

SelectField.propTypes = {
	field: object.isRequired,
	name: string.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	autoComplete: string,
	children: oneOfType([arrayOf(node), node]).isRequired,
	countryCode: string
};

export default SelectField;
