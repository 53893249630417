import { POSTAL_CODES_REGEX } from 'helpers/variables';

// check if postal code is valid
export const checkPostalCodeValidate = ({ postal_code, regex }) => {
	return postal_code.match(regex);
};

export const checkPolishPostalCodeValidation = ({ postal_code }) => {
	const isValid = checkPostalCodeValidate({
		postal_code,
		regex: POSTAL_CODES_REGEX.POLAND
	});

	return isValid && postal_code.includes('-') && postal_code.length === 6;
};
