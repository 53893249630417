import React, { createContext } from 'react';

export const AuthenticationContext = createContext();

export default class AuthenticationProvider extends React.Component {
	state = {
		isLoading: false
	};
	// Set loader
	handleLoader = (status) =>
		this.setState((prev) => ({
			...prev,
			isLoading: status
		}));

	render() {
		const { isLoading } = this.state;
		return (
			<AuthenticationContext.Provider
				value={{
					isLoading,
					handleLoader: this.handleLoader
				}}
			>
				{this.props.children}
			</AuthenticationContext.Provider>
		);
	}
}
