import * as types from 'store/actions/types';

const INITIAL_STATE = {
	data: null,
	season: { loading: false, data: {}, error: false },
	episodes: {},
	seriesFocusData: null,
	loading: false,
	error: {
		isError: false,
		message: '',
		errorCode: ''
	}
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case types.MOVIE_DETAILS_LOADING:
			return {
				...state,
				loading: true,
				error: {
					isError: false,
					message: '',
					errorCode: ''
				}
			};

		case types.MOVIE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				error: {
					isError: false,
					message: '',
					errorCode: ''
				},
				data: action.payload
			};

		case types.MOVIE_EPISODES_PREV_NEXT_SUCCESS:
			return {
				...state,
				episodes: action.payload
			};

		case types.MOVIE_DETAILS_ERROR:
			return {
				...state,
				error: {
					isError: true,
					message: action.payload.message,
					errorCode: action.payload.errorCode
				},
				loading: false
			};

		case types.NEXT_EPISODE_TO_WATCH_SUCCESS:
			return {
				...state,
				seriesFocusData: action.payload
			};

		case types.NEXT_EPISODE_TO_WATCH_ERROR:
			return {
				...state,
				seriesFocusData: null
			};

		case types.MOVIE_DETAILS_CLEAR:
			return INITIAL_STATE;
		default:
			return state;
	}
}
