import React from 'react';
import { shape, object, string, bool, number, array, func } from 'prop-types';

// Import helpers
import { createLinkParams } from 'helpers';

// Import components
import { Basic } from 'components/views/vod/components/Info/Basic';
import { DetailsButton } from 'components/views/vod/components/buttons/DetailsButton';
import { FavouriteButton } from 'components/views/vod/components/buttons/FavouriteButton/FavouriteButton';

// Import styles
import {
	TitleContainer,
	Image,
	TitleLink,
	TitleText,
	BasicWrap,
	Description,
	ButtonsWrapper
} from './styles';

// Import logic
import useInfo from './useInfo';

export const Info = ({ currentItem, kids, saveSectionId = () => {} }) => {
	const {
		uuid,
		title,
		subtype,
		hide_title,
		short_desc,
		titleImage,
		basicData,
		showFavouriteButton
	} = useInfo({ currentItem });

	return (
		<>
			<TitleContainer>
				{// Render title image. If image is missing, render title text if it's not deactivated.
				//Otherwise don't render anything
				!!titleImage ? (
					<>
						<Image titleImage={titleImage} />
						<TitleLink
							to={createLinkParams({ id: uuid, type: subtype, title })}
						/>
					</>
				) : (
					!hide_title && (
						<>
							<TitleText>{title}</TitleText>
							<TitleLink
								to={createLinkParams({ id: uuid, type: subtype, title })}
							/>
						</>
					)
				)}
			</TitleContainer>
			<BasicWrap>
				<Basic data={basicData} isCarousel={true} />
				{showFavouriteButton && <FavouriteButton kids={kids} uuid={uuid} />}
			</BasicWrap>
			<Description ellipsis={{ rows: 3, expandable: true }}>
				{short_desc}
			</Description>
			<ButtonsWrapper>
				<DetailsButton
					title={title}
					uuid={uuid}
					subtype={subtype}
					saveSectionId={saveSectionId}
				/>
			</ButtonsWrapper>
		</>
	);
};

Info.propTypes = {
	currentItem: shape({
		images: object,
		uuid: string.isRequired,
		subtype: string.isRequired,
		hide_title: number.isRequired,
		short_desc: string.isRequired,
		rating: number.isRequired,
		year: number,
		context: shape({ duration: number.isRequired }),
		genres: array.isRequired,
		star_rating: number
	}),
	kids: bool.isRequired,
	saveSectionId: func
};
