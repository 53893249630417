import styled from 'styled-components';

// Import styles
import { rtl } from 'components/styles';

export const Wrapper = styled.div`
	flex: 1;
	width: 100%;
	padding: 1rem 0;
`;

export const ListItem = styled.div`
	padding-bottom: 0.2rem;
	${rtl`
    direction: ltr;
  `}
`;
