import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

// Import styles
import { flex, rtl } from 'components/styles';

const Alert = ({
	text,
	testid,
	fixed = false,
	absolute = false,
	action,
	closeBtn = true
}) => {
	const [isVisible, setIsVisible] = useState(true);

	const handleClick = () => {
		if (!!action) {
			action();
			setIsVisible(false);
		} else {
			setIsVisible(false);
		}
	};

	return (
		isVisible && (
			<AlertWrapper absolute={absolute} fixed={fixed} data-testid={testid}>
				<AlertText>{text}</AlertText>
				{closeBtn && (
					<CloseButton onClick={handleClick}>
						<StyledCloseIcon />
					</CloseButton>
				)}
			</AlertWrapper>
		)
	);
};

const AlertWrapper = styled.div`
	background-color: ${({ theme }) => theme.alert.bg};
	border: 1px solid ${({ theme }) => theme.alert.border};
	color: ${({ theme }) => theme.red};
	padding: 1.5rem;
	margin-bottom: 1.8rem;
	border-radius: 4px;
	${flex()}

	${({ fixed }) =>
		fixed &&
		css`
			border: 1px solid ${({ theme }) => theme.red};
			position: fixed;
			top: 8rem;
			left: 50%;
			transform: translateX(-50%);
			width: 62.5rem;
			padding: 2rem;
			z-index: 1000;
		`}
	${({ absolute }) =>
		absolute &&
		css`
			border: 1px solid ${({ theme }) => theme.red};
			position: absolute;
			top: 3rem;
			left: 50%;
			transform: translateX(-50%);
			width: 62.5rem;
			padding: 2rem;
			z-index: 1000;
		`}
`;

const AlertText = styled.span`
	font-size: 1.4rem;
`;

const CloseButton = styled.button`
	border: none;
	background: transparent;
	position: absolute;
	${rtl`
		right: 20px;
	`}
	height: 10px;
	width: 10px;
	pointer-events: auto;
	cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
	width: 100%;
	height: 100%;
	display: block;
	path {
		stroke: ${({ theme }) => theme.red};
	}
`;

Alert.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	testid: PropTypes.string,
	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	action: PropTypes.func
};

export default Alert;
