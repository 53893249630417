import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { getDirection } from 'store/helpers/direction';

const { direction, isRtl } = getDirection();

const INITIAL_STATE = {
	direction,
	isRtl
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** SET DIRECTION ********************
			case types.SET_DIRECTION:
				draft.direction = action.payload;
				return;

			default:
				return state;
		}
	});
