import React from 'react';

// Import components
import { PlayerElements, Button } from 'components/elements';

// Import context
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

const { IconCast, IconCastConnected } = PlayerElements;

const CastButton = () => {
	const { isCasting, isCastAvailable, cast, isReady } = useContextProvider();

	if (!isCastAvailable || !isReady) return null;

	return (
		<Button onClick={cast} modifiers={['icon', 'playerIconHover']}>
			{isCasting ? <IconCastConnected aria-hidden /> : <IconCast aria-hidden />}
		</Button>
	);
};

export default CastButton;
