import { useEffect } from 'react';
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import services from 'services/services';

// Import helpers
import { isNumber } from 'helpers';
import { SECTIONS_TYPES } from 'helpers/variables';
import {
	GET_SECTIONS_LIST_API,
	GET_SECTION_CONTENT_API,
	QUERY_SECTIONS_LIST,
	QUERY_SECTION_CONTENT,
	QUERY_SECTION_LABEL
} from 'helpers/api';

const { VIRTUAL } = SECTIONS_TYPES;

const useSections = (label) => {
	const queryClient = useQueryClient();

	const fetchSectionsList = async () => {
		const url = GET_SECTIONS_LIST_API.replace(':label', label);
		const { data } = await services.get(url);
		return data;
	};

	const fetchSectionContent = async ({ pageParam: index = 0 }) => {
		const section = sections[index];

		if (section) {
			const { id, main_limit } = section;
			const url = GET_SECTION_CONTENT_API.replace(':id', id);

			const {
				data: { data: content }
			} = await services.get(`${url}?offset=0&limit=${main_limit}`);

			return { ...section, content, index };
		}

		return { content: [], index: 0 };
	};

	const getNextPageParam = (prev) => {
		const prevIndex = prev?.index;

		if (isNumber(prevIndex)) {
			const nextIndex = prevIndex + 1;
			const isNextSection = nextIndex < sections.length;
			return isNextSection ? nextIndex : undefined;
		}

		return undefined;
	};

	const select = (data) => ({
		...data,
		pages: data?.pages.filter(
			({ content, type }) => content?.length || type === VIRTUAL
		)
	});

	const {
		data: sections,
		isLoading: isSectionsLoading,
		isError,
		isFetching
	} = useQuery({
		queryKey: `${QUERY_SECTIONS_LIST}_${label}`,
		queryFn: fetchSectionsList,
		enabled: !!label,
		initialData: []
	});

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading: isContentLoading
	} = useInfiniteQuery({
		queryKey: `${QUERY_SECTION_CONTENT}_${label}`,
		queryFn: fetchSectionContent,
		getNextPageParam: getNextPageParam,
		enabled: sections.length > 0,
		select
	});

	useEffect(() => {
		queryClient.setQueryData(QUERY_SECTION_LABEL, label);

		return () => {
			queryClient.removeQueries(QUERY_SECTION_LABEL, { exact: true });
		};
		//eslint-disable-next-line
	}, []);

	const loadMore = () => !isFetchingNextPage && fetchNextPage();

	const sectionsData = data?.pages || [];

	const isSections = sections.length > 0;

	const isLoading = isFetching || isSectionsLoading || isContentLoading;

	return {
		isSections,
		sections,
		sectionsData,
		loadMore,
		hasNextPage,
		isLoading,
		isError
	};
};

export default useSections;
