import React from 'react';
import { Link } from 'react-router-dom';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import paths
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';

// Import components
import { AuthButtonStyled } from './styles';

const { LOGIN } = APP_PATHS_KEY;

const AuthButton = ({ isLink, ...rest }) => {
	const { t } = useTranslation();
	const loginPath = getNavigationPaths(LOGIN);
	const options = isLink ? { as: Link, to: loginPath } : {};

	return (
		<AuthButtonStyled {...options} {...rest} modifiers="default">
			{t('common_auth_modal_login_button')}
		</AuthButtonStyled>
	);
};

AuthButton.defaultProps = { isLink: false };

AuthButton.propTypes = { isLink: bool };

export default AuthButton;
