import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Import helpers
import { checkAppType, getCanonicalLink } from 'helpers';

// Import variables
import { META_LOGIN_LINKS } from 'helpers/variables';

// Import assets
import PortalLogo from 'assets/images/logo_big.png';

const { TV_ONLINE_LOGIN, TV_SMART_LOGIN } = META_LOGIN_LINKS;

const MetaTags = () => {
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const { appName, isTvSmart } = checkAppType();
	const { pathname } = useLocation();

	const applicationName = t(`common_document_head_titles_${appName}`);
	const applicationDescription = t(
		`common_document_head_app_description_${appName}`
	);
	const aplicationKeywords = t('common_document_head_app_keywords');
	const loginPageLink = isTvSmart ? TV_SMART_LOGIN : TV_ONLINE_LOGIN;

	const canonicalLink = getCanonicalLink(pathname, language);

	return (
		<Helmet htmlAttributes={{ lang: language }}>
			<title>{applicationName}</title>
			<link rel="canonical" href={canonicalLink} />

			{/* <!-- for Google --> */}
			<meta name="application-name" content={applicationName} />
			<meta name="keywords" content={aplicationKeywords} />
			<meta name="description" content={applicationDescription} />

			{/* <!-- for Facebook --> */}
			<meta property="og:title" content={applicationName} />
			<meta name="og:keywords" content={aplicationKeywords} />
			<meta property="og:description" content={applicationDescription} />
			<meta property="fb:app_id" content="297551793965166" />
			<meta property="og:url" content={loginPageLink} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={PortalLogo} />

			{/* <!-- for Twitter --> */}
			<meta name="twitter:title" content={applicationName} />
			<meta name="twitter:keywords" content={aplicationKeywords} />
			<meta name="twitter:description" content={applicationDescription} />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:image" content={PortalLogo} />
		</Helmet>
	);
};

export default MetaTags;
