import React from 'react';
import { func, string, array, bool } from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import components
import { DynamicIcon, Loader } from 'components/elements';
import SearchItem from './SearchItem/SearchItem';
import SearchItemChannel from './SearchItemChannel/SearchItemChannel';
import {
	SearchBoxStyled,
	Top,
	Name,
	LinkStyled,
	SearchList,
	EmptyContent
} from './styles';

// Import helpers
import { getNumberOfItems } from './helpers';
import { getAppName } from 'helpers';
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

// Import utilities
import { useResize } from 'components/utilities';

const { SEARCH, ALL } = APP_SUB_PATHS_KEY;

const SearchBox = ({ data, toggle, query, isLoaded, channels }) => {
	const { t } = useTranslation();
	const { viewportHeight } = useResize();

	const isDataEmpty = !data.length;

	const appName = getAppName();
	return (
		<SearchBoxStyled isDataEmpty={isDataEmpty}>
			{!isDataEmpty && (
				<Top>
					<Name>{t(`common_search_title_${appName}`)}</Name>
					{isLoaded && (
						<LinkStyled
							onClick={toggle}
							to={`/${getNavigationSubPaths(
								SEARCH
							)}/${query}/${getNavigationSubPaths(ALL)}`}
						>
							{t('common_search_see_all')}
						</LinkStyled>
					)}
				</Top>
			)}
			<SearchList isLoaded={isLoaded}>
				{data
					.slice(0, getNumberOfItems(viewportHeight))
					.map((item) =>
						item.type === 'channel' ? (
							<SearchItemChannel
								toggle={toggle}
								key={item.uuid}
								product={item}
								channels={channels}
							/>
						) : (
							<SearchItem
								toggle={toggle}
								key={item.uuid}
								product={item}
								channels={channels}
							/>
						)
					)}

				{!isLoaded && (
					<Loader
						width={10}
						height={10}
						zIndex={900}
						position="absolute"
						background={false}
					/>
				)}
			</SearchList>

			{isDataEmpty && (
				<EmptyContent>
					<DynamicIcon icon="ic_sad_smile" width={64} height={64} />
					<p>{t('common_search_empty_content')}</p>
				</EmptyContent>
			)}
		</SearchBoxStyled>
	);
};

SearchBox.propTypes = {
	toggle: func.isRequired,
	query: string.isRequired,
	data: array.isRequired,
	isLoaded: bool.isRequired,
	channels: array.isRequired
};

const mapStateToProps = ({
	search: { data, isLoaded },
	channels: {
		data: { lives: channels }
	}
}) => ({
	data,
	isLoaded,
	channels
});

export default connect(mapStateToProps)(SearchBox);
