import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Import hooks
import { useNoAuthRedirect, useSubscriptions } from 'components/utilities';

// Import components
import { Loader } from 'components/elements';

// Import helpers
import { APP_PATHS_KEY, getNavigationPaths } from 'components/routes/helpers';

const { NON_SUBSCRIBER, USER_ENTRY_TICKETS } = APP_PATHS_KEY;

const ProtectedUserLogged = ({
	isLogged,
	isCouponActivation,
	component: Component
}) => {
	const { redirectPath: pathBeforeLogin } = useNoAuthRedirect();

	const { isSubscriptionsLoading, isSubscriptions } = useSubscriptions({
		isLogged
	});

	const userEntryTicketsPath = getNavigationPaths(USER_ENTRY_TICKETS);
	const nonSubscriberPath = getNavigationPaths(NON_SUBSCRIBER);

	const properPath = isCouponActivation
		? userEntryTicketsPath
		: pathBeforeLogin;

	const redirectPath =
		isSubscriptions || isCouponActivation ? properPath : nonSubscriberPath;

	const isLoading = isLogged && isSubscriptionsLoading;

	if (isLoading) return <Loader />;

	return (
		<Route
			render={(props) =>
				isLogged ? <Redirect to={redirectPath} /> : <Component {...props} />
			}
		/>
	);
};

const mapStateToProps = ({ auth, coupon_activation }) => ({
	isLogged: auth.isLogged,
	isCouponActivation: !!coupon_activation.urlCode
});

export default connect(mapStateToProps)(ProtectedUserLogged);
