import React from 'react';
import styled from 'styled-components';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

const PlayController = () => {
	const { play, pause, isPaused, isFocus } = usePlayerTvContext();

	return isPaused ? (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={play}
			isFocus={isFocus}
		>
			<Player.IconPlay />
		</StyledButton>
	) : (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={pause}
			isFocus={isFocus}
		>
			<Player.IconPause />
		</StyledButton>
	);
};

const StyledButton = styled(Button)`
	margin-right: 1.5rem;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

export default PlayController;
