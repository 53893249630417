import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import {
	transitionMain,
	flex,
	rtl,
	customResponsive,
	borderGradient,
	customMinResponsive
} from 'components/styles';

export const FooterStyled = styled.footer`
	position: relative;
	border-top: 1px solid ${({ theme }) => theme.line};
	height: ${({ theme }) => theme.footer.height}rem;
	background-color: ${({ theme }) => theme.footer.bg};
	color: ${({ theme }) => theme.grayMedium};
	z-index: 0;
`;

FooterStyled.Content = styled.div`
	position: relative;
	height: 100%;
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	font-size: 1.2rem;
	padding: 4rem 6rem 0;

	${customResponsive(
		1440,
		css`
			padding: 4rem 2rem 0;
		`
	)}

	${customResponsive(
		1200,
		css`
			padding: 4rem 1rem 0;
		`
	)}
`;

export const FooterInfoStyled = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	${flex({ fd: 'column', ai: 'center', jc: 'flex-start' })}

	${customResponsive(
		1200,
		css`
			top: 55%;
			left: 50%;
			transform: translateY(-50%, -55%);
		`
	)}
`;

FooterInfoStyled.Copyrights = styled.div`
	color: ${({ theme }) => theme.footer.color};
	opacity: 0.5;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: ${({ theme }) => theme.font.regular};
	direction: ltr;
`;

FooterInfoStyled.Social = styled.div`
	direction: ltr;
	${flex()}
	margin-top: 1.2rem;

	svg {
		height: 4.7rem;
	}

	a {
		margin: 0 0.8rem;
	}
`;

FooterInfoStyled.Anchor = styled.a`
	${flex()}
	width: 4.2rem;
	height: 4.2rem;
	background: ${({ theme }) => theme.footer.socialBg};
	border-radius: 50%;
	${transitionMain()};

	${customResponsive(
		1440,
		css`
			width: 3.4rem;
			height: 3.4rem;
		`
	)}

	&:hover,
	&:focus {
		transform: scale(1.2);
	}

	svg {
		transform: scale(1.2);
	}

	&:nth-of-type(2) {
		svg {
			transform: scale(1.1);
		}
	}
`;

FooterInfoStyled.Version = styled.div`
	${flex()}
	margin-top: 1rem;
	font-size: 1.2rem;
	font-weight: ${({ theme }) => theme.font.regular};
`;

FooterInfoStyled.Text = styled.span`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.footer.color};
	opacity: 0.5;

	&:first-child {
		margin-right: 0.5rem;
		margin-left: 0;

		${({ isRtl }) =>
			isRtl &&
			css`
				margin-left: 0.5rem;
				margin-right: 0;
			`}
	}
`;

export const FooterNavStyled = styled.div`
	${flex({ fd: 'column', ai: 'center', jc: 'flex-start' })}
`;

FooterNavStyled.Items = styled.ul`
	list-style: none;
	display: flex;
	align-items: center;
`;

FooterNavStyled.Banners = styled.div`
	direction: ltr;
	margin-top: 1.5rem;
	display: flex;
	column-gap: 1.2rem;
`;

FooterNavStyled.Banner = styled.div`
	${flex()}
	position: relative;
	width: 11.8rem;
	height: 3.4rem;
	background-color: ${({ theme }) => theme.footer.payment.bg};
	background-image: ${({ icon }) => `url(${icon})`};
	background-repeat: no-repeat;
	background-position: center;
	border-radius: ${({ theme }) => theme.footer.payment.roundSize}rem;

	&:nth-child(1) {
		background-size: 31%;
	}

	&:nth-child(2) {
		background-size: 45%;
	}

	&:nth-child(3) {
		background-size: 57%;
	}

	${customResponsive(
		1440,
		css`
			width: 9.8rem;
			height: 2.8rem;
		`
	)}

	${({ theme }) => css`
		${borderGradient({
			borderSize: 2,
			borderBg: theme.footer.payment.border
		})}
	`}
`;

export const Item = styled.li`
	font-weight: ${({ theme }) => theme.font.bold};
	color: ${({ theme }) => theme.white};
`;

export const Divider = styled.div`
	height: 1.5rem;
	width: 3px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.footer.divider};
	margin: 0 2rem;

	${customResponsive(
		1440,
		css`
			margin: 0 1rem;
		`
	)}
`;

const linkStyle = css`
	&:link,
	&:visited {
		color: ${({ theme }) => theme.footer.link};
		text-decoration: none;
		font-size: 1.2rem;
		line-height: 2.5rem;
		transition: 0.2s ease-in-out;
	}

	&:hover,
	&:active {
		color: ${({ theme }) => theme.white};
	}
`;

export const RouterLink = styled(Link)`
	${linkStyle}
`;

export const LinkStyled = styled.a`
	${linkStyle}
`;

export const FooterStoreStyled = styled.div`
	margin-top: 0.4rem;
	display: flex;
	flex-direction: column;

	${customResponsive(
		1200,
		css`
			${rtl`
				margin-right: 2rem;
			`}
		`
	)}
`;

FooterStoreStyled.Text = styled.span`
	display: block;
	font-weight: ${({ theme }) => theme.font.regular};
	${rtl`
		text-align: left;
	`}
`;

FooterStoreStyled.Items = styled.div`
	margin-top: 1.7rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.2rem;

	${customMinResponsive(
		1440,
		css`
			grid-template-columns: repeat(3, 1fr);
		`
	)}
`;

FooterStoreStyled.Item = styled.a`
	${flex()}
	position: relative;
	width: 11.8rem;
	height: 3.4rem;
	background-color: ${({ theme }) => theme.footer.store.bg};
	border-radius: ${({ theme }) => theme.footer.store.roundSize}rem;

	${customResponsive(
		1440,
		css`
			width: 9.8rem;
			height: 2.8rem;
		`
	)}

	${({ theme }) =>
		borderGradient({
			borderSize: 1,
			borderBg: theme.footer.store.border
		})}


	& > svg {
		height: 85%;
		width: 90%;
	}
`;
