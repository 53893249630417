import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// import helpers
import { initialItem, getPromotion } from './helpers';

// Import utilities
import { useProductPrices } from 'components/utilities';

const useProductPromotion = () => {
	const { data } = useSelector(({ movieDetails }) => movieDetails);
	const userPromotions = useSelector(({ promotions }) => promotions.data);
	const [rentPromotion, setRentPromotion] = useState(initialItem);
	const [purchasePromotion, setPurchasePromotion] = useState(initialItem);
	const [subscriptionPromotion, setSubscriptionPromotion] = useState(
		initialItem
	);

	const productPrices = useProductPrices({ product: data });

	const {
		purchase: { price: purchasePrice, promotions: purchasePromotions },
		rent: { price: rentPrice, promotions: rentPromotions },
		subscription: {
			price: subscriptionPrice,
			promotions: subscriptionPromotions
		}
	} = productPrices;

	React.useEffect(() => {
		const userHasPromotions = userPromotions && userPromotions.length > 0;

		const isVoucher = false;

		if (userHasPromotions) {
			// rent promotions
			if (rentPrice && rentPromotions) {
				const favorablePromotion = getPromotion(
					rentPrice,
					rentPromotions,
					userPromotions,
					isVoucher
				);
				setRentPromotion(favorablePromotion);
			}

			// purchase promotions
			if (purchasePrice && purchasePromotions) {
				const favorablePromotion = getPromotion(
					purchasePrice,
					purchasePromotions,
					userPromotions,
					isVoucher
				);
				setPurchasePromotion(favorablePromotion);
			}

			// purchase promotions
			if (subscriptionPrice && subscriptionPromotions) {
				const favorablePromotion = getPromotion(
					subscriptionPrice,
					subscriptionPromotions,
					userPromotions,
					isVoucher
				);
				setSubscriptionPromotion(favorablePromotion);
			}
		}
		// eslint-disable-next-line
	}, [JSON.stringify(productPrices), userPromotions]);

	return { rentPromotion, purchasePromotion, subscriptionPromotion };
};

export default useProductPromotion;
