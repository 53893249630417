import styled, { css } from 'styled-components';

// Import styles
import {
	Content,
	Title,
	FogBackground
} from 'components/elements/molecules/common_modal/styles';
import { customMinResponsive } from 'components/styles';

// Import helpers
import { FONTS } from 'helpers/variables';

const { NOTO_SANS_REGULAR, NOTO_KUFI_ARABIC } = FONTS;

export const Container = styled(FogBackground)`
	position: absolute;
	background: none;
	width: 100%;
	height: 100%;
	z-index: 98;
`;

export const ContentStyled = styled(Content)`
	width: 90%;
	border-radius: 1.5rem;
	background: ${({ theme }) => theme.common_modal.content.bgError};

	span {
		margin-bottom: 6.8rem;
	}

	${customMinResponsive(
		1240,
		css`
			width: 93.2rem;
			padding: 5rem 2rem;
		`
	)}

	${({ isProgramDetails }) =>
		isProgramDetails &&
		css`
			padding: 2rem 1rem;

			${customMinResponsive(
				1240,
				css`
					width: 90%;
					padding: 5rem 2rem;
				`
			)};
		`}
`;

export const TitleStyled = styled(Title)`
	direction: ltr;
	font-family: ${NOTO_SANS_REGULAR};
	font-weight: ${({ theme }) => theme.font.regular};
	font-size: 3rem;
	line-height: 4.4rem;
	color: ${({ theme }) => theme.semiWhite};
	margin-bottom: 4.8rem;

	${({ isProgramDetails }) =>
		isProgramDetails &&
		css`
			font-size: 2.4rem;
			margin-bottom: 2.8rem;

			${customMinResponsive(
				1240,
				css`
					font-size: 3rem;
					margin-bottom: 4.8rem;
				`
			)}
		`}
`;

export const SecondTitleStyled = styled(Title)`
	direction: rtl;
	font-family: ${NOTO_KUFI_ARABIC};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 2.8rem;
	line-height: 5.3rem;
	color: ${({ theme }) => theme.semiWhite};
	margin-bottom: 2.2rem;

	${({ isProgramDetails }) =>
		isProgramDetails &&
		css`
			font-size: 2.1rem;

			${customMinResponsive(
				1240,
				css`
					font-size: 2.8rem;
				`
			)}
		`}
`;
