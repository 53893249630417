import { useTranslation } from 'react-i18next';

// Import helpers
import { getPacketCover } from './helpers';

// Import utilities
import { useAuth, useSubscriptions } from 'components/utilities';

const usePacketCover = ({ translations, uuid, countryCode }) => {
	const {
		i18n: { language }
	} = useTranslation();

	const { isAuth } = useAuth();

	const { subscriptionsData } = useSubscriptions({ isLogged: isAuth });

	const cover = getPacketCover({
		translations,
		language,
		subscriptionsData,
		uuid,
		countryCode
	});

	return { cover };
};

export default usePacketCover;
