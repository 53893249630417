import React from 'react';
import { array, string } from 'prop-types';

import { Banners as BannersSlider } from 'components/elements';

export function Banners({ items, id }) {
	return <BannersSlider items={items} id={id} />;
}

Banners.propTypes = {
	items: array.isRequired,
	id: string.isRequired
};
