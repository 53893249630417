import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import {
	LiveDot,
	LiveIndicatorText,
	LiveIndicatorWrapper
} from 'components/elements/organisms/player/player_overlay/player_controllers/styles';

const LiveIndicator = ({ isLive, isPaused, isCatchup }) => {
	const { t } = useTranslation();

	const isActive = !(isPaused || isCatchup) && isLive;

	return (
		<LiveIndicatorWrapper>
			<LiveDot isActive={isActive} />{' '}
			<LiveIndicatorText>{t('common_tv_online_live')}</LiveIndicatorText>
		</LiveIndicatorWrapper>
	);
};

LiveIndicator.propTypes = {
	isLive: bool.isRequired,
	isPaused: bool.isRequired,
	isCatchup: bool.isRequired
};

export default LiveIndicator;
