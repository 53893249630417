import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import actions
import {
	setProduct,
	setIsModalOpen,
	setSelected,
	setSelectedEpisodeNumber,
	setIsVodOnlyInPackets,
	setIsPacketsModalOpen
} from '../store/actions';

// Import helpers
import { getSeasonToFocus, getEpisodeToFocus } from '../helpers';
import { TRANSACTION_TYPES } from 'helpers/prices';
import { ROUTES_CORE_PATHS } from 'helpers';

// Import utilities
import { useProductPrices } from 'components/utilities';

const { SUBSCRIPTION } = TRANSACTION_TYPES;

const useInfo = ({
	state: { selected, isModalOpened, isPacketsModalOpen },
	dispatch,
	subtype
}) => {
	const { state } = useLocation();

	// REDUX DATA

	const { data, seriesFocusData } = useSelector(
		({ movieDetails }) => movieDetails
	);

	const seasonData = useSelector(({ tvShowSeason }) => tvShowSeason.data);

	const { previousPath } = useSelector(
		({ previous_location }) => previous_location
	);

	// Product prices
	const {
		subscription: { price: subscriptionPrice, period: subscriptionPeriod }
	} = useProductPrices({ product: data });

	// VARIABLES

	const { seasons, uuid, title } = data;

	const isSubscriptionUpdate =
		state?.isSubscriptionUpdate &&
		previousPath.includes(ROUTES_CORE_PATHS.USER);

	const areSeasons = seasons?.length > 0;

	const isSeries = subtype === 'series';

	const openSubscriptionPayment =
		isSubscriptionUpdate &&
		subscriptionPeriod !== null &&
		subscriptionPrice !== null;

	const episodeToFocus = getEpisodeToFocus({ seriesFocusData, seasonData });

	// HANDLERS

	const handleSeriesFocus = () => {
		const seasonTofocus = getSeasonToFocus({ seriesFocusData, seasons });

		const selected = areSeasons ? seasonTofocus : 0;

		handleSetSelected(selected);
	};

	const handleSetSelected = (value) => {
		// Set selected season
		setSelected(value)(dispatch);
	};

	const handleSetEpisodeNumber = (value) => {
		// function is called in child component to retrieve episode number from slider
		setSelectedEpisodeNumber(value)(dispatch);
	};

	const handleOpenModal = () => setIsModalOpen(!isModalOpened)(dispatch);

	const handleSetProduct = (product) => {
		setProduct(product)(dispatch);
		handleOpenModal();
	};

	const handleOpenPacketsModal = () => {
		setIsPacketsModalOpen(!isPacketsModalOpen)(dispatch);
	};

	const handleSetVodOnlyInPackets = (uuid, subtype) => {
		setIsVodOnlyInPackets({ uuid, subtype })(dispatch);
		handleOpenPacketsModal();
	};

	const handleRedirect = (e) => {
		// Pass this func to price button, and check if user should go to first season tab or redirect to watch url
		const isInfoTabActive = selected === 0;
		if (isInfoTabActive && isSeries && areSeasons) {
			// Prevent default link action
			e.preventDefault();
			// Go to first season tab
			setSelected(1)(dispatch);
		}
	};

	const handleOpenSubsciptionPayment = () => {
		handleSetProduct({
			price: subscriptionPrice,
			period: subscriptionPeriod,
			productName: title,
			uuid,
			isPurchaseRedirect: false,
			promotion: null,
			transactionType: SUBSCRIPTION,
			isSubscriptionUpdate,
			subtype
		});

		handleOpenModal();
	};

	// USE EFFECTS

	useEffect(() => {
		areSeasons && handleSeriesFocus();
		// eslint-disable-next-line
	}, [areSeasons, seriesFocusData]);

	useEffect(() => {
		handleSetEpisodeNumber(episodeToFocus);
		// eslint-disable-next-line
	}, [episodeToFocus]);

	useEffect(() => {
		// open subsciption update payment modal
		openSubscriptionPayment && handleOpenSubsciptionPayment();
		// eslint-disable-next-line
	}, [openSubscriptionPayment]);

	return {
		isSeries,
		areSeasons,
		openModal: handleOpenModal,
		setSelected: handleSetSelected,
		setProduct: handleSetProduct,
		setSelectedEpisodeNumber: handleSetEpisodeNumber,
		redirect: handleRedirect,
		setVodOnlyInPackets: handleSetVodOnlyInPackets,
		openPacketsModal: handleOpenPacketsModal
	};
};

export default useInfo;
