// Import themes
import { pureOttTheme } from 'components/theme/pure_ott_theme/pureOttTheme';
import { wideKhalijiTheme } from 'components/theme/wide_khaliji_theme/wideKhalijiTheme';

// Import variables
import { THEME_NAMES } from 'helpers/variables';

const { PURE_OTT, OTT, TV_ONLINE, WIDE_KHALIJI } = THEME_NAMES;

/**
 * Custom hook that checks for default theme based on provided value or env value.
 * Default pure ott theme is selected
 * Switch values can be added based on specific project
 * @param {string} theme - custom theme name (for future developement)
 */
const useSelectTheme = (theme) => {
	let selectedTheme = pureOttTheme;
	const themeName = theme || process.env.REACT_APP_DEFAULT_THEME;

	switch (themeName) {
		case PURE_OTT:
			selectedTheme = pureOttTheme;
			break;
		case OTT:
			selectedTheme = pureOttTheme;
			break;
		case TV_ONLINE:
			selectedTheme = pureOttTheme;
			break;
		case WIDE_KHALIJI:
			selectedTheme = wideKhalijiTheme;
			break;

		default:
			selectedTheme = pureOttTheme;
			break;
	}

	return selectedTheme;
};

export default useSelectTheme;
