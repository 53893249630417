import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import getSlug from 'speakingurl';
import { useTranslation } from 'react-i18next';

// Import redux actions
import { fetchTvShowSeason } from 'store/actions';

// Import components
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';
import ButtonBack from 'components/views/vod/components/PlayerWrapper/components/Buttons/ButtonBack';
import CastButton from 'components/views/vod/components/buttons/CastButton';

// Import styles
import {
	AbsoluteWrapper,
	ButtonsWrapper,
	StyledWrapper,
	Heading
} from 'components/views/vod/components/PlayerWrapper/TopHeadline/styles';

// Import variables
import { ROUTES_CORE_PATHS } from 'helpers';

// Import helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

// Import utilities
import { useAuth } from 'components/utilities';

const { SERIES } = APP_SUB_PATHS_KEY;

const { TRANSACTION } = ROUTES_CORE_PATHS;

const TopHeadline = React.memo(
	({ isFocus, isError }) => {
		const dispatch = useDispatch();
		const { t } = useTranslation();
		const { isAuth } = useAuth();
		const { pathname: currentPath } = useLocation();
		const history = useHistory();

		const {
			metadata: { title },
			uuid,
			number,
			parent_uuid
		} = useSelector(({ movieDetails }) => movieDetails.data);

		const seasonData = useSelector(({ tvShowSeason }) => tvShowSeason);

		const { previousPath } = useSelector(
			({ previous_location }) => previous_location
		);

		const { isRtl } = useSelector(({ direction }) => direction);

		const { isPreview, closePreview } = useContextProvider();

		const isFromTransaction =
			previousPath.includes(TRANSACTION) || currentPath === previousPath;

		const seriesTranslation = getNavigationSubPaths(SERIES);

		const isEpisode = currentPath.includes(seriesTranslation);
		const type = isEpisode ? 'series' : 'movies';

		const subtypeTranslated = getNavigationSubPaths(type);
		const slugTitle = getSlug(title);

		const redirectUrl = `/vod/${subtypeTranslated}/${uuid}/${slugTitle}`;

		const handleGoBack = () => {
			if (isPreview) {
				closePreview();
			} else {
				isFromTransaction ? history.push(redirectUrl) : history.goBack();
			}
		};

		const isTitleWithEpisodeNumber = isEpisode && !isPreview;

		const seriesTitle = seasonData?.data?.series?.title;

		const titleWithEpisodeNumber = seriesTitle
			? `${seriesTitle} : ${t('vod_movieDetails_episode')} ${number}`
			: `${t('vod_movieDetails_episode')} ${number}`;

		const titleText = isTitleWithEpisodeNumber ? titleWithEpisodeNumber : title;

		useEffect(() => {
			isEpisode &&
				!seasonData.data &&
				fetchTvShowSeason(parent_uuid, isAuth)(dispatch);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<AbsoluteWrapper isFocus={isFocus || isError}>
				<StyledWrapper>
					<Heading onClick={handleGoBack} isRtl={isRtl}>
						{titleText}
					</Heading>
					<ButtonsWrapper>
						<CastButton />
						<ButtonBack handleGoBack={handleGoBack} />
					</ButtonsWrapper>
				</StyledWrapper>
			</AbsoluteWrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

TopHeadline.propTypes = {
	isFocus: PropTypes.bool.isRequired,
	isError: PropTypes.bool.isRequired
};

export default TopHeadline;
