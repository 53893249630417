import React from 'react';
import { useSelector } from 'react-redux';
import { bool, func, shape, string, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Import assets
import { ReactComponent as PlayIcon } from 'assets/icons/ic_vod_play.svg';

// Import styles
import {
	Wrapper,
	Content,
	Info,
	EpisodeNumber,
	IconBasket,
	Button
} from './styles';

// Import utilities
import {
	useProductAvailability,
	useVodProgress,
	useEpisodeBuy,
	useEpisodePromotion,
	useProductPrices
} from 'components/utilities';

// Import components
import {
	AuthModal,
	ButtonRounded,
	SubscriptionPendingModal
} from 'components/elements';
import ProgressBar from 'components/views/vod/components/ProgressBar';

// Import hooks
import useInfoContext from 'components/views/vod/views/ProductDetails/Layout/Info/context/useInfoContext';
import { usePlay } from 'components/utilities';

// import helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

const { SERIES } = APP_SUB_PATHS_KEY;

const Episode = ({
	isActive,
	setSelected,
	resources: { data, width, height, index }
}) => {
	const { setProduct: setProductPayment } = useInfoContext();
	const { t } = useTranslation();
	const { uuid: seriesUuid, available_in: seriesAvailableIn } = useSelector(
		(state) => state.movieDetails.data
	);
	const { uuid: seasonUuid, available_in: seasonAvailableIn } = useSelector(
		(state) => state.tvShowSeason.data
	);

	const {
		title,
		uuid: episodeUuid,
		available_in: episodeAvailableIn,
		number,
		duration
	} = data;

	// Episode prices
	const { purchase: episodePurchase, rent: episodeRent } = useProductPrices({
		product: data
	});

	// check episode availability based on its own availability and its parents availability
	const isSeriesAvailable = useProductAvailability(
		seriesUuid,
		seriesAvailableIn
	);
	const isSeasonAvailable = useProductAvailability(
		seasonUuid,
		seasonAvailableIn
	);
	const isEpisodeAvailable = useProductAvailability(
		episodeUuid,
		episodeAvailableIn
	);

	const isEpisodeAvailableToPlay =
		isSeriesAvailable || isSeasonAvailable || isEpisodeAvailable;
	const isAvailableToBuy = episodePurchase.price || episodeRent.price;
	const location = useLocation();
	const isEpisode = location.pathname
		.split('/')
		.includes(getNavigationSubPaths(SERIES));
	const type = isEpisode ? 'series' : 'movie';

	const handleEpisodeHover = () => setSelected(index);

	const continuePercent = useVodProgress({
		id: episodeUuid,
		duration,
		isEpisode
	});

	const { rentPromotion, purchasePromotion } = useEpisodePromotion(episodeUuid);

	const episodeToBuy = useEpisodeBuy({
		purchasePrice: episodePurchase.price,
		purchasePeriod: episodePurchase.period,
		rentPrice: episodeRent.price,
		rentPeriod: episodeRent.period,
		productName: title,
		uuid: episodeUuid,
		rentPromotion,
		purchasePromotion
	});

	const handleSetProductPayment = () => setProductPayment(episodeToBuy);

	const { onClick, isSubscriptionModal, closeSubscriptionModal } = usePlay({
		type,
		uuid: episodeUuid,
		isAvailable: isEpisodeAvailableToPlay
	});

	/* Temporary buying of selected product is not available */
	const showProductPaymentButton = false;

	return (
		<Wrapper
			onMouseEnter={handleEpisodeHover}
			isActive={isActive}
			width={width}
			height={height}
		>
			<Content isActive={isActive} id={episodeUuid}>
				{isActive && (
					<>
						<AuthModal>
							<ButtonRounded onClick={onClick} width={55} height={55}>
								<PlayIcon width={30} height={27} />
							</ButtonRounded>
						</AuthModal>
						<SubscriptionPendingModal
							on={isSubscriptionModal}
							close={closeSubscriptionModal}
						/>
					</>
				)}

				{showProductPaymentButton && isAvailableToBuy && (
					<Button onClick={handleSetProductPayment}>
						<IconBasket />
					</Button>
				)}

				{!isActive && (
					<Info>
						{number && (
							<EpisodeNumber>
								{t('vod_movieDetails_episode')} {number}
							</EpisodeNumber>
						)}
					</Info>
				)}
				{isEpisodeAvailableToPlay && (
					<ProgressBar continuePercent={continuePercent} />
				)}
			</Content>
		</Wrapper>
	);
};

Episode.propTypes = {
	isActive: bool.isRequired,
	setSelected: func.isRequired,
	resources: shape({
		data: shape({
			title: string.isRequired,
			uuid: string.isRequired,
			prices: shape({
				purchase: object.isRequired,
				rent: object.isRequired
			})
		}),
		width: number.isRequired,
		height: number.isRequired,
		index: number.isRequired
	})
};

export default Episode;
