import React from 'react';
import { string, object, shape, bool } from 'prop-types';

// Import styles
import { FormError, InputStyled, Wrapper } from './styles';

const FirebaseAuthField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'off',
	form: { touched, errors },
	placeholder,
	marginBottom = '1.9rem',
	isDialCodeField = false,
	hideErrors = false,
	disabled = false,
	fieldRef
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';

	return (
		<Wrapper marginBottom={marginBottom} isDialCodeField={isDialCodeField}>
			<InputStyled
				ref={fieldRef}
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={placeholder}
				disabled={disabled}
				{...field}
			/>

			{!hideErrors && (
				<FormError isErrorVisible={isErrorVisible}>{errorText}</FormError>
			)}
		</Wrapper>
	);
};

FirebaseAuthField.propTypes = {
	type: string.isRequired,
	name: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string,
	autoComplete: string,
	marginBottom: string,
	isDialCodeField: bool,
	disabled: bool,
	fieldRef: object
};

export default FirebaseAuthField;
