import styled from 'styled-components';

// Import components
import { ButtonTransparent } from 'components/elements';

export const SkipIntroButton = styled(ButtonTransparent)`
	position: absolute;
	bottom: 12rem;
	right: 10rem;
	z-index: 99;
	font-size: 2.7rem;
	font-weight: ${({ theme }) => theme.font.normal};
`;
