import { useEffect } from 'react';
import i18n from 'i18next';

// Import helpers
import { APP_PATHS_KEY, getNavigationPaths } from 'components/routes/helpers';

const { ERROR_404 } = APP_PATHS_KEY;

const Error404 = () => {
	const { language } = i18n;

	useEffect(() => {
		// We need it for SEO to receive new index.html with status code 404
		window.location.replace(`/${language}${getNavigationPaths(ERROR_404)}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
export default Error404;
