// Import heleprs
import { capitalizeFirstLetter, capitalizeWords } from 'helpers/common';

export const getProductTitle = (str) => {
	const idRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	const id = str.match(idRegex);

	if (id) {
		const arr = str.split('/');
		const productSlug = arr[arr.length - 1];
		const isProductId = productSlug.match(idRegex);

		if (isProductId) {
			return null;
		}

		const [word, ...rest] = productSlug.split('-');
		const wordCapitalize = capitalizeFirstLetter(word);
		return [wordCapitalize, ...rest].join(' ');
	}
	return null;
};

export const getKeywords = (keywords) =>
	keywords.length > 1 ? keywords.join(', ') : keywords[0];

export const getProductData = ({ genres, metadata }) => {
	const { actors, summary_medium: summaryMedium, title } = metadata;
	const genre = genres?.[0].name ?? [];

	const keywords = [genre].concat(actors);

	return {
		summary: summaryMedium,
		title: capitalizeWords(title),
		keywords
	};
};

export const getProgramData = (program) => {
	const {
		title,
		description_long: descriptionLong,
		category,
		credits
	} = program;
	const actor = credits?.actor;

	const getActors = () => actor.map(({ person }) => person);
	const actors = actor && actor.length > 0 ? getActors() : [];

	const keywords = [category].concat(actors);

	return { title: capitalizeWords(title), descriptionLong, keywords };
};
