import NoImageSVG from 'components/views/vod/images/placeholder.svg';

// Import variables
import { IMAGES_TYPES } from './variables';

// Set image url
export const setImgUrl = (url) => {
	if (url && url.match(/^(https:\/\/)/)) {
		return url.split('?')[0];
	} else if (url && url.match(/^(http:\/\/)/)) {
		return url.split('?')[0];
	} else if (url) {
		return `https:${url.split('?')[0]}`;
	}
};

// Check preview img url
export const getImgUrl = ({
	images,
	width = 400,
	height = 350,
	isLive = true,
	onlyCover = false
}) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (images && images.cover && images.cover.length > 0) {
		imgUrl = images.cover[0].url;
	} else if (
		!onlyCover &&
		images &&
		images.slides &&
		images.slides.length > 0 &&
		isLive
	) {
		imgUrl = `${setImgUrl(images.slides[0].url)}${setImgSizePath(
			width,
			height
		)}`;
	} else if (!onlyCover && images && images.logo && images.logo.length > 0) {
		imgUrl = images.logo[0].url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl: imgUrl, isImgUrlValid };
};

// Set channel logo
export const setChannelLogo = (images) => {
	if (images?.logo?.length > 0) return images?.logo?.[0]?.url;
	else if (images?.logo_inverted?.length > 0)
		return images?.logo_inverted?.[0]?.url;
};

export const setValidChannelLogo = (images) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (images?.logo?.length > 0) {
		imgUrl = images.logo[0]?.url;
	} else if (images?.logo_inverted?.length > 0) {
		imgUrl = images.logo_inverted[0]?.url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

export const setChannelInverseLogo = (image) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (image && image.logo_inverted && image.logo_inverted.length > 0) {
		imgUrl = image.logo_inverted[0].url;
	} else if (image && image.logo && image.logo.length > 0) {
		imgUrl = image.logo[0].url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

export const checkPosterUrl = ({
	images,
	slides,
	width = 400,
	height = 350
}) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (images && images.poster && images.poster.length > 0) {
		imgUrl = images.poster[0].url;
	} else if (images && images.cover && images.cover.length > 0) {
		imgUrl = images.cover[0].url;
	} else if (slides) {
		imgUrl = `${setImgUrl(slides)}${setImgSizePath(width, height)}`;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

/**
 * returns poster image or error image
 * @param {object} images
 * @return {*}
 */

const CHANNEL = 'channel';
export function getPosterImage({ images, subtype }) {
	const imagesType =
		subtype === CHANNEL ? IMAGES_TYPES.LOGO : IMAGES_TYPES.POSTER;
	if (
		typeof images === 'object' &&
		!!images &&
		images[imagesType] &&
		images[imagesType].length > 0 &&
		!!images[imagesType][0].url
	) {
		return images[imagesType][0].url;
	}

	return NoImageSVG;
}

// Create image sizePath
export const setImgSizePath = (width = 400, height = 350) =>
	`?srcmode=4&srcw=${width}&srch=${height}&dstw=${width}&dsth=${height}&type=1&quality=80`;

// Check if image is exist
export const isImage = (images, sizeName) =>
	images &&
	images[sizeName] &&
	images[sizeName].length > 0 &&
	images[sizeName][0].url;

export const getImage = ({ images, type }) => {
	return images?.[type]?.[0]?.url || '';
};
