import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import context
import { PlayerSidebarContext } from 'components/context/player/PlayerSidebarProvider';

// Import styles
import { iconSearch, flex } from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

// Import components
import { Button } from 'components/elements';

const SidebarSearch = ({ isOpen, openSearchBox }) => {
	const { t } = useTranslation();
	// Get context
	const { query, setQuery, setCategory } = React.useContext(
		PlayerSidebarContext
	);

	const handleSearchChannel = ({ target: { value } }) => {
		// Set default category
		setCategory();
		// Set search query
		setQuery(value);
	};

	const handleClearSearch = () => {
		// Set reset query
		setQuery();
		// Close search box
		openSearchBox();
	};

	return !isOpen ? (
		<ButtonSearch onClick={openSearchBox}>
			<SearchIconStyled />
		</ButtonSearch>
	) : (
		<SearchBox>
			<InputSearch
				onChange={handleSearchChannel}
				placeholder={t('common_search_placeholder')}
				type="text"
				autoFocus
				value={query}
			/>
			<ButtonClose onClick={handleClearSearch}>
				<ClosehIconStyled />
			</ButtonClose>
		</SearchBox>
	);
};

const SearchBox = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	${flex({ jc: 'space-between' })}
`;

const ButtonStyled = styled(Button)`
	padding: 0rem 0.6rem;
	background-color: transparent;
`;

const ButtonSearch = styled(ButtonStyled)`
	position: absolute;
	top: 1rem;
	right: 0;
`;

const ButtonClose = styled(ButtonStyled)`
	top: 1.5rem;
`;

const SearchIconStyled = styled(SearchIcon)`
	${iconSearch(2.4, 2.4)}
`;

const ClosehIconStyled = styled(CloseIcon)`
	${iconSearch(1.5, 1.5)}
`;

const InputSearch = styled.input`
	flex: 1;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #cccccc45;
	padding: 0.7rem 1rem;
	color: ${({ theme }) => theme.grayMedium};
	font-size: 1.4rem;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${({ theme }) => theme.grayMedium};
		font-size: 1.4rem;
	}
`;

SidebarSearch.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	openSearchBox: PropTypes.func.isRequired
};

export default SidebarSearch;
