export const ERRORS_CODES_TRANSLATIONS = [
	{
		key: 'RESOURCE_NOT_FOUND',
		pl: 'Wybrany materiał nie istnieje.',
		en: "A selected content doesn't exist.",
		ar: 'المحتوى الذي تم إختياره غير متوفر'
	},
	{
		key: 'RESOURCE_NOT_ACTIVE',
		pl: 'Wybrany materiał nie jest dostępny.',
		en: 'A selected content is not available',
		ar: 'المحتوى الذي تم إختياره غير متاح'
	},
	{
		key: 'VIDEO_NOT_FOUND',
		pl: 'Wybrany materiał wideo nie został odnaleziony.',
		en: 'A selected video content was not found.',
		ar: 'الفيديو الذي تم إختياره غير موجود'
	},
	{
		key: 'PROGRAM_NOT_FOUND',
		pl: 'Wybrany program nie został odnaleziony.',
		en: 'A selected TV content was not found',
		ar: ''
	},
	{
		key: 'RESOURCE_NOT_AVAILABLE',
		pl: 'Wybrany materiał nie istnieje.',
		en: "A selected content doesn't exist.",
		ar: 'المحتوى الذي تم إختياره غير متوفر'
	},
	{
		key: 'BLOCKED_BY_PARENTAL_CONTROL',
		pl:
			'Wybrany materiał jest niedostępny ze względu na włączoną kontrolę rodzicielską.',
		en: 'A selected content is not available due to enabled parental control.',
		ar: 'المحتوى الذي تم إختياره غير متوفر بسبب تفعيل الرقابة الاسرية.'
	},
	{
		key: 'RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS',
		pl: 'Wybrany materiał nie znajduje się w pakiecie który subskrybujesz.',
		en: 'Please subscribe to activate this service.',
		ar: 'يتوجب الإشتراك لتفعيل هذه الخدمة'
	},
	{
		key: 'SUBSCRIBER_PARALLEL_STREAMS_LIMIT_EXCEEDED',
		pl:
			'Liczba jednoczesnych sesji wideo dla wybranego materiału został przekroczony. Proszę odczekać chwilę i spróbować ponownie albo zakończyć odtwarzanie na jednym z urządzeń przypisanych do konta.',
		en: 'Subscriber parallel streams limit exceeded',
		ar: 'تم تجاوز حد البث المتوازي للمشترك'
	},
	{
		key: 'SUBSCRIBER_GROUP_STREAMS_LIMIT_EXCEEDED',
		pl:
			'Liczba jednoczesnych sesji wideo dla kanałów pochodzących od nadawcy [nazwa grupy] została przekroczona. Proszę zakończyć odtwarzanie na jednym z urządzeń przypisanych do konta i spróbować ponownie.',
		en: 'Subscriber parallel streams limit exceeded',
		ar: 'تم تجاوز حد البث المتوازي للمشترك'
	},
	{
		key: 'IP_BLACKLISTED',
		pl:
			'Adres IP urządzenia znajduje się poza Polską, nie należy do kraju znajdującego się w obrębie EU albo został zablokowany przez regulatorów. Proszę mieć na uwadze że połączenia przez VPN będą blokowane.',
		en: 'IP blacklisted',
		ar: 'عنوان الإنترنت محظور'
	},
	{
		key: 'PROGRAM_BLACKOUT',
		pl:
			'Ze względów licencyjnych materiał jest niedostępny w Vectra TV Online.',
		en: 'Pogram blacked out',
		ar: 'تم إيقاف البرنامج حاليا'
	},
	{
		key: 'STARTOVER_NOT_ACTIVE_FOR_CHANNEL',
		pl:
			'Ze względów licencyjnych funkcja START OVER jest niedostępna na tym kanale.',
		en: 'Startover is not active for channel',
		ar: 'خدمة البدء من جديد غير متوفرة لهذه القناة'
	},
	{
		key: 'CATCHUP_NOT_ACTIVE_FOR_CHANNEL',
		pl:
			'Ze względów licencyjnych funkcja CATCHUP jest niedostępna na tym kanale.',
		en: 'Catchup is not active for channel',
		ar: 'خدمة شاهد ما فاتك غير متوفرة لهذه القناة'
	},
	{
		key: 'CATCHUP_NOT_AVAILABLE_FOR_PROGRAM',
		pl:
			'Ze względów licencyjnych funkcja CATCHUP jest niedostępna na tym materiale.',
		en: 'Catchup not available for program',
		ar: 'خدمة شاهد ما فاتك غير متوفرة لهذا البرنامج'
	},
	{
		key: 'NPVR_NOT_ACTIVE_FOR_CHANNEL',
		pl:
			'Ze względów licencyjnych materiały z kanału nie mogą zostać nagrane w chmurze.',
		en: 'NPVR is not active for channel',
		ar: 'خدمة تسجيل هذه القتاة على الشبكة غير متوفرة'
	},
	{
		key: 'NPVR_NOT_AVAILABLE_FOR_PROGRAM',
		pl: 'Ze względów licencyjnych materiał nie może zostać nagrany w chmurze.',
		en: 'NPVR is not available for program',
		ar: 'خدمة تسجيل هذا البرنامج على الشبكة غير متوفرة'
	},
	{
		key: 'NOT_AVAILABLE_ON_PLATFORM',
		pl:
			'Ze względów licencyjnych materiał jest niedostępny na platformie [nazwa].',
		en: 'Resource not available on current platform',
		ar: 'هذا المحتوى غير متوفر على المنصة حاليا'
	},
	{
		key: 'MUST_BE_IN_LOCAL',
		pl:
			'Ze względów licencyjnych materiał jest dostępny tylko w sieci domowej.',
		en: 'User must be local',
		ar: 'على المستخدم أن يكون محلياً'
	},
	{
		key: 'STB_CONNECTION_REFUSED',
		pl:
			'Błąd w komunikacji z dekoderem - proszę sprawdzić czy urządzenie jest podłączone do zasilania oraz pracuje w trybie standardowego oszczędzania energii.',
		en: 'Connection refused',
		ar: 'الإتصال مرفوض'
	},
	{
		key: '',
		pl: 'Wystąpił problem z komunikacją z serwerem.',
		en: 'Video session expired',
		ar: 'إنتهت صلاحية عرض المحتوى'
	},
	{
		key: '',
		pl: 'Portal chwilowo niedostępny.',
		en: 'Service temporary unavailable',
		ar: 'الخدمة غير متوفرة حالياً'
	},
	{
		key: 'VIDEO_SESSION_INVALID',
		pl: 'Sesja wideo wygasła - proszę uruchomić program ponownie.',
		en: 'Video session expired',
		ar: 'إنتهت صلاحية عرض المحتوى'
	},
	{
		key: 'LIST_RECORDING_FAILURE_EXCEPTION',
		pl:
			'Błąd podczas pobierania listy nagrań - proszę sprawdzić czy dekoder jest podłączony do zasilania oraz pracuje w trybie standardowego oszczędzania energii.',
		en: 'Error occurred while downloading recording list',
		ar: 'خطأ أثناء تنزيل قائمة التسجيل'
	},
	{
		key: 'ADD_RECORDING_FAILURE_EXCEPTION',
		pl:
			'Błąd podczas zlecania nagrania - proszę sprawdzić czy dekoder jest podłączony do zasilania oraz pracuje w trybie standardowego oszczędzania energii.',
		en: 'Error occurred while ordering a recording',
		ar: 'خطأ أثناء طلب التسجيل'
	},
	{
		key: 'DELETE_RECORDING_EXCEPTION',
		pl:
			'Błąd podczas usuwania nagrania - proszę sprawdzić czy dekoder jest podłączony do zasilania oraz pracuje w trybie standardowego oszczędzania energii.',
		en: 'Error occurred while deleting recording',
		ar: 'خطأ أثناء حذف التسجيل'
	},
	{
		key: 'ACCOUNT_DETAILS_EXCEPTION',
		pl: 'Błąd podczas pobierania szczegółów konta.',
		en: 'Error occurred while downloading user details',
		ar: 'خطأ أثناء تنزيل بيانات المستخدم'
	},
	{
		key: 'AUTHENTICATION_FAILED',
		pl: 'Błąd logowania - problem z autoryzacją.',
		en: 'Authentication error',
		ar: 'خطأ في مصادقة البيانات'
	},
	{
		key: 'BEARER_TOKEN_REQUIRED',
		pl: 'Błąd logowania - brak tokenu.',
		en: 'Authentication error - token required',
		ar: 'خطأ في مصادقة البيانات - الرمز مطلوب'
	},
	{
		key: 'INVALID_API_DEVICE_HEADER',
		pl: 'Niepoprawne dane urządzenia.',
		en: 'Invalid device',
		ar: 'الجهاز غير صالح'
	},
	{
		key: 'INVALID_PIN',
		pl: 'Podany kod PIN jest nieprawidłowy..',
		en: 'Invalid PIN',
		ar: 'الرمز غير صحيح'
	},
	{
		key: 'INVALID_PLATFORM',
		pl: 'Platforma jest nieznana.',
		en: 'Invalid platform',
		ar: 'المنصة غير صالحة'
	},
	{
		key: 'PLATFORM_MISSING',
		pl: 'Platforma nie jest rozpoznawana.',
		en: 'Platform not found',
		ar: 'المنصة غير موجودة'
	},
	{
		key: 'INVALID_UUID',
		pl: 'Niepoprawny UUID. Proszę ponownie wybrać materiał do odtworzenia.',
		en: 'Incorrect UUID. Please select a video again.',
		ar: 'المعرّف الخاص بجهازكم غير صحيح. الرجاء تحديد مقطع الفيديو مرة أخرى'
	},
	{
		key: 'PIN_CANNOT_BE_EMPTY',
		pl: 'Pole kodu PIN nie może być puste.',
		en: 'PIN field cannot be empty',
		ar: 'أدخل الرمز'
	},
	{
		key: 'PIN_WRONG_OLD_PIN',
		pl: 'Wprowadzony oryginalny kod PIN jest nieprawidłowy.',
		en: "Original PIN number isn't correct.",
		ar: 'الرمز الأساسي غير صحيح.'
	},
	{
		key: 'INVALID_PAYMENT_PIN',
		pl: 'Podany kod PIN płatności jest nieprawidłowy.',
		en: "PIN number isn't correct.",
		ar: 'الرمز غير صحيح.'
	},
	{
		key: 'MISSING_EMAIL',
		pl:
			'Aby dokończyć zakup musisz uzupełnić swój adres e-mail w Strefie Klienta.',
		en:
			'To complete this purchase, please add your e-mail address to your account in Client Area.',
		ar:
			'لإكمال هذا الشراء ، يرجى إضافة بريدك الإلكتروني إلى حسابك في القسم المخصص للعميل.'
	},
	{
		key: 'CAST_NOT_ALLOWED',
		pl:
			'Ze względów licencyjnych funkcja CAST jest niedostępna na wybranym materiale.',
		en: 'Cast is not active on this channel',
		ar: 'لا يمكن بث هذا المحتوى إلا على نفس الجهاز'
	},
	{
		key: 'CANNOT_BUY_PRODUCT',
		pl: 'Masz już dostęp do wybranego produktu.',
		en: 'You got back access to the content.',
		ar: 'يمكنك الولوج مجدداً الى المحتوى.'
	},
	{
		key: 'INVALID_PRICE_ENTRY',
		pl:
			'Przepraszamy, obecnie nie można dokonać zakupu. Spróbuj ponownie za chwilę.',
		en: 'Sorry, it is impossible to purchase a product now. Try again later.',
		ar: 'عذرا ، لا يمكن إتمام عملية الشراء. حاول مرة أخرى في وقت لاحق.'
	},
	{
		key: 'NOT_ENOUGH_CREDITS',
		pl: 'Przekroczono ustalony limit wydatków.',
		en: 'Not enough credits',
		ar: 'الرصيد غير كافي'
	},
	{
		key: 'PRICE_ENTRY_OUT_OF_DATE',
		pl:
			'Przepraszamy, obecnie nie można dokonać zakupu. Spróbuj ponownie za chwilę.',
		en: 'Sorry, it is impossible to purchase a product now. Try again later.',
		ar: 'لا يمكن إتمام عملية الشراء. حاول مرة أخرى في وقت لاحق.'
	},
	{
		key: 'SGT_AUTHENTICATION_FAILED',
		pl: 'Błąd autoryzacji.',
		en: 'Authorization error',
		ar: 'خطأ في الترخيص'
	},
	{
		key: 'ADD_RECORDING_CONFLICTS',
		pl:
			'Błąd podczas zlecania nagrania - w tym czasie jest już zlecone nagranie.',
		en:
			'Error occurred while ordering a recording - recording was already ordered.',
		ar: 'حدث خطأ أثناء طلب التسجيل - تم طلب التسجيل بالفعل.'
	},
	{
		key: 'ADD_RECORDING_DISK_FULL',
		pl: 'Błąd podczas zlecania nagrania - brak miejsca na dekoderze.',
		en: 'Error occurred while ordering a recording - no space avialable.',
		ar: 'حدث خطأ أثناء طلب التسجيل - لا يوجد مساحة كافية'
	},
	{
		key: 'ADD_RECORDING_UNKNOWN_ERROR',
		pl: 'Błąd podczas zlecania nagrania - nieznany błąd.',
		en: 'Error occurred while ordering a recording - unknown error.',
		ar: 'حدث خطأ أثناء طلب التسجيل - خطأ غير معروف'
	},
	{
		key: 'ACCOUNT_DETAILS_NOT_FOUND',
		pl:
			'Błąd podczas pobierania szczegółów konta z urządzenia - nie znaleziono konta.',
		en: 'Error occurred while downloading user details - no account found.',
		ar: 'حدث خطأ أثناء تنزيل بيانات المستخدم - لم يتم العثور على حساب.'
	},
	{
		key: 'ACCOUNT_DETAILS_UNKNOWN_ERROR',
		pl: 'Błąd podczas pobierania detalu konta z  urządzenia - nieznany błąd.',
		en: 'Error occurred while downloading user details - unknown error.',
		ar: 'حدث خطأ أثناء تنزيل تفاصيل المستخدم - خطأ غير معروف'
	},
	{
		key: 'DELETE_RECORDING_NOT_FOUND',
		pl: 'Błąd podczas usuwania nagrania - nie znaleziono nagrania.',
		en: 'Error occurred while deleting recording - no recording found',
		ar: 'حدث خطأ أثناء حذف التسجيل - لم يتم العثور على تسجيل'
	},
	{
		key: 'DELETE_RECORDING_WRONG_PIN',
		pl: 'Błąd podczas usuwania nagrania - niepoprawny kod PIN dekodera.',
		en: 'Error occurred while deleting recording -  an incorrect box PIN.',
		ar: 'حدث خطأ أثناء حذف التسجيل - رقم التعريف الشخصي غير صحيح'
	},
	{
		key: 'DELETE_RECORDING_UNKNOWN_ERROR',
		pl: 'Błąd podczas usuwania nagrania - nieznany błąd.',
		en: 'Error occurred while deleting recording - unknown error',
		ar: 'حدث خطأ أثناء حذف التسجيل - خطأ غير معروف'
	},
	{
		key: 'ESALES_SERVICE_UNAVAILABLE',
		pl: 'Błąd podczas połączenia z serwerem autoryzacji zakupów.',
		en:
			'Error occurred while trying to connect with payment authorization system',
		ar: 'حدث خطأ أثناء التواصل مع منفذ الشراء'
	},
	{
		key: 'ESALES_ADDRESS_NOT_FOUND_EXCEPTION',
		pl:
			'Brak danych użytkownika Aby dokończyć zakup musisz uzupełnić swój adres w Strefie Klienta',
		en:
			'No user data. To complete this purchase, please add your address to your account in Client Area.',
		ar:
			'بيانات المستخدم غير موجودة. لإكمال هذا الشراء، يرجى إضافة عنوان بريدك الإلكتروني إلى حسابك في منطقة العميل.'
	},
	{
		key: 'ESALES_EMAIL_NOT_FOUND_EXCEPTION',
		pl:
			'Brak adresu e-mail Aby dokończyć zakup musisz uzupełnić swój adres e-mail w Strefie Klienta',
		en:
			'No user data. To complete this purchase, please add your e-mail address to your account in Client Area.',
		ar:
			'بيانات المستخدم غير موجودة. لإكمال هذا الشراء، يرجى إضافة عنوان بريدك الإلكتروني إلى حسابك في منطقة العميل.'
	},
	{
		key: 'ESALES_OFFER_NOT_AVAILABLE',
		pl: 'Nie można pobrać szczegółów oferty.',
		en: "We couldn't upload this offer's details.",
		ar: 'لم نتمكن من تحميل تفاصيل هذا العرض.'
	},
	{
		key: 'CANNOT_DETERMINE_SYSTEM',
		pl:
			'Błąd autoryzacji. Spróbuj ponownie za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'ESALES_AUTHENTICATION_FAILED_EXCEPTION',
		pl: 'Przepraszamy, brak ofert dla użytkownika.',
		en: 'Sorry, no offers available.',
		ar: 'عذرا ، لا توجد عروض متاحة.'
	},
	{
		key: 'ESALES_DEVICE_NOT_FOUND',
		pl:
			'Błąd autoryzacji. Spróbuj ponownie za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'ESALES_CUSTOMER_NOT_FOUND_EXCEPTION',
		pl:
			'Błąd autoryzacji. Spróbuj ponownie za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'INVALID_SERIAL_NUMBER',
		pl:
			'Błąd autoryzacji. Spróbuj ponownie za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'INVALID_ACCESS_KEY',
		pl:
			'Błąd autoryzacji. Spróbuj ponownie za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'RECORDING_MAX_TIME_AVAILABLE_EXCEEDED_EXCEPTION',
		pl:
			'Nie udało się zlecić nagrania. Sprawdź ilość nagrań i usuń niepotrzebne.',
		en:
			'Recording not added. Please check current recordings list and remove unnecessary recording.',
		ar:
			'التسجيل غير مضاف. يرجى مراجعة قائمة التسجيلات الحالية وإزالة التسجيلات الغير ضرورية.'
	},
	{
		key: 'INVALID_PARENTAL_CONTROL_PIN',
		pl: 'PIN nieprawidłowy.',
		en: 'Incorrect PIN',
		ar: 'رقم التعريف الشخصي غير صحيح'
	},
	{
		key: 'INVALID_PROFILE_ID_EXCEPTION',
		pl: 'Nie można usunąć profilu, na którym obecnie się znajdujesz.',
		en: 'It is impossible to delete a profile which you are currently using.',
		ar: 'لا يمكنك حذف ملف التعريف الذي تستخدمه حاليًا.'
	},
	{
		key: 'DEVICE_AUTHENTICATION_FAILED',
		pl:
			'Błąd autoryzacji. Spróbuj za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في الترخيص. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'INVALID_PRODUCT_TYPE',
		pl:
			'Wystąpił błąd. Spróbuj za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'Authorization error. Try again later or contact our Customer Service.',
		ar: 'خطأ في التفويض. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'NDVR_CONNECTION_REFUSED',
		pl:
			'Błąd w komunikacji z dekoderem - proszę sprawdzić czy urządzenie jest podłączone do zasilania oraz  pracuje w trybie standardowego oszczędzania energii.',
		en: 'Error occurred while deleting recording',
		ar: 'حدث خطأ أثناء حذف التسجيل'
	},
	{
		key: 'SUBSCRIBER_SUSPENDED_EXCEPTION',
		pl:
			'Twoje konto jest zablokowane. Aby kontynuować skontaktuj się z Biurem Obsługi Klienta.',
		en:
			'Your account is blocked. To continue, please contact our Customer Service.',
		ar: 'تم حظر حسابك. للمتابعة ، يرجى الاتصال بخدمة العملاء.'
	},
	{
		key: 'NO_ACCESS_TO_SYSTEM_EXCEPTION',
		pl:
			'Wystąpił błąd. Spróbuj za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'An error occurred. Try again later or contact our Customer Service.',
		ar: 'حدث خطأ. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'CANNOT_USE_PROMOTION',
		pl: 'Ta promocja jest niedostępna.',
		en: 'This promotion is not available.',
		ar: 'هذا العرض غير متوفر.'
	},
	{
		key: 'INVALID_PROMOTION_CODE',
		pl: 'Podany kod nie istnieje. Sprawdź czy podany kod nie zawiera błędu.',
		en: "PIN doesn't exist. \nCheck if an entered PIN is correct.",
		ar:
			'رقم التعريف الشخصي غير موجود.تحقق مما إذا كان رقم التعريف الشخصي الذي تم إدخاله صحيحًا.'
	},
	{
		key: 'RESOURCE_ALREADY_EXISTS',
		pl:
			'Wystąpił problem z komunikacją z serwerem. Spróbuj ponownie za chwilę.',
		en: 'We have encountered an error. Try again later',
		ar: 'لقد واجهنا خطأ. حاول مرة أخرى في وقت لاحق'
	},
	{
		key: 'PUREOTT_SUBSCRIBER_ACCOUNT_SUSPENDED',
		pl:
			'Twoje konto jest zablokowane. Aby kontynuować skontaktuj się z Biurem Obsługi Klienta.',
		en:
			'Your account is blocked. To continue, please contact our Customer Service.',
		ar: 'تم حظر حسابك. للمتابعة ، يرجى الاتصال بخدمة العملاء.'
	},
	{
		key: 'PUREOTT_SUBSCRIBER_WRONG_CREDENTIALS',
		pl: 'Błędny login lub hasło. Spróbuj jeszcze raz lub zresetuj hasło.',
		en: 'Incorrect login or password. \nCheck if an entered PIN is correct.',
		ar:
			'تسجيل الدخول أو كلمة سر خاطئة. تحقق مما إذا كان رقم التعريف الشخصي الذي تم إدخاله صحيحًا.'
	},
	{
		key: 'PUREOTT_SUBSCRIBER_EMAIL_APPROVE_EXPIRED',
		pl:
			'Wiadomość wysłana w celu potwierdzenia adresu  email podanego przy rejestracji wygasła.',
		en:
			'The message sent to confirm the email address provided during registration has expired',
		ar:
			'انتهت صلاحية الرسالة المرسلة لتأكيد عنوان البريد الإلكتروني المقدم أثناء التسجيل'
	},
	{
		key: 'PUREOTT_SUBSCRIBER_NOT_FOUND',
		pl: 'Konto nie istnieje.',
		en: "Account doesn't exist.",
		ar: 'الحساب غير موجود.'
	},
	{
		key: 'UNPROVISIONED_DEVICE',
		pl:
			'To urządzenie nie jest powiązane z Twoim kontem. Skontaktuj się z Biurem Obsługi Klienta.',
		en:
			'This device is not associated with your account. Please contact our Customer Service.',
		ar: 'هذا الجهاز غير مرتبط بحسابك. يرجى الاتصال بخدمة العملاء لدينا.'
	},
	{
		key: 'NOSERVICE_DEVICE',
		pl:
			'Brak informacji o urządzeniu. Spróbuj za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'No device data. Try again later or contact our Customer Service.',
		ar: 'بيانات الجهاز غير موجودة. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'UNKNOWN_DEVICE',
		pl:
			'Brak informacji o urządzeniu. Spróbuj za chwilę lub skontaktuj się z Biurem Obsługi Klienta.',
		en: 'No device data. Try again later or contact our Customer Service.',
		ar: 'بيانات الجهاز غير موجودة. حاول مرة أخرى لاحقًا أو اتصل بخدمة العملاء.'
	},
	{
		key: 'PARTNER_ERROR',
		pl: 'Usługa czasowo niedostępna. Przepraszamy za utrudnienia.',
		en: 'Service temporarily unavailable. We apologize for any inconvenience.',
		ar: 'الخدمة غير متاحة مؤقتا. نعتذر عن أي إزعاج.'
	},
	{
		key: 'PADI_TOKEN_SERVICE_UNAVAILABLE',
		pl: 'Usługa czasowo niedostępna. Przepraszamy za utrudnienia.',
		en: 'Service temporarily unavailable. We apologize for any inconvenience.',
		ar: 'الخدمة غير متاحة مؤقتا. نعتذر عن أي إزعاج.'
	},
	{
		key: 'OPERATORS_PASSWORD_RESET_LINK_EXPIRED_EXCEPTION',
		pl: 'Link wygasł. Aby zresetować hasło wygeneruj link ponownie.',
		en: 'Link expired. To reset your password, generate a new link.',
		ar:
			'انتهت صلاحية الرابط. لإعادة تعيين كلمة المرور الخاصة بك ، قم بإنشاء ارتباط جديد.'
	},
	{
		key: 'DEVICE_LIMIT_EXCEEDED_EXCEPTION',
		pl: 'Osiągnięto limit urządzeń.',
		en: 'Device limit exceeded',
		ar: 'تم تجاوز عدد الأجهزة المسموح بها'
	},
	{
		key: 'DEVICE_CHANGES_LIMIT_EXCEEDED_EXCEPTION',
		pl: 'Maksymalna liczba zmian urządzeń w miesiącu została przekroczona.',
		en: 'A device change limit has been exceeded for this month.',
		ar: 'تم تجاوز الحد المسموح لتعديل الأجهزة لهذا الشهر.'
	},
	{
		key: 'SUBSCRIBER_LOGIN_CODE_EXPIRED',
		pl: 'Kod nie jest już aktywny. Aby się zalogować wygeneruj kod ponownie.',
		en: 'Login code is not active anymore. To log in, generate a new one.',
		ar: 'رمز الدخول لم يعد سارياً. لتسجيل الدخول، قم بإنشاء رمز جديد.'
	},
	{
		key: 'CANNOT_OVERWRITE_SUBSCRIBER_ID',
		pl: 'Nie można nadpisać danych dla urządzenia.',
		en: 'It is impossible to overwrite data for this device.',
		ar: 'لا يمكن إستبدال البيانات لهذا الجهاز.'
	},
	{
		key: 'PUREOTT_SUBSCRIBER_EMAIL_NOT_CONFIRMED',
		pl: 'Twój e-mail nie został potwierdzony.',
		en: 'Your e-mail address has not been confirmed.',
		ar: 'لم يتم تأكيد عنوان البريد الإلكتروني الخاص بك.'
	},
	{
		key: 'INVALID_SUBSCRIBER_SOURCE_EXCEPTION',
		pl: 'Przepraszamy, nie można wykonać polecenia.',
		en: 'Sorry, it is impossible to complete this operation.',
		ar: 'عذراً، لا يمكن إكمال هذه العملية.'
	},
	{
		key: 'INVALID_ARGUMENT',
		pl: '',
		en: '',
		ar: ''
	},
	{
		key: 'INVALID_SUBSCRIBER_LOGIN_CODE_EXCEPTION',
		pl: 'Logowanie nie powiodło się. Podany kod jest nieprawidłowy.',
		en: 'Unsuccessful login operation. Entered code is not correct.',
		ar: 'عملية تسجيل دخول غير ناجحة. الكود الذي تم إدخاله غير صحيح.'
	},
	{
		key: 'INVALID_ORDER_ID_EXCEPTION',
		pl: '',
		en: '',
		ar: ''
	},
	{
		key: 'COUNTRY_ACCESS_DENIED',
		pl: '',
		en: 'Sorry, this content is not available in your region.',
		ar: 'عذراً، هـــــذا المحتوى غير متوفر للعرض في منطقتكم.'
	},
	{
		key: 'auth/too-many-requests',
		pl: '',
		en:
			'Unable to log in from the mobile number, please try again using the other available options.',
		ar:
			'تعذر الدخول من رقم الجوال, يرى إعادة المحاولة من خلال الخيارات الأخرى المتوفرة'
	},
	{
		key: 'auth/invalid-phone-number',
		pl: '',
		en:
			'Invalid Entry! key in accurate phone number without extra characters or missing digits.',
		ar: 'إدخال خاطئ! أدخل رقم هاتف صحيح بدون أحرف إضافية أو أرقام مفقودة.'
	}
];
