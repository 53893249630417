import React from 'react';
import { func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Title, Subtitle } from '../styles';
import { Item } from './styles';

// Import helpers
import { createLinkParams } from 'helpers';
import { getBannerUrl } from './helpers';
import { coverImage } from 'components/views/vod/helpers/coverImage';

// Import components
import NoImage from 'components/views/vod/images/placeholder.svg';

// Import hooks
import { useProductTranslations } from 'components/utilities';

export const BannersItem = ({
	width,
	height,
	data,
	saveSectionId = () => {}
}) => {
	const { t } = useTranslation();

	const { product } = useProductTranslations({ data });

	const { images, title, context, subtype, uuid } = product;

	// remove domain name from url
	let url = context?.url_pc ? getBannerUrl(context.url_pc) : '';

	const isBanner = subtype === 'banner';

	const link = isBanner
		? url.replace(/^.*\/\/[^/]+/, '')
		: createLinkParams({ id: uuid, type: subtype, title });

	return (
		<Item
			to={link}
			onClick={saveSectionId}
			image={coverImage(images) || NoImage}
			width={width}
			height={height}
		>
			<Title>{title}</Title>
			<Subtitle>
				{t('vod_section_category_subtytle')} <span>&#8594;</span>
			</Subtitle>
		</Item>
	);
};

BannersItem.propTypes = {
	width: number.isRequired,
	height: number.isRequired,
	data: object.isRequired,
	saveSectionId: func
};
