import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as IconClock } from 'assets/icons/ic_clock.svg';

// Import helpers
import { createLinkParams, convertTime } from 'helpers/index';

// Import styles
import { iconClock, rtl } from 'components/styles';

// Import components
import { Heading, Catchup } from 'components/elements';

const PopoverContent = ({
	since,
	till,
	title,
	description_short,
	category,
	t,
	uuid: id,
	isCatchup = false
}) => {
	const description = description_short
		? description_short
				.split('')
				.splice(0, 90)
				.join('')
		: '';

	const linkDetails = createLinkParams({ id, hash: 'live' });
	return (
		<Content>
			<Top>
				<Clock /> <Time>{convertTime(since, till)}</Time>
			</Top>
			<Title>{title || '---'}</Title>
			{category && <Genres>{category}</Genres>}
			{isCatchup && <Catchup>catchup</Catchup>}

			{description_short ? (
				<React.Fragment>
					<Description>
						{description}
						...
					</Description>
					{id && (
						<LinkStyled to={linkDetails}>
							{t('common_popover_more')} >>
						</LinkStyled>
					)}
				</React.Fragment>
			) : null}
		</Content>
	);
};

const Content = styled.div`
	flex: 1;
	padding: 1.5rem;
`;

const Top = styled.div`
	margin-bottom: 0.8rem;
	display: block;
	${rtl`text-align: left;`}
`;

const Clock = styled(IconClock)`
	position: relative;
	top: 0.1rem;
	${iconClock};

	g,
	path {
		fill: ${({ theme }) => theme.grayMedium};
	}
`;

const Time = styled.span`
	font-weight: ${({ theme }) => theme.font.medium};
`;

const Title = styled(Heading.Third)`
	color: ${({ theme }) => theme.grayMedium};
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	${rtl`text-align: left;`}
`;

const Genres = styled.span`
	font-size: 1.2rem;
	margin-bottom: 0.4rem;
	margin-right: 1rem;
	display: block;
	${rtl`text-align: left;`}
`;

const Description = styled.p`
	font-size: 1.2rem;
	font-weight: ${({ theme }) => theme.font.normal};
	margin: 1rem 0;
	display: block;
	${rtl`text-align: left;`}
`;

const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		margin-left: 0.4rem;
		color: ${({ theme }) => theme.grayMedium};
		transition: all 0.3s ease-in-out;
	}
	&:hover,
	&:active {
		color: ${({ theme }) => theme.primary};
	}
	display: block;
	${rtl`text-align: left;`}
`;

PopoverContent.propTypes = {
	title: PropTypes.string,
	since: PropTypes.string.isRequired,
	till: PropTypes.string.isRequired,
	id: PropTypes.string,
	category: PropTypes.string,
	description_short: PropTypes.string,
	isCatchup: PropTypes.bool
};

export default withTranslation()(PopoverContent);
