import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { borderGradient, flex, transitionMain } from 'components/styles';

const iconNavBase = (theme) => css`
	padding: 0rem 0.6rem;
	svg {
		height: 2.2rem;
		width: 2.2rem;
		path {
			fill: ${theme.header.icon};
		}
	}
`;

const vodColor = {
	darkBlue: '#3562a5',
	lightBlue: '#4387d0'
};

const BUTTON_MODIFIERS = {
	primary: ({ theme }) => css`
		position: relative;
		background-color: ${theme.buttonPrimary.bg};
		color: ${theme.buttonPrimary.color};
		${borderGradient({
			borderSize: 2,
			borderBg: theme.buttonPrimary.border
		})}

		&:hover,
		&:focus {
			background-color: ${theme.buttonPrimary.bgHover};
		}
	`,
	default: ({ theme }) => css`
		background-image: linear-gradient(to bottom, #fff 0, #edeff2 100%);
		border-color: ${theme.grayLightExtra};
		color: ${theme.grayMedium};
		text-align: left;

		&:hover {
			color: ${theme.primary};
			box-shadow: 0 0 4px #e1e2e4;
		}
	`,
	dropdown: ({ theme }) => css`
		${flex({ jc: 'space-between' })}

		background: ${theme.dropdown.button.bg};
		border-color: ${theme.line};
		color: ${theme.grayMedium};
		text-align: left;

		&:hover {
			color: ${theme.primary};
		}
	`,
	icon: () => css`
		background-color: transparent;
		padding: 0;
	`,
	primaryVod: ({ theme, cancel, isNotAvailable, borderColor, resume }) => css`
		border-radius: 3px;
		color: ${theme.button.primaryVod.color};
		background: transparent;
		border: 1px solid ${borderColor ? `${vodColor.lightBlue}` : 'transparent'};
		width: 12.7rem;
		padding: ${resume ? 1.15 : 1.4}rem 0;
		font-size: 1.1rem;
		position: relative;
		z-index: 10;
		overflow: hidden;
		box-shadow: 0px 1px 0px 0px ${theme.black};
		transition: all 0.2s ease-in-out;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background: linear-gradient(
				0deg,
				${theme.button.primaryVod.beforeBgColor1} 12%,
				${theme.button.primaryVod.beforeBgColor2} 75%
			);
			z-index: -1;
		}

		${isNotAvailable &&
			css`
				color: #9ba7b3;
				cursor: not-allowed;
				&:before {
					background: linear-gradient(
						0deg,
						'transparent' 12%,
						'transparent' 75%
					);
				}
			`}

		${cancel &&
			css`
				border: 1px solid ${resume ? '#48484a' : '#6b747d'};
				background: ${resume ? '#303133' : '#455b72'};
				color: ${resume ? '#797979' : '#9ba7b3'};
				&:before {
					background: linear-gradient(0deg, transparent 12%, transparent 75%);
				}
			`}
	`,

	playerIconHover: ({ theme }) => css`
		&:hover {
			svg {
				path,
				text {
					fill: ${theme.white};
				}
			}
		}
	`,
	playerPlay: () => css`
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
	`,
	navIcon: ({ theme }) => css`
		${iconNavBase(theme)}

		&:hover {
			color: ${theme.header.iconActive};
		}
	`,
	pipIcon: ({ theme, isAvailable }) => css`
		${iconNavBase(theme)}
		cursor: ${isAvailable ? 'pointer' : 'not-allowed'};
		svg {
			path {
				fill: ${isAvailable ? theme.primary : theme.header.icon};
			}
		}
	`,
	parental: ({ theme }) => css`
		width: 19rem;
		height: 5rem;
		border-radius: 2px;
		font-size: 1.6rem;
		font-weight: ${theme.font.medium};
		background: linear-gradient(
			to bottom,
			${theme.button.parental.bgColor1} 0,
			${theme.button.parental.bgColor2} 100%
		);
	`
};

const Button = styled.button`
	padding: ${({ padding }) => padding || '1rem 1.2rem'};
	font-size: 1.3rem;
	line-height: 1.28;
	border-radius: 5px;
	font-weight: ${({ theme }) => theme.font.medium};
	cursor: ${({ isFocus = true }) => (isFocus ? 'pointer' : 'none')};
	border: 1px solid transparent;
	user-select: none;
	width: ${({ width = 'auto' }) => width};
	background-color: transparent;
	visibility: ${({ visible }) => (!visible ? 'visible' : 'hidden')};

	${transitionMain}

	svg {
		path {
			${transitionMain}
		}
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	${({ isCatchupControllers }) =>
		isCatchupControllers &&
		css`
			svg {
				height: 3rem;
				width: 3rem;
			}
		`}

	${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export default Button;
