import styled from 'styled-components';

// Import styles
import { flex, rtl } from 'components/styles';

export const Wrapper = styled.div`
	text-align: center;
	position: relative;
	height: 100vh;
	${flex()}
`;

export const BackgroundCover = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
`;

export const Box = styled.div`
	position: relative;
	z-index: 10;
	padding: 4.7rem 0;
	width: 76.8rem;
	border: 1px solid ${({ theme }) => theme.notFoundPage.borderColor};
	border-radius: 1rem;
	background: ${({ theme }) => theme.notFoundPage.bgColor};
`;

export const Title = styled.p`
	color: ${({ theme: { notFoundPage } }) => notFoundPage.titleColor};
	font-weight: ${({ theme }) => theme.font.semiMedium};
	line-height: 1.6;
	margin-bottom: 3rem;
	font-size: 3rem;
`;

export const DescriptionWrapper = styled.div`
	margin: 4rem auto;
	text-align: center;
`;

export const Description = styled.p`
	color: ${({ theme: { notFoundPage } }) => notFoundPage.descriptionColor};
	font-weight: ${({ theme }) => theme.font.regular};
	line-height: 1.6;
	font-size: 2.1rem;
	max-width: 40rem;
	margin: 0 auto;
`;

export const DescriptionSecond = styled.p`
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: 1.7rem;
	color: ${({ theme: { notFoundPage } }) =>
		notFoundPage.descriptionSecondColor};
	opacity: 0.7;
	line-height: 3.2rem;
	margin-bottom: 1.9rem;
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

export const GridItem = styled.div`
	${flex({ fd: 'column', jc: 'space-between' })}
	padding: 1rem 0;

	&:first-child {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			${rtl`left: 100%;`};
			display: block;
			width: 0.1rem;
			height: 100%;
			background-color: ${({ theme }) => theme.notFoundPage.grid.borderColor};
			opacity: 0.3;
		}
	}
`;
