import React from 'react';
import { useTranslation } from 'react-i18next';

// Import helpers
import { HIDDEN_TAGS } from './helpers';

// Import components
import { HiddenTag } from 'components/elements';

const HiddenTags = () => {
	const { t } = useTranslation();

	return HIDDEN_TAGS.map(({ component, text }) => (
		<HiddenTag key={text} component={component} text={t(text)} />
	));
};

export default HiddenTags;
