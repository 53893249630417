import styled from 'styled-components';

// Import styles
import { flex, transitionMain } from 'components/styles';

export const ButtonStyled = styled.div`
	cursor: pointer;
	border: none;
	background: none;
	${flex()};
	${transitionMain()};

	&:hover,
	&:focus {
		transform: scale(1.2);
	}
`;
