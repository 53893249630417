import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchContinueWatchingData,
	fetchMovieEpisodesPrevNext,
	fetchRecommendations
} from 'store/actions';

// Import helpers
import {
	ARABIC_SLUGS,
	ENGLISH_SLUGS,
	PLAYER_SELECTORS
} from 'helpers/variables';
import { PRODUCT_TYPES } from 'sitemap/helpers/variables';

const { VOD } = PRODUCT_TYPES;

export const getIsEpisode = (type) =>
	[ENGLISH_SLUGS.SERIES, ARABIC_SLUGS.SERIES].includes(type);

export const fetchMovieResources = ({ id, type, dispatch, getState }) => {
	const playerSelector = PLAYER_SELECTORS.VOD;
	const isEpisode = getIsEpisode(type);

	// Dispatch an fetchContinueWatchingData action in continue watching folder
	fetchContinueWatchingData(id)(dispatch);
	// Dispatch an fetchMovieDetails action in movieDetails folder
	fetchMovieDetails(id, VOD, dispatch);

	// Dispatch an fetchMovieEpisodesPrevNext action in movieDetails folder
	isEpisode && fetchMovieEpisodesPrevNext(id, dispatch);
	// Dispatch an fetchRecommendations action in recommendations folder
	!isEpisode && fetchRecommendations(id, VOD, dispatch);
	// Dispatch an fetchPlayerConfig action in player folder
	fetchPlayerConfig({
		productID: id,
		type: playerSelector,
		videoType: VOD
	})(dispatch, getState);
};
