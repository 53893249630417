import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import mock data
import { CUSTOM_ERROR } from 'helpers/error_code_api';

// Import helpers
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import utilities
import { useNextProgramParentalControl } from 'components/utilities';

/**
 * Empty functional component responsible for destroying player and showing proper error message
 * either when parental control is active for specific program or when program ends in
 * program details
 */
function PlayerDestroy({ errorAction, destroyPlayer, error, isCatchup }) {
	// check if parental control is active and player type
	const {
		isParentalControlActive,
		playerType
	} = useNextProgramParentalControl();

	// ending time of a program (only used in program details)
	const programEndTime = useSelector(
		(state) => state.program_details.data?.till
	);

	// time for interval
	const intervalTime = parseInt(process.env.REACT_APP_WATCHING_IN_PROGRESS);

	// destroy player and show parental control error when parental control is active
	const handleParentalControl = async () => {
		await destroyPlayer();
		errorAction(CUSTOM_ERROR.CUSTOM_PARENTAL_CONTROL_ERROR, playerType);
	};

	// destroy program and show message when program ends (in program details)
	const handleProgramEnd = async () => {
		if (isProgramFinished()) {
			await destroyPlayer();
			errorAction(CUSTOM_ERROR.CUSTOM_END_OF_PROGRAM, playerType);
		}
	};

	// check if program has finished
	const isProgramFinished = () => new Date() >= new Date(programEndTime);

	React.useEffect(() => {
		// block program if parental control is active
		if (isParentalControlActive && !error.isError) {
			handleParentalControl();
		}
		/*eslint-disable-next-line  */
	}, [isParentalControlActive]);

	React.useEffect(() => {
		let programEndInterval = null;
		const { DETAILS } = PLAYER_SELECTORS;
		// set interval only in program details
		if (playerType === DETAILS && programEndTime && !isCatchup) {
			// Check if current time is after programEndTime
			if (isProgramFinished()) {
				handleProgramEnd();
			} else {
				programEndInterval = setInterval(handleProgramEnd, intervalTime);
			}
		}
		return () => clearInterval(programEndInterval);
		/*eslint-disable-next-line  */
	}, []);
	return null;
}

PlayerDestroy.propTypes = {
	destroyPlayer: PropTypes.func.isRequired,
	error: PropTypes.object,
	isCatchup: PropTypes.bool
};

export default PlayerDestroy;
