// BENEFITS TYPES

export const USER_PROFILE = 'USER_PROFILE';
export const LOGIN = 'LOGIN';
export const SUBSCRIBER_ADVANTAGES = 'SUBSCRIBER_ADVANTAGES';
export const NON_SUBSCRIBER_DISADVANTAGES = 'NON_SUBSCRIBER_DISADVANTAGES';

// BENEFITS

export const USER_PROFILE_BENEFITS = [
	{ type: 'hours', icon: 'ic_popcorn' },
	{ type: 'devices', icon: 'ic_devices_second' },
	{ type: 'trial', icon: 'ic_trial' },
	{ type: 'channels', icon: 'ic_clapperboard' },
	{ type: 'ads', icon: 'ic_ads' },
	{ type: 'quality', icon: 'ic_fullhd' }
];

export const LOGIN_BENEFITS = [
	{ type: 'hours', icon: 'ic_popcorn' },
	{ type: 'devices', icon: 'ic_devices_second' },
	{ type: 'ads', icon: 'ic_ads' }
];

export const SUBSCRIBER_PROFITS = [
	{ type: 'full_content', icon: 'ic_popcorn' },
	{ type: 'all_tv_channels', icon: 'ic_tv_channel' },
	{ type: 'no_ads', icon: 'ic_ads' }
];

export const NON_SUBSCRIBER_LIMITS = [
	{ type: 'limited_content', icon: 'ic_limited_content' },
	{ type: 'limited_tv_channels', icon: 'ic_limited_channels' },
	{ type: 'contains_ads', icon: 'ic_contains_ads' }
];

export const getBenefitsData = ({ type }) => {
	switch (type) {
		case USER_PROFILE:
			return USER_PROFILE_BENEFITS;

		case SUBSCRIBER_ADVANTAGES:
			return SUBSCRIBER_PROFITS;

		case NON_SUBSCRIBER_DISADVANTAGES:
			return NON_SUBSCRIBER_LIMITS;

		case LOGIN:
			return LOGIN_BENEFITS;

		default:
			return [];
	}
};
