import { useEffect, useState } from 'react';

// Import helpers
import { filterProductPrices, defaultPriceValues } from 'helpers/prices';

const useProductPrices = ({ product }) => {
	const [purchasePrice, setPurchasePrice] = useState(defaultPriceValues);
	const [rentPrice, setRentPrice] = useState(defaultPriceValues);
	const [subscriptionPrice, setSubscriptionPrice] = useState(
		defaultPriceValues
	);
	const [currency, setCurrency] = useState(null);

	const handleSetPrices = ({ purchase, rent, subscription, currency }) => {
		setPurchasePrice(purchase);
		setRentPrice(rent);
		setSubscriptionPrice(subscription);
		setCurrency(currency);
	};

	useEffect(() => {
		if (product) {
			const prices = filterProductPrices({ ...product });
			handleSetPrices(prices);
		}
		// eslint-disable-next-line
	}, [JSON.stringify(product)]);

	return {
		purchase: purchasePrice,
		rent: rentPrice,
		subscription: subscriptionPrice,
		currency
	};
};

export default useProductPrices;
