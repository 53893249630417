import styled from 'styled-components';

// Import icons
import { ReactComponent as IconClock } from 'assets/icons/ic_clock.svg';

// Import styles
import { iconClock, transitionMain } from 'components/styles';

export const Info = styled.span`
	display: inline-block;
	min-height: 1.8rem;
	font-size: 1.2rem;
	color: ${({ theme: { searchBox } }) => searchBox.itemInfo};
	${transitionMain}
`;

export const Time = styled.span`
	margin-left: 0.5rem;
`;

export const Clock = styled(IconClock)`
	position: relative;
	top: 0.3rem;
	${iconClock};
	margin-right: 0.2rem;

	g,
	path {
		fill: ${({ theme: { searchBox } }) => searchBox.itemInfo};
		${transitionMain}
	}
`;
