import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { CouponInfo, CouponText, CouponButton } from './styles';

// Import helpers
import { APP_PATHS_KEY, getNavigationPaths } from 'components/routes/helpers';

const { USER_ENTRY_TICKETS } = APP_PATHS_KEY;

const CouponActivationInfo = () => {
	const { t } = useTranslation();

	const userEntryTicketsPath = getNavigationPaths(USER_ENTRY_TICKETS);

	return (
		<CouponInfo>
			<CouponText>
				{t('packets_coupon_activation_info')}
				<CouponButton to={userEntryTicketsPath}>
					{t('packets_coupon_activation_button')}
				</CouponButton>
			</CouponText>
		</CouponInfo>
	);
};

export default CouponActivationInfo;
