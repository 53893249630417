import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import { Nav } from 'components/elements';

// Import styles
import { getNavLinkUnderline } from 'components/styles';

const FilterCategoryList = (props) => {
	const { onChange, inputs, selectedValue, filterName } = props;

	return (
		<Nav.List dropdown>
			{inputs.map(({ value, name }, index) => (
				<Nav.Item key={`${value}-${index}`}>
					<Label>
						<input
							type="radio"
							name={filterName}
							value={value}
							onChange={onChange}
							checked={value === selectedValue}
						/>
						<span>{name}</span>
					</Label>
				</Nav.Item>
			))}
		</Nav.List>
	);
};

const Label = styled.label`
	position: relative;
	padding: 0 1.6rem;

	input {
		position: absolute;
		visibility: hidden;
	}

	span {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0.9rem;
		color: ${({ theme }) => theme.grayMedium};
		font-size: 1.5rem;
		font-weight: bold;
		transition: all 0.3s;
		cursor: pointer;

		&:hover {
			color: ${({ theme: { nav } }) => nav.menuActiveText};
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 4px;
			width: 100%;
			transform: scaleX(0);
			transform-origin: 0% 50%;
			transition: all 0.3s ease-in-out;
			${getNavLinkUnderline()}
		}
	}

	input:checked + span {
		color: ${({ theme: { nav } }) => nav.menuActiveText};
		&::after {
			transform: scaleX(1);
		}
	}
`;

FilterCategoryList.propTypes = {
	onChange: PropTypes.func.isRequired,
	inputs: PropTypes.array.isRequired,
	selectedValue: PropTypes.string.isRequired,
	filterName: PropTypes.string.isRequired
};

export default FilterCategoryList;
