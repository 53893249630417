const getProgramTimes = (program) => {
	// Get timestamps
	const now = new Date().getTime();
	const since = new Date(program.since.replace(/-/g, '/')).getTime();
	const till = new Date(program.till.replace(/-/g, '/')).getTime();

	return { now, since, till };
};

const isProgramLive = ({ now, since, till }) => now >= since && now <= till;

const getProgramIndex = (program) => {
	const { now, since, till } = getProgramTimes(program);
	// Check if program is live now
	return isProgramLive({ now, since, till });
};

export const setNextChannel = (channel, currentLiveIndex) => {
	if (channel.epgProgrammes.length - 1 !== currentLiveIndex) {
		const currentProgram = channel.epgProgrammes[currentLiveIndex];

		if (!currentProgram) return null;

		const { now, since, till } = getProgramTimes(currentProgram);

		if (isProgramLive({ now, since, till })) {
			return null;
		} else {
			const isProgramIndex = channel.epgProgrammes.findIndex(getProgramIndex);

			return isProgramIndex && isProgramIndex !== currentLiveIndex
				? isProgramIndex
				: null;
		}
	} else {
		return null;
	}
};
