import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { SkipIntroButton } from './styles';

const PlayerSkipIntro = ({ currentTimeProgress, updateTime, introData }) => {
	const { t } = useTranslation();

	const [currentTime, setCurrentTime] = useState(0);

	const startTimeIntro = introData?.start_time_in_seconds;
	const endTimeIntro = introData?.end_time_in_seconds;

	const showButton =
		currentTime >= startTimeIntro && currentTime < endTimeIntro;

	const handleButtonClick = () => updateTime(endTimeIntro);

	useEffect(() => {
		currentTimeProgress((currentTime) => setCurrentTime(currentTime));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		showButton && (
			<SkipIntroButton onClick={handleButtonClick}>
				{t('common_skip_intro')}
			</SkipIntroButton>
		)
	);
};

PlayerSkipIntro.propTypes = {
	currentTimeProgress: func.isRequired,
	updateTime: func.isRequired,
	introData: object.isRequired
};

export default PlayerSkipIntro;
