import React from 'react';
import { useSelector } from 'react-redux';
import { number, string, oneOfType, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import getSlug from 'speakingurl';
import { useQueryClient } from 'react-query';

// Import assets
import { ReactComponent as Arrow } from 'assets/icons/arrow_link.svg';

// HELPERS
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';
import { QUERY_SECTION_LABEL } from 'helpers/api';

// Import styles
import { flex, rtl } from 'components/styles';
import { getRTLBooleanValue } from 'helpers/rtl';

const LinkWrapper = styled.div`
	height: 50px;
	width: 155px;
	margin-right: 1rem;
	${({ addBottomMargin }) =>
		addBottomMargin &&
		css`
			margin-bottom: 1.5rem;
		`};
`;

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.buttonLink.color};
	width: 100%;
	height: 100%;
	text-decoration: none;
	${flex()}
	transition: color 0.3s ease-in-out 0s;
	svg {
		${rtl`
      margin-left: 1.4rem;
    `}
		${({ isRtl }) =>
			isRtl &&
			css`
				transform: rotateY(180deg);
			`}
		path {
			transition: fill 0.3s ease-in-out 0s;
		}
	}
	&:hover {
		color: ${({ theme }) => theme.buttonLink.hoverColor};
		svg {
			path {
				fill: ${({ theme }) => theme.buttonLink.hoverColor};
			}
		}
	}
`;

const { SECTION, SECTION_CHANNELS_DETAILS } = APP_SUB_PATHS_KEY;

export function VodSectionButton({
	sectionId,
	sectionTitle,
	redirectPath = '',
	isChannelSection = false,
	saveSectionId = () => {}
}) {
	const { t } = useTranslation();
	const { direction } = useSelector(({ direction }) => direction);
	const queryClient = useQueryClient();

	const { pathname } = useLocation();
	const sectionName = getSlug(sectionTitle);

	const label = queryClient.getQueryData(QUERY_SECTION_LABEL);

	const sectionAllSubPath = getNavigationSubPaths(SECTION);
	const sectionChannelsDetailsSubPath = getNavigationSubPaths(
		SECTION_CHANNELS_DETAILS
	);

	const sectionChannelsDetailsPath = `/vod/${sectionChannelsDetailsSubPath}/${sectionId}/${sectionName}`;

	const sectionAllPath = `/vod/${sectionAllSubPath}/${sectionId}/${sectionName}`;

	const redirectToSection = {
		pathname: sectionAllPath,
		state: {
			fromPage: label,
			fromPagePath: pathname
		}
	};

	const redirectTo = isChannelSection
		? sectionChannelsDetailsPath
		: redirectPath || redirectToSection;

	const isRtl = getRTLBooleanValue({ direction });

	return (
		<LinkWrapper>
			<StyledLink to={redirectTo} isRtl={isRtl} onClick={saveSectionId}>
				{t('common_search_see_all')}
				<Arrow />
			</StyledLink>
		</LinkWrapper>
	);
}

VodSectionButton.propTypes = {
	sectionId: oneOfType([string, number]).isRequired,
	sectionTitle: string.isRequired,
	redirectPath: string,
	addBottomMargin: bool,
	isChannelSection: bool
};
