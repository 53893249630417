import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: {},
	isLoaded: false,
	isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch static content *******************
			case types.STATIC_CONTENT_LOADING:
				return { ...INITIAL_STATE };

			case types.STATIC_CONTENT_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				return;

			case types.STATIC_CONTENT_ERROR:
				draft.isError = true;
				return;

			default:
				return state;
		}
	});
