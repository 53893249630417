import * as types from 'store/actions/types';

const INITIAL_STATE = {
	data: null,
	loading: true,
	error: false
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case types.TV_SHOW_SEASON_LOADING:
			return INITIAL_STATE;

		case types.TV_SHOW_SEASON_SUCCESS:
			return {
				data: action.payload,
				loading: false,
				error: false
			};
		case types.TV_SHOW_SEASON_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};

		default:
			return state;
	}
}
