import React from 'react';
import { func, number } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RESUME_PLAYBACK_OFFSET_TIME } from 'helpers/variables';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';

// Import helpers
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';
import { getTimeLineFormat } from 'helpers';

const Resume = React.memo(
	({ currentTime, toggle }) => {
		const { t } = useTranslation();
		const { play, pause, restart, updateTime } = useContextProvider();
		const backwardcurrentTime = currentTime - RESUME_PLAYBACK_OFFSET_TIME;

		React.useEffect(() => {
			// Pause player
			pause();
			// eslint-disable-next-line
		}, []);

		const handleContinueWatching = (toggle) => () => {
			// Seek to current time
			updateTime(backwardcurrentTime);
			// Play player
			play();
			// Close
			toggle();
		};

		const handleRestartWatching = (toggle) => () => {
			// Restart
			restart();
			// Pause player
			play();
			// Close
			toggle();
		};

		return (
			<Wrapper classNAme="RESUME">
				<Box>
					<Text>
						{t('player_remuse_desc_part_1')}
						<br />
						{t('player_remuse_desc_part_2')}
						{getTimeLineFormat(backwardcurrentTime)}?
					</Text>
					<Controllers>
						<Button
							onClick={handleRestartWatching(toggle)}
							type="button"
							modifiers="primaryVod"
							cancel={true}
							resume
						>
							{t('player_remuse_btn_restart')}
						</Button>
						<Button
							onClick={handleContinueWatching(toggle)}
							type="button"
							resume
							borderColor
							modifiers="primaryVod"
						>
							{t('player_remuse_btn_remuse')}
						</Button>
					</Controllers>
				</Box>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.47);
	${flex()}
`;

const Box = styled.div`
	border: 1px solid rgba(255, 255, 255, 0.29);
	border-radius: 6px;
	width: 28.5rem;
	padding: 1rem;
	background-color: ${({ theme }) => theme.black};
`;

const Text = styled.p`
	font-size: 1.8rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.white};
	padding: 2rem;
	text-align: center;
	opacity: 0.8;
`;

const Controllers = styled.div`
	display: flex;
	justify-content: space-between;
`;

Resume.propTypes = {
	currentTime: number.isRequired,
	toggle: func.isRequired
};

export default Resume;
