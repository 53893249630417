import React from 'react';
import { getYear } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';

// Import variables
import { CLIENT_EXTERNAL_LINKS } from 'helpers/variables';

// Import styles
import { FooterInfoStyled } from './styles';

// Import helpers
import { getRTLBooleanValue, getFontFamily } from 'helpers/rtl';

const { FACEBOOK_GROUP, INSTAGRAM, TWITTER } = CLIENT_EXTERNAL_LINKS;

const FooterInfo = () => {
	const { t } = useTranslation();

	const { direction } = useSelector(({ direction }) => direction);

	const currentYear = getYear(new Date());

	const copyrightTranslation = t('common_footer_copyrights');
	const copyrightText = copyrightTranslation.replace('year', currentYear);

	const isRtl = getRTLBooleanValue({ direction });
	const fontFamily = getFontFamily(isRtl);

	return (
		<FooterInfoStyled>
			<FooterInfoStyled.Copyrights fontFamily={fontFamily}>
				<span>{copyrightText}</span>
			</FooterInfoStyled.Copyrights>
			<FooterInfoStyled.Social>
				<FooterInfoStyled.Anchor
					target="_blank"
					rel="noopener noreferrer"
					href={FACEBOOK_GROUP}
				>
					<Facebook />
				</FooterInfoStyled.Anchor>
				<FooterInfoStyled.Anchor
					target="_blank"
					rel="noopener noreferrer"
					href={INSTAGRAM}
				>
					<Instagram />
				</FooterInfoStyled.Anchor>
				<FooterInfoStyled.Anchor
					target="_blank"
					rel="noopener noreferrer"
					href={TWITTER}
				>
					<Twitter />
				</FooterInfoStyled.Anchor>
			</FooterInfoStyled.Social>

			<FooterInfoStyled.Version fontFamily={fontFamily}>
				<FooterInfoStyled.Text isRtl={isRtl}>
					{t('common_app_version')}:
				</FooterInfoStyled.Text>{' '}
				<FooterInfoStyled.Text>
					{process.env.REACT_APP_VERSION}
				</FooterInfoStyled.Text>
			</FooterInfoStyled.Version>
		</FooterInfoStyled>
	);
};

export default FooterInfo;
