import * as TYPES from './sliderActionTypes';

const sliderReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case TYPES.SET_CONT_WIDTH:
			return { ...state, contWidth: payload };
		case TYPES.SET_DRAGGABLE:
			return { ...state, draggable: payload };
		case TYPES.SET_TRANSLATE_X:
			return { ...state, translateX: payload };
		case TYPES.SET_SHOWN_PERCENT:
			return { ...state, shownPercent: payload };
		case TYPES.SET_CURSOR_POSITION:
			return { ...state, cursorPosition: payload };
		case TYPES.SET_PREVIEW_VISIBLE:
			return { ...state, isPopoverVisible: payload };
		case TYPES.SET_POINT_VISIBLE:
			return { ...state, isPointVisible: payload };
		default:
			return state;
	}
};

export default sliderReducer;
