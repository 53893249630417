import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import helpers
import { PLAYER_SELECTORS } from 'helpers/variables';
import { ROUTES_CORE_PATHS } from 'helpers';

// destructure helpers
const { DETAILS, PLAYER } = PLAYER_SELECTORS;
const { HOME } = ROUTES_CORE_PATHS;

/**
 * Helper function that determines conditions for parental control in
 * player program stream
 * @param {number} programRating - parental control rating of a program
 * @param {number} userRating - user parental control rating access
 */
const checkParentalControl = (programRating, userRating) => {
	if (userRating === null) return false;
	else if (programRating === 0 && userRating === 0) return false;
	else if (userRating < programRating) return true;
	else return false;
};

/**
 * Custom hook that handles parental control for player program stream
 */
const useNextProgramParentalControl = () => {
	let isParentalControlActive = false;

	const { pathname } = useLocation();
	// check if player is on home page or in program details
	const isHome = pathname.includes(HOME);
	const playerType = isHome ? PLAYER : DETAILS;

	// get user rating (parental control)
	const { rating: userRating } = useSelector(
		(state) => state.auth.authenticate
	);

	// programIdSelected, channelIdSelected and programIdDetails, channelIdDetails
	// have corresponding values but are called based on current location
	const { id: programIdSelected, channelID: channelIdSelected } = useSelector(
		(state) => state.selected_program
	);
	const {
		uuid: programIdDetails,
		channel_uuid: channelIdDetails
	} = useSelector((state) => state.program_details.data);

	// get current channel
	const { lives } = useSelector((state) => state.channels.data);

	const channelId = isHome ? channelIdSelected : channelIdDetails; // channel id based on current location
	const programId = isHome ? programIdSelected : programIdDetails; // channel id based on current location
	const currentChannel = lives.find(({ uuid }) => uuid === channelId);
	if (!currentChannel) return { isParentalControlActive };

	// get current program
	const { epgProgrammes, currentLiveIndex } = currentChannel;
	if (!epgProgrammes[currentLiveIndex]) return { isParentalControlActive };

	// get pc rating from current program
	const { pc_rating } = epgProgrammes[currentLiveIndex];

	// check if parental control is aplicable
	return {
		isParentalControlActive: checkParentalControl(pc_rating, userRating),
		channelId,
		programId,
		playerType
	};
};

export default useNextProgramParentalControl;
