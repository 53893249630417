import { getHours, getMinutes, getTime } from 'date-fns';
import i18n from 'i18next';
import RelativeTimeFormat from 'relative-time-format';
import en from 'relative-time-format/locale/en.json';
import ar from 'relative-time-format/locale/ar.json';

// Import variables
import { INTL_STYLES, PERIOD_UNITS } from 'helpers/variables';

const RELATIVE_TIME_FORMAT_LOCALES = { en, ar };

// Convert time unit format
export const unitTimeFormat = (time) => (time < 10 ? `0${time}` : time);

// Convert time to since and till
export const convertTime = (since, till) => {
	const hoursSince = getHours(since);
	const minutesSince = getMinutes(since);

	const hoursTill = getHours(till);
	const minutesTill = getMinutes(till);

	return `${unitTimeFormat(hoursSince)}:${unitTimeFormat(
		minutesSince
	)} - ${unitTimeFormat(hoursTill)}:${unitTimeFormat(minutesTill)}`;
};

// Format vod timeline / progress time
export const getTimeLineFormat = (currentTime) => {
	const formatTime = (time) => (time >= 10 ? time : `0${time}`);
	const hours = Math.floor(currentTime / 60 / 60);
	const minutes = Math.floor(currentTime / 60) - hours * 60;
	const seconds = Math.floor(currentTime % 60);

	return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
};

// Convert time to h:mm
export const convertDateTimeFormat = (since) => {
	const date = new Date(since);
	const minutes = date.getMinutes();
	const checkMinutes = date.getMinutes() > 9 ? minutes : `0${minutes}`;
	return `${date.getHours()}:${checkMinutes}`;
};

export const calculateTime = (since, till) =>
	Math.floor((getTime(till) - getTime(since)) / 1000 / 60);

export const convertSecondsToHours = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const min = Math.floor((seconds % 3600) / 60);

	return `${hours} ${i18n.t('vod_movieDetails_hours')} ${min} ${i18n.t(
		'vod_movieDetails_minutes'
	)}`;
};

export const getUnitLocale = ({
	period,
	periodUnit = PERIOD_UNITS.HOUR,
	unitStyle = INTL_STYLES.SHORT
}) => {
	const { language } = i18n;

	const locale = RELATIVE_TIME_FORMAT_LOCALES[language];

	RelativeTimeFormat.addLocale(locale);

	if (period && periodUnit) {
		const rtf1 = new RelativeTimeFormat(language, { style: unitStyle });
		const { 2: unitLocale } = rtf1.format(period, periodUnit).split(' ');
		return unitLocale;
	}

	return '';
};

export const getPeriodWithUnit = ({
	period,
	periodUnit = PERIOD_UNITS.HOUR,
	unitStyle = INTL_STYLES.LONG
}) => {
	if (!period) return '';
	const unitLocale = getUnitLocale({ period, periodUnit, unitStyle });
	return `${period} ${unitLocale}`;
};
