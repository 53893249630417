import React from 'react';
import { string, number, array, oneOfType, bool } from 'prop-types';

// COMPONENTS
import { Basic } from '../Basic';
import SliderItem from '../components/SliderItem';
import { RegularHeader } from '../components/RegularHeader';
import { SectionFocus } from 'components/elements';

// Import hooks
import { useSectionFocus } from 'components/utilities';

export function Promo({
	content,
	name,
	id,
	label,
	showAllMoviesButton,
	borderLine,
	backgroundImage,
	shuffle
}) {
	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	const renderHeader = () => (
		<RegularHeader
			showAllMoviesButton={showAllMoviesButton}
			sectionId={id}
			title={name}
			borderLine={borderLine}
			saveSectionId={saveSectionId}
		/>
	);

	return (
		<SectionFocus ref={restorationRef}>
			<Basic
				itemResolutionHeight={9}
				itemResolutionWidth={6}
				itemsList={content}
				itemsToShow={6.2}
				headerHeight={75}
				spaceBetweenItems={7}
				backgroundImage={backgroundImage}
				renderItemComponent={({ data, height, width }) => (
					<SliderItem
						sectionLabel={label}
						data={data}
						height={height}
						width={width}
						saveSectionId={saveSectionId}
					/>
				)}
				renderHeader={renderHeader}
				shuffle={shuffle}
			/>
		</SectionFocus>
	);
}

Promo.defaultProps = {
	showAllMoviesButton: true,
	content: [],
	shuffle: false
};

Promo.propTypes = {
	id: oneOfType([string, number]).isRequired,
	name: string.isRequired,
	label: string.isRequired,
	content: array.isRequired,
	showAllMoviesButton: bool,
	borderLine: bool,
	backgroundImage: oneOfType([string, bool]),
	shuffle: bool
};
