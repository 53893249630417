import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';

// Import heleprs
import { callAllFns } from './helpers';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import styles
import { ModalStyled } from './styles';

// Import components
import useAuthModal from './useAuthModal';
import ModalContent from './ModalContent';

const AuthModal = ({ children }) => {
	const { isAuth, redirect, auth, open } = useAuthModal();

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					{React.cloneElement(children, {
						onClick: callAllFns(auth(toggle), open(children?.props?.onClick)),
						isAuth
					})}

					{on && (
						<ModalStyled
							maskClosable={false}
							width={600}
							closable={true}
							onCancel={toggle}
							visible={on}
							bodyStyle={{ padding: '56px 20px  40px' }}
						>
							<ModalContent redirect={redirect} toggle={toggle} />
						</ModalStyled>
					)}
				</>
			)}
		</ToggleRPC>
	);
};

AuthModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default AuthModal;
