import React from 'react';
import { useTranslation } from 'react-i18next';
import { string, bool, number, oneOf } from 'prop-types';

// Import components
import { DynamicIcon } from 'components/elements';

// Import styles
import {
	BenefitsWrapper,
	BenefitsHeading,
	BenefitsGrid,
	Benefit,
	BenefitText
} from './styles';

// Import helpers
import {
	getBenefitsData,
	USER_PROFILE,
	NON_SUBSCRIBER_DISADVANTAGES,
	SUBSCRIBER_ADVANTAGES
} from 'helpers';

const Benefits = ({
	type = '',
	isSubscriptions = false,
	width,
	height,
	direction = 'rtl'
}) => {
	const { t } = useTranslation();
	const headingType = isSubscriptions ? 'enjoy' : 'subscribe';

	const isUserProfile = type === USER_PROFILE;
	const isNonSubscriber =
		type === NON_SUBSCRIBER_DISADVANTAGES || type === SUBSCRIBER_ADVANTAGES;

	const benefits = getBenefitsData({ type });

	const benefitTitlePrefix = isUserProfile
		? 'user_profile_subscriptions'
		: 'common';

	return (
		<BenefitsWrapper isUserProfile={isUserProfile}>
			{isUserProfile && (
				<BenefitsHeading as="h4">
					{t(`user_profile_subscriptions_benefits_header_${headingType}`)}
				</BenefitsHeading>
			)}
			<BenefitsGrid isUserProfile={isUserProfile} direction={direction}>
				{benefits.map(({ type, icon }) => (
					<Benefit key={type}>
						<DynamicIcon icon={icon} width={width} height={height} />
						<BenefitText isNonSubscriber={isNonSubscriber}>
							{t(`${benefitTitlePrefix}_benefits_${type}`)}
						</BenefitText>
					</Benefit>
				))}
			</BenefitsGrid>
		</BenefitsWrapper>
	);
};

Benefits.propTypes = {
	type: string,
	isSubscriptions: bool,
	width: number,
	height: number,
	direction: oneOf(['ltr', 'rtl'])
};

export default Benefits;
