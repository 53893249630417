// Import helpers
import {
	checkProperFavouriteProduct,
	createLinkParams,
	getImage
} from 'helpers';

// Import helpers
import { PRODUCT_IMAGES_TYPES, LINK_TYPES } from 'helpers/variables';
import { getGenreName } from 'helpers/languages';

// Import utilities
import {
	useProductAvailability,
	useProductTranslations
} from 'components/utilities';
import useImageCheck from '../useImageCheck';

// Import assets
import placeholder from 'assets/images/placeholder.png';

const { LOGO, POSTER, COVER_SMALL } = PRODUCT_IMAGES_TYPES;
const { CHANNEL, COLLECTION, VOD } = LINK_TYPES;

const useSliderItem = ({ data, sliderId = '', imageType = POSTER }) => {
	const { product } = useProductTranslations({ data });

	const {
		title,
		year,
		country,
		genres,
		uuid,
		subtype,
		previews,
		type,
		images,
		available_in
	} = product;

	const isAvailable = useProductAvailability(uuid, available_in);

	const isCollectionPage = sliderId === COLLECTION;
	const isChannel = subtype === CHANNEL;
	const backgroundSize = isChannel ? 'scale-down' : 'cover';
	const showInformation = type === VOD;

	const imageSrc = getImage({ images, type: isChannel ? LOGO : imageType });

	const linkParams = createLinkParams({ id: uuid, type: subtype, title });

	const { isError } = useImageCheck(imageSrc);

	const imgSrc = isError ? placeholder : imageSrc;

	const channelBackgroundImageSrc =
		isChannel && getImage({ images, type: COVER_SMALL });

	const genre = getGenreName({ genres });

	const metadata = [year, genre, country].filter((meta) => meta);

	const videoAssetId = previews?.preview?.[0]?.videoAssetId;

	const showWatchlistButton = checkProperFavouriteProduct(subtype);

	return {
		isAvailable,
		isCollectionPage,
		linkParams,
		backgroundSize,
		imgSrc,
		channelBackgroundImageSrc,
		isChannel,
		title,
		uuid,
		subtype,
		metadata,
		videoAssetId,
		showInformation,
		showWatchlistButton
	};
};

export default useSliderItem;
