import React from 'react';
import styled from 'styled-components';
import { coverImage } from '../../../helpers/coverImage';

// HELPERS
import { CONTAINER_SCREEN_PERCENT } from './helpers';

const Cover = styled.div.attrs(({ cover }) => ({
	style: {
		backgroundImage: cover ? `url(${cover}=w1920-h1080-c)` : 'none'
	}
}))`
	width: 100%;
	height: 100%;
	max-height: ${CONTAINER_SCREEN_PERCENT}vh;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center top;
	background-size: cover;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	opacity: ${({ shouldBeShown }) => (shouldBeShown ? '1' : '0')};
`;

export function BackgroundAndPreview(props) {
	const { content, currentIndex } = props;

	return content.map((item, index) => {
		const shouldBeShown = currentIndex === index;

		return (
			<Cover
				key={`${item.uuid}-${index}`}
				cover={coverImage(item.images)}
				shouldBeShown={shouldBeShown}
			/>
		);
	});
}
