// -----------------  GET USER -----------------
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_APP_CHECK_TOKEN_ERROR =
	'AUTH_USER_APP_CHECK_TOKEN_ERROR';

// -----------------  GET USER DETAILS -----------------
export const AUTH_USER_DETAILS_LOADING = 'AUTH_USER_DETAILS_LOADING';
export const AUTH_USER_DETAILS_SUCCESS = 'AUTH_USER_DETAILS_SUCCESS';
export const AUTH_USER_DETAILS_ERROR = 'AUTH_USER_DETAILS_ERROR';

// -----------------  GET USER PACKETS -----------------
export const FETCH_USER_PACKETS_SUCCESS = 'FETCH_USER_PACKETS_SUCCESS';
export const FETCH_USER_PACKETS_ERROR = 'FETCH_USER_PACKETS_ERROR';

// -----------------  GET USER DEVICES -----------------
export const AUTH_FETCH_USER_DEVICES_LOADING =
	'AUTH_FETCH_USER_DEVICES_LOADING';
export const AUTH_FETCH_USER_DEVICES_SUCCESS =
	'AUTH_FETCH_USER_DEVICES_SUCCESS';
export const AUTH_FETCH_USER_DEVICES_ERROR = 'AUTH_FETCH_USER_DEVICES_ERROR';

// -----------------  UPDATE USER DEVICE -----------------
export const AUTH_UPDATE_USER_DEVICES_SUCCES =
	'AUTH_UPDATE_USER_DEVICES_SUCCES';
export const AUTH_UPDATE_USER_DEVICES_ERROR = 'AUTH_UPDATE_USER_DEVICES_ERROR';

// -----------------  UPDATE USER'S AGREEMENTS -----------------
export const AUTH_UPDATE_AGREEMENTS_SUCCESS = 'AUTH_UPDATE_AGREEMENTS_SUCCESS';
export const AUTH_UPDATE_AGREEMENTS_ERROR = 'AUTH_UPDATE_AGREEMENTS_ERROR';

// -----------------  SET UNAVAILABLE CHANNELS  -----------------
export const SET_USER_UNAVAILABLE_CHANNELS_SUCCESS =
	'SET_USER_UNAVAILABLE_CHANNELS_SUCCESS';
export const SET_USER_UNAVAILABLE_CHANNELS_ERROR =
	'SET_USER_UNAVAILABLE_CHANNELS_ERROR';

// -----------------  ENABLE PARENTAL CONTROL  -----------------
export const ENABLE_USER_PARENTAL_CONTROL = 'ENABLE_USER_PARENTAL_CONTROL';

// -----------------  SET USER IN LOCAL  -----------------
export const SET_USER_IN_LOCAL = 'SET_USER_IN_LOCAL';

// -----------------  CLEAR AUTHORIZATION -----------------
export const AUTH_USER_CLEAR = 'AUTH_USER_CLEAR';

// -----------------  UNAUTHORIZE - TOKEN INVALID -----------------
export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const SIGNOUT_REDIRECT = 'SIGNOUT_REDIRECT';
