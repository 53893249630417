import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import Provider
import PlayerSidebarProvider, {
	PlayerSidebarContext
} from 'components/context/player/PlayerSidebarProvider';

// Import components
import useUpdateCurrentProgram from 'components/utilities/hooks/program/useUpdateCurrentProgram';
import SidebarNav from './SidebarNav';
import SidebarChannels from './sidebar_channels/SidebarChannels';
import { ReactComponent as ArrowIconDown } from 'assets/icons/ic_arrow_down.svg';

// Import styles
import { rtl } from 'components/styles';

const PlayerSidebar = ({ isFocus }) => {
	useUpdateCurrentProgram();

	const channelsList = document.querySelector('.player-sidebar-list');

	const handleArrowClick = (direction) => {
		const top = direction === 'down' ? 99 : -99;
		channelsList.scrollBy({
			top,
			left: 0,
			behavior: 'smooth'
		});
	};

	return (
		<PlayerSidebarProvider>
			<PlayerSidebarContext.Consumer>
				{({ query, category, setCategory }) => (
					<Sidebar isFocus={isFocus}>
						<SidebarNav category={category} setCategory={setCategory} />
						<ArrowButton onClick={() => handleArrowClick('up')}>
							<ArrowIconUp width="22px" height="10px" />
						</ArrowButton>
						<SidebarChannels query={query} category={category} />
						<ArrowButton onClick={() => handleArrowClick('down')}>
							<ArrowIconDown width="22px" height="10px" />
						</ArrowButton>
					</Sidebar>
				)}
			</PlayerSidebarContext.Consumer>
		</PlayerSidebarProvider>
	);
};

const Sidebar = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	height: 88%;
	max-width: 55.8rem;
	min-width: 48rem;
	background-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0.7) 10%,
		rgba(0, 0, 0, 0.7) 70%,
		#000
	);
	padding: 0 1.2rem;
	display: flex;
	flex-direction: column;
	opacity: 0;
	transition: opacity 0.6s ease-in 0.2s;
	z-index: 999;
	${rtl`
    direction: ltr;
  `}

	${({ isFocus }) =>
		isFocus &&
		css`
			opacity: 0;
		`};

	&:hover {
		transition: opacity 0.3s ease-in 0s;
		opacity: 1;
	}

	&& {
		.ReactVirtualized__List {
			overflow: scroll !important;
			overflow-x: hidden !important;
		}
	}
`;

const ArrowButton = styled.button`
	border: transparent;
	background: transparent;
	outline: none;
	cursor: pointer;
	margin: 1rem;

	&:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}
`;

const ArrowIconUp = styled(ArrowIconDown)`
	transform: rotate(180deg);
`;

PlayerSidebar.propTypes = {
	isFocus: PropTypes.bool.isRequired
};

export default PlayerSidebar;
