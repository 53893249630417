import { useDispatch, useSelector } from 'react-redux';
import { deleteFavourite } from 'store/actions';

export function useFavouritesActions() {
	const dispatch = useDispatch();
	const favourites = useSelector((store) => store.favourites.data);

	const deleteFavouritesAction = (id) => deleteFavourite(id)(dispatch);

	return {
		deleteFavourite: deleteFavouritesAction,
		favourites
	};
}
