import React from 'react';
import { array, bool } from 'prop-types';
import styled from 'styled-components';

// COMPONENTS
import { BackgroundAndPreview } from './BackgroundAndPreview';
import { Slider } from './components/Slider';
import { Info } from './components/Info';
import GradientBg from 'components/views/vod/components/GradientBg';
import { ErrorBoundary } from 'components/elements';

// HOOKS
import { useCarousel } from './useCarousel';

// HELPERS
import { CONTAINER_SCREEN_PERCENT } from './helpers';

// Import styles
import { rtl } from 'components/styles';

const Root = styled.div`
	position: relative;
	padding-top: 8vh;
	background-color: ${({ theme }) => theme.carousel.bg};
	min-height: 100vh;
	scroll-margin-top: ${({ theme }) => theme.nav.height}rem;
`;

const InfoWrapper = styled.div`
	width: 40vw;
	max-width: 1100px;
	min-width: 45rem;
	position: relative;
	${rtl`
		margin-left: 9rem;
	`}
	z-index: 4;
`;

const SliderWrapper = styled.div`
	position: relative;
	z-index: 4;
`;

export function Carousel({ itemsList, kids, id, name, shuffle }) {
	const {
		currentIndex,
		changeCurrentIndex,
		isRtl,
		sliderItems,
		saveSectionId,
		restorationRef
	} = useCarousel({
		itemsList,
		shuffle,
		id
	});

	return (
		<Root
			id="carousel_slider"
			isSeries={sliderItems[currentIndex]?.subtype === 'series'}
			ref={restorationRef}
		>
			{sliderItems.length > 0 && (
				<>
					<BackgroundAndPreview
						content={sliderItems}
						currentIndex={currentIndex}
					/>
					<GradientBg
						containerScreenPercent={CONTAINER_SCREEN_PERCENT}
						isRtl={isRtl}
					/>
					<InfoWrapper>
						<Info
							kids={kids}
							currentItem={sliderItems[currentIndex]}
							saveSectionId={saveSectionId}
						/>
					</InfoWrapper>
					<SliderWrapper>
						<ErrorBoundary>
							<Slider
								itemsList={sliderItems}
								changeCurrentIndex={changeCurrentIndex}
								currentIndex={currentIndex}
								id={id}
								name={name}
								shuffle={shuffle}
								isCarousel
							/>
						</ErrorBoundary>
					</SliderWrapper>
				</>
			)}
		</Root>
	);
}

Carousel.defaultProps = {
	kids: false,
	shuffle: false
};

Carousel.propTypes = {
	itemsList: array.isRequired,
	kids: bool,
	shuffle: bool
};
