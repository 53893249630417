function sortByType(arr, type) {
	return arr.filter((item) => item.type === type);
}

function sortBySubtype(arr, subtype) {
	return arr.filter((item) => item.subtype === subtype);
}

/**
 * function sorts favourites in case of label
 * @param {{ type: string, subtype: string }[]} array
 * @param {string} label
 */
export function favouritesSorter(array, label) {
	// returns empty array if no data in array, or argument has invalid data type
	if (!array.length || typeof array === 'string') return [];

	switch (label) {
		// todo: should be done in the future
		// case 'platform_favourites':
		// 	return array;

		// todo: should be done in the future
		// case 'favourite_channels':
		// 	return sortByType(array, 'channel');

		// for favourite_movies it returns only favourites with
		// type === 'vod' and subtype === 'vod'
		case 'favourite_movies':
			return sortBySubtype(sortByType(array, 'vod'), 'vod');
		// for favourite_series it returns only favourites with
		// type === 'vod' and subtype === 'series'
		case 'favourite_series':
			return sortBySubtype(sortByType(array, 'vod'), 'series');
		// by default function returns empty array
		default:
			return [];
	}
}
