import styled from 'styled-components';

const Grid = styled.div`
	display: grid;
	grid-gap: 0rem;
	grid-template-columns: ${({ columns = '1fr' }) => columns};
	grid-auto-rows: auto;
`;

const Row = styled.div`
	padding: 0 1rem;
	display: grid;
	align-items: center;
	grid-template-columns: ${({
		columns = 'repeat(auto-fit, minmax(0px, 1fr))'
	}) => columns};
	grid-template-rows: ${({ rows = '7.2rem' }) => rows};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: 1.4rem;
	color: ${({ theme: { user } }) => user.tableItem};
	transition: 0.1s ease-in-out;

	border-top: 1px solid ${({ theme: { user } }) => user.line};
`;

const Header = styled.div`
	padding: 0 1rem;
	display: grid;
	align-items: center;
	grid-template-columns: ${({
		columns = 'repeat(auto-fit, minmax(0px, 1fr))'
	}) => columns};
	grid-template-rows: ${({ rows = '7.2rem' }) => rows};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 1.6rem;
	color: ${({ theme: { user } }) => user.tableTitle};
	transition: 0.1s ease-in-out;
`;

export { Grid, Row, Header };
