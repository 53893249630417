import { format } from 'date-fns';
import localePl from 'date-fns/locale/pl';

// Convert Date to  yyyy-mm-dd format
export const convertDateFormat = (since) => {
	const date = new Date(since);
	let month = date.getMonth() + 1;
	let day = date.getDate();

	if (month < 10) month = `0${month}`;

	if (day < 10) day = `0${day}`;

	return `${date.getFullYear()}-${month}-${day}`;
};

export const formatedDate = (since) =>
	format(
		// new Date must get date in format '1995-12-17T03:24:00' (safari)
		new Date(since.replace(/\s/g, 'T')),
		'Do MMMM YYYY',
		{
			locale: localePl
		}
	);
