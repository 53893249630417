import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { Info, Time, Clock } from './styles';

// Import helpers
import { convertTime } from 'helpers';

const ProgramInfo = ({ product: { parent_uuid, context }, channels }) => {
	const channel = channels.find((item) => item.uuid === parent_uuid);

	const since = context?.since;
	const till = context?.till;

	const date = since?.split(/\s/)[0];

	return (
		<>
			<div>
				<Info>{channel && channel.title}</Info>
			</div>

			{!!date && <Info>{date}</Info>}
			{!!since && !!till && (
				<Time>
					<Info>
						<Clock /> {convertTime(since, till)}
					</Info>
				</Time>
			)}
		</>
	);
};

ProgramInfo.propTypes = {
	product: PropTypes.shape({
		parent_uuid: PropTypes.string.isRequired,
		context: PropTypes.shape({
			since: PropTypes.string.isRequired,
			till: PropTypes.string.isRequired
		}).isRequired
	}).isRequired,
	channels: PropTypes.array.isRequired
};

export default ProgramInfo;
