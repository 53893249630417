import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const NavToolbarStyled = styled.ul`
	padding-left: 0.4rem;
	list-style: none;
	display: flex;
`;

export const Item = styled.li`
	display: flex;
	align-items: center;
	padding: 0.2rem;
	color: ${({ theme: { header } }) => header.icon};
`;

export const SearchButtonStyled = styled(Button)`
	& > svg {
		width: 2.5rem;
		height: 2.5rem;
	}
`;

export const IconWrapper = styled.div`
	svg {
		width: ${({ width = 'auto' }) => width};
		height: ${({ height = 'auto' }) => height};
	}
`;
