import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import images
import { ReactComponent as AppStore } from 'assets/icons/ic_appstore_badge.svg';
import { ReactComponent as TvOs } from 'assets/icons/ic_tvos_badge.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/ic_googleplay_badge.svg';
import { ReactComponent as AndroidTv } from 'assets/icons/ic_android_badge.svg';
import { ReactComponent as Lg } from 'assets/icons/ic_lg_badge.svg';
import { ReactComponent as Samsung } from 'assets/icons/ic_samsung_badge.svg';

// Import helpers
import { getRTLBooleanValue } from 'helpers/rtl';
import { getAppStoreUrls } from './helpers';

// Import styles
import { FooterStoreStyled } from './styles';

const FooterStore = () => {
	const { t } = useTranslation();

	const { direction } = useSelector((state) => state.direction);
	const isRtl = getRTLBooleanValue({ direction });

	const { androidUrl, appleUrl, lgUrl } = getAppStoreUrls({ isRtl });

	return (
		<FooterStoreStyled>
			<FooterStoreStyled.Text>
				{t('common_footer_download_apps')}
			</FooterStoreStyled.Text>
			<FooterStoreStyled.Items isRtl={isRtl}>
				<FooterStoreStyled.Item
					target="_blank"
					rel="noopener noreferrer"
					href={appleUrl}
				>
					<AppStore />
				</FooterStoreStyled.Item>
				<FooterStoreStyled.Item
					target="_blank"
					rel="noopener noreferrer"
					href={appleUrl}
				>
					<TvOs />
				</FooterStoreStyled.Item>
				<FooterStoreStyled.Item
					target="_blank"
					rel="noopener noreferrer"
					href={lgUrl}
				>
					<Lg />
				</FooterStoreStyled.Item>
				<FooterStoreStyled.Item
					target="_blank"
					rel="noopener noreferrer"
					href={androidUrl}
				>
					<GooglePlay />
				</FooterStoreStyled.Item>
				<FooterStoreStyled.Item
					target="_blank"
					rel="noopener noreferrer"
					href={androidUrl}
				>
					<AndroidTv />
				</FooterStoreStyled.Item>
				<FooterStoreStyled.Item as="div">
					<Samsung />
				</FooterStoreStyled.Item>
			</FooterStoreStyled.Items>
		</FooterStoreStyled>
	);
};

export default FooterStore;
