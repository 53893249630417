import { useSelector } from 'react-redux';

// Import translation
import { useTranslation } from 'react-i18next';

// Import helpers
import { getCodeErrorMessage } from 'helpers/error_code_api';

const useProductRatingCheck = (programRating, programStart) => {
	const { t } = useTranslation();
	const placeholderRatingText = t('parental_control_placeholder_text');
	const mockParentalControlError = {
		isError: true,
		code: 400,
		errorCode: 'BLOCKED_BY_PARENTAL_CONTROL',
		message: getCodeErrorMessage('BLOCKED_BY_PARENTAL_CONTROL')
	};

	// get user rating
	const userRating = useSelector((state) => state.auth?.authenticate?.rating);

	let isContentBlocked = false;

	// determine if program starting time is between 22:00 and 6:00
	let isAdultHours = false;
	if (programStart) {
		let startHour = new Date(programStart).getHours();
		isAdultHours = startHour >= 22 || startHour < 6;
	}

	// if there is no program rating, base program rating on program starting time
	if (programRating === null) {
		isAdultHours ? (programRating = 18) : (programRating = 0);
	}

	// if there is parental control and program rating is 18 or higher block program data
	if (userRating === null) isContentBlocked = false;
	else if (userRating < 18 && programRating >= 18) isContentBlocked = true;

	return {
		isContentBlocked,
		placeholderRatingText,
		mockParentalControlError
	};
};

export default useProductRatingCheck;
