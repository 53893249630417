import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const CountryFlagWrapper = styled.div`
	margin-left: 1.5rem;
`;

export const FormFieldStyled = styled.div`
	${({ isDialCodeField }) =>
		isDialCodeField &&
		css`
			direction: ltr;
			font-weight: bold;
			margin-bottom: 0;
			margin-right: 1.2rem;
			border: 1px solid ${({ theme }) => theme.authentication.select.border}
			border-radius: 0.5rem;
			${flex()}
		`}
`;

export const FormError = styled.span`
	display: block;
	margin-top: 1rem;
	font-size: 1.2rem;
	color: ${({ theme }) => theme.authentication.select.error};
	text-align: left;
	padding-left: 0.7rem;
`;
