import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import store
import store from './store/store';

const queryCache = new QueryCache();

const overrides = {
	queryCache,
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnWindowFocus: false
		}
	}
};

const queryClient = new QueryClient(overrides);

const Providers = ({ children }) => {
	const { i18n } = useTranslation();

	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter basename={i18n.language}>{children}</BrowserRouter>
			</QueryClientProvider>
		</Provider>
	);
};

export default Providers;
