import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { getAssignedSubscriptions } from './helpers';

// ******************** FETCH ACTIVE PAYMENT METHODS ********************
export const fetchActivePaymentMethods = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_LOADING,
			payload: true
		});

		const { data } = await services.get('/payments/paymentData/list');

		const { data: subscriptionsList } = await services.get(
			'/payments/subscriptions/list'
		);

		const activePaymentMethods = data.map((method) => {
			const subscriptions = subscriptionsList.filter(
				({ payment_data_id }) => payment_data_id === method.id
			);

			return { ...method, subscriptions };
		});

		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_SUCCESS,
			payload: activePaymentMethods
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_ERROR,
			payload: false
		});
	}
};

// ******************** FETCH_LAST_GATEWAY_TYPE_ ********************
export const fetchLastGatewayType = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_LOADING,
			payload: true
		});

		const { data } = await services.get('/payments/getLastGatewayType');

		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_SUCCESS,

			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_ERROR,
			payload: false
		});
	}
};

// ******************** Active  Subscriptions List ********************
export const fetchActiveSubscriptionsList = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_LOADING,
			payload: true
		});

		const { data } = await services.get('/payments/basicSubscriptions/list');

		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_ERROR,
			payload: false
		});
	}
};

// ******************** Assigned Subscriptions List ********************
export const fetchAssignedSubscriptionsList = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_ASSIGNED_SUBSCRIPTIONS_LOADING,
			payload: true
		});

		const { data: subscriberProducts } = await services.get(
			'subscriber/products'
		);

		const assignedSubscriptions = getAssignedSubscriptions(
			subscriberProducts.data
		);

		dispatch({
			type: types.FETCH_ASSIGNED_SUBSCRIPTIONS_SUCCESS,
			payload: assignedSubscriptions
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ASSIGNED_SUBSCRIPTIONS_ERROR,
			payload: false
		});
	}
};

// ******************** Subscription Deactivate ********************
export const deactivateSubscription = (id) => async (dispatch) => {
	try {
		const url = `/payments/paypal/subscription/${id}/cancel`;

		await services.post(url);

		// keeps subsctiptions list up-to-date
		await fetchActiveSubscriptionsList()(dispatch);
	} catch (error) {
		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('vod_root_error_title'),
				description: i18next.t('common_subscription_error_description')
			});
	}
};
