import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';

const FormField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'true',
	form: { touched, errors },
	placeholder
}) => {
	return (
		<FormFieldStyled>
			<Input
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={placeholder}
				{...field}
			/>
			{touched[field.name] && errors[field.name] && (
				<FormError>{errors[field.name]}</FormError>
			)}
		</FormFieldStyled>
	);
};

const FormFieldStyled = styled.div`
	margin-bottom: 1.5rem;
`;

const FormError = styled.span`
	display: block;
	margin-top: 1rem;
	font-size: 1.2rem;
	color: ${({ theme }) => theme.red};
	text-align: left;
	padding-left: 0.7rem;
`;

FormField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string.isRequired,
	autoComplete: PropTypes.string
};

export default FormField;
