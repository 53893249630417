import { useTranslation } from 'react-i18next';

const useSearchTypes = () => {
	const { t } = useTranslation();

	const searchTypes = {
		program: t('common_header_nav_epg'),
		channel: t('common_header_nav_channels'),
		vod: t('common_header_nav_movie'),
		series: t('common_header_nav_tv_shows'),
		season: t('common_header_nav_season'),
		episode: t('vod_movieDetails_episode'),
		packet: t('common_header_nav_packet'),
		provision: t('common_header_nav_packet'),
		collection: t('user_profile_products_filters_collection'),
		main: t('common_header_nav_packet')
	};

	const getSearchType = (type) => searchTypes[type];

	return getSearchType;
};

export default useSearchTypes;
