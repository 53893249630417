import { ASSIGNED_SUPSCRIPTION_TYPES } from 'helpers/variables';

const { PACKET, PAYMENT_METHOD_FREE } = ASSIGNED_SUPSCRIPTION_TYPES;

export const getAssignedSubscriptions = (data) => {
	const assignedSubscriptions = data.filter(
		(product) =>
			product.type === PACKET && product.payment_method === PAYMENT_METHOD_FREE
	);

	return assignedSubscriptions;
};
