// Import variables
import {
	APP_PROJECT_TYPES,
	FAVOURITES_AVAILABLE_TYPES,
	PLAYER_SELECTORS
} from 'helpers/variables';
import { ROUTES_CORE_PATHS } from './core_paths';

// Import StyledBorderButton styles
import {
	backgroundHovered,
	borderHovered,
	smartBackgroundHovered,
	smartBorderHovered
} from 'components/styles';

// get app name based on env file
export const getAppName = () => {
	const { TV_SMART, TV_ONLINE } = APP_PROJECT_TYPES;
	const appName =
		process.env.REACT_APP_PROJECT === TV_SMART ? TV_SMART : TV_ONLINE;

	return appName.toLowerCase();
};

// check if app type === tv smart
export const checkAppType = () => {
	const { TV_SMART } = APP_PROJECT_TYPES;
	const appName = getAppName();
	const isTvSmart = appName === TV_SMART.toLowerCase();

	return { isTvSmart, appName };
};

// get StyledBorderButton hover background gradient
export const getButtonHoverGradient = (background = null, border = null) => {
	const { isTvSmart } = checkAppType();

	const tvonlineBackground = background || backgroundHovered;
	const tvonlineBorder = border || borderHovered;

	const backgroundHover = isTvSmart
		? smartBackgroundHovered
		: tvonlineBackground;
	const borderHover = isTvSmart ? smartBorderHovered : tvonlineBorder;

	return { backgroundHover, borderHover };
};

// check player type based on url
export const checkPlayerType = (pathname) => {
	const { WATCH, PROGRAM_DETAILS, HOME } = ROUTES_CORE_PATHS;
	const { PLAYER, DETAILS, VOD } = PLAYER_SELECTORS;

	if (pathname.includes(WATCH)) {
		return VOD;
	} else if (pathname.includes(PROGRAM_DETAILS)) {
		return DETAILS;
	} else if (pathname.includes(HOME)) {
		return PLAYER;
	}

	return null;
};

// Find proper priceId by payment method in all payments list
export const getPriceIdByMethod = (paymentMethod, paymentOptions) => {
	// find payment
	const selectedPayment = paymentOptions.find(
		(payment) => payment.payment_method === paymentMethod
	);
	// return selected payment id
	return selectedPayment?.id;
};

// filter genres list with translations
export const filterProductGenres = ({ genresList, productGenres }) => {
	return genresList.filter(({ id }) =>
		productGenres.some((genre) => genre.id === id)
	);
};

// Suffle array
export const shuffledArray = (array) => array.sort(() => Math.random() - 0.5);

// Capitalize
export const capitalizeFirstLetter = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeWords = (string) => {
	return string.replace(/(?:^|\s)\S/g, function(a) {
		return a.toUpperCase();
	});
};

export const importIcon = ({ name, isCountryIcon }) => {
	try {
		if (isCountryIcon) return require(`../assets/icons/countries/${name}.svg`);

		return require(`../assets/icons/${name}.svg`);
	} catch (e) {
		return '';
	}
};

export const getUrlPC = (url_pc) => {
	const url = new URL(window.location.href);
	const urlPc = new URL(url_pc);
	const isExternal = url.host !== urlPc.host;
	const path = isExternal ? url_pc : urlPc.pathname;
	return { isExternal, path };
};

export const checkProperFavouriteProduct = (type) =>
	FAVOURITES_AVAILABLE_TYPES.includes(type);

export const replaceStringChar = ({
	string = '',
	regex = null,
	object = null,
	splitBy = '',
	joinBy = ''
}) => {
	if (regex && object) {
		return string.replace(regex, (char) => object[char]);
	}

	return string.split(splitBy).join(joinBy);
};

export const findChannel = (lives, channel_uuid) =>
	lives.find(({ uuid }) => uuid === channel_uuid);
