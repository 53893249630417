import React from 'react';
import { func } from 'prop-types';

// Import components
import NavSearchForm from './nav_search_form/NavSearchForm';

// Import styles
import { Search } from './styles';

const NavSearch = ({ toggle }) => (
	<Search>
		<NavSearchForm toggle={toggle} />
	</Search>
);

NavSearch.propTypes = {
	toggle: func.isRequired
};

export default NavSearch;
