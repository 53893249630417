import styled, { css } from 'styled-components';

export const Container = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	background: ${({ theme: { general } }) => general.body};
	${({ isMobile }) =>
		!isMobile &&
		css`
			min-width: 900px;
			overflow: hidden;
		`};
`;
