import i18n from 'i18n';

export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

export const projectlanguages = process.env.REACT_APP_LANGUAGES.split(',');

export const languagesOptions = projectlanguages.map((lang) => ({
	name: lang,
	value: lang
}));

export const hasSeveralLanguages = projectlanguages.length > 1;

export const getProductTitleTranslation = (defaultTitle, translations) => {
	const { language } = i18n;

	return translations?.[language]?.title ?? defaultTitle;
};

export const getChannelName = ({ title, translations }) => {
	const { language } = i18n;
	return translations?.[language]?.title ?? title;
};

export const getGenreName = ({ genres }) => {
	const { language } = i18n;
	const genre = genres?.[0];

	if (genre) {
		return genre.translations?.[language]?.name ?? genre.name;
	}

	return '';
};
