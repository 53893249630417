import React from 'react';
import { string, object, shape } from 'prop-types';

// Import styles
import { CodeFieldStyled, StyledInput } from './styles';

const CodeField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'false',
	form: { touched, errors }
}) => {
	return (
		<CodeFieldStyled>
			<StyledInput
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				error={errors[field.name]}
				{...field}
			/>
		</CodeFieldStyled>
	);
};

CodeField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	name: string,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	autoComplete: string
};

export default CodeField;
