import styled from 'styled-components';

// Import utilities
import { flex } from 'components/styles';

export const BaseStrap = styled.div`
	height: 0.7rem;
	width: 100%;
	background-color: ${({ theme }) => theme.player.slider.strap};
	border-radius: 0.6rem;
	${flex({ jc: 'start' })};
`;

export const LoadedStrap = styled.div.attrs(({ percent }) => ({
	style: {
		width: `${percent}%`
	}
}))`
	height: 100%;
	background-color: ${({ theme }) => theme.player.slider.backgroundColor};
	border-radius: 0.6rem;
`;
