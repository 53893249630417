import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { flex } from 'components/styles';

// Import assets
import Loader from 'assets/images/loader.gif';

const LOADER_MODIFIERS = {
	sections: ({ theme }) => css`
		position: static;
		height: calc(100vh - ${theme.nav.height}rem);
	`
};

export const LoaderWrapper = styled.div`
	position: ${({ position }) => position};
	top: ${({ top }) => top};
	left: 0;
	width: 100%;
	height: ${({ wrapperHeight }) => wrapperHeight};
	z-index: ${({ zIndex }) => zIndex};
	${flex()}

	${applyStyleModifiers(LOADER_MODIFIERS)};
`;

export const LoaderItem = styled.div`
	width: ${({ width }) => `${width}rem`};
	height: ${({ height }) => `${height}rem`};
	background-image: ${`url(${Loader})`};
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
`;
