import React from 'react';
import { useHistory } from 'react-router-dom';

//Import helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

const { PAGE, REGULATION } = APP_SUB_PATHS_KEY;

const RegulationRedirect = () => {
	const history = useHistory();

	const replacePath = `/${getNavigationSubPaths(PAGE)}/${getNavigationSubPaths(
		REGULATION
	)}?app=true`;

	React.useEffect(() => {
		history.replace(replacePath);
		// eslint-disable-next-line
	}, []);

	return null;
};

export default RegulationRedirect;
