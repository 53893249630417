import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { string, node } from 'prop-types';

// Import components
import { Loader, ErrorBoundary, Alert } from 'components/elements';
import Section from './Section';

// Import logic
import useSections from './useSections';

const loader = <Loader key="loader" modifiers="sections" />;

const Sections = ({ label, children }) => {
	const { t } = useTranslation();

	const {
		isSections,
		sectionsData,
		loadMore,
		hasNextPage,
		isLoading,
		isError
	} = useSections(label);

	return (
		<>
			{isLoading && loader}

			{isError && <Alert fixed={true} text={t('common_errors_connection')} />}

			{!isSections && children}

			<InfiniteScroll
				pageStart={0}
				loadMore={loadMore}
				hasMore={hasNextPage}
				loader={loader}
				threshold={1500}
			>
				{sectionsData.map(({ type, id, name, content, shuffle }) => (
					<ErrorBoundary key={id}>
						<Section
							type={type}
							id={id}
							name={name}
							content={content}
							label={label}
							shuffle={shuffle}
						/>
					</ErrorBoundary>
				))}
			</InfiniteScroll>
		</>
	);
};

Sections.propTypes = {
	label: string.isRequired,
	children: node
};

export default Sections;
