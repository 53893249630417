import styled, { css } from 'styled-components';

// Import styles
import { borderGradientTransparent, flex, rtl } from 'components/styles';

// Import variables
import { DIRECTION_TYPES } from 'helpers/variables';

const Box = styled.div`
	display: flex;
	width: 41.2rem;
	height: 28.1rem;
	flex-direction: column;
	text-align: center;
	background: ${({ theme }) => theme.payment.paymentOptions.box.bg};
	border-radius: ${({ theme }) =>
		theme.payment.paymentOptions.box.roundSize}rem;
	${({ theme }) =>
		borderGradientTransparent({
			width: 1,
			gradient: theme.payment.paymentOptions.box.border
		})}
`;

const Name = styled.p`
	font-size: 1.6rem;
	color: ${({ theme }) => theme.payment.paymentOptions.name.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.name.fontWeight};
	line-height: 2.1rem;
	margin-bottom: 1.8rem;

	${({ isPaymentMethod }) =>
		isPaymentMethod &&
		css`
			margin: 0.5rem 0 2.1rem 0;
		`}
`;

const Info = styled.p`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.payment.paymentOptions.info.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.info.fontWeight};
	margin-bottom: 2rem;
`;

const Price = styled.span`
	margin: 1rem 0 1.5rem;
	font-size: 4.2rem;
	color: ${({ theme }) => theme.payment.paymentOptions.price.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.price.fontWeight};
	opacity: ${({ theme }) => theme.payment.paymentOptions.price.opacity};
`;

const CurrentPrice = styled.div`
	margin-top: 3rem;
	${({ theme: { direction } }) =>
		direction === DIRECTION_TYPES.RTL &&
		css`
			${flex()};
			flex-direction: row-reverse;
		`};
`;

const AdditionalWrapper = styled.div`
	margin-top: 1rem;
	height: 4rem;
	${flex()};
`;

const AdditionalText = styled.p`
	${flex()};
	height: 100%;
	${rtl`
		margin-right: 1rem;
	`};
	color: ${({ theme }) => theme.payment.paymentOptions.additionalDescription};
	font-size: 1.2rem;
	line-height: 1.6rem;
	font-weight: ${({ theme }) => theme.font.semiMedium};
`;

const AdditionalDescription = styled.p`
	margin: 0 auto;
	width: 31.2rem;
	text-align: center;
	font-size: 1.2rem;
	font-weight: ${({ theme }) => theme.font.semiMedium};

	color: ${({ theme }) => theme.payment.paymentOptions.additionalDescription};
	${flex()}
`;

export {
	Box,
	Name,
	Info,
	Price,
	AdditionalText,
	AdditionalWrapper,
	CurrentPrice,
	AdditionalDescription
};
