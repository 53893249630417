import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';

// ******************** FETCH FAVOURITES ********************
export const fetchFavourites = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_FAVOURITES_LOADING,
			payload: true
		});

		const url = 'subscriber/bookmarks/favourite';

		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.FETCH_FAVOURITES_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_FAVOURITES_ERROR,
			payload: true
		});
	}
};

/**
 * function is responsible for delete favourite action and
 * refresh favourites list
 * @param {number} id
 * @returns {*}
 */
export const deleteFavourite = (id) => async (dispatch) => {
	try {
		dispatch({
			type: types.DELETE_FAVOURITE_LOADING,
			payload: id
		});

		const url = `subscriber/bookmarks/favourite/${id}`;

		await services.delete(url);

		// keeps favourites stare up-to-date
		await fetchFavourites()(dispatch);
	} catch (error) {
		dispatch({
			type: types.DELETE_FAVOURITE_ERROR,
			payload: true
		});

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('common_favourites_error_title'),
				description: i18next.t('common_favourites_error_description')
			});
	}
};

/**
 * this function is responsible for add favourite action and
 * refresh favourites list
 * @param {number} id
 * @returns {*}
 */
export const addFavourite = (id, type = 'channel') => async (dispatch) => {
	try {
		dispatch({
			type: types.ADD_FAVOURITE_LOADING,
			payload: true
		});

		const url = 'subscriber/bookmarks/favourite/create';

		await services.post(url, { productUuid: id, type });

		// keeps favourites stare up-to-date
		await fetchFavourites()(dispatch);
	} catch (error) {
		dispatch({
			type: types.ADD_FAVOURITE_ERROR,
			payload: true
		});

		openNotification({
			type: 'error',
			title: i18next.t('common_favourites_error_title'),
			description: i18next.t('common_favourites_error_description')
		});
	}
};
