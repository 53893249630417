import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Button } from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import TopHeadline from 'components/views/vod/components/PlayerWrapper/TopHeadline/TopHeadline';

// Import styles
import { IconPlayStyled } from '../styles';

const TheoPlayerInner = ({ isFocus, type }) => {
	const { error, handlePlayPause, showBigPlayButton } = usePlayerContent({
		type
	});

	return (
		<>
			<TopHeadline isFocus={isFocus} isError={error.isError} />
			{showBigPlayButton && (
				<Button
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={handlePlayPause}
				>
					<IconPlayStyled />
				</Button>
			)}
		</>
	);
};

TheoPlayerInner.propTypes = {
	type: PropTypes.string.isRequired,
	isFocus: PropTypes.bool.isRequired
};

export default TheoPlayerInner;
