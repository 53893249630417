import React from 'react';

// COMPONENTS
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';
import { Button, PlayerElements as Player } from 'components/elements';

const VolumeController = () => {
	const { volume, setVolume, mute, isMuted } = useContextProvider();

	const handleChangeVolume = (e) => {
		e.persist();
		// Get volume
		const value = e.target.value;
		// Set volume in player provider
		setVolume(value);
	};

	return (
		<Player.Wrapper>
			<Button onClick={mute} modifiers={['icon', 'playerIconHover']}>
				{!isMuted ? <Player.IconVolume /> : <Player.IconVolumeOff />}
			</Button>
			<Player.RangeWrapper>
				<Player.Volume>
					<Player.Range
						value={volume}
						onChange={handleChangeVolume}
						type="range"
						name="volume"
						className="volume_range"
						id="volume"
						min="0"
						max="100"
						step="1"
					/>
				</Player.Volume>
			</Player.RangeWrapper>
		</Player.Wrapper>
	);
};

export default VolumeController;
