import React from 'react';
import { string, number, bool } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import helpers
import { importIcon } from 'helpers';

const DynamicIcon = ({ icon, width, height, isCountryIcon = false }) => {
	if (!icon) return null;
	const src = importIcon({ name: icon, isCountryIcon });
	return (
		<LazyLoadImage
			height={height}
			width={width}
			src={src}
			effect="blur"
			delayTime={0}
		/>
	);
};

DynamicIcon.propTypes = {
	icon: string.isRequired,
	width: number,
	height: number,
	isCountryIcon: bool
};

export default DynamicIcon;
