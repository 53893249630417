import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import context
import { useContextProvider } from '../useContextProvider';

// Import redux actions
import { deleteVideoSession } from 'store/actions';

// Import helpres
import { NEXT_EPISODE_SHOW_TIME, PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from 'components/views/vod/helpers/player';

const playerSelector = PLAYER_SELECTORS.VOD;

const useNextEpisode = ({ currentTimeProgress }) => {
	const dispatch = useDispatch();
	const { getState } = useStore();

	const { type } = useParams();

	const { duration, segmentsData } = useContextProvider();

	const { episodes } = useSelector(({ movieDetails }) => movieDetails);
	const { isRtl } = useSelector(({ direction }) => direction);

	const [currentTime, setCurrentTime] = useState(0);

	const { uuid: id, number } = episodes?.data?.next || episodes?.next;

	const nextEpisodeSegmentData = segmentsData?.nextEpisode;
	const startTimeNextEpisode = nextEpisodeSegmentData?.start_time_in_seconds;

	// After this time, next episode is disabled
	const nextEpisodeEnd = duration - NEXT_EPISODE_SHOW_TIME;
	const disabledNextEpisode = currentTime >= nextEpisodeEnd;

	const showNextEpisode =
		!disabledNextEpisode && currentTime >= startTimeNextEpisode;

	const handlePlayNextEpisode = async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(dispatch, getState);
		// Fetch movie resources
		await fetchMovieResources({ id, type, dispatch, getState });
	};

	useEffect(() => {
		currentTimeProgress((currentTime) => setCurrentTime(currentTime));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		showNextEpisode,
		id,
		number,
		isRtl,
		playNextEpisode: handlePlayNextEpisode
	};
};

export default useNextEpisode;
