import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const Wrapper = styled.div`
	margin: 3.7rem auto;
	width: 61rem;
	${flex()}
`;
