import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getVodBuildFlag } from 'helpers/build_conditions';

// ******************** FETCH SEARCH QUERY ********************
export const fetchSearchQuery = (query, results = 100) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_SEARCH_QUERY_LOADING,
			payload: { query }
		});

		const buildVod = getVodBuildFlag();
		const noVod = buildVod ? '' : '&noVod=1';

		const url = `/products/search?q=${query}&limit=${results}&offset=0${noVod}`;

		// Get tv categories
		const {
			data: { data }
		} = await services.get(url);

		// CURRENTLY CHANNELS AND PROGRAMS ARE HIDDEN
		const filteredData = data.filter(
			({ type }) => type !== 'channel' && type !== 'program'
		);

		// Dispatch an action
		dispatch({
			type: types.FETCH_SEARCH_QUERY_SUCCESS,
			payload: { items: filteredData }
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_SEARCH_QUERY_ERROR,
			payload: true
		});
	}
};
