import React from 'react';

// Import components
import Providers from './Providers';
import App from './components/views/App';

const Root = () => (
	<Providers>
		<App />
	</Providers>
);

export default Root;
