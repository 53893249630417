import sliderReducer from './sliderReducer';
import * as TYPES from './sliderActionTypes';
import {
	STRIPES_URL,
	FRAMES_PER_STRIPE,
	TIME_PER_FRAME
} from 'helpers/variables';
export * from './style';

// initial state for useSlider
const initialState = {
	contWidth: 0,
	draggable: false,
	translateX: 0,
	shownPercent: 0,
	cursorPosition: 0,
	isPopoverVisible: false,
	isPointVisible: false
};

// get preview stripe url and current preview frame
const getPreviewData = ({ seekTime, productID, videoId }) => {
	// display time of single stripe
	const timePerStripe = TIME_PER_FRAME * FRAMES_PER_STRIPE;
	// current stripe index based on cursor position
	const currentIndex = getCurrentStripeIndex(seekTime, timePerStripe);
	// number of current frame to display
	const currentFrame =
		parseInt(seekTime / TIME_PER_FRAME) - currentIndex * FRAMES_PER_STRIPE;
	// preview image url
	const imgUrl = `${STRIPES_URL}/${productID}/${videoId}/stripes/stripe_${currentIndex}.jpg`;

	return { imgUrl, currentFrame };
};

// get current stripe index
const getCurrentStripeIndex = (seekTime, timePerStripe) => {
	// current stripe index based on cursor position
	const index = Math.ceil(seekTime / timePerStripe - 1);
	return index < 1 ? 0 : index;
};

const percent = (fraction, totality) => (100 * fraction) / totality;

const getShownPercent = (currentClientX, contWidth, prev) => {
	const { clientX: prevClientX, maxInLeft, maxInRight } = prev.current;

	// difference in milliseconds
	const difference = currentClientX - prevClientX;

	// milliseconds from beginning to current moment
	const newClientX = maxInLeft + difference;

	// percent from beginning to current moment
	let shownPercent;

	if (difference < 0 && Math.abs(difference) > maxInLeft) {
		shownPercent = 0;
	} else if (difference > maxInRight) {
		shownPercent = 100;
	} else {
		shownPercent = percent(newClientX, contWidth);
	}

	return shownPercent;
};

export {
	initialState,
	sliderReducer,
	TYPES,
	getPreviewData,
	getShownPercent,
	percent
};
