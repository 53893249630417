// Import heleprs
import { capitalizeFirstLetter } from 'helpers/common';

export const getTrackingOptions = (str) => {
	const idRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	const uuid = str.match(idRegex);
	const id = uuid || null;

	if (id) {
		const arr = str.split('/');
		const productSlug = arr[arr.length - 1];
		const isProductId = productSlug.match(idRegex);

		if (isProductId) {
			return { uuid: uuid[0] };
		}

		const [word, ...rest] = productSlug.split('-');
		const wordCapitalize = capitalizeFirstLetter(word);
		const productTitle = [wordCapitalize, ...rest].join(' ');
		return { productTitle, uuid: uuid[0] };
	}

	return { productTitle: '', uuid: null };
};
