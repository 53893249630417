export const PAGE_TITLES = {
	TERMS_AND_CONDITIONS: 'Terms and Conditions',
	PRIVACY_POLICY: 'Privacy Policy',
	ABOUT_US: 'About us',
	RODO: 'RODO',
	CONTACT: 'Contact Us',
	SUPPORT: 'Support',
	VOD: 'VOD',
	SERIRES: 'Series',
	WATCH: 'Watch',
	DETAILS: 'Details',
	CATEGORY_DETAILS: 'Category Details',
	PRODUCT_DETAILS: 'Product Details',
	PROGRAM: 'Program',
	CHANNELS: 'Channels',
	HOME: 'Home',
	EPG: 'Epg'
};
