// Import variables
import { SPECIAL_PRICE_COUNTRIES_CODES } from 'helpers/variables';

export const getPacketCover = ({
	translations,
	language,
	subscriptionsData,
	uuid,
	countryCode
}) => {
	const isPacketActivated = !!subscriptionsData.find(
		(subscription) =>
			subscription.product_uuid === uuid || subscription.uuid === uuid
	);

	const currentData = translations?.[language];

	const isSpecialPriceCountry = SPECIAL_PRICE_COUNTRIES_CODES.includes(
		countryCode?.toUpperCase()
	);

	const subscribedImage = isSpecialPriceCountry
		? 'subscribed_image_link_iraq'
		: 'subscribed_image_link';
	const notSubscribedImage = isSpecialPriceCountry
		? 'not_subscribed_image_link_iraq'
		: 'not_subscribed_image_link';

	return isPacketActivated
		? currentData?.[subscribedImage]
		: currentData?.[notSubscribedImage];
};
