import { DEVICES_LIMIT_EXCEEDED } from 'helpers/variables';
import i18next from 'i18next';

export const checkUserResources = (user) => {
	// Check if there is a device limit
	let isDeviceLimited = false;
	if (user.status.limits) {
		const findLimits = user.status.limits.find(
			({ name }) => name === DEVICES_LIMIT_EXCEEDED
		);
		if (findLimits) {
			isDeviceLimited = true;
		}
	}

	const missingAgreements = user.status.missingAgreements.map((agreement) => ({
		...agreement,
		value: false
	}));

	// Check if device exists
	let deviceExists = user.status.deviceName ? true : false;
	// Check if user accepts all agreements
	const accepetedAgreements = missingAgreements.length > 0 ? false : true;
	// If there is a device limit, get the message
	const limitMessage = isDeviceLimited
		? i18next.t('common_devices_limits_error')
		: '';
	// If there is device limit or device does not exists or all agreements are not accepted
	const isLogged =
		isDeviceLimited || !deviceExists || !accepetedAgreements ? false : true;

	const status = {
		...user.status,
		agreements: missingAgreements,
		limits: isDeviceLimited
	};

	// Create user data
	const userData = {
		...user,
		status,
		limitMessage,
		isAuthenticated: true,
		isDeviceLimited,
		isLogged,
		deviceExists,
		accepetedAgreements,
		raiting: null
	};

	return userData;
};

export const checkUserPackets = (packets, products) =>
	products.reduce((acc, next) => {
		const findPacket = packets.find(({ uuid }) => uuid === next);
		findPacket && acc.push(findPacket);
		return acc;
	}, []);
