import { css } from 'styled-components';

// Import styles
import { rtl } from './Rtl';

// import helpers
import { checkAppType } from 'helpers';

// Icons
export const iconClock = () => css`
	height: 1.4rem;
	width: 1.4rem;
	margin: 0 0.5rem;

	g,
	path {
		fill: ${({ theme }) => theme.grayMedium};
	}
`;

export const iconController = css`
	height: 5rem;
	width: 5rem;
	path {
		transition: all 0.3s ease-in-out;
		fill: ${({ theme }) => theme.player.grayLight};
	}
`;

export const iconSearch = (
	height = 2.6,
	width = 2.6,
	color = 'grayMedium'
) => css`
	height: ${height}rem;
	width: ${width}rem;

	path {
		fill: ${({ theme }) => theme[color]};
	}
`;

// Row
export const row = () => css`
	width: 117rem;
	height: 100%;
	margin: 0 auto;
	padding: 0 1.5rem;
`;

// Flex
export const flex = ({ jc = 'center', ai = 'center', fd = 'row' } = {}) => css`
	display: flex;
	flex-direction: ${fd};
	justify-content: ${jc};
	align-items: ${ai};
`;

export const flexColumn = () => css`
	display: flex;
	flex-direction: column;
`;

// Linear gradient
export const linearGradient = ({
	deg = 'to right',
	yper = '0',
	rper = '100%',
	mainTheme = 'primaryLight',
	secondTheme = 'primary'
} = {}) => css`
	background: linear-gradient(
		${deg},
		${({ theme }) => theme[mainTheme]} ${yper},
		${({ theme }) => theme[secondTheme]} ${rper}
	);
`;

export const linearGradientGray = ({
	deg = 'to right',
	yper = '0',
	rper = '100%'
} = {}) => css`
	background: linear-gradient(
		${deg},
		${({ theme }) => theme.progressbar.default} ${yper},
		${({ theme }) => theme.progressbar.default} ${rper}
	);
`;

// Scroll
export const scroll = ({ track, thumb, scrollbarWdth = '12' } = {}) =>
	css`
		&::-webkit-scrollbar-track {
			background-color: ${({ theme }) => theme[track]};
		}

		&::-webkit-scrollbar {
			width: ${scrollbarWdth}px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme[thumb]};
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}
	`;

// Ant Modal
export const antModal = () => css`
	&& {
		.ant-modal-body {
			button {
				background: ${({ theme }) => theme.buttonDefault.bg};
				border-color: ${({ theme }) => theme.buttonDefault.border};
				color: ${({ theme }) => theme.buttonDefault.text};
			}
		}

		.ant-modal-title {
			text-align: center;
			font-size: 2.4rem;
			color: ${({ theme }) => theme.grayMedium};
			font-family: 'Noto', sans-serif;
			margin: 0.5rem 0;
			line-height: 1.2;
		}

		.ant-modal-footer {
			display: none;
		}

		.ant-modal-content,
		.ant-modal-header {
			background-color: ${({ theme }) => theme.modal.bg};
		}

		.ant-modal-header {
			border-bottom: transparent;
		}

		.ant-modal-close {
			color: ${({ theme }) => theme.white};
			${rtl`
				right: 0;
				left: auto;
			`}
		}
	}
`;

// Transitions
export const transitionMain = () => css`
	transition: all 0.3s ease-in-out 0s;
`;

// TV Smart
export const getNavLinkUnderline = () => {
	const { isTvSmart } = checkAppType();

	if (isTvSmart) {
		return css`
			background-image: linear-gradient(
				to right,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorStart} 0,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorMiddle} 50%,
				${({ theme }) => theme.activeGradientLine.tvsmart.colorEnd} 100%
			);
		`;
	}

	return css`
		background-image: linear-gradient(
			to right,
			${({ theme }) => theme.activeGradientLine.colorStart} 0,
			${({ theme }) => theme.activeGradientLine.colorEnd} 100%
		);
	`;
};

// Section background
export const sectionBackground = (image, isSectionDetails = false) => {
	const backgroundAttachment = isSectionDetails ? 'fixed' : 'unset';

	return css`
		background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url(${image}) no-repeat center top/cover;
		background-attachment: ${backgroundAttachment};
	`;
};

export const borderGradient = ({
	borderSize = 3,
	borderBg = '#fff',
	borderOpacity
}) => css`
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: -${borderSize}px;
		left: -${borderSize}px;
		border-radius: inherit;
		width: calc(100% + ${borderSize * 2}px);
		height: calc(100% + ${borderSize * 2}px);
		background: ${borderBg};
		z-index: -1;

		${borderOpacity &&
			css`
				opacity: ${borderOpacity};
			`}
	}
`;

export const menuGradient = ({
	deg = 0,
	start,
	end,
	transTime = '500ms'
}) => css`
	background-size: 100%;
	position: relative;
	z-index: 100;

	&:before {
		background-image: ${({ isRtl }) =>
			isRtl
				? `linear-gradient(${deg}, ${end}, ${start})`
				: `linear-gradient(${deg}, ${start}, ${end})`};

		content: '';
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		z-index: -100;
		transition: opacity ${transTime} ease-in-out;
	}

	&:hover,
	&:active,
	&:focus {
		&:before {
			opacity: 1;
		}
	}

	&.active-nav {
		&:link,
		&:visited {
			&:before {
				opacity: 1;
			}
		}
	}
`;

export const borderGradientTransparent = ({ width = 1, gradient }) => css`
	position: relative;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: inherit;
		padding: ${width}px; /* control the border thickness */
		background: ${gradient};
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
`;

export const textEllipsis = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
