import { useState, useEffect } from 'react';

// Import helpers
import { createNavList } from 'helpers/index';

// Import utilities
import { useAuth } from 'components/utilities';

const useNavList = (categories) => {
	const [navList, setNavList] = useState([]);
	const { isAuth } = useAuth();

	useEffect(() => {
		const navList = createNavList(categories);
		const authNavList = navList.filter(
			({ id }) => !!isAuth || id !== 'favourite'
		);
		setNavList(authNavList);
	}, [categories, isAuth]);

	return navList;
};

export default useNavList;
