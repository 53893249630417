import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import assets
import NoImage from 'components/views/vod/images/placeholder.svg';

const Figure = styled.figure`
	width: 100%;
	height: ${({ bannerHeight }) =>
		bannerHeight ? `${bannerHeight}px` : '100%'};
	position: relative;
	margin: 0;
`;

const Image = styled.img`
	width: 100%;
	height: 100%;
	background-color: ${({ theme, isPlaceholder }) =>
		isPlaceholder ? theme.placeholder : 'transparent'};
`;

const Caption = styled.figcaption`
	position: absolute;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 2rem;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme }) => theme.white};
`;

const BannerImage = ({ bannerImage, title, bannerHeight }) => {
	const [isImageError, setImageError] = useState(false);

	const handleImageError = () => setImageError(true);

	const imageUrl = isImageError ? NoImage : bannerImage;
	const isPlaceholder = imageUrl === NoImage;

	return (
		<Figure bannerHeight={bannerHeight}>
			<Image
				src={imageUrl}
				alt={title}
				isPlaceholder={isPlaceholder}
				onError={handleImageError}
			/>
			{isImageError && <Caption>{title}</Caption>}
		</Figure>
	);
};

BannerImage.propTypes = {
	bannerImage: PropTypes.string,
	title: PropTypes.string,
	bannerHeight: PropTypes.number
};

export default BannerImage;
