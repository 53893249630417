export const buttons = {
	gradientButton: {
		backgroundDefault: {
			color1: 'rgba(255,255,255,0.2)',
			color2: 'rgb(250,176,171,0.2)',
			color3: 'rgba(242,103,92,0.2)',
			color4: 'rgba(239,73,60,0.2)'
		},
		backgroundHovered: {
			color1: 'rgba(255,216,216,0.4)',
			color2: 'rgba(250,176,171,0.4)',
			color3: 'rgba(242,103,92,0.4)',
			color4: 'rgba(239,73,60,0.4)'
		},
		borderDefault: {
			color1: 'rgba(255,255,255,0.4)',
			color2: 'rgba(222,179,176,0.74)',
			color3: 'rgba(239,73,60, 0.6)'
		},
		borderHovered: {
			color1: 'rgba(255,255,255,0.4)',
			color2: 'rgba(222,179,176,0.74)',
			color3: 'rgba(239,73,60, 0.8)'
		},
		smartBackgroundHovered: {
			color1: 'rgba(154,104,0,0.2)',
			color2: 'rgba(203,147,30,0.2)',
			color3: 'rgba(234,176,54,0.2)',
			color4: 'rgba(255,235,0,0.2)'
		},
		smartBorderHovered: {
			color1: 'rgba(255,243,105,0.74)',
			color2: 'rgba(234,176,54,0.2)',
			color3: 'rgb(154,104,0)'
		}
	},
	priceButton: {
		backgroundDefaultKids: {
			color1: 'rgba(255,255,255,0.2)',
			color2: 'rgb(250,176,171,0.2)',
			color3: 'rgba(242,103,92,0.2)',
			color4: 'rgba(239,73,60,0.2)'
		},
		backgroundHoveredKids: {
			color1: 'rgba(255,216,216,0.4)',
			color2: 'rgba(250,176,171,0.4)',
			color3: 'rgba(242,103,92,0.4)',
			color4: 'rgba(239,73,60,0.4)'
		}
	},
	sectionButton: {
		background: {
			color1: 'rgba(1,1,1,0.4)',
			color2: 'rgba(1,1,1,0)',
			color3: 'rgba(255,255,255,0)'
		},
		border: {
			color1: 'rgba(255,255,255,0.5)',
			color2: 'rgba(255,255,255,0.8)',
			color3: 'rgba(186,186,186,0.37)'
		}
	},
	vodSectionButton: {
		backgroundHovered: {
			color1: 'rgba(2,27,56)',
			color2: 'rgba(8,56,83)'
		},
		borderGradient: {
			color1: 'rgba(255,255,255,0.2)',
			color2: 'rgba(255,255,255,0.5)',
			color3: 'rgba(255,255,255,0.2)'
		}
	},
	favouriteButtonInherit: {
		background: {
			color1: 'rgba(1,1,1,0.4)',
			color2: 'rgba(1,1,1,0)',
			color3: 'rgba(255,255,255,0)'
		},
		backgroundKids: {
			color1: 'rgba(1,1,1,0.5)',
			color2: 'rgba(1,1,1,0.3)',
			color3: 'rgba(0,0,0,0.3)'
		},
		border: {
			color1: 'rgba(255,255,255,0.5)',
			color2: 'rgba(255,255,255,0.8)',
			color3: 'rgba(186,186,186,0.37)'
		}
	},
	dropdownButton: {
		buttonGlow: {
			color1: 'rgba(255, 255, 255, 1)',
			color2: 'rgba(45, 104, 144, 0)',
			color3: 'rgba(45, 104, 144, 0)'
		},
		gradient: {
			color1: 'rgba(230, 207, 93, 0.4)',
			color2: 'rgba(176, 127, 39, 0.4)',
			color3: 'rgba(176, 127, 39, 0.4)'
		},
		divider: {
			color1: 'rgba(255, 255, 255, 0.15)',
			color2: 'rgba(255, 255, 255, 0.1)',
			color3: 'rgba(255, 255, 255, 0.05)',
			color4: 'rgba(255, 255, 255, 0)',
			color5: 'rgba(255, 255, 255, 0)',
			color6: 'rgba(255, 255, 255, 0)'
		}
	},
	menuButton: {
		buttonGlow: {
			color1: 'rgba(239, 73, 60, 0.4)',
			color2: 'rgba(242, 103, 92, 0.4)',
			color3: 'rgba(45, 104, 144, 0)',
			color4: 'rgba(45, 104, 144, 0)'
		}
	}
};
