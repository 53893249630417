import React from 'react';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';
import { PlayerElements as Player, Button } from 'components/elements';

// Import styles
import {
	Bg,
	Box,
	Content,
	Setting,
	WrapperStyled,
	HeadingStyled,
	IconSettingsStyled
} from 'components/views/vod/components/PlayerWrapper/player_controllers/settings_controller/styles';

const SettingsController = () => {
	const {
		profiles,
		selectedProfile,
		setProfile,
		subtitles,
		selectedSubtitle,
		setSubtitle,
		languages,
		selectedLanguage,
		setLanguage,
		isSafari
	} = useContextProvider();

	const { t } = useTranslation();

	// Set profile in player provider
	const handleSetProfile = (values) => () => setProfile(values);
	const handleSetSubtitle = (id) => () => setSubtitle(id);
	const handleSetLanguage = (id) => () => setLanguage(id);

	const renderProfile = ({ id, height, bandwidth }) => (
		<Player.Profile
			onClick={handleSetProfile({ id, bandwidth })}
			profile={id}
			selected={selectedProfile}
			key={id}
		>
			<span>{`${height}p`}</span>
		</Player.Profile>
	);

	const renderSubtitle = ({ id, language }) => (
		<Player.Profile
			onClick={handleSetSubtitle(language)}
			profile={language}
			selected={selectedSubtitle}
			key={id}
		>
			<Player.IconTick /> <span>{language.toUpperCase()}</span>
		</Player.Profile>
	);
	const renderLanguage = (language, index) => (
		<Player.Profile
			onClick={handleSetLanguage(language)}
			profile={language}
			selected={selectedLanguage}
			key={index}
		>
			<Player.IconTick /> <span>{language.toUpperCase()}</span>
		</Player.Profile>
	);

	const hasVideoProfile = profiles && selectedLanguage;

	return (
		<WrapperStyled>
			<Button modifiers={['icon', 'playerIconHover']}>
				<IconSettingsStyled />
			</Button>

			<Box>
				<Bg />
				<Content>
					{languages.length > 1 && (
						<Setting>
							<HeadingStyled as={'h4'}>
								{t('player_settings_language')}
							</HeadingStyled>
							<Player.Profiles>{languages.map(renderLanguage)}</Player.Profiles>
						</Setting>
					)}
					{subtitles.length > 0 && (
						<Setting>
							<HeadingStyled as={'h4'}>
								{t('player_settings_subtitles')}
							</HeadingStyled>
							<Player.Profiles>
								<Player.Profile
									onClick={handleSetSubtitle('disabled')}
									profile="disabled"
									selected={selectedSubtitle}
								>
									<Player.IconTick />{' '}
									<span>{t('player_settings_subtitles_disabled')}</span>
								</Player.Profile>

								{subtitles.map(renderSubtitle)}
							</Player.Profiles>
						</Setting>
					)}

					<Setting>
						<HeadingStyled as={'h4'}>
							{t('player_settings_profiles')}
						</HeadingStyled>
						<Player.Profiles>
							{hasVideoProfile &&
								!isSafari &&
								profiles[selectedLanguage].map(renderProfile)}

							<Player.Profile
								onClick={handleSetProfile({ id: 'auto' })}
								profile="auto"
								selected={selectedProfile}
							>
								<span>Auto</span>
							</Player.Profile>
						</Player.Profiles>
					</Setting>
				</Content>
			</Box>
		</WrapperStyled>
	);
};

export default SettingsController;
