import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/app-check';

export const initializeFirebaseApp = () => {
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID,
		measurementId: process.env.REACT_APP_APP_MEASUREMENT_ID
	};

	const reCaptchaEnterpriseSiteKey =
		process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY;

	firebase.initializeApp(firebaseConfig);

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-restricted-globals
		self.FIREBASE_APPCHECK_DEBUG_TOKEN =
			process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
	}

	const appCheck = firebase.appCheck();
	appCheck.activate(
		new firebase.appCheck.ReCaptchaEnterpriseProvider(
			reCaptchaEnterpriseSiteKey
		),
		true
	);

	firebase.analytics();
};

export const hasFirebaseAuthentication = !!process.env.REACT_APP_FIREBASE_AUTH;
