import styled, { css } from 'styled-components';

// Import components
import { Progress } from 'components/elements';

// Import styles
import { flex } from 'components/styles';

export const Channel = styled.div`
	padding: 0.8rem 1.2rem 0 0.4rem;
	display: flex;
	height: 100%;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;

	&:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}

	${({ isSelected }) =>
		isSelected &&
		css`
			background-color: rgba(255, 255, 255, 0.2);
		`}
`;

export const Content = styled.div`
	display: flex;
	width: 100%;
`;

export const LogoBox = styled.div`
	padding: 0.8rem 1.2rem 0 0.4rem;
	margin-bottom: 0.5rem;
	height: 100%;
	width: 8rem;
	float: left;
	margin-right: 0.8rem;
	${flex()}
`;

export const Figure = styled.figure`
	position: relative;
	height: 6rem;
	width: 6rem;
	background-color: ${({ theme }) => theme.channels.bg};
	border-radius: 0.3rem;
	overflow: hidden;
`;

export const Logo = styled.img`
	width: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const Description = styled.div`
	width: calc(100% - 9rem);
	color: ${({ theme }) => theme.white};
`;

export const ChannelName = styled.p`
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.8rem;
`;

export const ProgressBarStyled = styled(Progress.Bar)`
	width: 100%;
	margin: 0.4rem 0;
`;
