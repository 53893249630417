import { Menu, Dropdown } from 'antd';

import styled, { css } from 'styled-components';

// Import styles
import { flex, responsive, rtl, transitionMain } from 'components/styles';
import { Nav } from 'components/elements/molecules/dropdown/styles';
import { ItemStyled } from 'components/elements/molecules/dropdown/styles';

// Import components
import { Button } from 'components/elements';

// Import helpers
import { getFontFamily } from 'helpers/rtl';

export const ButtonStyled = styled(Button)`
	padding: 0.6rem 1.6rem;
	background-color: ${({ theme }) => theme.nav.loginButton.bg};
	font-weight: ${({ theme }) => theme.font.regular};
	font-size: 1.4rem;
	${flex({ ai: 'center' })}
`;

export const DropdownStyled = styled(Dropdown)`
	${transitionMain()}

	${({ isDropdownOpened }) =>
		isDropdownOpened &&
		css`
			svg {
				transform: rotate(180deg);
			}
		`}
`;

export const MenuStyled = styled(Menu)`
	margin-top: 1.3rem;
	border-radius: 0;

	.ant-dropdown-menu-item-divider {
		width: 90%;
		height: 0.5px;
		margin: 0 auto;
		background-color: ${({ theme: { dropdown } }) => dropdown.menu.itemDivider};
	}
`;

export const StyledNav = styled(Nav)`
	font-family: ${({ isRtl }) => getFontFamily(isRtl)};
`;

export const ButtonText = styled.span`
	direction: ltr;
	width: max-content;
	display: flex;
	${flex()}
	font-weight: ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.dropdown.button.color};
	${responsive.medium(css`
		display: none;
	`)}

	svg {
		margin-left: 0.9rem;
		width: 1.1rem;
	}
`;

export const ButtonItem = styled(ItemStyled)`
	padding: 0;
`;

export const ButtonNav = styled(Button)`
	font-weight: ${({ theme }) => theme.font.regular};
	font-family: ${({ isRtl }) => getFontFamily(isRtl)};
	font-size: 1.4rem;
	background-color: transparent;
	border-radius: 0;
	border: none;
	color: ${({ theme }) => theme.nav.dropdown.item};
	padding: 1rem 3rem;
	width: 100%;
	${rtl`text-align: left;`}

	&:focus,
	&:hover {
		background: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};

		${({ isRtl }) =>
			isRtl &&
			css`
				background: ${({ theme: { dropdown } }) =>
					dropdown.menu.itemHoverBgRtl};
			`};
	}
`;
