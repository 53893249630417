import React from 'react';
import {
	string,
	number,
	node,
	bool,
	func,
	oneOfType,
	arrayOf
} from 'prop-types';

// Import components
import { DynamicIcon, Loader } from 'components/elements';

// Import styles
import { FogBackground, Content, Title, Description } from './styles';

// Import utilities
import { Portal } from 'components/utilities';

const CommonModal = ({
	title,
	description,
	children,
	isContentLoading = false,
	isSmallDescription = false,
	width = 30,
	icon,
	close
}) => {
	return (
		<Portal>
			<FogBackground onClick={close}>
				<Content width={width} onClick={(e) => e.stopPropagation()}>
					{isContentLoading ? (
						<Loader position="absolute" background={false} width={10} />
					) : (
						<>
							{title && <Title>{title}</Title>}

							{icon && <DynamicIcon icon={icon} width={130} height={130} />}

							{description && (
								<Description isSmallDescription={isSmallDescription}>
									{description}
								</Description>
							)}

							{children}
						</>
					)}
				</Content>
			</FogBackground>
		</Portal>
	);
};

CommonModal.propTypes = {
	title: string,
	description: string,
	children: oneOfType([node, arrayOf(node)]),
	isContentLoading: bool,
	isSmallDescription: bool,
	width: number,
	icon: string,
	close: func.isRequired
};

export default CommonModal;
