import React from 'react';
import { string, array, bool } from 'prop-types';

// Import components
import {
	CarouselSlider,
	RegularSlider,
	PromotionSlider,
	BannersSlider,
	GenresSlider,
	VirtualSections,
	CoverSlider
} from 'components/views/vod/components/sliders';
import TvOnline from 'components/views/tv/home/tv_online/TvOnline';

// Import variables
import { SECTIONS_TYPES } from 'helpers/variables';

const {
	BANNER,
	NORMAL,
	VIRTUAL,
	PROMOTED,
	CAROUSEL,
	LIVE_NOW,
	NORMAL_COVER,
	LARGE
} = SECTIONS_TYPES;

const Section = React.memo(
	({ type, id, name, content, label, backgroundImage, shuffle = false }) => {
		switch (type) {
			case LIVE_NOW:
				return <TvOnline id={id} title={name} />;

			case VIRTUAL:
				return (
					<VirtualSections
						label={label}
						id={id}
						name={name}
						backgroundImage={backgroundImage}
						shuffle={shuffle}
					/>
				);

			case CAROUSEL:
				return (
					<CarouselSlider
						itemsList={content}
						id={id}
						name={name}
						shuffle={shuffle}
					/>
				);

			case NORMAL:
				const isBanner = content[0]?.type === 'banner';
				return isBanner ? (
					<GenresSlider
						id={id}
						name={name}
						content={content}
						backgroundImage={backgroundImage}
						shuffle={shuffle}
					/>
				) : (
					<RegularSlider
						id={id}
						name={name}
						content={content}
						label={label}
						backgroundImage={backgroundImage}
						shuffle={shuffle}
					/>
				);

			case NORMAL_COVER:
				return (
					<CoverSlider
						id={id}
						name={name}
						content={content}
						label={label}
						backgroundImage={backgroundImage}
						shuffle={shuffle}
					/>
				);

			case LARGE:
				return (
					<CoverSlider
						id={id}
						name={name}
						content={content}
						label={label}
						backgroundImage={backgroundImage}
						itemResolutionHeight={19.2}
						itemResolutionWidth={34}
						itemsList={content}
						itemsToShow={4.2}
						spaceBetweenItems={17}
						shuffle={shuffle}
					/>
				);

			case PROMOTED:
				return (
					<PromotionSlider
						id={id}
						name={name}
						content={content}
						label={label}
						backgroundImage={backgroundImage}
						shuffle={shuffle}
					/>
				);

			case BANNER:
				return <BannersSlider id={id} items={content} />;

			default:
				return null;
		}
	},
	(prev, next) => prev.id === next.id
);

Section.propTypes = {
	type: string.isRequired,
	id: string.isRequired,
	name: string.isRequired,
	content: array.isRequired,
	label: string.isRequired,
	backgroundImage: string,
	shuffle: bool
};

export default Section;
