import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { ShakaPlayerContent } from './shaka_player';
import { TheoPlayerInner } from './theo_player';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const PlayerContent = React.memo(
	({ isFocus, type }) => {
		switch (DEFAULT_PLAYER) {
			case THEO_PLAYER:
				return <TheoPlayerInner isFocus={isFocus} type={type} />;

			default:
				return <ShakaPlayerContent isFocus={isFocus} type={type} />;
		}
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerContent.propTypes = {
	type: PropTypes.string.isRequired,
	isFocus: PropTypes.bool.isRequired
};

export default PlayerContent;
