import styled, { css } from 'styled-components';
import { Modal } from 'antd';

// Import styles
import { antModal } from 'components/styles';

// Import components
import { Paragraph, Button } from 'components/elements';

export const ModalStyled = styled(Modal)`
	${antModal()}
`;

export const Text = styled(Paragraph.Ellipsis)`
	color: ${({ theme }) => theme.vod.font.white};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 2.2rem !important;
	white-space: initial;
	margin-bottom: 1rem;

	${({ description }) =>
		description &&
		css`
			font-weight: ${({ theme }) => theme.font.light};
		`}
`;

export const Content = styled.div`
	text-align: center;
`;

export const ButtonStyled = styled(Button)`
	margin-top: 2rem;
`;
