import React from 'react';
import { shape, string, number, func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { getTime, format } from 'date-fns';
import { deleteReminder } from 'store/actions';

// Import helpers
import { getProductTitleTranslation } from 'helpers/languages';
import { convertProgramTimeFromUTC } from 'helpers';

// Import components
import {
	Wrapper,
	DataDisplay,
	ChannelName,
	ProgramInfo,
	DateInfo,
	ClockIconStyled,
	ButtonStyled,
	TrashIconStyled
} from './style';

// Import variables
import { ROUTES_CORE_PATHS } from 'helpers';

const { CHANNELS, PROGRAM_DETAILS } = ROUTES_CORE_PATHS;

export function Reminder({
	reminder: {
		id: reminderId,
		product_uuid: programId,
		title,
		translations,
		program_start,
		program_start: programStart,
		program_end: programEnd,
		channel: channelName
	},
	toggle
}) {
	const dispatch = useDispatch();

	const { since } = convertProgramTimeFromUTC({
		since: programStart,
		till: programEnd
	});

	const programStartTime = format(since, 'DD.MM.YYYY HH:mm');

	const redirectData = {
		pathname: `/tv/${CHANNELS}/${PROGRAM_DETAILS}/${programId}`
	};

	const programTitle = getProductTitleTranslation(title, translations);

	const handleRedirect = () => {
		const currentTime = getTime(new Date());
		const startTime = getTime(since);

		if (currentTime > startTime) {
			redirectData.hash = 'live';
		}

		return redirectData;
	};

	const handleDeleteReminder = () => deleteReminder(reminderId)(dispatch);

	return (
		<Wrapper>
			<DataDisplay onClick={toggle} to={handleRedirect()}>
				<ChannelName>{channelName}</ChannelName>
				<ProgramInfo>{programTitle}</ProgramInfo>
				<DateInfo>
					<ClockIconStyled />
					{programStartTime}
				</DateInfo>
			</DataDisplay>
			<ButtonStyled onClick={handleDeleteReminder}>
				<TrashIconStyled />
			</ButtonStyled>
		</Wrapper>
	);
}

Reminder.propTypes = {
	reminder: shape({
		id: number.isRequired,
		product_uuid: string.isRequired,
		title: string.isRequired,
		program_start: string.isRequired,
		channel: string.isRequired
	}).isRequired,
	toggle: func.isRequired
};
