import React from 'react';
import { useSelector } from 'react-redux';

// Import helpers
import { getTimeLineFormat } from 'helpers';

// Import components
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

// Import styles
import { Timer } from 'components/views/vod/components/PlayerWrapper/player_controllers/styles';

const PlayerTimer = () => {
	const [startTime, setStartTime] = React.useState('00:00:00');
	const [endTime, setEndTime] = React.useState('00:00:00');
	const { currentTimeProgress, duration } = useContextProvider();

	const movieDetails = useSelector(({ movieDetails }) => movieDetails.data);

	const isLiveEvent = movieDetails?.is_live_event;

	// TEMPORARY SOLUTION TO HIDE TIME ON LIVE EVENTS
	const showTime = !isLiveEvent;

	React.useEffect(() => {
		currentTimeProgress((currentTime) => {
			setStartTime(getTimeLineFormat(currentTime));
			setEndTime(getTimeLineFormat(duration - currentTime));
		});
		// eslint-disable-next-line
	}, []);
	return (
		<Timer showTime={showTime}>
			{startTime} / {endTime}
		</Timer>
	);
};

export default PlayerTimer;
