export const getImage = ({ images, type }) => {
	if (
		typeof images === 'object' &&
		images &&
		images[type] &&
		images[type].length > 0 &&
		images[type][0].url
	) {
		return images[type][0].url;
	}

	return false;
};
