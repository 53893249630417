import React from 'react';
import { string, number, shape, array } from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import getSlug from 'speakingurl';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Heading, Button } from 'components/elements';

// Import Helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

const { COLLECTION, PACKETS, PACKET } = APP_SUB_PATHS_KEY;

const Packet = ({ width, height, title, description, uuid, type, images }) => {
	const { t } = useTranslation();

	const slugTitle = getSlug(title);

	const packetPath = `/${getNavigationSubPaths(
		PACKETS
	)}/${getNavigationSubPaths(PACKET)}/${uuid}/${slugTitle}`;

	const isCollection = type === 'collection';
	const pathName = isCollection
		? `/vod/${getNavigationSubPaths(COLLECTION)}/${uuid}/${slugTitle}`
		: packetPath;
	const packetImage = images?.cover_large[0]?.url;

	return (
		<Item
			rel="noopener noreferrer"
			image={packetImage}
			width={width}
			height={height}
		>
			<div>
				{title && <HeadingThirdStyled>{title}</HeadingThirdStyled>}
				{description && <Paragraph>{description}</Paragraph>}
			</div>
			<LinkStyled
				as={Link}
				to={pathName}
				type="submit"
				modifiers={['primaryVod']}
			>
				{t('packets_slider_btn_show')}
			</LinkStyled>
		</Item>
	);
};

const Item = styled.div.attrs(({ width, height }) => ({
	style: {
		width,
		height,
		backgroundSize: `${width}px ${height}px`
	}
}))`
	${flex()}
	flex-direction: column;

	${({ image }) =>
		image
			? css`
					background: linear-gradient(
							0deg,
							rgba(0, 0, 0, 0.9),
							rgba(0, 0, 0, 0.55)
						),
						url(${({ image }) => image}) no-repeat top center;
			  `
			: css`
					background: transparent;
			  `}
`;

const HeadingThirdStyled = styled(Heading.Third)`
	text-align: center;
	font-weight: ${({ theme }) => theme.font.normal};
	margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.white};
	opacity: 0.7;
	text-align: center;
	padding: 0 2rem;
`;

const LinkStyled = styled(Button)`
	text-align: center;
	border: 1px solid rgba(255, 255, 255, 0.22);
	box-shadow: none;
	margin-top: 1rem;

	&:hover {
		color: ${({ theme }) => theme.white};
		background-color: ${({ theme }) => theme.orangeMain};
	}

	&:before {
		background: #00000024;
	}
`;

Packet.propTypes = {
	width: number.isRequired,
	height: number.isRequired,
	title: string,
	description: string,
	uuid: string.isRequired,
	type: string.isRequired,
	images: shape({
		cover: array,
		cover_large: array
	})
};

export default Packet;
