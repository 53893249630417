import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	urlCode: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** SET COUPON ACTIVATION CODE ********************
			case types.SET_COUPON_ACTIVATION_CODE:
				draft.urlCode = action.payload;
				return;

			default:
				return state;
		}
	});
