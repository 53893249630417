import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	z-index: 1;
	right: 6.3rem;
	top: ${({ isMovedUp, isProgramDetails, isFullScreen }) =>
		isMovedUp && !isProgramDetails && !isFullScreen ? 5 : 11}rem;

	${({ isProgramDetails, isFullScreen }) =>
		isProgramDetails &&
		css`
			top: ${isFullScreen ? 11 : 5}rem;
		`}
`;

export const WatermarkLogo = styled.img`
	height: 8rem;
	object-fit: contain;
`;
