import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import styles
import { Button, ChildrenWrapper, ButtonText } from './styles';

const BUTTON = 'button';
const LINK = 'link';

const ButtonRounded = ({
	children,
	type,
	title,
	modifiers,
	onClick,
	to,
	height,
	width
}) => {
	const renderAs = type === LINK ? Link : BUTTON;

	return (
		<Button modifiers={modifiers} as={renderAs} to={to} onClick={onClick}>
			<ChildrenWrapper height={height} width={width}>
				{children}
			</ChildrenWrapper>
			{title && <ButtonText>{title}</ButtonText>}
		</Button>
	);
};

ButtonRounded.defaultProps = {
	type: 'button',
	width: 80,
	height: 80,
	title: ''
};

ButtonRounded.propTypes = {
	onClick: PropTypes.func,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.string,
	href: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	className: PropTypes.string,
	modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ButtonRounded;
