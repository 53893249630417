import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers
import { NAV_LINKS } from './nav_helpers';
import { checkAppType, checkNavItemActivity } from 'helpers';
import { getRTLBooleanValue } from 'helpers/rtl';

// Import components
import { Nav } from 'components/elements';

const NavLinks = ({ t, isMainNavigation }) => {
	const { appName } = checkAppType();
	const location = useLocation();
	const { direction } = useSelector(({ direction }) => direction);

	const isRtl = getRTLBooleanValue({ direction });

	return (
		<Nav.List dropdown isMainNavigation={isMainNavigation}>
			{NAV_LINKS.map(({ id, path, name }) => {
				const navItem = name === 'home' ? `home_${appName}` : name;
				return (
					<Nav.Item key={id}>
						<Nav.NavLink
							to={path}
							activeClassName="active-nav"
							isMainNavigation={isMainNavigation}
							isActive={checkNavItemActivity(location, path)}
							isRtl={isRtl}
						>
							{t(`common_header_nav_${navItem}`)}
						</Nav.NavLink>
					</Nav.Item>
				);
			})}
		</Nav.List>
	);
};

NavLinks.propTypes = {
	isMainNavigation: PropTypes.bool
};

NavLinks.defaultProps = {
	isMainNavigation: false
};

export default withTranslation()(NavLinks);
