import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

//Import styles
import {
	baseRootStyle,
	borderGradientTransparent,
	transitionMain
} from 'components/styles';

const BUTTON_TRANSPARENT_MODIFIERS = {
	secondary: ({ theme }) => css`
		background: ${theme.buttonTransparent.bgSecond};

		&:hover,
		&:focus {
			box-shadow: ${theme.buttonTransparent.shadowSecondHover};
			background-color: ${theme.buttonTransparent.bgSecondHover};
		}
	`
};

export const buttonStyles = css`
	${baseRootStyle}
	display:flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.buttonTransparent.bg};
	color: ${({ theme }) => theme.buttonTransparent.color};
	border: none;
	font-size: ${({ fontSize }) => fontSize || 1.6}rem;
	opacity: ${({ opacity }) => opacity || 1};
	${transitionMain()}

	${({ theme }) =>
		borderGradientTransparent({
			width: 1,
			gradient: theme.buttonTransparent.border
		})};

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.buttonTransparent.bgHover};
	}

	${applyStyleModifiers(BUTTON_TRANSPARENT_MODIFIERS)};
`;

export const ButtonStyled = styled.button`
	${buttonStyles}
`;

export const LinkStyled = styled(Link)`
	${buttonStyles}
`;

export const AnchorStyled = styled.a`
	${buttonStyles}
`;
