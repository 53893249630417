import styled from 'styled-components';

// Import styles
import { shadows, flex } from 'components/styles';

export const Wrapper = styled.header`
	position: fixed;
	z-index: 1000;
	padding: 0 4.2rem;
	width: 100%;
	height: ${({ theme }) => theme.nav.height}rem;
	${flex({ jc: 'space-between' })}
	background-color: ${({ theme: { header } }) => header.bg};
	box-shadow: ${({ theme: { header } }) => header.shadow};
	${shadows.base}
`;

export const LogoWrapper = styled.div`
	width: 20%;
	height: 100%;
	${flex({ jc: 'flex-start' })};
`;
