import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from 'antd';

// Import helpers
import { minMaxHeight } from 'components/views/vod/components/sliders/Carousel/helpers';

// Import styles
import { rtl } from 'components/styles';

const LINE_HEIGHT = 29;

export const TitleContainer = styled.div`
	width: 41vw;
	height: 12.5vh;
	height: ${({ isSeries }) => (isSeries ? 9 : 12.5)}vh;
	${({ isSeries }) => (isSeries ? minMaxHeight(9) : minMaxHeight(12.5))};
	margin-bottom: 2.3vh;
	position: relative;
	display: flex;
	align-items: center;
`;

export const Image = styled.div`
	background-image: ${({ titleImage }) =>
		titleImage === 'none' ? titleImage : `url("${titleImage}")`};
	background-repeat: no-repeat;
	background-attachment: scroll;
	${rtl`
    background-position: center left;
  `}
	background-size: contain;
	width: 100%;
	height: 100%;
`;

export const TitleLink = styled(Link)`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	&:hover {
		color: initial;
	}
`;

export const TitleText = styled.div`
	color: ${({
		theme: {
			vod: { font }
		}
	}) => font.whiteGrayLight};
	font-size: 3rem;
`;

export const BasicWrap = styled.div`
	display: flex;
	margin-bottom: 3.6vh;
`;

export const Description = styled(Typography.Paragraph)`
	margin-bottom: 1.5rem;
	height: ${LINE_HEIGHT * 3}px;
	line-height: ${LINE_HEIGHT}px;
	overflow: auto;
	font-size: 1.76rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({
		theme: {
			vod: { font }
		}
	}) => font.whiteGrayLight};

	.ant-typography-expand {
		display: none;
	}

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(255, 255, 255, 0.05);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.07);
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	width: calc(100vw - 11.6vw + 14px);
	min-width: calc(90rem - 11.6vw);
	min-height: 7.3rem;
	margin: 0 0 0 -7px;

	> * {
		margin: 0 2rem 1.4rem;
	}
`;
