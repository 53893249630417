// Import helpers
import { projectlanguages } from 'helpers/languages';

// Import translation file
import translations from './translations.json';

const getTranslation = ({ lang }) => {
	const language = lang.toUpperCase();
	return translations.reduce((acc, current) => {
		const key = current['KEY'].replace(/\./g, '_');
		acc[key] = current[`TRANSLATION_${language}`];
		return acc;
	}, {});
};

export const langResources = projectlanguages.reduce((acc, lang) => {
	const translation = getTranslation({ lang });
	acc[lang] = { translation };
	return acc;
}, {});

export const pathnameLngDetector = {
	name: 'pathname',
	lookup({ supported = [] }) {
		const { pathname } = window.location;
		const lng = pathname.split('/')[1];
		const isSupported = supported.includes(lng);
		return isSupported ? lng : undefined;
	}
};
