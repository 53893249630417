import React from 'react';
import { shape, string } from 'prop-types';
import { FacebookShareButton } from 'react-share';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Import icons
import { ReactComponent as IconShare } from 'assets/icons/ic_share.svg';

const Share = () => {
	const { pathname, hash } = useLocation();

	const url = `${process.env.REACT_APP_WEBSITE_URL}${pathname}#${hash}`;
	return (
		<FacebookShareButton url={url}>
			<IconShareStyled />
		</FacebookShareButton>
	);
};

const IconShareStyled = styled(IconShare)`
	g,
	path {
		fill: ${({ theme: { popover } }) => popover.icon};
	}
`;

Share.propTypes = {
	linkDetails: shape({ pathname: string, hash: string })
};

export default Share;
