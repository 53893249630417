import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { InputStyled, SearchInputStyled } from './styles';

const SearchInput = ({ handleOnChange, query }) => {
	const { t } = useTranslation();
	return (
		<SearchInputStyled>
			<InputStyled
				name="query"
				type="text"
				autoFocus
				placeholder={t('common_header_nav_search_placeholder')}
				onChange={handleOnChange}
				value={query}
			/>
		</SearchInputStyled>
	);
};

SearchInput.propTypes = {
	handleOnChange: func.isRequired,
	query: string.isRequired
};

export default SearchInput;
