import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';

// Import styles
import { antModal } from 'components/styles';

// Import components
import { ModalForm } from 'components/elements';
import ParentalControlFields from './parental_control_fields/ParentalControlFields';

const ParentalControlModal = ({
	toggle,
	title,
	isEnabled,
	initialValues,
	validation,
	on,
	submitAction
}) => {
	return (
		<ModalStyled
			maskClosable={false}
			width={600}
			closable={true}
			onCancel={toggle}
			title={title}
			visible={on}
			bodyStyle={{ padding: '2rem' }}
		>
			<ModalForm
				initialValues={initialValues}
				submitAction={submitAction}
				type="parental_control"
				translation="parental_control_error_message"
				isOpen={on}
				closeModal={toggle}
				validation={validation}
				isEnabled={isEnabled}
			>
				{({ isSubmitting }) => (
					<ParentalControlFields
						isEnabled={isEnabled}
						isSubmitting={isSubmitting}
					/>
				)}
			</ModalForm>
		</ModalStyled>
	);
};

const ModalStyled = styled(Modal)`
	${antModal()}
`;

ParentalControlModal.propTypes = {
	toggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	isEnabled: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	validation: PropTypes.func.isRequired,
	on: PropTypes.bool.isRequired
};

export default ParentalControlModal;
