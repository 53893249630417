import React from 'react';

const useUserAgent = () => {
	const [isMobile, setIsMobile] = React.useState(false);
	const [mobileType, setMobileType] = React.useState(null);

	/* eslint-disable */
	React.useEffect(() => {
		const match = /iphone|ipad|ipod|android|webOS|opera mini|blackBerry|windows Phone/;
		const matchiOS = /iphone|ipad|ipod/;
		const matchAndroid = /android/;
		const userAgent = window.navigator.userAgent.toLowerCase();
		const isMobileDevice = match.test(userAgent);
		const ios = matchiOS.test(userAgent);
		const android = matchAndroid.test(userAgent);

		if (ios) {
			setMobileType('ios');
		} else if (android) {
			setMobileType('android');
		}
		setIsMobile(isMobileDevice);
	}, []);
	/* eslint-enable */

	return { isMobile, mobileType };
};

export default useUserAgent;
