import styled from 'styled-components';

export const Search = styled.div`
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 900;
	background-color: ${({ theme: { navSearch } }) => navSearch.bg};
`;
