import styled from 'styled-components';

export const Root = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const Item = styled.div.attrs(({ bottom }) => ({
	style: {
		bottom
	}
}))`
	position: absolute;
	left: 0;
	width: 100%;
`;

export const BottomChecker = styled.div`
	position: absolute;
	bottom: 0;
	height: 0;
`;

export const ButtonContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
