import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import icons
import { ReactComponent as IconTick } from 'assets/icons/ic_tick.svg';
import { ReactComponent as IconDollar } from 'assets/icons/ic_dollar.svg';
import { ReactComponent as IconDollarContrast } from 'assets/icons/ic_dollar_contrast.svg';

// Import variables
import { DIRECTION_TYPES } from 'helpers/variables';

// Import styles
import { customMinResponsive, rtl } from 'components/styles';

const BOX_MODIFIERS = {
	small: ({ theme: { direction, availability_marks } }) => css`
		transform: scale(-0.7)
			${`rotate(${direction === DIRECTION_TYPES.RTL ? '-45deg' : '45deg'})`};
		background: ${availability_marks.bg_secondary};
	`,
	big: ({ theme: { direction, availability_marks } }) => css`
		transform: scale(2.6)
			${`rotate(${direction === DIRECTION_TYPES.RTL ? '-45deg' : '45deg'})`};
		background: ${availability_marks.bg_secondary};

		${customMinResponsive(
			1750,
			css`
				transform: scale(1.8)
					${`rotate(${direction === DIRECTION_TYPES.RTL ? '-45deg' : '45deg'})`};
			`
		)};
	`
};

const ICON_UNAVAILABLE_MODIFIERS = {
	small: ({ theme: { direction } }) => css`
		bottom: 1.8rem;
		${direction === DIRECTION_TYPES.RTL ? 'right:2rem' : 'left:2rem'}
		width: 2rem;
		transform: ${`rotate(${
			direction === DIRECTION_TYPES.RTL ? '-135deg' : '135deg'
		})`};

		${customMinResponsive(
			1750,
			css`
				bottom: 3.4rem;
				${direction === DIRECTION_TYPES.RTL ? 'right:4.3rem' : 'left:4.3rem'}
			`
		)}
	`,
	big: ({ theme: { direction } }) => css`
		bottom: -0.6rem;
		width: 1rem;
		${direction === DIRECTION_TYPES.RTL ? 'right:2.4rem' : 'left:2.4rem'}
		transform: ${`rotate(${
			direction === DIRECTION_TYPES.RTL ? '45deg' : '-45deg'
		})`};

		${customMinResponsive(
			1750,
			css`
				width: 1.6rem;
				bottom: -0.4rem;
				${direction === DIRECTION_TYPES.RTL ? 'right:3.4rem' : 'left:3.4rem'}
			`
		)}`
};

const Box = styled.div`
	position: absolute;
	top: -1.6rem;
	${rtl`right: -2.5rem;`}
	width: 6rem;
	height: 4rem;
	z-index: 2;
	transform: rotate(
		${({ theme: { direction } }) =>
			direction === DIRECTION_TYPES.RTL ? '-45deg' : '45deg'}
	);
	box-shadow: -0.1rem 0.2rem 0.4rem
		${({ theme: { slider } }) => slider.available.shadow};

	${({ isAvailable, theme }) =>
		!isAvailable &&
		css`
			background: ${theme.availability_marks.bg_primary}
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		`};

	${customMinResponsive(
		1750,
		css`
			top: -2.4rem;
			${rtl`right: -4.8rem;`}
			width: 9.5rem;
			height: 6rem;
		`
	)}

	${applyStyleModifiers(BOX_MODIFIERS)};
`;

const iconBaseStyle = css`
	position: absolute;
	transform: rotate(
		${({ theme: { direction } }) =>
			direction === DIRECTION_TYPES.RTL ? '41deg' : '-41deg'}
	);
`;

const IconAvailable = styled(IconTick)`
	${iconBaseStyle}
	bottom: 0.6rem;
	${rtl`left: 2.5rem;`}
	height: 1rem;

	${customMinResponsive(
		1750,
		css`
			bottom: 0.7rem;
			${rtl`left: 3.7rem;`}
		`
	)}
`;

const unavailableIconBaseStyle = css`
	transform: scale(1.2);
	${iconBaseStyle}
	bottom: -0.4rem;
	${rtl`left: 2.5rem;`}

	${customMinResponsive(
		1750,
		css`
			bottom: -0.1rem;
			${rtl`left: 4rem;`}
		`
	)}
`;

const IconUnavailable = styled(IconDollar)`
	${unavailableIconBaseStyle}

	${applyStyleModifiers(ICON_UNAVAILABLE_MODIFIERS)};
`;

const IconUnavailableContrast = styled(IconDollarContrast)`
	${unavailableIconBaseStyle}

	${applyStyleModifiers(ICON_UNAVAILABLE_MODIFIERS)};
`;

export { Box, IconAvailable, IconUnavailable, IconUnavailableContrast };
