import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Helpers
import {
	getFilteredChannels,
	getLivePrograms,
	getFavouriteChannels
} from 'helpers/epg';

// Import components
import { MasonryLayout, MasonryLive } from 'components/elements';

const TvOnlineItem = ({ location: { pathname }, lives, favourites }) => {
	// Get category path
	const [, , , category] = pathname.split('/');

	const renderMasonryLive = (item, index, adjustHeight) => (
		<MasonryLive
			key={item.uuid}
			adjustHeight={adjustHeight}
			element={item}
			index={index}
			channelID={item.uuid}
		/>
	);

	const filterLiveChannels = (elements) => {
		const filteredChannels = lives.filter(getLivePrograms);
		const channels =
			category === 'ulubione'
				? getFavouriteChannels(filteredChannels, favourites)
				: getFilteredChannels(category, filteredChannels);
		return channels.slice(0, elements);
	};

	return (
		<MasonryLayout>
			{({ adjustHeight, elements }) => (
				<React.Fragment>
					{filterLiveChannels(elements).map((item, index) =>
						renderMasonryLive(item, index, adjustHeight)
					)}
				</React.Fragment>
			)}
		</MasonryLayout>
	);
};

TvOnlineItem.propTypes = {
	location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
		.isRequired,
	lives: PropTypes.array.isRequired,
	favourites: PropTypes.array.isRequired
};

const mapStateToProps = ({ channels: { data }, favourites }) => ({
	lives: data.lives,
	favourites: favourites.data
});

export default connect(mapStateToProps)(TvOnlineItem);
