import myAxios from './myAxiosFactory';

import {
	checkUrlPlatform,
	createAxiosInterceptors,
	prepareFetchApiUrl
} from './helpers';

const servicesFactory = (myAxios) => ({
	post: (url, payload = {}, appCheckToken) => {
		let axios = myAxios(appCheckToken);

		axios = createAxiosInterceptors(axios);
		return axios.post(checkUrlPlatform(url), payload);
	},
	put: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.put(checkUrlPlatform(url), payload);
	},
	patch: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.patch(checkUrlPlatform(url), payload);
	},
	delete: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.delete(checkUrlPlatform(url), payload);
	},
	get: (url, token, appCheckToken) => {
		let axios = myAxios(appCheckToken);

		axios = createAxiosInterceptors(axios);
		const apiUrl = prepareFetchApiUrl(url);

		return axios.get(apiUrl, token);
	}
});

const services = servicesFactory(myAxios);
export default services;
