import UAParser from 'ua-parser-js';

// Get user agent
export const getUserAgent = () => {
	const uastring = navigator.userAgent;
	const parser = new UAParser();
	const { browser, os } = parser.setUA(uastring).getResult();
	const systemDeviceName = os.name + os.version;
	return { browser, os, systemDeviceName };
};
