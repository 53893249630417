import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';

// Import helpers
import {
	setValidChannelLogo,
	checkPosterUrl,
	createLinkParams
} from 'helpers/index';
import { getProductTitleTranslation } from 'helpers/languages';

// Import components
import { Paragraph, Button } from 'components/elements';

// Import Icons
import { ReactComponent as TrashIcon } from 'assets/icons/ic_trash.svg';

// Import styles
import { transitionMain, rtl } from 'components/styles';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';
import { ROUTES_CORE_PATHS } from 'helpers';

const Favourite = ({
	item: { images, title: productTitle, uuid: id, type, subtype, translations },
	toggle,
	deleteFavourite,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel,
	videoSessionId
}) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const isHomePage = pathname.includes(ROUTES_CORE_PATHS.HOME);
	const title = getProductTitleTranslation(productTitle, translations);

	const { imgUrl, isImgUrlValid } =
		type === 'vod' || type === 'packet'
			? checkPosterUrl({ images })
			: setValidChannelLogo(images);

	const handleRedirect = () => {
		if (subtype === 'channel') {
			const toggleTime = isHomePage ? 1000 : 20;
			setTimeout(() => toggle(), toggleTime);
			isHomePage
				? handlePlaySelected()
				: history.push(`/tv/${ROUTES_CORE_PATHS.HOME}`, {
						channelId: id
				  });
		} else {
			setTimeout(() => toggle(), 20);
			history.push(createLinkParams({ id, type: subtype, title }));
		}
	};

	const handlePlaySelected = async () => {
		// delete request
		playerOnCancel(PLAYER_SELECTORS.PLAYER);
		// delete video session
		videoSessionId && (await deleteVideoSession());
		// set title in player overlay content
		setSelectedProgram(null, id);
		// fetch new session config
		fetchPlayerConfig({ productID: id });
	};

	return (
		<Wrapper>
			<Box onClick={handleRedirect}>
				<Preview>
					{isImgUrlValid && <Image src={imgUrl} alt="Preview" />}
				</Preview>
				<Content>{title && <ProductName>{title}</ProductName>}</Content>
			</Box>
			<ButtonStyled onClick={() => deleteFavourite(id)}>
				<TrashIconStyled />
			</ButtonStyled>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
`;

const Box = styled.div`
	padding: 1rem 1.5rem;
	height: 8.2rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid ${({ theme }) => theme.line};
	color: ${({ theme }) => theme.grayMedium};
	${transitionMain}

	& > * {
		${transitionMain}
	}

	&:hover {
		background-color: ${({ theme: { sidebar } }) => sidebar.itemHoverBg};
		& > * {
			color: ${({ theme }) => theme.white};
		}
	}
`;

const Preview = styled.figure`
	width: 5rem;
	${rtl`
		margin: 0 2rem 0 0;
	`}
`;

const Image = styled.img`
	max-height: 40px;
	max-width: 40px;
	object-fit: cover;
`;

const ProductName = styled(Paragraph.Ellipsis)`
	font-weight: ${({ theme }) => theme.font.normal};
	width: 16rem;
`;

const Content = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
`;

const TrashIconStyled = styled(TrashIcon)`
	height: 2rem;
	width: 2rem;

	path {
		fill: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		stroke: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		${transitionMain};
	}
`;

const ButtonStyled = styled(Button)`
	position: absolute;
	top: 3rem;
	${rtl`
		right: 2.5rem;
	`}
	height: 2rem;
	width: 2rem;
	padding: 0;

	&:hover {
		${TrashIconStyled} {
			path {
				fill: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
				stroke: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
			}
		}
	}
`;

Favourite.propTypes = {
	deleteFavourite: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	item: PropTypes.shape({
		images: PropTypes.object,
		title: PropTypes.string,
		uuid: PropTypes.string.isRequired
	}),
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	playerOnCancel: PropTypes.func.isRequired,
	videoSessionId: PropTypes.string
};

const mapStateToProps = ({ player: { videoSessionId } }) => ({
	videoSessionId
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		playerOnCancel
	}
)(Favourite);
