import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import { flex, rtl } from 'components/styles';

export const SearchBoxStyled = styled.div`
	position: absolute;
	${rtl`
      top: 5.3rem;
	  left: 0rem;
  `}
	width: 60rem;
	${({ isDataEmpty }) =>
		isDataEmpty &&
		css`
			min-height: 31.2rem;
		`}
	background-color: ${({ theme: { searchBox } }) => searchBox.bg};
	box-shadow: ${({ theme: { searchBox } }) => searchBox.shadow};
	border: 1px solid ${({ theme: { searchBox } }) => searchBox.border};
	border-top: none;
	z-index: 9000;
	margin-bottom: 2rem;
`;

export const Top = styled.div`
	padding: 1rem 1.5rem;
	${flex()}
	justify-content: space-between;
`;

export const Name = styled.div`
	color: ${({ theme: { searchBox } }) => searchBox.name};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: 1.5rem;
`;

export const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		font-size: 1.3rem;
		color: ${({ theme: { searchBox } }) => searchBox.linkColor};
		font-weight: ${({ theme }) => theme.font.medium};
		transition: all 0.2s ease-in-out;
	}

	&:hover,
	&:active {
		color: ${({ theme: { searchBox } }) => searchBox.linkColorHover};
	}
`;

export const EmptyContent = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	${flex({ fd: 'column' })}

	p {
		margin-top: 2rem;
		color: ${({ theme: { searchBox } }) => searchBox.emptyContent};
		font-size: 1.4rem;
		font-weight: ${({ theme }) => theme.font.normal};
	}
`;

export const SearchList = styled.ul.attrs(({ isLoaded }) => ({
	style: {
		minHeight: isLoaded ? '10rem' : '20rem'
	}
}))`
	position: relative;
	list-style: none;
`;
