import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import styles
import { flex } from 'components/styles';

const metadataDivider = css`
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -1.39em;
		transform: translateY(-50%);
		width: 0.4em;
		height: 0.4em;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.slider.item.metadata.divider};
	}
`;

export const StyledLink = styled.button`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	border-radius: inherit;
	border: 2px solid transparent;
	border: none;
	background-color: transparent;
	cursor: pointer;
	${flex()}

	& > * {
		border-radius: inherit;
	}
`;

export const StyledImage = styled(LazyLoadImage)`
	margin: 0 auto;
`;

export const Information = styled.div`
	${flex({ ai: 'flex-start', jc: 'flex-start', fd: 'column' })}
	opacity: 0;
	position: absolute;
	top: 99%;
	visibility: hidden;
	width: 100%;
	padding: 0.5em 2.5em;
	font-weight: ${({ theme }) => theme.font.semiMedium};
	background-color: ${({ theme }) => theme.slider.item.backgroundColor};
	color: ${({ theme }) => theme.slider.item.color};
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	text-align: center;
`;

export const Wrap = styled.div`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	font-size: ${({ width }) => width * 0.05}px;
	border-radius: ${({ isChannel }) => (isChannel ? 1.2 : 0.4)}rem;
	position: relative;
	transition: transform 0.3s ease-in-out;
	${flex()}

	${({ isChannel, theme }) =>
		isChannel &&
		css`
			background: ${theme.slider.item.channelBackgroundColor};
			img {
				filter: grayscale(1);
			}
		`};

	&:hover {
		background-color: ${({ theme }) => theme.slider.item.backgroundColor};
		border-radius: 1rem;
		filter: ${({ theme }) => theme.slider.item.shadow};
		transform: scale(1.2) !important;
		z-index: 10;

		${({ isChannel, channelBackgroundImageSrc }) =>
			isChannel &&
			css`
				${channelBackgroundImageSrc &&
					css`
						background: ${`url(${channelBackgroundImageSrc}) no-repeat center/cover`};
					`};
				img {
					filter: grayscale(0);
				}
			`}

		${({ showInformation, isChannel }) =>
			showInformation && !isChannel
				? css`
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
						border-bottom: none;

						${Information} {
							border-bottom-right-radius: 1rem;
							border-bottom-left-radius: 1rem;
							opacity: 1;
							visibility: visible;
							border: ${({ theme }) => theme.slider.item.border};
							&:before {
								content: '';
								position: absolute;
								left: 0;
								top: -5px;
								width: 100%;
								height: 10px;
								background-color: ${({ theme }) =>
									theme.slider.item.backgroundColor};
							}
						}

						${StyledLink} {
							border: ${({ theme }) => theme.slider.item.border};

							&:after {
								content: '';
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								background-image: ${({ theme }) =>
									theme.slider.item.hoverGradient};
							}
						}
				  `
				: css`
						${StyledLink} {
							border: ${({ theme }) => theme.slider.item.border};
						}
				  `}
	}

	img {
		object-fit: ${({ backgroundSize }) => backgroundSize};
		object-position: center center;
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: ${({ gap }) => gap}em;
	width: 100%;

	&:first-of-type {
		margin-bottom: 1.15em;
	}
`;

export const MetaItem = styled.span`
	position: relative;

	${({ isRtl }) =>
		isRtl
			? css`
					&:not(:first-of-type) {
						${metadataDivider}
					}
			  `
			: css`
					&:not(:last-of-type) {
						${metadataDivider}
					}
			  `}
`;
