import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch, useStore } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { deleteVideoSession } from 'store/actions';
import getSlug from 'speakingurl';

// Import context
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

// Import helpres
import {
	PLAYER_SELECTORS,
	RESUME_PLAYBACK_OFFSET_TIME
} from 'helpers/variables';
import { fetchMovieResources } from 'components/views/vod/helpers/player';
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';
import { getVideoEndStatus } from './helpers';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

// Set player type
const playerSelector = PLAYER_SELECTORS.VOD;

const { MOVIES } = APP_SUB_PATHS_KEY;

const usePlayerContent = ({ type }) => {
	const { continueProgress } = useSelector((state) => state.continue_watching);
	const continueProgressTime = continueProgress?.time;

	// Get data from state
	const {
		data: { subtype, metadata },
		episodes
	} = useSelector(({ movieDetails }) => movieDetails);

	const { error } = useSelector((state) => state[type]);

	const { getState } = useStore();
	const dispatch = useDispatch();
	const history = useHistory();
	const { type: productType } = useParams();

	// Get data from context
	const {
		isEnded,
		isReady,
		endMarginAction,
		buffering,
		play,
		pause,
		isPaused,
		setFullScreen,
		setEndMargin,
		isPreview,
		closePreview,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		segmentsData,
		useWatermark
	} = useContextProvider();

	const next = episodes?.data?.next || episodes?.next;

	// SEGMENTS DATA
	const introData = segmentsData?.intro;

	// TODO
	// Check if product is avaiable / purschased by user
	const episodeId = next ? !!next.uuid : false;
	// const isAvailableIn = next ? next.available_in || [] : [];
	// const isEpisodeAvailable = useProductAvailability(episodeId, isAvailableIn);

	const isNextEpisodeAvailable = episodeId;

	// check if product is an episode
	const isEpisode = subtype === 'episode' ? true : false;

	const isTheoPlayer = DEFAULT_PLAYER === THEO_PLAYER;

	const isNextEpisode = isEpisode && next && isNextEpisodeAvailable;

	const showRecommendation = endMarginAction && !isPreview && !isEpisode;

	const showContinueProgress =
		isReady &&
		!isPreview &&
		continueProgressTime >= 4 &&
		continueProgressTime <= duration - 15;

	const backwardCurrentTime =
		continueProgressTime - RESUME_PLAYBACK_OFFSET_TIME;

	const setContinueProgress = () =>
		showContinueProgress && updateTime(backwardCurrentTime);

	const showBigPlayButton = isReady && isPaused;

	const handleFetchResources = async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(dispatch, getState);
		// Fetch movie resources
		await fetchMovieResources({
			id: next.uuid,
			type: productType,
			dispatch,
			getState
		});
	};

	const handlePlayPause = () => (isPaused ? play() : pause());

	const goToMovieDetails = () => {
		const { vod_uuid, title } = metadata;
		const slugTitle = getSlug(title);

		history.push(
			`/vod/${getNavigationSubPaths(MOVIES)}/item/${vod_uuid}/${slugTitle}`
		);
	};

	const handleVideoEnded = () => {
		const {
			isPreviewEnded,
			isNextEpisode,
			isLastEpisodeEnded,
			isMovieEnded
		} = getVideoEndStatus({
			isEpisode,
			isPreview,
			isNextEpisodeAvailable
		});

		if (isPreviewEnded) {
			closePreview();
		} else if (isNextEpisode) {
			handleFetchResources();
		} else if (isLastEpisodeEnded) {
			history.goBack();
		} else if (isMovieEnded) {
			goToMovieDetails();
		}
	};

	useEffect(() => {
		isEnded && handleVideoEnded();
		// eslint-disable-next-line
	}, [isEnded]);

	return {
		buffering,
		isReady,
		error,
		continueProgress,
		isEpisode,
		isNextEpisodeAvailable,
		handlePlayPause,
		setFullScreen,
		setEndMargin,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		isTheoPlayer,
		isNextEpisode,
		showRecommendation,
		showContinueProgress,
		showBigPlayButton,
		setContinueProgress,
		introData,
		useWatermark
	};
};

export default usePlayerContent;
