import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styles
import { Content, Text, ButtonLogIn } from './styles';

const ModalContent = ({ toggle, redirect }) => {
	const { t } = useTranslation();

	return (
		<Content>
			<Text>{t('common_auth_modal_login_required')}</Text>
			<Text description>
				{t('common_auth_modal_login_description_required')}
			</Text>
			<ButtonLogIn modifiers={['primary']} onClick={redirect(toggle)}>
				{t('common_auth_modal_login_button')}
			</ButtonLogIn>
		</Content>
	);
};

ModalContent.propTypes = {
	redirect: func,
	toggle: func
};

export default ModalContent;
