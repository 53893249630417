import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

// Import components
import useHelmetTags from './useHelmetTags';

const HelmetTags = ({ title, ...otherProps }) => {
	const {
		titlePage,
		applicationName,
		applicationDescription,
		aplicationKeywords,
		canonicalLink,
		language
	} = useHelmetTags(otherProps);

	return (
		<Helmet htmlAttributes={{ lang: language }}>
			<title>{title || titlePage}</title>
			<link rel="canonical" href={canonicalLink} />

			{/* <!-- for Google --> */}
			<meta name="description" content={applicationDescription} />
			<meta name="keywords" content={aplicationKeywords} />
			<meta name="application-name" content={applicationName} />

			{/* <!-- for Facebook --> */}
			<meta property="og:title" content={applicationName} />
			<meta name="og:keywords" content={aplicationKeywords} />
			<meta property="og:description" content={applicationDescription} />

			{/* <!-- for Twitter --> */}
			<meta name="twitter:title" content={applicationName} />
			<meta name="twitter:keywords" content={aplicationKeywords} />
			<meta name="twitter:description" content={applicationDescription} />
		</Helmet>
	);
};

HelmetTags.propTypes = {
	title: string,
	metaTitle: string,
	metaDescription: string,
	metaKeywords: string
};

export default HelmetTags;
