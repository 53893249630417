import services from 'services/services';

// Import variables
import { VOD_WATERMARK_URL } from 'helpers/variables';
import { GET_SETTINGS_GLOBAL_WATERMARK_ENABLED_API } from 'helpers/api';

const getChannelLogoUrl = ({ lives, selectedChannelUuid, channelUuid }) => {
	const channelData = lives.find(
		({ uuid }) => uuid === channelUuid || uuid === selectedChannelUuid
	);

	return channelData?.images?.logo_additional?.[0]?.url || '';
};

export const getProductLogoUrl = ({
	isChannel,
	isGlobalWatermarkEnabled,
	selectedChannelUuid,
	channelUuid,
	lives
}) => {
	if (isGlobalWatermarkEnabled) return null;

	if (isChannel) {
		const channelLogoUrl = getChannelLogoUrl({
			selectedChannelUuid,
			channelUuid,
			lives
		});

		return channelLogoUrl;
	}

	return VOD_WATERMARK_URL;
};

export const getLogoSettings = async () => {
	const { data } = await services.get(
		GET_SETTINGS_GLOBAL_WATERMARK_ENABLED_API
	);

	return data?.message || false;
};
