import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';

// Import actions
import { addReminder } from 'store/actions';

// import helpers
import { menuItems, getMinutesFromNow } from './helpers';

export function RemindersMenu({ programId, startDate }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const minutesLeft = getMinutesFromNow(startDate);

	return (
		<Menu>
			{menuItems.map(({ id, minutes }) =>
				minutesLeft && minutesLeft > minutes ? (
					<Menu.Item
						key={id}
						onClick={() => addReminder(programId, minutes)(dispatch)}
					>
						{t(`common_reminders_list_${minutes}_before`)}
					</Menu.Item>
				) : null
			)}
		</Menu>
	);
}

RemindersMenu.propTypes = {
	programId: string.isRequired,
	startDate: string.isRequired
};
