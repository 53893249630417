import { useSelector } from 'react-redux';

const useNoAuthRedirect = () => {
	const { latestNoAuthPath } = useSelector(
		({ previous_location }) => previous_location
	);

	// redirect user to location from before login
	const redirectPath = latestNoAuthPath || '/';

	return { redirectPath };
};

export default useNoAuthRedirect;
