import moment from 'moment';
import age_0 from 'assets/images/age_rating_0.png';
import age_7 from 'assets/images/age_rating_7.png';
import age_12 from 'assets/images/age_rating_12.png';
import age_16 from 'assets/images/age_rating_16.png';
import age_18 from 'assets/images/age_rating_18.png';

/**
 * divides ms to proper hours and minutes notation
 * @param {number} duration
 * @return {{durationHours: number, durationMinutes: number}}
 */
export function getDurationHoursAndMinutes(duration) {
	const minutes = moment.duration(duration, 'seconds').minutes();
	const hours = moment.duration(duration, 'seconds').hours();

	return { durationHours: hours, durationMinutes: minutes };
}

export function getAgeRatingImage(rating) {
	switch (rating) {
		case 0:
			return age_0;
		case 7:
			return age_7;
		case 12:
			return age_12;
		case 16:
			return age_16;
		case 18:
			return age_18;
		default:
			return null;
	}
}

export const ExpandDescriptionStyle = (handler, textRef, LINE_HEIGHT) => `
	height: ${
		!handler
			? textRef.current.scrollHeight > LINE_HEIGHT * 4
				? textRef.current.scrollHeight + 'px'
				: LINE_HEIGHT * 4 + 'px'
			: `${LINE_HEIGHT * 4}px`
	};
	background: ${
		!handler
			? 'none'
			: 'linear-gradient(180deg,rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255.7) 50%, rgba(255, 255, 255, 0) 100%)'
	};
	-webkit-background-clip: ${!handler ? 'none' : 'text'};
	-webkit-text-fill-color: ${
		!handler ? 'rgba(255, 255, 255, 0.7)' : 'transparent'
	};
`;

export const getRatingStars = (stars) => {
	if (typeof stars === 'number' && stars >= 0 && stars <= 5) {
		const rating = parseInt(stars);

		const allStars = Array(5).fill({ isStarFull: false });

		const fullStars = Array(rating).fill({ isStarFull: true });

		allStars.splice(0, rating, ...fullStars);

		return allStars;
	}

	return;
};
