import styled from 'styled-components';
import { fadeIn } from 'components/styles';

export const Header = styled.h1`
	width: 90%;
	margin: 0 auto 3rem;
	font-weight: ${({ theme }) => theme.regular};
	color: ${({ theme }) => theme.player.recommendations.header.color};
	font-size: 2.5rem;
`;

export const Wrapper = styled.div`
	position: absolute;
	bottom: 11rem;
	left: 0;
	width: 100%;
	z-index: 900;
	animation: ${fadeIn(0)} 0.5s ease-in-out forwards;
`;

export const Title = styled.p`
	font-weight: ${({ theme }) => theme.regular};
	color: ${({ theme }) => theme.player.recommendations.item.title.color};
	font-size: 2.5rem;
	padding: 1rem 0;
	text-align: center;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: scale(1.07);
	}
`;

export const Wrap = styled.div`
	border: ${({ theme }) => theme.player.recommendations.slider.border};

	background-color: ${({ theme }) =>
		theme.player.recommendations.slider.backgroundColor};
	border-radius: 1rem;
	filter: ${({ theme }) => theme.player.recommendations.slider.shadow};

	img {
		object-fit: ${({ backgroundSize }) => backgroundSize};
		object-position: center center;
	}
`;
