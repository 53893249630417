import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setTvProgramsLive,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import helpers
import { setChannelLogo } from 'helpers/index';

// Import utilities
import {
	useProgramTimeProgress,
	ImageOnLoadRPC,
	useProductTranslations
} from 'components/utilities';

// Import components
import { AvailableMarks, Progress } from 'components/elements';
import SidebarInfo from './SidebarInfo';
import FavouriteButton from './FavouriteButton/FavouriteButton';

// Import styles
import {
	Channel,
	ChannelName,
	Content,
	Description,
	Figure,
	Logo,
	LogoBox,
	ProgressBarStyled
} from './styles';

const SidebarChannel = React.memo(
	({
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		style,
		videoSessionId,
		selectedChannelID,
		playerOnCancel,
		isScrolling,
		isVisible,
		images,
		channelID,
		channelName,
		available,
		program,
		nextProgram
	}) => {
		const { product: programProduct } = useProductTranslations({
			data: program
		});

		const { product: nextProgramProduct } = useProductTranslations({
			data: nextProgram
		});

		const { title, since, till, uuid: id, pc_rating } = programProduct;

		// Check if program is selected
		const isProgramSelected = channelID === selectedChannelID ? true : false;

		const updateCurrentLive = (channelId) => {
			// Dispatch an setTvProgramsLive action in channels folder
			!isProgramSelected && setTvProgramsLive(channelId);
		};

		// Calculate the program's time progress
		const { progress } = useProgramTimeProgress(
			program,
			channelID,
			updateCurrentLive,
			isScrolling,
			isVisible
		);

		// also change function in Favourite.js
		const handlePlaySelected = async () => {
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel(PLAYER_SELECTORS.PLAYER);
			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());
			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			setSelectedProgram({ title, id, channel: channelName, channelID });
			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: channelID });
		};

		const renderNextProgram = () => {
			const { title, since, till, pc_rating } = nextProgramProduct;
			return (
				<SidebarInfo
					isUpcoming={true}
					since={since}
					till={till}
					title={title}
					pcRating={pc_rating}
				/>
			);
		};

		const progressbarStyle = isProgramSelected ? 'primary' : 'default';
		return (
			<Channel isSelected={isProgramSelected} style={style}>
				<FavouriteButton channelID={channelID} />
				<Content onClick={handlePlaySelected}>
					<LogoBox>
						<Figure>
							<AvailableMarks
								isAvailable={available}
								isContrast
								modifiers="small"
							/>
							<ImageOnLoadRPC>
								{({ setError }) => (
									<Logo
										onError={setError}
										src={`${setChannelLogo(images)}=w70-h70`}
										alt="Logo"
									/>
								)}
							</ImageOnLoadRPC>
						</Figure>
					</LogoBox>
					<Description>
						<ChannelName>{channelName}</ChannelName>

						<SidebarInfo
							since={since}
							till={till}
							title={title}
							pcRating={pc_rating}
						/>
						<ProgressBarStyled>
							<Progress.Current
								modifiers={progressbarStyle}
								progress={progress}
							/>
						</ProgressBarStyled>
						{nextProgram && renderNextProgram()}
					</Description>
				</Content>
			</Channel>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

SidebarChannel.propTypes = {
	images: PropTypes.object.isRequired,
	channelName: PropTypes.string.isRequired,
	channelID: PropTypes.string.isRequired,
	nextPrograms: PropTypes.object,
	program: PropTypes.object,
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	setTvProgramsLive: PropTypes.func.isRequired,
	videoSessionId: PropTypes.string,
	selectedChannelID: PropTypes.string,
	available: PropTypes.bool,
	favourites: PropTypes.array,
	isVisible: PropTypes.bool.isRequired,
	isScrolling: PropTypes.bool.isRequired
};

const mapStateToProps = ({
	player: { videoSessionId },
	selected_program: { channelID }
}) => ({
	videoSessionId,
	selectedChannelID: channelID
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		playerOnCancel
	}
)(SidebarChannel);
