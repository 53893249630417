import React from 'react';
import { string, func, number } from 'prop-types';

// Import components
import { useLogic } from './useLogic';
import PlayerWrapper from 'components/views/vod/components/PlayerWrapper';

const PlayerPreview = React.memo(
	({ uuid, startCarousel, stopStartCarousel, toggle, videoAssetId }) => {
		const data = useLogic({
			uuid,
			startCarousel,
			stopStartCarousel,
			videoAssetId
		});

		return <PlayerWrapper isPreview={true} toggle={toggle} {...data} />;
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerPreview.propTypes = {
	uuid: string.isRequired,
	stopStartCarousel: func.isRequired,
	startCarousel: func.isRequired,
	toggle: func.isRequired,
	videoAssetId: number.isRequired
};

export default PlayerPreview;
