import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import redux actions
import { signOut } from 'store/actions';

// Import Icons
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';

// Import styles
import { ItemStyled } from 'components/elements/molecules/dropdown/styles';
import {
	ButtonNav,
	MenuStyled,
	StyledNav,
	ButtonItem,
	ButtonStyled,
	ButtonText,
	DropdownStyled
} from './styles';

// Import helpers
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';
import { TRACKING_GTM_MANAGER_EVENTS } from 'helpers/variables';
import { getRTLBooleanValue } from 'helpers/rtl';
import { USER_MENU_NAV_LINKS } from 'helpers/user_menu_nav';

// Import utilities
import {
	useAuth,
	useEventTracking,
	useNoAuthPathSave
} from 'components/utilities';
import useToggle from 'components/utilities/hooks/toggle/useToggle';

const { LOGIN } = APP_PATHS_KEY;

const NavDropdown = ({ t, signOut, direction }) => {
	const { isAuth } = useAuth();
	const { setTrackingEvent } = useEventTracking();
	const { savePath } = useNoAuthPathSave();
	const [isDropdownOpened, toggleDropdown] = useToggle();

	// SignOut
	// Dispatch an signOut action in auth folder
	// Trigger Loader component in Dashboard view
	const handleSignOut = () => {
		signOut();
		setTrackingEvent({
			action: TRACKING_GTM_MANAGER_EVENTS.LOGIN_OUT
		});
		toggleDropdown();
	};

	const isRtl = getRTLBooleanValue({ direction });

	const handleLoginClick = () => {
		savePath();
		setTrackingEvent({
			action: TRACKING_GTM_MANAGER_EVENTS.GO_TO_LOGIN_PAGE
		});
	};

	const getDropdownContainer = (trigger) => trigger.parentNode;

	const menu = (
		<MenuStyled>
			{USER_MENU_NAV_LINKS.map(({ path, translation }) => (
				<ItemStyled key={path} isRtl={isRtl} onClick={toggleDropdown}>
					<StyledNav to={path} activeClassName="active-drop-nav" isRtl={isRtl}>
						{t(translation)}
					</StyledNav>
				</ItemStyled>
			))}
			<Menu.Divider />
			<ButtonItem>
				<ButtonNav onClick={handleSignOut} isRtl={isRtl}>
					{t('common_static_btn_sign_out')}
				</ButtonNav>
			</ButtonItem>
		</MenuStyled>
	);

	if (!isAuth) {
		const loginPath = getNavigationPaths(LOGIN);
		return (
			<ButtonStyled
				onClick={handleLoginClick}
				as={Link}
				to={loginPath}
				modifiers={['primary']}
			>
				<ButtonText>{t('common_auth_modal_login_button')}</ButtonText>
			</ButtonStyled>
		);
	}

	return (
		<DropdownStyled
			overlay={menu}
			trigger={['click']}
			onVisibleChange={toggleDropdown}
			isDropdownOpened={isDropdownOpened}
			getPopupContainer={getDropdownContainer}
		>
			<ButtonStyled modifiers={['navIcon']}>
				<ButtonText>
					{t('common_account')}
					<ArrowIcon />
				</ButtonText>
			</ButtonStyled>
		</DropdownStyled>
	);
};

NavDropdown.propTypes = {
	t: PropTypes.func.isRequired,
	signOut: PropTypes.func.isRequired,
	direction: PropTypes.string.isRequired
};

const mapStateToProps = ({ direction: { direction } }) => ({ direction });

export default compose(
	connect(
		mapStateToProps,
		{ signOut }
	),
	withTranslation()
)(NavDropdown);
