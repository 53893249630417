// Import helpers
import {
	getNavigationSubPaths,
	APP_SUB_PATHS_KEY
} from 'helpers/navigationSubPaths';

const {
	USER,
	DEVICES,
	GENERAL,
	FAVOURITES,
	REGULATION,
	SUBSCRIPTIONS,
	RECORDINGS,
	PRODUCTS,
	PAYMENTS,
	WATCHLIST,
	DELETING_ACCOUNT,
	ENTRY_TICKETS
} = APP_SUB_PATHS_KEY;

const PATHS = {
	USER_GENERAL: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		GENERAL
	)}`,
	USER_DEVICES: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		DEVICES
	)}`,
	USER_RECORDINGS: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		RECORDINGS
	)}`,
	USER_PRODUCTS: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		PRODUCTS
	)}`,
	USER_SUBSCRIPTIONS: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		SUBSCRIPTIONS
	)}`,
	USER_FAVOURITES: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		FAVOURITES
	)}`,
	USER_PAYMENTS: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		PAYMENTS
	)}`,
	USER_REGULATION: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		REGULATION
	)}`,
	USER_WATCHLIST: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		WATCHLIST
	)}`,
	USER_ENTRY_TICKETS: `/${getNavigationSubPaths(USER)}/${getNavigationSubPaths(
		ENTRY_TICKETS
	)}`,
	USER_DELETING_ACCOUNT: `/${getNavigationSubPaths(
		USER
	)}/${getNavigationSubPaths(DELETING_ACCOUNT)}`
};

export const USER_MENU_NAV_LINKS = [
	{
		path: PATHS.USER_GENERAL,
		translation: 'user_profile_navigation_general',
		icon: 'ic_user'
	},
	{
		path: PATHS.USER_DEVICES,
		translation: 'user_profile_navigation_devices',
		icon: 'ic_devices'
	},
	{
		path: PATHS.USER_SUBSCRIPTIONS,
		translation: 'user_profile_navigation_subscription',
		icon: 'ic_subscription'
	},
	{
		path: PATHS.USER_WATCHLIST,
		translation: 'user_profile_navigation_watchlist',
		icon: 'ic_favorite'
	},
	{
		path: PATHS.USER_ENTRY_TICKETS,
		translation: 'coupon_activation_use_my_coupon',
		icon: 'ic_use_my_coupon'
	},
	{
		path: PATHS.USER_DELETING_ACCOUNT,
		translation: 'user_profile_navigation_delete_account',
		icon: 'ic_bin'
	}
];

export const USE_MY_COUPON_ICON = 'ic_use_my_coupon';
