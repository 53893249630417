import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import assets
import Mastercard from 'assets/icons/ic_mastercard_footer.svg';
import Paypal from 'assets/icons/ic_paypal_footer.svg';
import Visa from 'assets/icons/ic_visa_footer.svg';

// Import helpers
import { APP_SUB_PATHS_KEY } from 'helpers/navigationSubPaths';
import { getRTLBooleanValue } from 'helpers/rtl';
import { getDocumentNavLink } from 'helpers';

// Import styles
import { FooterNavStyled, Item, RouterLink, Divider } from './styles';

const { REGULATION, PRIVACY_POLICY, ABOUT_US, CONTACT } = APP_SUB_PATHS_KEY;

const FooterNav = ({ t }) => {
	const { direction } = useSelector(({ direction }) => direction);
	const isRtl = getRTLBooleanValue({ direction });

	const regulationPath = getDocumentNavLink(REGULATION);
	const privacyPolicyPath = getDocumentNavLink(PRIVACY_POLICY);
	const aboutUsPath = getDocumentNavLink(ABOUT_US);
	const contactPath = getDocumentNavLink(CONTACT);

	return (
		<FooterNavStyled>
			<FooterNavStyled.Items>
				<Item>
					<RouterLink to={privacyPolicyPath}>
						{t('common_my_account_privacy_policy')}
					</RouterLink>
				</Item>
				<Divider />
				<Item>
					<RouterLink to={regulationPath}>
						{t('common_footer_terms_conditions')}
					</RouterLink>
				</Item>
				<Divider />
				<Item>
					<RouterLink to={contactPath}>{t('common_footer_contact')}</RouterLink>
				</Item>
				<Divider />
				<Item>
					<RouterLink to={aboutUsPath}>{t('common_footer_about')}</RouterLink>
				</Item>
			</FooterNavStyled.Items>
			<FooterNavStyled.Banners isRtl={isRtl}>
				<FooterNavStyled.Banner icon={Mastercard} />
				<FooterNavStyled.Banner icon={Visa} />
				<FooterNavStyled.Banner icon={Paypal} />
			</FooterNavStyled.Banners>
		</FooterNavStyled>
	);
};

export default withTranslation()(FooterNav);
