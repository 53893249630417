import React from 'react';
import { string, array, number, oneOfType, bool } from 'prop-types';

// Import Components
import { ErrorBoundary, SectionFocus } from 'components/elements';
import { Basic } from '../Basic';
import SliderItem from '../components/SliderItem';
import { RegularHeader } from '../components/RegularHeader';

// Import helpers
import { checkIsChannelSection } from './helpers';

// Import hooks
import { useSectionFocus } from 'components/utilities';

const CoverSlider = ({
	id,
	content,
	name,
	label,
	backgroundImage,
	itemResolutionHeight,
	itemResolutionWidth,
	itemsToShow,
	headerHeight,
	spaceBetweenItems,
	showAllMoviesButton,
	shuffle
}) => {
	const isChannelSection = checkIsChannelSection(content);

	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	const renderHeader = () => (
		<RegularHeader
			showAllMoviesButton={showAllMoviesButton}
			sectionId={id}
			title={name}
			isChannelSection={isChannelSection}
			saveSectionId={saveSectionId}
		/>
	);

	return (
		<SectionFocus ref={restorationRef}>
			<Basic
				key={id}
				itemResolutionHeight={itemResolutionHeight}
				itemResolutionWidth={itemResolutionWidth}
				itemsList={content}
				itemsToShow={itemsToShow}
				headerHeight={headerHeight}
				spaceBetweenItems={spaceBetweenItems}
				backgroundImage={backgroundImage}
				renderItemComponent={({ data, height, width }) => (
					<ErrorBoundary>
						<SliderItem
							sectionLabel={label}
							data={data}
							height={height}
							width={width}
							imageType="cover_small"
							saveSectionId={saveSectionId}
						/>
					</ErrorBoundary>
				)}
				renderHeader={renderHeader}
				shuffle={shuffle}
			/>
		</SectionFocus>
	);
};

CoverSlider.defaultProps = {
	showAllMoviesButton: true,
	label: '',
	backgroundImage: false,
	itemResolutionHeight: 170,
	itemResolutionWidth: 300,
	itemsToShow: 6.2,
	headerHeight: 75,
	spaceBetweenItems: 8,
	shuffle: false
};

CoverSlider.propTypes = {
	id: oneOfType([string, number]).isRequired,
	content: array.isRequired,
	name: string.isRequired,
	label: string.isRequired,
	backgroundImage: oneOfType([string, bool]),
	itemResolutionHeight: number,
	itemResolutionWidth: number,
	itemsToShow: number,
	headerHeight: number,
	spaceBetweenItems: number,
	showAllMoviesButton: bool,
	shuffle: bool
};

export default CoverSlider;
