import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { Nav } from 'components/elements';

// import helpers
import { getSearchCategory } from 'helpers';

const NavCategoryList = React.memo(
	({ links, path = '', isInverse = false, modifiers }) => (
		<Nav.List dropdown modifiers={modifiers}>
			{links.map((link) => (
				<Nav.Item key={link.id} modifiers={modifiers}>
					<Nav.NavLink
						exact
						to={`${path}${link.slug &&
							`/${
								path.includes('szukaj') || path.includes('search')
									? getSearchCategory(link.slug)
									: link.slug
							}`}`}
						activeClassName="active-nav"
						inverse={isInverse ? 1 : 0}
						modifiers={modifiers}
					>
						{link.name}
					</Nav.NavLink>
				</Nav.Item>
			))}
		</Nav.List>
	)
);

NavCategoryList.propTypes = {
	links: PropTypes.array.isRequired,
	path: PropTypes.string,
	isInverse: PropTypes.bool,
	modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default NavCategoryList;
