import styled, { css } from 'styled-components';
import { Heading, PlayerElements as Player } from 'components/elements';

// Import icons
import { borderGradient } from 'components/styles';

export const Box = styled.div`
	position: absolute;
	bottom: 9rem;
	right: 0;
	border-radius: 0.7rem;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s ease-in-out;
	text-align: center;

	${({ theme }) => css`
		${borderGradient({
			borderSize: 1,
			borderBg: theme.player.settings.borderColor
		})}
	`}
`;

export const WrapperStyled = styled(Player.Wrapper)`
	width: auto;

	&:hover ${Box} {
		opacity: 1;
		visibility: visible;
	}
`;

export const Bg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => theme.player.settings.backgroundColor};
	border-radius: 6px;
	opacity: 1;
	z-index: 0;
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 6px;
	display: flex;
	padding: 1rem 0;

	font-size: 2rem;
	font-weight: ${({ theme }) => theme.font.normal};
	line-height: 1.9;
`;

export const HeadingStyled = styled(Heading.Third)`
	margin-bottom: 0.8rem;
`;

export const Setting = styled.div`
	padding: 0 1rem;
	border: 1px solid ${({ theme }) => theme.player.settings.borderColor};
	z-index: 1;

	&:last-child {
		border-right: 1px solid transparent;
	}
`;

export const IconSettingsStyled = styled(Player.IconSettings)`
	cursor: pointer;
`;
