import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Button, Paragraph } from 'components/elements';
import { ReactComponent as ClockIcon } from 'assets/icons/ic_clock.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/ic_trash.svg';

// Import styles
import { transitionMain, rtl } from 'components/styles';

export const ClockIconStyled = styled(ClockIcon)`
	height: 1.5rem;
	width: 1.5rem;

	g,
	path {
		fill: ${({ theme }) => theme.grayMedium};
		${transitionMain}
	}
`;

export const Wrapper = styled.div`
	position: relative;
`;

export const DataDisplay = styled(Link)`
	padding: 0.5rem 1.5rem;
	height: 8.2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-decoration: none;
	border-bottom: 1px solid ${({ theme }) => theme.line};
	color: ${({ theme }) => theme.grayMedium};
	${transitionMain}

	& > * {
		${transitionMain}
	}

	&:hover {
		background-color: ${({ theme: { sidebar } }) => sidebar.itemHoverBg};
		& > * {
			color: ${({ theme }) => theme.white};
		}

		${ClockIconStyled} {
			g,
			path {
				fill: ${({ theme }) => theme.white};
			}
		}
	}
`;

export const ChannelName = styled(Paragraph.Ellipsis)`
	font-weight: ${({ theme }) => theme.font.normal};
`;

export const ProgramInfo = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 250px;
`;

export const DateInfo = styled.div`
	display: flex;
	align-items: center;

	& svg {
		${rtl`
			margin-right: 8px;
		`}
	}
`;

export const TrashIconStyled = styled(TrashIcon)`
	height: 2rem;
	width: 2rem;

	path {
		fill: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		stroke: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		${transitionMain};
	}
`;

export const ButtonStyled = styled(Button)`
	position: absolute;
	top: 3rem;
	${rtl`
		right: 2.5rem;
	`}
	height: 2rem;
	width: 2rem;
	padding: 0;

	&:hover {
		${TrashIconStyled} {
			path {
				fill: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
				stroke: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
			}
		}
	}
`;
