export const isNumber = (value) => typeof value === 'number';

export const convertPrice = (value) => {
	if (isNumber(value)) {
		const price = value / 100;
		return price.toFixed(2);
	}

	return '';
};
