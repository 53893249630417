// Import variables
import { LOCALSTORAGE_PLAYER, SECTIONS_TYPES } from 'helpers/variables';

// Import helpers
import { checkAppType } from 'helpers';

export const getAvailableChannel = (channels) =>
	channels.find(
		({ currentLiveIndex, noPrograms, subscriberLocalLimited }) =>
			currentLiveIndex >= 0 && !noPrograms && !subscriberLocalLimited
	);

export const getChannel = (channels, productID) =>
	channels.find(({ uuid }) => uuid === productID);

export const getChannelID = ({ location, channels }) => {
	let channelId = null;
	const playerStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE_PLAYER));
	if (location.state && location.state.channelId) {
		channelId = location.state.channelId;
	} else if (playerStorage && playerStorage.productID) {
		channelId = playerStorage.productID;
	}

	if (channelId) {
		const channel = getChannel(channels, channelId);

		if (channel && channel.subscriberLocalLimited) {
			const avaiableChannel = getAvailableChannel(channels);
			if (avaiableChannel) channelId = avaiableChannel.uuid;
		}
	}

	return channelId;
};

export const filterSections = (sections) => {
	const sectionTypes = Object.values(SECTIONS_TYPES);
	const { VIRTUAL } = SECTIONS_TYPES;
	return sections.filter(({ content, type }) => {
		return (
			(content.length > 0 && sectionTypes.includes(type)) || type === VIRTUAL
		);
	});
};

// get LIVE TV section title
export const getTVLiveSectionTitle = ({ title }) => {
	const regEx = /tv online|tvonline/gi;

	const { isTvSmart } = checkAppType();

	const shouldReplaceAppName = isTvSmart && title.match(regEx);

	const sectionTitle = shouldReplaceAppName
		? title.replace(regEx, LIVE_TITLES.TV_SMART)
		: title;

	return sectionTitle;
};

const LIVE_TITLES = {
	TV_ONLINE: 'TV Online',
	TV_SMART: 'TV Smart'
};
