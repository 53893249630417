import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import paths
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';

const { HOME, LOGIN } = APP_PATHS_KEY;

const useAuth = () => {
	const history = useHistory();
	const isAuth = useSelector((state) => state.auth.isLogged);

	const mainPath = getNavigationPaths(HOME);
	const loginPath = getNavigationPaths(LOGIN);
	const handleRedirectToLogin = () => history.push(loginPath);

	const handleRedirectToMain = () => history.push(mainPath);

	return {
		isAuth,
		redirectToLoginPage: handleRedirectToLogin,
		redirectToMainPage: handleRedirectToMain,
		mainRedirectPath: mainPath,
		loginRedirectPath: loginPath
	};
};

export default useAuth;
