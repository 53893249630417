import { notification } from 'antd';
import { LANGUAGES_TYPES } from 'helpers/variables';
import i18next from 'i18next';

export const openNotification = ({
	type,
	title,
	description,
	duration = 7
}) => {
	const { language } = i18next;
	const placement = language === LANGUAGES_TYPES.AR ? 'topLeft' : 'topRight';

	notification[type]({
		message: title,
		description,
		duration,
		placement
	});
};
