import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import icons
import { ReactComponent as IconVolumeOffSVG } from 'assets/icons/volume_off.svg';
import { ReactComponent as IconVolumeSVG } from 'assets/icons/volume.svg';
import { ReactComponent as IconFullScreenSVG } from 'assets/icons/fullscreen-vod.svg';
import { ReactComponent as IconSettingsSVG } from 'assets/icons/settings.svg';
import { ReactComponent as IconPauseSVG } from 'assets/icons/pause.svg';
import { ReactComponent as IconPlaySVG } from 'assets/icons/play.svg';
import { ReactComponent as IconSeekPrevSVG } from 'assets/icons/seek_15_prev.svg';
import { ReactComponent as IconSeekNextSVG } from 'assets/icons/seek_15_next.svg';
import { ReactComponent as IconPreviousSVG } from 'assets/icons/previous.svg';
import { ReactComponent as IconNextSVG } from 'assets/icons/next.svg';
import { ReactComponent as IconTickSVG } from 'assets/icons/tick.svg';
import { ReactComponent as IconCloseThickSVG } from 'assets/icons/close_thick.svg';
import { ReactComponent as IconLockSVG } from 'assets/icons/ic_lock.svg';
import { ReactComponent as IconExitFullScreenSVG } from 'assets/icons/exit_fullscreen.svg';
import { ReactComponent as IconCastSVG } from 'assets/icons/ic_cast.svg';
import { ReactComponent as IconCastConnectedSVG } from 'assets/icons/ic_cast_connected.svg';

// Import styles
import { iconController, linearGradient, flex } from 'components/styles';

// Import components
import { Heading } from 'components/elements';

const PLAYER_MODIFIERS = {
	primaryRange: () => css`
		${linearGradient({ deg: 'to left' })}
	`,
	iconOpacity: () => css`
		opacity: 0.7;
	`
};

const Volume = styled.div`
	position: relative;
	width: 0;
	height: 0.45rem;
	display: flex;
	align-items: center;
	border-radius: 5px;
	background-color: ${({ theme }) => theme.player.volume.track};

	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease-in-out;

	${({ isMuted }) =>
		isMuted &&
		css`
			background-color: transparent;
		`}
`;

const RangeWrapper = styled.div`
	width: 8rem;
	height: 0.45rem;

	margin: 0 2.25rem;

	opacity: 0;
	visibility: hidden;

	&:hover {
		${Volume} {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;

	&:hover {
		${Volume} {
			opacity: 1;
			visibility: visible;

			width: 8rem;

			${({ isTv }) =>
				isTv &&
				css`
					margin: 0 0.5rem 0 1.5rem;
				`}
		}

		${RangeWrapper} {
			opacity: 1;
			visibility: visible;
		}
	}
`;

const Range = styled.input.attrs(({ value }) => ({
	style: {
		background: `linear-gradient(to right, #FFFFFF 0%, #FFFFFF ${value}%, rgba(255,255,255,0.3) ${value}%)`
	}
}))`
	position: relative;
	z-index: 100;

	width: 100%;

	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;

	border-radius: 5px;
	height: 0.45rem;

	transition: background 450ms ease-in;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 1rem;
		width: 1rem;
		border-radius: 50%;

		cursor: ew-resize;
		background-color: ${({ theme }) => theme.player.volume.thumb};
	}

	${({ isMuted }) =>
		isMuted &&
		css`
			display: none;
		`}
`;

const baseIconStyle = css`
	${iconController}

	height: 2.5rem;
	width: 2.5rem;

	&:hover {
		opacity: 1;
	}
	${applyStyleModifiers(PLAYER_MODIFIERS)};
`;

const Profiles = styled.ul`
	list-style: none;
	padding: 0.8rem 4.5rem 0;

	border-top: 1px solid
		${({ theme }) => theme.player.settings.profile.borderTopColor};
`;

const Profile = styled.li`
	position: relative;
	margin-bottom: 0.8rem;
	cursor: pointer;

	${flex()}

	span {
		transition: color 0.3s ease-in-out;

		color: ${({ theme }) => theme.player.settings.profile.color};
	}

	svg {
		visibility: hidden;
		opacity: 0;
		margin-right: 0.5rem;
	}

	&:hover {
		span {
			color: ${({ theme }) => theme.player.settings.profile.hover};
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	${({ profile, selected }) =>
		profile === selected &&
		css`
			span {
				color: ${({ theme }) => theme.player.settings.profile.selected};
			}
		`}
`;

// Player Error
const Error = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.8);
	${flex()};
	z-index: 10;

	${({ isDoubleErrors = false }) =>
		isDoubleErrors &&
		css`
			background-color: rgb(0, 0, 0);
			z-index: 1000;
		`}
`;

const ErrorContent = styled.div`
	max-width: 85.5rem;
`;

const ErrorHeading = styled(Heading.Second)`
	color: #697184;
	font-size: 3.8rem;
	margin: 3.3rem 0 3.8rem 0;
	font-weight: ${({ theme }) => theme.font.light};
`;

// Player Icons
const baseIconControllerStyle = css`
	${iconController}
	${baseIconStyle}
`;

const IconVolume = styled(IconVolumeSVG)`
	${baseIconStyle}
`;

const IconVolumeOff = styled(IconVolumeOffSVG)`
	${baseIconStyle}
`;

const IconSettings = styled(IconSettingsSVG)`
	${baseIconStyle}
`;

const IconFullScreen = styled(IconFullScreenSVG)`
	${baseIconStyle}
`;

const IconExitFullScreen = styled(IconExitFullScreenSVG)`
	${baseIconStyle}

	g {
		opacity: 1;
	}
`;

const IconCloseThick = styled(IconCloseThickSVG)`
	${baseIconStyle}
`;

const IconTick = styled(IconTickSVG)`
	${baseIconStyle}
	height: 1.2rem;
	width: 1.2rem;
`;

const IconPlay = styled(IconPlaySVG)`
	${baseIconControllerStyle};
`;

const IconPause = styled(IconPauseSVG)`
	${baseIconControllerStyle}
`;

const IconSeekPrev = styled(IconSeekPrevSVG)`
	${baseIconControllerStyle}
`;

const IconSeekNext = styled(IconSeekNextSVG)`
	${baseIconControllerStyle}
`;

const IconPrevious = styled(IconPreviousSVG)`
	${baseIconControllerStyle}
`;

const IconNext = styled(IconNextSVG)`
	${baseIconControllerStyle}
`;

const IconLock = styled(IconLockSVG)`
	height: 3.6rem;
	width: 3.6rem;
	margin-right: 1rem;
	position: relative;
	top: 4px;
`;

const IconCast = styled(IconCastSVG)`
	${baseIconControllerStyle}
`;

const IconCastConnected = styled(IconCastConnectedSVG)`
	${baseIconControllerStyle}
`;

export {
	Wrapper,
	Range,
	Volume,
	IconVolume,
	IconVolumeOff,
	IconSettings,
	IconFullScreen,
	IconCloseThick,
	IconPlay,
	IconPause,
	IconSeekPrev,
	IconSeekNext,
	IconNext,
	IconPrevious,
	IconTick,
	IconLock,
	Profiles,
	Profile,
	Error,
	ErrorContent,
	ErrorHeading,
	IconExitFullScreen,
	RangeWrapper,
	IconCast,
	IconCastConnected
};
