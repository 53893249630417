import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import GlobalStyle from 'components/theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import uuidv4 from 'uuid/v4';

// Import helpers
import { COOKIE_EXPIRES } from 'helpers/variables';
import { initializeFirebaseApp } from 'helpers/firebase';
import { getRTLBooleanValue } from 'helpers/rtl';

// Import utilities
import useUserAgent from 'components/utilities/hooks/user_agent/useUserAgent';
import useSelectTheme from 'components/utilities/hooks/templates/useSelectTheme';
import { useCouponActivationUrl, useTracking } from 'components/utilities';

// Import components
import { CookieMessage, ErrorBoundary } from 'components/elements';
import { ToggleRPC } from 'components/utilities';
import PortalUnavailable from 'components/views/PortalUnavailable';
import MetaTags from 'components/layout/helmet/HelmetMetaTags';

// Import styles
import { Container } from './styles';

const AppTemplate = ({ children }) => {
	const [cookieNotice, setCookieNotice] = useState(false);
	const isPortalAvailable = useSelector(
		({ portal }) => portal.isPortalAvailable
	);
	const { isMobile } = useUserAgent();
	const { direction } = useSelector((state) => state.direction);
	const isRtl = getRTLBooleanValue({ direction });
	const { pathname } = useLocation();

	useCouponActivationUrl();

	// select theme or get one based on project env
	const selectedTheme = useSelectTheme();

	const checkUID = () => {
		// Get uid
		const cookies = Cookies.get('uid');
		// If uid is nod defined, create uid's cookie
		if (!cookies) {
			const createUID = uuidv4();
			Cookies.set('uid', createUID, { expires: COOKIE_EXPIRES });
		}
	};

	const checkCookieNotice = () => {
		// Gett cookie notice
		const cookieNotice = Cookies.get('cookie-notice');
		// If there is not cookie-notice show cookie message
		if (cookieNotice && cookieNotice === '1') {
			setCookieNotice(true);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [pathname]);

	useEffect(() => {
		document.querySelector('html').setAttribute('dir', direction);
	}, [direction]);

	/* eslint-disable */
	useEffect(() => {
		// Initialize firebase
		initializeFirebaseApp();
		// Check uid
		checkUID();
		// Check cookie notice
		checkCookieNotice();
	}, []);
	/* eslint-enable */

	useTracking();

	const renderContainerContent = () => (
		<ErrorBoundary>
			{children}
			<ToggleRPC initial={!cookieNotice}>
				{({ on, toggle }) => <>{on && <CookieMessage toggle={toggle} />}</>}
			</ToggleRPC>
		</ErrorBoundary>
	);

	return (
		<ThemeProvider theme={{ ...selectedTheme, direction }}>
			<MetaTags />
			<GlobalStyle isRtl={isRtl} />
			{isPortalAvailable ? (
				<Container isMobile={isMobile}>{renderContainerContent()}</Container>
			) : (
				<PortalUnavailable />
			)}
		</ThemeProvider>
	);
};

AppTemplate.propTypes = {
	children: PropTypes.array.isRequired
};

export default AppTemplate;
