// Import helpers
import { QUERY_SETTINGS_DOCUMENTS } from 'helpers/api';

// Import utilities
import { useSubscriptions, useAuth, useDocuments } from 'components/utilities';

const usePlayerAds = ({ isPreview = false, isLiveEvent = false } = {}) => {
	const { isAuth } = useAuth();
	const { isSubscriptions, isSubscriptionsLoading } = useSubscriptions({
		isLogged: isAuth
	});
	const { documents, isDocumentsLoading } = useDocuments({
		queryKey: QUERY_SETTINGS_DOCUMENTS,
		documents: ['settings']
	});

	const settingsDocument = documents?.[0]?.content;

	const isAdsLoading = isPreview
		? isDocumentsLoading
		: isDocumentsLoading || isSubscriptionsLoading;

	const isAdsAvailable = !isSubscriptions && !isPreview && !isLiveEvent;

	const parsedSettingsDocument =
		settingsDocument && JSON.parse(settingsDocument)?.avod_vast_web;

	const adsSettingsUrl = isAdsAvailable ? parsedSettingsDocument : '';

	return { isAdsLoading, adsSettingsUrl };
};

export default usePlayerAds;
