export const buttons = {
	gradientButton: {
		backgroundDefault: {
			color1: 'rgba(255,255,255,0.2)',
			color2: 'rgba(144,181,215,0.2)',
			color3: 'rgba(59,125,185,0.2)',
			color4: 'rgba(49,85,156,0.2)'
		},
		backgroundHovered: {
			color1: 'rgba(165,217,229,0.2)',
			color2: 'rgba(110,196,197,0.2)',
			color3: 'rgba(60,185,190,0.2)',
			color4: 'rgba(7,82,108,0.2)'
		},
		borderDefault: {
			color1: 'rgba(255,255,255,0.74)',
			color2: 'rgba(187,255,235,0.2)',
			color3: 'rgb(49,84,155)'
		},
		borderHovered: {
			color1: 'rgba(217,238,224,0.74)',
			color2: 'rgba(65,187,237,0.2)',
			color3: 'rgb(0,181,215)'
		},
		smartBackgroundHovered: {
			color1: 'rgba(154,104,0,0.2)',
			color2: 'rgba(203,147,30,0.2)',
			color3: 'rgba(234,176,54,0.2)',
			color4: 'rgba(255,235,0,0.2)'
		},
		smartBorderHovered: {
			color1: 'rgba(255,243,105,0.74)',
			color2: 'rgba(234,176,54,0.2)',
			color3: 'rgb(154,104,0)'
		}
	},
	priceButton: {
		backgroundDefaultKids: {
			color1: 'rgba(125,125,125,0.8)',
			color2: 'rgba(52,74,105,0.8)',
			color3: 'rgba(40,74,115,0.8)',
			color4: 'rgba(29,42,85,0.8)'
		},
		backgroundHoveredKids: {
			color1: 'rgba(54,93,105,0.8)',
			color2: 'rgba(46,94,95,0.8)',
			color3: 'rgba(39,90,95,0.8)',
			color4: 'rgba(6,42,55,0.8)'
		}
	},
	sectionButton: {
		background: {
			color1: 'rgba(1,1,1,0.4)',
			color2: 'rgba(1,1,1,0)',
			color3: 'rgba(255,255,255,0)'
		},
		border: {
			color1: 'rgba(255,255,255,0.5)',
			color2: 'rgba(255,255,255,0.8)',
			color3: 'rgba(186,186,186,0.37)'
		}
	},
	vodSectionButton: {
		backgroundHovered: {
			color1: 'rgba(2,27,56)',
			color2: 'rgba(8,56,83)'
		},
		borderGradient: {
			color1: 'rgba(255,255,255,0.2)',
			color2: 'rgba(255,255,255,0.5)',
			color3: 'rgba(255,255,255,0.2)'
		}
	},
	favouriteButtonInherit: {
		background: {
			color1: 'rgba(1,1,1,0.4)',
			color2: 'rgba(1,1,1,0)',
			color3: 'rgba(255,255,255,0)'
		},
		backgroundKids: {
			color1: 'rgba(1,1,1,0.5)',
			color2: 'rgba(1,1,1,0.3)',
			color3: 'rgba(0,0,0,0.3)'
		},
		border: {
			color1: 'rgba(255,255,255,0.5)',
			color2: 'rgba(255,255,255,0.8)',
			color3: 'rgba(186,186,186,0.37)'
		}
	},
	dropdownButton: {
		buttonGlow: {
			color1: 'rgba(255, 255, 255, 1)',
			color2: 'rgba(45, 104, 144, 0)',
			color3: 'rgba(45, 104, 144, 0)'
		},
		gradient: {
			color1: 'rgba(45, 104, 144, 0.3)',
			color2: 'rgba(45, 104, 144, 0.4)',
			color3: 'rgba(45, 104, 144, 0.5)'
		},
		divider: {
			color1: 'rgba(255, 255, 255, 0.15)',
			color2: 'rgba(255, 255, 255, 0.1)',
			color3: 'rgba(255, 255, 255, 0.05)',
			color4: 'rgba(255, 255, 255, 0)',
			color5: 'rgba(255, 255, 255, 0)',
			color6: 'rgba(255, 255, 255, 0)'
		}
	},
	menuButton: {
		buttonGlow: {
			color1: 'rgba(45, 104, 144, 1)',
			color2: 'rgba(45, 104, 144, 0.5)',
			color3: 'rgba(45, 104, 144, 0)',
			color4: 'rgba(45, 104, 144, 0)'
		}
	}
};
