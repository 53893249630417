import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

// Import utilities
import { useFullScreen, usePlayerAds } from 'components/utilities';

// Import context
import PlayerVodProvider from './PlayerVodProvider';

// Import helpers
import { playerVodStyles } from 'helpers/player';
import { getUserAgent } from 'helpers/index';

// Import utilities
import { PlayerFocus, useSystemSupport } from 'components/utilities';

// Import components
import { Portal } from 'components/utilities';
import {
	Loader,
	PlayerError,
	SupportError,
	ErrorBoundary
} from 'components/elements';
import PlayerContent from './player_content/PlayerContent';

import { clearRecommendations } from 'store/actions';

const Root = styled.div`
	flex: 1;
	background-color: ${({ theme }) => theme.black};
	width: 100%;
	position: relative;

	&::before {
        content: '';
        display: block;
        height: 0;
        padding-top: 56.25%;
    }

	${({ isReady }) =>
		css`
			${playerVodStyles(isReady)}
		`}

	${({ isSafari, isPreview }) =>
		isSafari &&
		isPreview &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 9999;
		`}

`;

const EmbedWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
`;

export default function PlayerWrapper({
	isLoaded,
	isDetailsLoaded,
	isReady,
	type,
	error,
	errorMessage,
	toggle = () => {},
	isPreview = false,
	isLiveEvent = false
}) {
	const dispatch = useDispatch();

	const handleClosePreview = () => toggle();

	const {
		browser: { name: browserName }
	} = getUserAgent();
	const isSafari = browserName.toLowerCase() === 'safari';

	const { setFullscreen, elementRef } = useFullScreen(
		handleClosePreview,
		isSafari
	);

	const { isAdsLoading, adsSettingsUrl } = usePlayerAds({
		isPreview,
		isLiveEvent
	});

	const {
		showSupportMessage,
		supportError,
		setSupportAccept
	} = useSystemSupport();

	React.useEffect(() => {
		setFullscreen();

		return () => clearRecommendations(dispatch);
		// eslint-disable-next-line
	}, []);

	return isSafari && isPreview ? (
		<Portal>
			<PlayerFocus intervalTime={250} isReady={isReady}>
				{({ isFocus, resetFocus }) => (
					<Root isSafari={isSafari} isPreview={isPreview} isReady={isReady}>
						<EmbedWrapper id={type}>
							{!isLoaded && !isDetailsLoaded && (
								<Loader background={false} position="absolute" zIndex={1} />
							)}
							{isLoaded && !isDetailsLoaded && !error.isError && (
								<ErrorBoundary>
									<PlayerVodProvider
										closePreview={handleClosePreview}
										isPreview={isPreview}
										isSafari={isSafari}
										type={type}
										isFocus={isFocus}
										resetFocus={resetFocus}
									>
										<PlayerContent type={type} isFocus={isFocus} />
									</PlayerVodProvider>
								</ErrorBoundary>
							)}
							{error.isError && (
								<PlayerError error={{ message: errorMessage }} />
							)}
							{showSupportMessage && (
								<SupportError
									error={supportError}
									setSupportAccept={setSupportAccept}
									playerType={type}
								/>
							)}
						</EmbedWrapper>
					</Root>
				)}
			</PlayerFocus>
		</Portal>
	) : (
		<PlayerFocus intervalTime={250} isReady={isReady}>
			{({ isFocus, resetFocus }) => (
				<Root isReady={isReady}>
					<EmbedWrapper ref={elementRef} id={type}>
						{!isLoaded && !isDetailsLoaded && (
							<Loader background={false} position="absolute" zIndex={1} />
						)}
						{isLoaded && !isDetailsLoaded && !isAdsLoading && !error.isError && (
							<ErrorBoundary>
								<PlayerVodProvider
									closePreview={handleClosePreview}
									isPreview={isPreview}
									isSafari={isSafari}
									type={type}
									isFocus={isFocus}
									resetFocus={resetFocus}
									adsSettingsUrl={adsSettingsUrl}
								>
									<PlayerContent type={type} isFocus={isFocus} />
								</PlayerVodProvider>
							</ErrorBoundary>
						)}
						{error.isError && <PlayerError error={{ message: errorMessage }} />}
						{showSupportMessage && (
							<SupportError
								error={supportError}
								setSupportAccept={setSupportAccept}
								playerType={type}
							/>
						)}
					</EmbedWrapper>
				</Root>
			)}
		</PlayerFocus>
	);
}

PlayerWrapper.propTypes = {
	isLoaded: PropTypes.bool.isRequired,
	isReady: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	error: PropTypes.object.isRequired,
	errorMessage: PropTypes.string,
	toggle: PropTypes.func,
	isPreview: PropTypes.bool,
	isLiveEvent: PropTypes.bool
};
