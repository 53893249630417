import React from 'react';
import { func, string, oneOf } from 'prop-types';
import styled from 'styled-components';

// Import heleprs
import { DIRECTION_WAY_TYPES } from 'helpers/variables';

// Import components
import { DynamicIcon } from 'components/elements';

const { RIGHT, LEFT } = DIRECTION_WAY_TYPES;

const IconContainer = styled.div`
	height: 140px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	right: ${({ direction }) =>
		direction === RIGHT ? '-40px' : 'unset'} !important;
	left: ${({ direction }) =>
		direction === LEFT ? '-40px' : 'unset'} !important;

	&::before {
		content: '' !important;
	}

	&.slick-disabled {
		display: none;
	}

	svg g polygon {
		fill: ${({ theme }) => theme.slider.arrow.color};
	}
`;

export function CustomArrow({ onClick, className, direction }) {
	return (
		<IconContainer
			direction={direction}
			className={className}
			onClick={onClick}
		>
			{direction === RIGHT && <DynamicIcon icon="ic_slider_arrow_r" />}
			{direction === LEFT && <DynamicIcon icon="ic_slider_arrow_l" />}
		</IconContainer>
	);
}

CustomArrow.propTypes = {
	onClick: func,
	className: string,
	direction: oneOf([RIGHT, LEFT])
};
