import { useSelector } from 'react-redux';
import _ from 'lodash';

const useProductAvailability = (uuid, productAvailableIn) => {
	const { products } = useSelector(({ auth }) => auth);

	// Check if product has availableIn array with products
	if (productAvailableIn && productAvailableIn.length > 0) {
		const availableIn = [...productAvailableIn, uuid];

		const isProductAvailable = _.intersection(products, availableIn);

		return isProductAvailable.length > 0 ? true : false;
	} else {
		const isProductAvailable = products.find(
			(productUuid) => productUuid === uuid
		);
		return isProductAvailable ? true : false;
	}
};

export default useProductAvailability;
