import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';

// Import components
import { Button, Field as FormField, SelectField } from 'components/elements';

const { Option } = Select;

const ParentalControlFields = ({ isSubmitting, isEnabled }) => {
	// Get translation
	const { t } = useTranslation();

	return (
		<React.Fragment>
			{isEnabled ? (
				<Field
					placeholder={t('parental_control_pin')}
					name="pin"
					type="text"
					component={FormField}
				/>
			) : (
				<Field name="rating" component={SelectField}>
					<Option value={0}>{t('parental_control_no_age_limits')}</Option>
					<Option value={7}>{t('parental_control_seven_age_limits')}</Option>
					<Option value={12}>{t('parental_control_twelve_age_limits')}</Option>
					<Option value={16}>{t('parental_control_sixteen_age_limits')}</Option>
					<Option value={18}>
						{t('parental_control_eighteen_age_limits')}
					</Option>
				</Field>
			)}

			<Button
				type="submit"
				disabled={isSubmitting}
				width="100%"
				modifiers="primary"
			>
				{isEnabled
					? t('parental_control_btn_enabled')
					: t('parental_control_btn_active')}
			</Button>
		</React.Fragment>
	);
};

ParentalControlFields.propTypes = {
	isSubmitting: PropTypes.bool.isRequired,
	isEnabled: PropTypes.bool.isRequired
};

export default React.memo(ParentalControlFields);
