import { getNavigationSubPaths, APP_SUB_PATHS_KEY } from './navigationSubPaths';

const {
	HOME,
	WATCH,
	RECORDINGS,
	PACKETS,
	PAYMENTS,
	FAVOURITES,
	REGULATION,
	CHANNELS,
	PROGRAM_DETAILS,
	SEARCH,
	REGISTER,
	LOGIN,
	TRANSACTION,
	USER
} = APP_SUB_PATHS_KEY;

// core paths as stings
export const ROUTES_CORE_PATHS = {
	HOME: getNavigationSubPaths(HOME),
	WATCH: getNavigationSubPaths(WATCH),
	RECORDINGS: getNavigationSubPaths(RECORDINGS),
	PACKETS: getNavigationSubPaths(PACKETS),
	PAYMENTS: getNavigationSubPaths(PAYMENTS),
	FAVOURITES: getNavigationSubPaths(FAVOURITES),
	REGULATION: getNavigationSubPaths(REGULATION),
	CHANNELS: getNavigationSubPaths(CHANNELS),
	PROGRAM_DETAILS: getNavigationSubPaths(PROGRAM_DETAILS),
	SEARCH: getNavigationSubPaths(SEARCH),
	REGISTER: getNavigationSubPaths(REGISTER),
	LOGIN: getNavigationSubPaths(LOGIN),
	TRANSACTION: getNavigationSubPaths(TRANSACTION),
	USER: getNavigationSubPaths(USER)
};
