import firebase from 'firebase/compat/app';
import ReactPixel from 'react-facebook-pixel';

const useEventTracking = () => {
	const handleTrackingEvent = ({ action, ...rest }) => {
		firebase.analytics().logEvent(action, { name: action, ...rest });
		ReactPixel.trackCustom(action, { name: action, ...rest });

		window.dataLayer.push({
			event: action,
			eventProps: {
				...rest
			}
		});
	};

	return {
		setTrackingEvent: handleTrackingEvent
	};
};

export default useEventTracking;
