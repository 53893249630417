import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';

// Import helpers
import { createLinkParams, setValidChannelLogo } from 'helpers';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

// Import components
import {
	SearchItemStyled,
	LinkStyled,
	Preview,
	Image,
	Content,
	ParagraphStyled
} from './styles';

const SearchItemChannel = ({
	toggle,
	product,
	channels,
	videoSessionId,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
}) => {
	const { pathname } = useLocation();
	const channel = channels.find((channel) => channel.uuid === product.uuid);

	if (!channel) return null;

	const {
		title,
		images,
		uuid,
		type,
		epgProgrammes,
		currentLiveIndex
	} = channel;

	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = setValidChannelLogo(images);

	const handleLinkRedirect = async (e) => {
		const regex = /\b(\w*strona-glowna\w*)\b/i;
		if (regex.test(pathname)) {
			e.preventDefault();
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel();
			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());
			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			const program = epgProgrammes[currentLiveIndex];
			program &&
				setSelectedProgram({
					title: program.title,
					id: program.uuid,
					channel: title,
					channelID: uuid
				});
			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: uuid });
		}
		toggle();
	};

	const link = createLinkParams({ id: uuid, type });

	return (
		<SearchItemStyled>
			<LinkStyled onClick={handleLinkRedirect} to={link}>
				<Preview>
					{isImgUrlValid && (
						<ImageOnLoadRPC>
							{({ setError }) => (
								<Image onError={setError} src={imgUrl} alt={title} />
							)}
						</ImageOnLoadRPC>
					)}
				</Preview>
				<Content>{title && <ParagraphStyled>{title}</ParagraphStyled>}</Content>
			</LinkStyled>
		</SearchItemStyled>
	);
};

SearchItemChannel.propTypes = {
	product: PropTypes.object.isRequired,
	toggle: PropTypes.func.isRequired,
	channels: PropTypes.array.isRequired
};

const mapStateToProps = ({ player: { videoSessionId } }) => ({
	videoSessionId
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		playerOnCancel
	}
)(SearchItemChannel);
