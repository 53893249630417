import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

// Import styles
import {
	ProfilesBox,
	Wrapper,
	Content,
	ProfileName,
	Profiles,
	Profile
} from 'components/elements/organisms/player/player_overlay/player_controllers/styles';
import { Bg } from 'components/views/vod/components/PlayerWrapper/player_controllers/settings_controller/styles';

const SettingsController = ({ isCatchupControllers }) => {
	// Get player methods from Player context
	const {
		profiles,
		selectedProfile,
		setProfile,
		resetFocus,
		isFocus,
		languages,
		selectedLanguage,
		setLanguage,
		isSafari
	} = usePlayerTvContext();

	// Define the state
	const [isVisible, setIsVisible] = React.useState(false);

	const { t } = useTranslation();

	// Set profile in player provider
	const handleSetProfile = React.useCallback(
		(values) => () => setProfile(values),
		[setProfile]
	);

	// Update the isVisible state
	const handleSetVisible = () => {
		resetFocus();
		setIsVisible((prev) => !prev);
	};

	const hasVideoProfile = profiles && selectedLanguage;
	const hasVideoLanguages = languages?.length > 1;

	React.useEffect(() => {
		!isFocus && setIsVisible(false);
	}, [isFocus]);

	const renderProfiles = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_profiles')}</ProfileName>
				<Profile
					onClick={handleSetProfile({ id: 'auto' })}
					profile="auto"
					selected={selectedProfile}
				>
					<span>auto</span>
				</Profile>
				{hasVideoProfile &&
					profiles[selectedLanguage].map(
						({ id, height, bandwidth }) =>
							height > 0 && (
								<Profile
									onClick={handleSetProfile({ id, bandwidth })}
									profile={id}
									selected={selectedProfile}
									key={id}
								>
									<span>{`${height}p`}</span>
								</Profile>
							)
					)}
			</ProfilesBox>
		);
	};

	const renderLanguages = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_language')}</ProfileName>
				{languages.map((language, index) => (
					<Profile
						onClick={() => setLanguage(language)}
						profile={language}
						selected={selectedLanguage}
						key={index}
					>
						<span>{language.toUpperCase()}</span>
					</Profile>
				))}
			</ProfilesBox>
		);
	};

	return (
		(!isSafari || hasVideoLanguages) && (
			<Wrapper>
				<Button
					isCatchupControllers={isCatchupControllers}
					onClick={handleSetVisible}
					modifiers={['icon', 'playerIconHover']}
					isFocus={isFocus}
				>
					<Player.IconSettings />
				</Button>
				<Content isVisible={isVisible}>
					<Bg />
					<Profiles>
						{!isSafari && renderProfiles()}
						{hasVideoLanguages && renderLanguages()}
					</Profiles>
				</Content>
			</Wrapper>
		)
	);
};

SettingsController.propTypes = {
	isCatchupControllers: bool
};

export default SettingsController;
