import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import helpers
import { PLAYER_ERROR_TYPES } from 'helpers/player';
import { checkPlayerType } from 'helpers';

// Import components
import { PlayerElements as Player } from 'components/elements';
import ParentalControlButton from './ParentalControlButton';
import Packets from './Packets/Packets';
import GeoblockingError from './GeoblockingError';

const {
	BLOCKED_BY_PARENTAL_CONTROL,
	RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS,
	COUNTRY_ACCESS_DENIED
} = PLAYER_ERROR_TYPES;

const PlayerError = ({ error: { message, errorCode = '' }, playerType }) => {
	const { pathname } = useLocation();
	const type = playerType || checkPlayerType(pathname);

	const isGeoblockingError = errorCode === COUNTRY_ACCESS_DENIED;

	return (
		<>
			{isGeoblockingError ? (
				<GeoblockingError code={errorCode} />
			) : (
				<Player.Error>
					<Player.ErrorContent>
						<Player.IconLock />
						<Player.ErrorHeading>{message}</Player.ErrorHeading>
						{errorCode === BLOCKED_BY_PARENTAL_CONTROL && (
							<ParentalControlButton playerType={type} />
						)}
						{errorCode === RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS && <Packets />}
					</Player.ErrorContent>
				</Player.Error>
			)}
		</>
	);
};

PlayerError.propTypes = {
	error: PropTypes.shape({ message: PropTypes.string }).isRequired,
	playerType: PropTypes.string
};

export default PlayerError;
