import React from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';

// Import utilities
import { CountdownRPC } from 'components/utilities';

// Import variables
import { NEXT_EPISODE_SHOW_TIME } from 'helpers/variables';

// Import components
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

const Timer = ({ endFunction }) => {
	const { t } = useTranslation();

	const { isPaused, isAdVisible } = useContextProvider();

	return (
		<CountdownRPC
			time={NEXT_EPISODE_SHOW_TIME}
			isPaused={isPaused || isAdVisible}
			endFunction={endFunction}
		>
			{({ counter }) => (
				<span>
					{counter.toFixed(0)} {t('common_seconds')}
				</span>
			)}
		</CountdownRPC>
	);
};

Timer.propTypes = {
	endFunction: func.isRequired
};

export default Timer;
