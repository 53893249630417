import styled, { css } from 'styled-components';

// Import styles
import { borderGradientTransparent, flex } from 'components/styles';

export const FogBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: ${({ theme }) => theme.common_modal.bg};
	z-index: 99999;
	${flex()}
`;

export const Content = styled.div`
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 20.8rem;
	${({ width }) =>
		width &&
		css`
			width: ${width}rem;
		`}
	padding: ${({ padding }) => (padding ? padding : '2.5rem 1.8rem')};
	background: ${({ theme }) => theme.common_modal.content.bg};
	text-align: center;
	border-radius: ${({ theme }) => theme.common_modal.content.roundSize}rem;

	span {
		margin-bottom: 3.8rem;
	}

	${({ theme }) =>
		borderGradientTransparent({
			width: 1,
			gradient: theme.common_modal.content.border
		})};
`;

export const Title = styled.h3`
	color: ${({ theme }) => theme.common_modal.title};
	font-size: 2.5rem;
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 3.4rem;
	margin-bottom: 3.8rem;
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.common_modal.description};
	font-size: 2rem;
	font-weight: ${({ theme, isSmallDescription }) =>
		isSmallDescription ? theme.font.regular : theme.font.semiMedium};
	padding: ${({ isSmallDescription }) => (isSmallDescription ? '0 2rem' : '0')};
	margin-bottom: ${({ isSmallDescription }) =>
		isSmallDescription ? 0 : 3.6}rem;
	line-height: ${({ isSmallDescription }) =>
		isSmallDescription ? 3.4 : 2.7}rem;
	white-space: pre-line;
`;
