import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';

// Import variables
import { COOKIE_EXPIRES } from 'helpers/variables';

// Import icons
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

// Import styles
import { flex, fadeInUp } from 'components/styles';

// Import components
import { Button } from 'components/elements';

const CookieMessage = ({ toggle }) => {
	const { t } = useTranslation();

	const handleAcceptCookie = () => {
		// Set cookie notice
		Cookie.set('cookie-notice', 1, { expires: COOKIE_EXPIRES });
		// Close cookie message
		toggle();
	};
	return (
		<Wrapper>
			<Close>
				<ButtonClose onClick={handleAcceptCookie}>
					<IconCloseStyled />
				</ButtonClose>
			</Close>
			<Description>{t('common_cookie_description')}</Description>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: fixed;
	bottom: 1.8rem;
	right: 1.8rem;
	max-width: 53rem;
	opacity: 0;
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.white};
	border-radius: 4px;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
	animation: ${fadeInUp} 0.5s 1.7s ease-in-out forwards;
	z-index: 1200;
`;

const Close = styled.div`
	${flex({ jc: 'flex-end' })}
	padding: 0.8rem 1.2rem 0 1.2rem;
`;
const ButtonClose = styled(Button)`
	padding: 0;
`;
const IconCloseStyled = styled(IconClose)`
	height: 1.6rem;
	width: 1.6rem;
`;

const Description = styled.p`
	padding: 0rem 2rem 2rem;
	font-size: 1.4rem;
	line-height: 1.286;
`;

CookieMessage.propTypes = {
	toggle: PropTypes.func.isRequired
};

export default CookieMessage;
