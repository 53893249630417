import { useHistory } from 'react-router-dom';

// Import paths
import {
	getNavigationPaths,
	APP_PATHS_KEY
} from 'components/routes/helpers/paths';

// Import utilities
import { useAuth, useNoAuthPathSave } from 'components/utilities';

const { LOGIN } = APP_PATHS_KEY;

const useAuthModal = () => {
	const { isAuth } = useAuth();
	const history = useHistory();

	const { savePath } = useNoAuthPathSave();

	const loginPath = getNavigationPaths(LOGIN);

	const handleRedirect = (toggle) => () => {
		toggle();

		savePath();

		history.push(loginPath);
	};

	const handleOnAuth = (cb) => (e) => {
		if (!isAuth) {
			e.preventDefault();
			cb();
		}
	};

	const handleOnOpen = (cb) => () => isAuth && cb?.();

	return {
		isAuth,
		redirect: handleRedirect,
		auth: handleOnAuth,
		open: handleOnOpen
	};
};

export default useAuthModal;
