import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// Import heleprs
import { DIRECTION_WAY_TYPES } from 'helpers/variables';

// Import styles
import { rtl } from 'components/styles';

// Import helpers
import { getRTLBooleanValue } from 'helpers/rtl';

const { RIGHT } = DIRECTION_WAY_TYPES;

const DrawerRPC = ({
	children,
	onClose,
	isOpen,
	title,
	position = RIGHT,
	closeable = true,
	width = 330,
	theme
}) => {
	const { direction } = useSelector((state) => state.direction);

	const isRtl = getRTLBooleanValue({ direction });

	return (
		<DrawerStyled
			isRtl={isRtl}
			title={title}
			placement={position}
			closable={closeable}
			onClose={onClose}
			visible={isOpen}
			width={width}
		>
			{children}
		</DrawerStyled>
	);
};

const DrawerStyled = styled(Drawer)`
	&& {
		.ant-drawer-wrapper-body {
			background: ${({ theme: { sidebar } }) => sidebar.bg};
			transform: ${({ isRtl }) => (isRtl ? 'scale(-1, 1)' : 'scale(1, 1)')};
		}

		.ant-drawer-header {
			background: transparent;
			border-bottom-color: ${({ theme }) => theme.line};
		}

		.ant-drawer-title {
			color: ${({ theme }) => theme.grayMedium};
		}

		.ant-drawer-close {
			color: ${({ theme: { sidebar } }) => sidebar.closeIcon};
			${rtl`
				right: 0;
				left: auto;
			`}
		}

		.ant-drawer-body {
			padding: 0 0 2rem 0;
		}
	}
`;

DrawerRPC.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	title: PropTypes.string.isRequired,
	position: PropTypes.string,
	closeable: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired
};

export default withTheme(DrawerRPC);
