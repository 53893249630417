import React from 'react';
import { string, bool } from 'prop-types';

// Import components
import { DynamicIcon } from 'components/elements';

// Import styles
import {
	ContentStyled,
	SecondTitleStyled,
	TitleStyled,
	Container
} from './styles';

const GeoblockingErrorModal = ({
	title,
	secondTitle,
	isProgramDetails = false
}) => {
	return (
		<Container>
			<ContentStyled isProgramDetails={isProgramDetails}>
				<DynamicIcon icon="ic_sad_smile" width={135} height={135} />

				{title && (
					<TitleStyled isProgramDetails={isProgramDetails}>{title}</TitleStyled>
				)}
				{secondTitle && (
					<SecondTitleStyled isProgramDetails={isProgramDetails}>
						{secondTitle}
					</SecondTitleStyled>
				)}
			</ContentStyled>
		</Container>
	);
};

GeoblockingErrorModal.propTypes = {
	title: string,
	secondTitle: string,
	isProgramDetails: bool
};

export default GeoblockingErrorModal;
