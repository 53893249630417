import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { createLinkParams } from 'helpers/index';
import { getProductImage } from 'helpers/search';
import { PRODUCT_TYPES } from 'helpers/variables';

// Import components
import { ProgramInfo, VodSerialInfo } from '../info';
import NoImage from 'components/views/vod/images/placeholder.svg';
import {
	SearchItemStyled,
	LinkStyled,
	Preview,
	ChannelImage,
	Image,
	Content,
	ParagraphStyled
} from './styles';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';

const SearchItem = ({ toggle, product, channels }) => {
	// REPLACE RATING WITH PC_RATING AFTER API UPDATE
	const {
		uuid,
		images,
		screenshots,
		title,
		type,
		subtype,
		context,
		pc_rating,
		translations
	} = product;

	const since = context?.since;
	const till = context?.till;

	const {
		i18n: { language }
	} = useTranslation();

	const { imgUrl, isImgUrlValid, isChannelLogo } = getProductImage({
		screenshots,
		images,
		type,
		product,
		channels,
		channelInverse: false
	});

	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pc_rating,
		since
	);

	const isLive =
		new Date(since).getTime() < new Date().getTime() &&
		new Date(till).getTime() > new Date().getTime();

	const hash = isLive ? 'live' : 'program';

	const productTitle = translations?.[language]?.title || title;

	return (
		<SearchItemStyled>
			<LinkStyled
				onClick={toggle}
				to={createLinkParams({
					id: uuid,
					type: subtype,
					title: productTitle,
					hash
				})}
			>
				<Preview>
					{isImgUrlValid && isChannelLogo && (
						<ChannelImage src={imgUrl} alt="Logo" />
					)}
					{isImgUrlValid && !isChannelLogo && (
						<Image
							src={isContentBlocked ? NoImage : imgUrl}
							alt={productTitle}
						/>
					)}
				</Preview>
				<Content>
					{isContentBlocked ? (
						<ParagraphStyled>{placeholderRatingText}</ParagraphStyled>
					) : (
						productTitle && <ParagraphStyled>{productTitle}</ParagraphStyled>
					)}

					{type === PRODUCT_TYPES[0] && (
						<ProgramInfo product={product} channels={channels} />
					)}

					{type === PRODUCT_TYPES[1] && <VodSerialInfo product={product} />}

					{type === PRODUCT_TYPES[2] && <VodSerialInfo product={product} />}
				</Content>
			</LinkStyled>
		</SearchItemStyled>
	);
};

SearchItem.propTypes = {
	product: PropTypes.shape({
		uuid: PropTypes.string.isRequired,
		images: PropTypes.object,
		screenshots: PropTypes.object,
		title: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired
	}).isRequired,
	channels: PropTypes.array.isRequired,
	toggle: PropTypes.func.isRequired
};

export default SearchItem;
