// initial focus helpers
export const getSeasonToFocus = ({ seriesFocusData, seasons }) => {
	if (seriesFocusData && seasons?.length) {
		const { parent_uuid: seasonId } = seriesFocusData;

		// if the season doesn't exist findIndex return -1
		let seasonIndex = seasons.findIndex(({ uuid }) => uuid === seasonId);
		seasonIndex++;
		const seasonToFocus = seasonIndex === 0 ? 1 : seasonIndex;

		return seasonToFocus;
	}

	return 1;
};

export const getEpisodeToFocus = ({ seriesFocusData, seasonData }) => {
	if (seriesFocusData && seasonData) {
		const { uuid: episodeId } = seriesFocusData;
		const { episodes } = seasonData;

		if (episodes?.length) {
			// if the episode doesn't exist findIndex return -1
			const episodeIndex = episodes.findIndex(({ uuid }) => uuid === episodeId);
			const episodeToFocus = episodeIndex === -1 ? 0 : episodeIndex;

			return episodeToFocus;
		}

		return 0;
	}

	return 0;
};

export const INITIAL_STATE = {
	product: {
		price: 0,
		period: 0,
		productName: '',
		uuid: 0,
		isPurchaseRedirect: true
	},
	isModalOpened: false,
	selected: 0,
	selectedEpisodeNumber: 1,
	vodOnlyInPackets: null,
	isPacketsModalOpen: false
};
