import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import heleprs
import { capitalizeWords } from 'helpers/common';
import { getCanonicalLink } from 'helpers';
import { getKeywords, getProductData, getProgramData } from './helpers';

const useHelmetTags = ({ metaTitle, metaDescription, metaKeywords }) => {
	const {
		t,
		i18n: { language }
	} = useTranslation();
	const product = useSelector(({ movieDetails }) => movieDetails.data);
	const program = useSelector(({ program_details }) => program_details.data);
	const { pathname } = useLocation();

	const defaultMetaTitle = t('common_document_meta_title');

	// Get product title
	const metaTitlePage = metaTitle && t(metaTitle);
	let titlePage = metaTitlePage || program?.title || defaultMetaTitle;

	// Check if program is not empty
	const isProgram = Object.keys(program).length;

	// Aplications info
	let applicationName = t(metaTitle) || defaultMetaTitle;

	let applicationDescription =
		t(metaDescription) || t('common_document_meta_description');

	let aplicationKeywords =
		t(metaKeywords) || t('common_document_meta_keywords');

	// Check if product exists and has necessary data
	const isProduct = product?.genres && product?.metadata;

	if (isProduct) {
		const { genres, metadata } = product;

		const { summary, title, keywords } = getProductData({ genres, metadata });

		titlePage = title;
		applicationName = title;
		applicationDescription = summary;
		aplicationKeywords = getKeywords(keywords);
	} else if (isProgram) {
		const { title, descriptionLong, keywords } = getProgramData(program);

		applicationName = title;
		applicationDescription = descriptionLong;
		aplicationKeywords = getKeywords(keywords);
	}

	const titlePageCapitalize = capitalizeWords(titlePage);

	// Create canonical link
	const canonicalLink = getCanonicalLink(pathname, language);

	return {
		titlePage: titlePageCapitalize,
		applicationName,
		applicationDescription,
		aplicationKeywords,
		canonicalLink,
		language
	};
};

useHelmetTags.propTypes = {
	metaTitle: string,
	metaDescription: string,
	metaKeywords: string
};

export default useHelmetTags;
