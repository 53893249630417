import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { ButtonStyled, LinkStyled } from './styles';

const BUTTON = 'button';
const LINK = 'link';

const ButtonTransparent = ({ children, type, ...props }) => {
	const renderButton = () => {
		switch (type) {
			case BUTTON:
				return <ButtonStyled {...props}>{children}</ButtonStyled>;

			case LINK:
				return <LinkStyled {...props}>{children}</LinkStyled>;

			default:
				return (
					<ButtonStyled {...props} type={type}>
						{children}
					</ButtonStyled>
				);
		}
	};

	return renderButton();
};

ButtonTransparent.defaultProps = {
	type: 'button',
	width: 238,
	height: 59
};

ButtonTransparent.propTypes = {
	onClick: PropTypes.func,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.string,
	href: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	className: PropTypes.string
};

export default ButtonTransparent;
