import styled from 'styled-components';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

export const ButtonCloseFullScreen = styled(Button)`
	position: absolute;
	width: 15rem;
	top: 5rem;
	right: 4rem;
	z-index: 10;
`;

export const IconPlayStyled = styled(Player.IconPlay)`
	width: 15rem;
	height: 15rem;
`;
