import React from 'react';
import { Link } from 'react-router-dom';
import { string, object, number } from 'prop-types';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import helpers
import { getUrlPC } from 'helpers';
import { getImage } from 'components/views/vod/helpers/getImage';

// Import components
import BannerImage from './BannerImage';

const { COVER } = PRODUCT_IMAGES_TYPES;

const BannerItem = ({ images, title, bannerHeight, urlPc, saveSectionId }) => {
	const coverUrl = getImage({ images, type: COVER });
	const bannerImage = `${coverUrl}=w1920-h400-c`;

	if (!urlPc) {
		return (
			<BannerImage
				bannerImage={bannerImage}
				title={title}
				bannerHeight={bannerHeight}
			/>
		);
	}

	const { isExternal, path } = getUrlPC(urlPc);

	return isExternal ? (
		<a href={path} target="_blank" rel="noopener noreferrer">
			<BannerImage
				bannerImage={bannerImage}
				title={title}
				bannerHeight={bannerHeight}
			/>
		</a>
	) : (
		<Link to={path} onClick={saveSectionId}>
			<BannerImage
				bannerImage={bannerImage}
				title={title}
				bannerHeight={bannerHeight}
			/>
		</Link>
	);
};

BannerItem.propTypes = {
	images: object,
	title: string.isRequired,
	bannerHeight: number,
	urlPc: string
};

export default BannerItem;
