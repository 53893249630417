import { getUserAgent } from 'helpers/index';
import Cookies from 'js-cookie';

// Import variables
import { LOCALSTORAGE_USER } from './variables';

export const getHeaders = (appCheckToken) => {
	const uid = Cookies.get('uid');
	let user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)) || null;
	const { browser, os } = getUserAgent();
	const apiDevice = `${browser.name}; ${browser.major}; ${os.name}; ${os.version}; ${os.name}; ${os.version};`;

	let headers = {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'API-DeviceUID': uid,
		'API-Device': apiDevice
	};

	if (user) {
		headers['Authorization'] = `Bearer ${user.token}`;
	}

	if (appCheckToken) {
		headers['X-Firebase-AppCheck'] = appCheckToken;
	}

	return headers;
};
