import { useState, useEffect } from 'react';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';

const ShakaPlayerFocus = ({ isReady, children, intervalTime = 800 }) => {
	const [isFocus, setFocus] = useState(true);
	const [longFocus, setLongFocus] = useState(true);
	const [focusTimeout, setFocusTimeout] = useState(null);
	const [focusInterval, setFocusInterval] = useState(null);

	const shakaControlsContainer = `.${PLAYER_SELECTORS.SHAKA_CONTROLS_CONTAINER_CLASS}`;
	const shakaControls = document.querySelector(shakaControlsContainer);

	const longFocusTime = 10000;

	const handleIsFocus = () => {
		shakaControls && shakaControls.hasAttribute('shown')
			? setFocus(true)
			: setFocus(false);
	};

	const handleClearLongFocus = () => setLongFocus(false);

	const handleResetFocus = () => {
		clearTimeout(focusTimeout);
		clearInterval(focusInterval);
		setFocus(true);
		setLongFocus(true);
		setTimeout(handleClearLongFocus, longFocusTime);
	};

	const handleSidebarMouseEnter = () => {
		clearTimeout(focusTimeout);
		clearInterval(focusInterval);
		setFocus(true);
		setLongFocus(true);
	};

	const handleSidebarMouseLeave = () => setLongFocus(false);

	useEffect(() => {
		if (isReady) {
			const timeout = setTimeout(handleClearLongFocus, longFocusTime);
			setFocusTimeout(timeout);
		}
		return () => clearTimeout(focusTimeout);
		// eslint-disable-next-line
	}, [isReady]);

	useEffect(() => {
		if (isReady && !longFocus) {
			const interval = setInterval(handleIsFocus, intervalTime);
			setFocusInterval(interval);
		}
		return () => clearInterval(focusInterval);
		// eslint-disable-next-line
	}, [isReady, longFocus]);

	return children({
		isFocus,
		resetFocus: handleResetFocus,
		onSidebarMouseEnter: handleSidebarMouseEnter,
		onSidebarMouseLeave: handleSidebarMouseLeave
	});
};

export default ShakaPlayerFocus;
