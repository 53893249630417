import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import helpers
import { OS_NOT_SUPPORTED } from 'helpers/variables';

// Import components
import { PlayerElements as Player } from 'components/elements';
import OsSupportButton from './OsSupportButton';

const SupportError = ({
	error: { message, errorCode = '' },
	setSupportAccept = () => {},
	playerType
}) => {
	const { isError: isPlayerError } = useSelector(
		(state) => state[playerType].error
	);

	const isDoubleErrors = isPlayerError && errorCode === OS_NOT_SUPPORTED;

	return (
		<Player.Error isDoubleErrors={isDoubleErrors}>
			<Player.ErrorContent>
				<Player.IconLock />
				<Player.ErrorHeading>{message}</Player.ErrorHeading>
				<OsSupportButton setSupportAccept={setSupportAccept} />
			</Player.ErrorContent>
		</Player.Error>
	);
};

SupportError.propTypes = {
	error: PropTypes.shape({ message: PropTypes.string }).isRequired,
	setSupportAccept: PropTypes.func.isRequired,
	playerType: PropTypes.string.isRequired
};

export default SupportError;
