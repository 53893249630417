import React from 'react';
import { bool } from 'prop-types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Import styles
import { Wrapper, WatermarkLogo } from './styles';

// Import helpers
import { GLOBAL_WATERMARK_URL } from 'helpers/variables';
import { getLogoSettings, getProductLogoUrl } from './helpers';
import { QUERY_SETTINGS_GLOBAL_WATERMARK_ENABLED } from 'helpers/api';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

const Watermark = ({
	useWatermark,
	isChannel = false,
	isMovedUp = false,
	isFullScreen = false,
	isError = false
}) => {
	const { channelID: selectedChannelUuid } = useSelector(
		({ selected_program }) => selected_program
	);
	const { channel_uuid: channelUuid } = useSelector(
		({ program_details }) => program_details.data
	);
	const { lives } = useSelector(({ channels }) => channels.data);

	const { data: isGlobalWatermarkEnabled, isLoading } = useQuery({
		queryKey: QUERY_SETTINGS_GLOBAL_WATERMARK_ENABLED,
		queryFn: getLogoSettings
	});

	const isProgramDetails = !!channelUuid;

	const showGlobalWatermark = isGlobalWatermarkEnabled && useWatermark;

	const watermarkUrl = showGlobalWatermark
		? GLOBAL_WATERMARK_URL
		: getProductLogoUrl({
				isChannel,
				isGlobalWatermarkEnabled,
				selectedChannelUuid,
				channelUuid,
				lives
		  });

	if (!watermarkUrl || isError) return null;

	return (
		<>
			{!isLoading && (
				<Wrapper
					isMovedUp={isMovedUp}
					isProgramDetails={isProgramDetails}
					isFullScreen={isFullScreen}
				>
					<ImageOnLoadRPC>
						{({ setError }) => (
							<WatermarkLogo
								onError={setError}
								src={watermarkUrl}
								alt="logo vod"
							/>
						)}
					</ImageOnLoadRPC>
				</Wrapper>
			)}
		</>
	);
};

Watermark.propTypes = {
	useWatermark: bool.isRequired,
	isChannel: bool,
	isMovedUp: bool,
	isFullScreen: bool,
	isError: bool
};

export default Watermark;
