import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import app paths
import { appPaths } from 'components/routes/helpers';

const { en, ar } = appPaths;
const { channels, epg, sectionMovies, sectionSeries, home } = en;
const {
	channels: channelsAr,
	sectionMovies: sectionMoviesAr,
	sectionSeries: sectionSeriesAr
} = ar;

export const useHeader = () => {
	const location = useLocation();

	const { isRtl } = useSelector(({ direction }) => direction);

	const enRoutes = [channels, epg, sectionMovies, sectionSeries, home];

	const arRoutes = [channelsAr, sectionMoviesAr, sectionSeriesAr];

	const routesWithUniqeHeadings = [...enRoutes, ...arRoutes];

	const areTitlesHidden = routesWithUniqeHeadings.some((path) =>
		location.pathname.includes(path)
	);

	return { areTitlesVisible: !areTitlesHidden, isRtl };
};
