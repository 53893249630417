import React from 'react';
import { shape, number, array, string, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// HELPERS
import {
	getDurationHoursAndMinutes,
	getAgeRatingImage
} from 'components/views/vod/views/ProductDetails/Layout/Info/helpers';

import star_full from 'assets/icons/star_full.svg';
import star_empty from 'assets/icons/star_empty.svg';

// Import styles
import { rtl } from 'components/styles';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.75rem;
	z-index: 2;
	${rtl`
		margin-right: 2rem;
	`}
`;

const Divider = styled.div`
	height: 2.4rem;
	width: 3px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.white};
	${rtl`
		margin-right: 1.8rem;
	`}
	z-index: 2;
`;

const RatingImage = styled.div`
	height: 2.3rem;
	width: 2.3rem;
	background-image: url("${({ image }) => image}");
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center center;
	background-size: contain;
	${rtl`
		margin-right: 2rem;
	`}
`;

const Text = styled.p`
	${rtl`
		margin-right: 1rem;
	`}
	color: ${({ theme }) => theme.white};
  font-size: 2.1rem;
  line-height: 3.6rem;
`;

const StarContainer = styled.div`
	height: 1.5rem;
	line-height: 1;
`;

const Star = styled.div`
	display: inline-block;
	width: 1.6rem;
	height: 1.5rem;
	${rtl`
		margin-right: 0.3rem;
	`}
	background: ${`url(${({ isFull }) =>
		isFull ? star_full : star_empty}) 50% / 100% no-repeat transparent`};
`;

export function Basic({ data, isCarousel = false }) {
	const { t } = useTranslation();
	const {
		rating,
		year,
		duration,
		genres,
		subtype,
		country_of_origin: movieCountryDetails,
		country: movieCountryCarousel
	} = data;

	const { durationHours, durationMinutes } = getDurationHoursAndMinutes(
		duration
	);

	const genreName = !!genres && genres.length > 0 && genres[0].name;

	const image = getAgeRatingImage(rating);

	const isSeries = subtype === 'series';

	// hide star rating GO LIVE
	const ratingStars = null;
	// const ratingStars = getRatingStars(starRating);

	const hasRatingStars = ratingStars && ratingStars.length > 0;

	const movieCountry = isCarousel ? movieCountryCarousel : movieCountryDetails;

	return (
		<Container>
			{image && <RatingImage image={image} />}
			{!!year && <Text>{year}</Text>}

			{!!genreName && (
				<>
					{!!year && <Divider />}
					<Text>{genreName}</Text>
				</>
			)}

			{movieCountry && (
				<>
					<Divider />
					<Text>{movieCountry}</Text>
				</>
			)}

			{!isSeries && (!!durationHours || !!durationMinutes) && (
				<>
					{(!!year || !!genreName) && <Divider />}
					<Text>
						{`${durationHours || '0'} ${t(
							'vod_movieDetails_hours'
						)} ${durationMinutes || '0'}
					${t('vod_movieDetails_minutes')}`}
					</Text>
				</>
			)}

			{hasRatingStars && (
				<>
					<Divider />
					<StarContainer>
						{ratingStars.map(({ isStarFull }, i) => (
							<Star key={i} isFull={isStarFull} />
						))}
					</StarContainer>
				</>
			)}
		</Container>
	);
}

Basic.propTypes = {
	data: shape({
		rating: number,
		year: number,
		context: shape({ duration: number }),
		genres: array,
		starRating: number,
		subtype: string,
		country: string
	}),
	isCarousel: bool
};
