import React from 'react';
import { number, oneOfType, string, bool } from 'prop-types';
import { useLogic } from './useLogic';
import { RegularSlider } from 'components/views/vod/components/sliders';

export function VirtualSections({
	label,
	name,
	id,
	backgroundImage,
	shuffle = false
}) {
	const { dataArray } = useLogic(label);

	if (!dataArray.length > 0) return null;

	return (
		<RegularSlider
			sectionLabel={label}
			id={id}
			name={name}
			content={dataArray}
			backgroundImage={backgroundImage}
			showAllMoviesButton={true}
			shuffle={shuffle}
		/>
	);
}

VirtualSections.propTypes = {
	label: string.isRequired,
	id: oneOfType([string, number]).isRequired,
	name: string.isRequired,
	backgroundImage: oneOfType([string, bool]),
	shuffle: bool
};
