// Import helpers
import { checkAppType } from 'helpers';

// Import variables
import {
	BASE_APPS_URLS,
	ANDROID_APPS_IDS,
	APPLE_APPS_IDS,
	LG_APPS_IDS
} from 'helpers/variables';

// get url for apple and android app
export const getAppStoreUrls = () => {
	const { ANDROID_BASE_URL, APPLE_BASE_URL, LG_BASE_URL } = BASE_APPS_URLS;
	const { ANDROID_TV_SMART_ID, ANDROID_TV_ONLINE_ID } = ANDROID_APPS_IDS;
	const { LG_TV_ONLINE_ID, LG_TV_SMART_ID } = LG_APPS_IDS;
	const { APPLE_TV_ONLINE_ID, APPLE_TV_SMART_ID } = APPLE_APPS_IDS;

	const { isTvSmart } = checkAppType();

	const androidAppId = isTvSmart ? ANDROID_TV_SMART_ID : ANDROID_TV_ONLINE_ID;
	const appleAppid = isTvSmart ? APPLE_TV_SMART_ID : APPLE_TV_ONLINE_ID;
	const lgAppId = isTvSmart ? LG_TV_SMART_ID : LG_TV_ONLINE_ID;

	const androidUrl = `${ANDROID_BASE_URL}${androidAppId}`;
	const appleUrl = `${APPLE_BASE_URL}${appleAppid}`;
	const lgUrl = `${LG_BASE_URL}${lgAppId}`;

	return { androidUrl, appleUrl, lgUrl };
};
