import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { enableParentalControl, fetchFavourites } from 'store/actions';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import validation
import {
	disabledValidation,
	enableValidation
} from 'components/elements/organisms/modal/modal_form/parental_control_modal/parental_control_fields/validation';

// Import icons
import { ReactComponent as AdultIcon } from 'assets/icons/ic_adult.svg';

// Import components
import { Button, ParentalControlModal } from 'components/elements';

// Import utilities
import { useParentalControlDisable } from 'components/utilities';

const ParentalControl = ({
	enableParentalControl,
	parentalControlEnabled,
	fetchFavourites
}) => {
	const { t } = useTranslation();
	const [isEnable, setIsEnable] = React.useState(false);
	const [isDisable, setIsDisable] = React.useState(false);

	const { isLoaded: isAppLoaded } = useSelector(({ auth }) => auth);

	const { getPlayerConfiguration } = useParentalControlDisable();

	const handleOpenDisabled = () => setIsDisable((prev) => !prev);
	const handleOpenEnabled = () => setIsEnable((prev) => !prev);

	const handleParentalControl = async (values) => {
		if (values.isEnabled) {
			values.configCallback = getPlayerConfiguration;
		}

		await enableParentalControl(values);
		// fetch favourites products
		await fetchFavourites();
	};

	return (
		<>
			{parentalControlEnabled ? (
				<ButtonStyled
					modifiers={['navIcon']}
					parentalControlEnabled
					onClick={handleOpenDisabled}
					disabled={!isAppLoaded}
				>
					<AdultIcon />
				</ButtonStyled>
			) : (
				<Button
					modifiers={['navIcon']}
					onClick={handleOpenEnabled}
					disabled={!isAppLoaded}
				>
					<AdultIcon />
				</Button>
			)}
			<ParentalControlModal
				toggle={handleOpenDisabled}
				title={t('parental_control_title')}
				isEnabled={true}
				initialValues={{ pin: '' }}
				validation={disabledValidation}
				on={isDisable}
				submitAction={handleParentalControl}
			/>
			<ParentalControlModal
				toggle={handleOpenEnabled}
				title={t('parental_control_title_enable')}
				isEnabled={false}
				initialValues={{ rating: 0 }}
				validation={enableValidation}
				on={isEnable}
				submitAction={handleParentalControl}
			/>
		</>
	);
};

const IconStyle = css`
	&& svg {
		path {
			fill: ${({ theme }) => theme.red};
		}
	}
`;

const ButtonStyled = styled(Button)`
	${({ parentalControlEnabled }) =>
		parentalControlEnabled &&
		css`
			${IconStyle}

			&:hover {
				${IconStyle}
			}
		`}
`;

ParentalControl.propTypes = {
	enableParentalControl: PropTypes.func.isRequired,
	parentalControlEnabled: PropTypes.bool,
	fetchFavourites: PropTypes.func.isRequired
};

const mapStateToProps = ({
	auth: {
		authenticate: { parentalControlEnabled }
	}
}) => ({ parentalControlEnabled });

export default connect(
	mapStateToProps,
	{ enableParentalControl, fetchFavourites }
)(ParentalControl);
