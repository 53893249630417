import React from 'react';
import { string, array, number, oneOfType, bool } from 'prop-types';

// Import components
import { Basic } from '../Basic';
import { BannersItem } from './BannersItem';
import { RegularHeader } from '../components/RegularHeader';
import { ErrorBoundary, SectionFocus } from 'components/elements';

// Import hooks
import { useSectionFocus } from 'components/utilities';

export function Genres({
	id,
	content,
	name,
	showAllMoviesButton,
	backgroundImage,
	shuffle
}) {
	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	const renderHeader = () => (
		<RegularHeader
			showAllMoviesButton={showAllMoviesButton}
			sectionId={id}
			title={name}
			saveSectionId={saveSectionId}
		/>
	);

	return (
		<SectionFocus ref={restorationRef}>
			<Basic
				key={id}
				itemResolutionWidth={435}
				itemResolutionHeight={310}
				itemsList={content}
				itemsToShow={4}
				headerHeight={90}
				spaceBetweenItems={20}
				backgroundImage={backgroundImage}
				renderItemComponent={({ height, width, data }) => (
					<ErrorBoundary>
						<BannersItem
							height={height}
							width={width}
							data={data}
							saveSectionId={saveSectionId}
						/>
					</ErrorBoundary>
				)}
				renderHeader={renderHeader}
				shuffle={shuffle}
			/>
		</SectionFocus>
	);
}

Genres.defaultProps = {
	showAllMoviesButton: false,
	backgroundImage: false,
	shuffle: false
};

Genres.propTypes = {
	id: oneOfType([string, number]).isRequired,
	content: array.isRequired,
	name: string.isRequired,
	showAllMoviesButton: bool,
	backgroundImage: oneOfType([string, bool]),
	shuffle: bool
};
