import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Import components
import { Button } from 'components/elements';
// Import variables
import { SESSIONSTORAGE_OS_SUPPORT } from 'helpers/variables';

const OsSupportButton = ({ setSupportAccept }) => {
	const { t } = useTranslation();

	const onSupportAccept = () => {
		setSupportAccept(true);

		const systemSupportStorage = JSON.parse(
			sessionStorage.getItem(SESSIONSTORAGE_OS_SUPPORT)
		);

		sessionStorage.setItem(
			SESSIONSTORAGE_OS_SUPPORT,
			JSON.stringify({
				...systemSupportStorage,
				isMessageAccepted: true
			})
		);
	};

	return (
		<Button onClick={onSupportAccept} modifiers={['primary', 'parental']}>
			{t('common_os_support_btn')}
		</Button>
	);
};

OsSupportButton.propTypes = {
	setSupportAccept: PropTypes.func
};

export default OsSupportButton;
