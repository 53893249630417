import React from 'react';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';
import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

const PlayController = () => {
	const { isPaused, play, pause } = useContextProvider();

	return isPaused ? (
		<Button modifiers={['icon', 'playerIconHover']} onClick={play}>
			<Player.IconPlay />
		</Button>
	) : (
		<Button modifiers={['icon', 'playerIconHover']} onClick={pause}>
			<Player.IconPause />
		</Button>
	);
};

export default PlayController;
