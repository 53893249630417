// ----------------- MOVIE DETAILS -----------------
export const MOVIE_DETAILS_LOADING = 'MOVIE_DETAILS_LOADING';
export const MOVIE_DETAILS_SUCCESS = 'MOVIE_DETAILS_SUCCESS';

// ----------------- MOVIE EPISODES PREV NEXT -----------------
export const MOVIE_EPISODES_PREV_NEXT_SUCCESS =
	'MOVIE_EPISODES_PREV_NEXT_SUCCESS';

// -----------------  ERROR -----------------
export const MOVIE_DETAILS_ERROR = 'MOVIE_DETAILS_ERROR';

// -----------------  CLEAR -----------------
export const MOVIE_DETAILS_CLEAR = 'MOVIE_DETAILS_CLEAR';

// -----------------  NEXT EPISODE TO WATCH -----------------
export const NEXT_EPISODE_TO_WATCH_SUCCESS = 'NEXT_EPISODE_TO_WATCH_SUCCESS';
export const NEXT_EPISODE_TO_WATCH_ERROR = 'NEXT_EPISODE_TO_WATCH_ERROR';
