import activePaymentMethods from './active_payment_methods_reducer';
import paymentGatewayTypes from './payment_gateway_types_reducer';
import activeSubscriptions from './active_subscriptions_reducer';
import userSubscriptions from './user_subscriptions_reducer';
import assignedSubscriptions from './assigned_subscriptions_reducer';

export default {
	activePaymentMethods,
	activeSubscriptions,
	paymentGatewayTypes,
	userSubscriptions,
	assignedSubscriptions
};
