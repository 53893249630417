import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { useSlider } from './useSlider';
import { Dot, DotContainer, Point, Root, StrapsContainer } from './helpers';
import { Strap } from 'components/elements';

const ShakaSlider = ({
	duration,
	updateTime,
	currentTimeProgress,
	isCatchup = false,
	isPreviewAvailable = false
}) => {
	const {
		isPointVisible,
		mouseDown,
		click,
		containerRef,
		pointRef,
		translateX,
		shownPercent,
		onContainerMouseMove,
		setPointVisible
	} = useSlider(duration, updateTime, currentTimeProgress);

	return (
		<Root
			ref={containerRef}
			onMouseMove={onContainerMouseMove}
			onMouseEnter={() => setPointVisible(true)}
			onMouseLeave={() => setPointVisible(false)}
		>
			<StrapsContainer onMouseDown={click}>
				<Strap percent={shownPercent} />
			</StrapsContainer>
			{isPointVisible && (
				<Point
					ref={pointRef}
					translateX={translateX}
					leftPercent={shownPercent}
				>
					<DotContainer>
						<Dot onMouseDown={mouseDown} />
					</DotContainer>
				</Point>
			)}
		</Root>
	);
};

ShakaSlider.propTypes = {
	duration: PropTypes.number.isRequired,
	updateTime: PropTypes.func.isRequired,
	currentTimeProgress: PropTypes.func.isRequired,
	isCatchup: PropTypes.bool
};

export default ShakaSlider;
