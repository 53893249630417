import styled from 'styled-components';

export const ContinueWatchingData = styled.div`
	position: absolute;
	bottom: -3%;
	width: 98%;
	height: 0.4rem;
	border-radius: ${({ theme }) =>
		theme.vodDetails.continueWatching.roundSize}rem;
	background: ${({ theme }) => theme.vodDetails.continueWatching.bg};
`;

export const Strap = styled.div`
	height: 100%;
	width: ${({ percent }) => percent}%;
	border-radius: inherit;
	background: ${({ theme }) => theme.vodDetails.continueWatching.strapBg};
`;
