import React from 'react';
import { array, string } from 'prop-types';
import styled from 'styled-components';

// Import utilities
import { Slider } from 'components/utilities';
import useBannerLogic from './useBannerLogic';

// Import components
import { ErrorBoundary, SectionFocus } from 'components/elements';
import BannerItem from './BannerItem';

const Banners = ({ items, id }) => {
	const {
		containerRef,
		bannerHeight,
		settings,
		saveSectionId,
		restorationRef
	} = useBannerLogic({ id });

	return (
		<SectionFocus ref={restorationRef}>
			<BannersSliderWrap ref={containerRef}>
				<Slider defaultBtn={false} settings={settings}>
					{items.map(({ uuid, images, title, context }) => (
						<ErrorBoundary>
							<BannerItem
								key={uuid}
								images={images}
								title={title}
								bannerHeight={bannerHeight}
								urlPc={context?.url_pc}
								saveSectionId={saveSectionId}
							/>
						</ErrorBoundary>
					))}
				</Slider>
			</BannersSliderWrap>
		</SectionFocus>
	);
};

const BannersSliderWrap = styled.div`
	margin-top: 7.5rem;

	.slick-slider {
		.button {
			height: 100%;
			bottom: 0;
		}
	}
`;

Banners.propTypes = {
	items: array.isRequired,
	id: string.isRequired
};

export default Banners;
