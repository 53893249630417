import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu, Select } from 'antd';

// Import styles
import { transitionMain, rtl } from 'components/styles';

export const Nav = styled(NavLink)`
	color: ${({ theme }) => theme.nav.dropdown.item};
`;

export const ItemStyled = styled(Menu.Item)`
	${rtl`text-align: left`}

	
	> ${Nav} {
		padding: 1rem 3rem;
		font-weight: ${({ theme }) => theme.font.regular};
		color: ${({ theme }) => theme.nav.dropdown.item};
		${transitionMain}

		&.active-drop-nav {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemSelected};
		}

		&:focus,
		&:hover {
			background: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};

			${({ isRtl }) =>
				isRtl &&
				css`
					background: ${({ theme: { dropdown } }) =>
						dropdown.menu.itemHoverBgRtl};
				`};

		}
	}

	&.ant-dropdown-menu-item-active {
		background: none;

		> ${Nav} {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}
	}
`;

export const StyledSelect = styled(Select)`
	.ant-select,
	.ant-select-arrow {
		color: ${({ theme }) => theme.primary} !important;
	}
	.ant-select-selection {
		color: ${({ theme }) => theme.primary} !important;
		border: none !important;
		font-size: 1.4rem !important;
		font-weight: ${({ theme }) => theme.font.regular};
	}
`;
