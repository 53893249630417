import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import {
	getUrlSystemName,
	convertErrors,
	addCustomTranslationToErrors
} from 'services/helpers';
import { checkForInvalidDataError } from 'store/helpers/error_status';

// ******************** REGISTER USER ********************
export const registerUser = (userData, agreementData, setErrors) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.REGISTER_USER_LOADING
		});

		// add system name to request (tvonline || ott)
		const system = getUrlSystemName();
		// check if obligatory agreements are accepted
		// (they always are since it's obligatory condition to dispatch action in RegisterForm)
		const updatedData = {
			...userData,
			system,
			agreements_accepted: agreementData
		};

		const url = '/register';
		await services.post(url, updatedData);

		dispatch({
			type: types.REGISTER_USER_SUCCESS
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// One exeption becouse error must be visible but there is
		// no translation to this message
		checkForInvalidDataError(error);

		// Dispatch an error action
		dispatch({
			type: types.REGISTER_USER_ERROR,
			payload: error.response
		});

		if (
			error.response?.data?.validator?.errors &&
			error.response.status === 422
		) {
			// convert errors from request to format that is accepted by Formik
			const errors = convertErrors(error.response.data.validator.errors);

			// In this case translate all data from request validator to custom text
			addCustomTranslationToErrors(errors, 'Nieprawidłowy format danych');

			//set errors in proper fields in Formik
			setErrors(errors);
		}
	}
};

// ******************** FETCH REGISTER AGREEMENTS ********************
export const fetchRegisterAgreements = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_LOADING
		});

		const url = 'register/agreements';
		const { data } = await services.get(url);

		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_REGISTER_AGREEMENTS_ERROR
		});
	}
};

export const clearRegisterAgreements = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_REGISTER_AGREEMENTS
	});
};

// ******************* Clear register data *******************
export const clearRegisterData = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_REGISTER_DATA
	});
};
