import i18n from 'i18next';

// Import helpers
import { TRANSACTION_TYPES } from 'helpers/prices';

const { SUBSCRIPTION } = TRANSACTION_TYPES;

// ----- VOD SECTION BUTTON -----
// vod section button background when hover
export const backgroundHovered = ({
	vodSectionButton: { backgroundHovered }
}) => [
	{ offset: 0, color: backgroundHovered.color1 },
	{ offset: 100, color: backgroundHovered.color2 }
];

// vod section button border
export const borderGradient = ({ vodSectionButton: { borderGradient } }) => [
	{ offset: 0, color: borderGradient.color1 },
	{ offset: 50, color: borderGradient.color2 },
	{ offset: 100, color: borderGradient.color3 }
];

// ----- PRICE BUTTON -----
export const setButtonNameTranslation = (buyName, rentName, vodSubtype) => {
	switch (vodSubtype) {
		case 'series':
			buyName = 'vod_buttons_series_buy';
			rentName = 'vod_buttons_series_rent';
			return [buyName, rentName];
		case 'season':
			buyName = 'vod_buttons_season_buy';
			rentName = 'vod_buttons_season_rent';
			return [buyName, rentName];
		case 'episode':
			buyName = 'vod_buttons_episode_buy';
			rentName = 'vod_buttons_episode_rent';
			return [buyName, rentName];

		default:
			return [buyName, rentName];
	}
};

export const getButtonNameTranslation = ({ transactionType, vodSubType }) => {
	switch (transactionType) {
		case SUBSCRIPTION:
			return 'vod_buttons_subscription';

		default:
			return `vod_buttons_${vodSubType}_${transactionType}`;
	}
};

export const getWachlistButtonTitle = ({ isFavourite, isUserProfile }) => {
	if (isUserProfile) return i18n.t('user_profile_watchlist_button_delete');

	return isFavourite
		? i18n.t('vod_buttons_watchlist_delete')
		: i18n.t('vod_buttons_watchlist_add');
};
