// styles for BannersItem.js

import styled, { css } from 'styled-components';

//import styles
import { customMinResponsive } from 'components/styles';

export const Title = styled.span`
	display: block;
	position: absolute;
	left: 1.6rem;
	bottom: 1.6rem;
	right: 1.6rem;
	z-index: 2;
	font-size: 1.6rem;
	line-height: 1.4;
	${customMinResponsive(
		1280,
		css`
			left: 2.2rem;
			bottom: 6.8rem;
			right: 2.2rem;
			font-size: 2.2rem;
		`
	)}
`;

export const Subtitle = styled.span`
	display: none;
	position: absolute;
	left: 2.2rem;
	bottom: 2.5rem;
	z-index: 2;
	font-size: 1.2rem;
	${customMinResponsive(
		1280,
		css`
			display: block;
		`
	)}
	${customMinResponsive(
		1280,
		css`
			font-size: 1.4rem;
		`
	)}
	${customMinResponsive(
		1600,
		css`
			font-size: 1.6rem;
		`
	)}

	span {
		position: absolute;
		right: -1.8rem;
		top: 50%;
		transform: translateY(-46%);
		color: ${({ theme }) => theme.bluePelorus};
	}
`;
