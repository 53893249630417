import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import Timer from './Timer';

// Import styles
import { Wrapper, Cover, HeadingStyled, Title, TimerText } from './styles';

// Import hooks
import useNextEpisode from './useNextEpisode';

const NextEpisode = ({ currentTimeProgress }) => {
	const { t } = useTranslation();

	const {
		showNextEpisode,
		id,
		number,
		isRtl,
		playNextEpisode
	} = useNextEpisode({
		currentTimeProgress
	});

	const titleText = `${t('vod_movieDetails_episode')} ${number}`;

	return (
		showNextEpisode && (
			<Wrapper onClick={playNextEpisode}>
				<Cover id={id} />
				<div>
					<HeadingStyled isRtl={isRtl}>
						{t('common_next_episode')}
					</HeadingStyled>
					<Title isRtl={isRtl}>{titleText}</Title>
					<TimerText isRtl={isRtl}>
						{t('common_starts_in')} <Timer endFunction={playNextEpisode} />
					</TimerText>
				</div>
			</Wrapper>
		)
	);
};

NextEpisode.propTypes = {
	currentTimeProgress: func.isRequired
};

export default NextEpisode;
