import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPipOn } from 'store/actions';

// Import icons
import { ReactComponent as TvIcon } from 'assets/icons/ic_tv.svg';

// Import utilities
import { usePip } from 'components/utilities';

// Import components
import { Button } from 'components/elements';
import { IconWrapper } from '../styles';

const Pip = () => {
	const dispatch = useDispatch();
	const { isAvailable, isPipOn } = useSelector((state) => state.pip);
	const { requestPip } = usePip();

	const handleRequestPip = () => {
		if (isAvailable) {
			requestPip();
			setPipOn(true)(dispatch);
		}
	};

	return (
		<Button
			modifiers="pipIcon"
			isAvailable={isAvailable}
			isPipOn={isPipOn}
			onClick={handleRequestPip}
		>
			<IconWrapper width="3.2rem" height="3.2rem">
				<TvIcon />
			</IconWrapper>
		</Button>
	);
};

export default Pip;
