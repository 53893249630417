import services from 'services/services';
import * as types from 'store/actions/types';

export const setCouponActivationCode = (code) => async (dispatch) => {
	try {
		const { data } = await services.get(
			`promotions/${code}/isAvailableForUser`
		);

		const isAvailable = data?.available;

		dispatch({
			type: types.SET_COUPON_ACTIVATION_CODE,
			payload: isAvailable ? code : ''
		});
	} catch (e) {
		return;
	}
};
