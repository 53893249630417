import { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

// Import helpers
import { QUERY_SECTIONS_FOCUS_DATA } from 'helpers/query';

const useSectionFocus = ({ id }) => {
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const history = useHistory();
	const restorationRef = useRef(null);
	const timeoutIdRef = useRef(null);

	const handleSaveSectionId = () =>
		queryClient.setQueryData(QUERY_SECTIONS_FOCUS_DATA, {
			pagePathname: pathname,
			sectionId: id
		});

	const handleFocusedSection = () => {
		timeoutIdRef.current = setTimeout(
			() =>
				restorationRef.current.scrollIntoView({
					behavior: 'auto'
				}),
			0
		);

		queryClient.removeQueries(QUERY_SECTIONS_FOCUS_DATA, { exact: true });
	};

	const clearFocusTimeout = () => {
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = null;
		}
	};

	useEffect(() => {
		const sectionsFocusData = queryClient.getQueryData(
			QUERY_SECTIONS_FOCUS_DATA
		);

		const isSectionFocused =
			restorationRef.current &&
			history.action === 'POP' &&
			sectionsFocusData?.sectionId === id &&
			sectionsFocusData?.pagePathname === pathname;

		if (isSectionFocused) {
			handleFocusedSection();
		}

		return () => {
			clearFocusTimeout();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { saveSectionId: handleSaveSectionId, restorationRef };
};

export default useSectionFocus;
