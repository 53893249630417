import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import styles
import { spin } from 'components/styles';

/**
 * Simple, small loader which can be added to buttons.
 * @param {number | string} size - size of a loader (same width and height) in rem
 * @param {number | string} thickness - thickness of a loader circle in px
 */
const ButtonLoader = ({ size = 3, thickness = 7 }) => {
	return <ButtonLoaderItem height={size} width={size} thickness={thickness} />;
};

const ButtonLoaderItem = styled.div`
	width: ${({ width }) => `${width}rem`};
	height: ${({ height }) => `${height}rem`};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: ${({ thickness }) => `${thickness}px`} solid
		${({ theme }) => theme.whiteSemiTranslucent};
	border-radius: 50%;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		width: ${({ width }) => `${width}rem`};
		height: ${({ height }) => `${height}rem`};
		top: ${({ thickness }) => `-${thickness}px`};
		left: ${({ thickness }) => `-${thickness}px`};
		animation: ${spin} 1.5s linear infinite;
		border: ${({ thickness }) => `${thickness}px`} solid transparent;
		border-radius: 50%;
		border-top-color: ${({ theme }) => theme.white};
	}
`;

ButtonLoader.propTypes = {
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ButtonLoader;
