import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import styles
import {
	Wrapper,
	Header
} from 'components/views/vod/components/PlayerWrapper/recommendations/styles';

// Import components
import { ErrorBoundary } from 'components/elements';
import { Basic } from 'components/views/vod/components/sliders/Basic';
import RecommendationsItem from 'components/views/vod/components/PlayerWrapper/recommendations/RecommendationsItem';

// Import helpers
import { getFilteredVODRecommendations } from 'components/views/vod/helpers/getFilteredVODRecommendations';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

const Recommendations = () => {
	const { t } = useTranslation();
	const recommendations = useSelector(({ recommendations }) =>
		getFilteredVODRecommendations(recommendations.data)
	);

	const renderHeader = () => (
		<Header>{t('player_recommendations_title')}</Header>
	);

	if (recommendations.length === 0) return null;

	return (
		<Wrapper>
			<Basic
				key={'recommendations'}
				itemResolutionHeight={5.4}
				itemResolutionWidth={7}
				itemsList={recommendations}
				itemsToShow={4}
				spaceBetweenItems={103}
				renderItemComponent={({ data, height, width }) => (
					<ErrorBoundary>
						<RecommendationsItem
							data={data}
							height={height}
							width={width}
							imageType={COVER}
							sliderId={'recommendations'}
						/>
					</ErrorBoundary>
				)}
				renderHeader={renderHeader}
				horizontalPadding={0}
				isVODRecommendation
			/>
		</Wrapper>
	);
};

export default Recommendations;
