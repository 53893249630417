import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import redux actions
import { fetchActiveSubscriptionsList, fetchUserPackets } from 'store/actions';

// Import hooks
import { useSubscriptions, useAuth, useToggle } from 'components/utilities';

// Import helpers
import { getWatchPath } from 'helpers';

const usePlay = ({ uuid, type, isAvailable = true }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isAuth } = useAuth();

	const [isSubscriptionModal, toggleSubscriptionModal] = useToggle();

	const { isSubscriptionPending } = useSubscriptions({ isLogged: isAuth });

	const watchPath = getWatchPath({ uuid, type, isAvailable });

	const handleCloseSubscriptionModal = () => {
		fetchActiveSubscriptionsList()(dispatch);
		fetchUserPackets()(dispatch);

		toggleSubscriptionModal();
	};

	const handlePlayButtonClick = () => {
		if (!isAvailable && isSubscriptionPending) {
			return toggleSubscriptionModal();
		}

		history.push(watchPath);
	};

	return {
		onClick: handlePlayButtonClick,
		isSubscriptionModal,
		toggleSubscriptionModal,
		closeSubscriptionModal: handleCloseSubscriptionModal
	};
};

export default usePlay;
