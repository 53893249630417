import React from 'react';

// Import utilities
import {
	useNextProgramParentalControl,
	useSystemSupport
} from 'components/utilities';

// Import components
import {
	Loader,
	Button,
	PlayerError,
	PlayerElements as Player,
	PlayerPip,
	SupportError,
	Watermark
} from 'components/elements';
import PlayerControllers from 'components/elements/organisms/player/player_overlay/player_controllers/PlayerControllers';
import { PlayerStickyButtons } from 'components/elements/organisms/player/player_sticky_buttons/PlayerStickyButtons';
import PlayerDestroy from 'components/elements/organisms/player/player_context/PlayerDestroy';
import CastReceiverName from 'components/elements/organisms/player/player_cast/CastReceiverName';

// Import styles
import { ButtonCloseFullScreen, IconPlayStyled } from './styles';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const PlayerTvContent = ({
	error,
	setPlayerCustomError,
	isFullScreen,
	isCatchup,
	type
}) => {
	const {
		isReady,
		isPaused,
		play,
		pause,
		destroy,
		setFullScreen: playerFullScreen,
		isEnded,
		catchupRepeat,
		showLoader,
		isSiedebarVisible,
		isFocus,
		useWatermark
	} = usePlayerTvContext();

	const { playerType } = useNextProgramParentalControl();

	const {
		showSupportMessage,
		supportError,
		setSupportAccept
	} = useSystemSupport();

	const renderClosebutton = (playerFullScreen) => (
		<ButtonCloseFullScreen
			onClick={playerFullScreen}
			modifiers={['icon', 'playerIconHover']}
		>
			<Player.IconCloseThick />
		</ButtonCloseFullScreen>
	);

	const closeButtonCondition = !isSiedebarVisible && isFullScreen && isFocus;

	const handleBigPlayButtonClick = (params) => {
		const { play, pause, isPaused, isEnded, catchupRepeat } = params;
		const videoPlayPause = () => (isPaused ? play() : pause());

		// repeat stream if catchup has ended
		// else play/pause stream
		isEnded ? catchupRepeat() : videoPlayPause();
	};

	const renderPlayerControllers = () => (
		<PlayerControllers isCatchup={isCatchup} isFocus={isFocus} />
	);

	const isTheoPlayer = DEFAULT_PLAYER === THEO_PLAYER;

	return (
		<>
			<PlayerDestroy
				errorAction={setPlayerCustomError}
				destroyPlayer={destroy}
				error={error}
				isCatchup={isCatchup}
			/>
			{showLoader && (
				<Loader zIndex={1} position="absolute" background={false} />
			)}

			{!isTheoPlayer && (
				<>
					{closeButtonCondition &&
						isCatchup &&
						renderClosebutton(playerFullScreen)}

					{closeButtonCondition && renderClosebutton(playerFullScreen)}

					<Watermark
						useWatermark={useWatermark}
						isChannel
						isMovedUp={!isFocus}
						isFullScreen={isFullScreen}
						isError={error.isError}
					/>

					<CastReceiverName />

					{isReady && (isFullScreen || isCatchup) ? (
						renderPlayerControllers()
					) : (
						<PlayerStickyButtons>
							{renderPlayerControllers()}
						</PlayerStickyButtons>
					)}
				</>
			)}

			{isReady && isPaused && (
				<Button
					isCatchupControllers={false}
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={() =>
						handleBigPlayButtonClick({
							play,
							pause,
							isPaused,
							isEnded,
							catchupRepeat
						})
					}
				>
					<IconPlayStyled />
				</Button>
			)}

			<PlayerPip isReady={isReady} />

			{error.isError && <PlayerError error={error} playerType={playerType} />}

			{showSupportMessage && (
				<SupportError
					error={supportError}
					setSupportAccept={setSupportAccept}
					playerType={type}
				/>
			)}
		</>
	);
};

export default PlayerTvContent;
