import React from 'react';
import {
	func,
	arrayOf,
	shape,
	object,
	oneOfType,
	string,
	number,
	bool
} from 'prop-types';

// Import components
import { Basic } from '../../Basic';
import { ErrorBoundary } from 'components/elements';
import { RegularHeader } from '../../components/RegularHeader';
import SliderItem from 'components/views/vod/components/sliders/components/SliderItem';

export function Slider({
	itemsList,
	changeCurrentIndex,
	id,
	name,
	shuffle = false,
	isCarousel = false
}) {
	const handleOnChangeCurrentIndex = (index) => () => changeCurrentIndex(index);
	return (
		<Basic
			itemResolutionWidth={6}
			itemResolutionHeight={9}
			itemsToShow={8}
			headerHeight={70}
			spaceBetweenItems={10}
			itemsList={itemsList}
			renderItemComponent={({ data, width, height, index }) => (
				<ErrorBoundary key={data.uuid}>
					<SliderItem
						onClick={handleOnChangeCurrentIndex(index)}
						data={data}
						height={height}
						width={width}
						isCarousel
					/>
				</ErrorBoundary>
			)}
			renderHeader={() => (
				<RegularHeader
					showAllMoviesButton={false}
					sectionId={id}
					title={name}
				/>
			)}
			shuffle={shuffle}
			isCarousel={isCarousel}
		/>
	);
}

Slider.propTypes = {
	itemsList: arrayOf(
		shape({
			images: object
		})
	).isRequired,
	changeCurrentIndex: func.isRequired,
	id: oneOfType([string, number]).isRequired,
	name: string.isRequired,
	shuffle: bool,
	isCarousel: bool
};
