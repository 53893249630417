import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import helpers
import { getRTLBooleanValue } from 'helpers/rtl';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

const ButtonBack = ({ handleGoBack }) => {
	const { direction } = useSelector(({ direction }) => direction);
	const isRtl = getRTLBooleanValue({ direction });
	return (
		<Button
			isRtl={isRtl}
			onClick={handleGoBack}
			modifiers={['icon', 'playerIconHover']}
		>
			<Player.IconCloseThick />
		</Button>
	);
};

ButtonBack.propTypes = {
	handleGoBack: PropTypes.func.isRequired
};

export default ButtonBack;
