import styled, { css } from 'styled-components';

// Import components
import { Input } from 'components/elements';

export const Wrapper = styled.div`
	height: 5rem;
	position: relative;
	margin-bottom: ${({ marginBottom }) => marginBottom};
	border: 1px solid
		${({ theme }) => theme.authentication.firebase_auth_field.border};
	border-radius: 0.5rem;
	display: flex;

	${({ isDialCodeField }) =>
		isDialCodeField &&
		css`
			margin-right: 2rem;
			margin-bottom: 0;
		`}
`;

export const InputStyled = styled(Input)`
	position: relative;
	width: 72%;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid
		${({ theme }) => theme.authentication.firebase_auth_field.underlining};
	color: ${({ theme }) => theme.authentication.firebase_auth_field.color};
	margin-bottom: 0.3rem;
	margin-left: 2.3rem;
	padding-bottom: 0.2rem;
	padding-left: 0 !important;

	&:focus {
		border-bottom: 1px solid
			${({ theme }) => theme.authentication.firebase_auth_field.underlining};
	}

	&.errors {
		border: none;
		border-bottom: 1px solid
			${({ theme }) =>
				theme.authentication.firebase_auth_field.errorUnderlining};
		color: ${({ theme }) => theme.authentication.firebase_auth_field.error};
	}
`;

export const FormError = styled.span`
	position: absolute;
	top: 5rem;
	left: 0;
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: 1rem;
	color: ${({ theme }) => theme.authentication.firebase_auth_field.error};
	text-align: left;
`;
