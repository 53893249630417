import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import components
import { DynamicIcon } from 'components/elements';

const SliderController = ({ type, onClick, defaultBtn }) => {
	const isPrev = type === 'prev';

	return (
		<ButtonController
			defaultBtn={defaultBtn}
			onClick={onClick}
			className={`button button-${type}`}
			isPrev={isPrev}
		>
			{isPrev ? (
				<DynamicIcon icon="ic_slider_arrow_l" />
			) : (
				<DynamicIcon icon="ic_slider_arrow_r" />
			)}
		</ButtonController>
	);
};

const ButtonController = styled.button`
	position: absolute;
	top: 0;
	bottom: 6px;
	width: ${({ defaultBtn }) => (defaultBtn ? '4rem' : 'max-content')};
	background-color: ${({ theme }) =>
		theme.slider.arrow.sliderControllerBgColor};
	border: none;
	height: 98%;
	z-index: 1000;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:focus {
		outline: none;
	}

	${({ defaultBtn, isPrev }) =>
		isPrev
			? css`
					left: ${defaultBtn ? '-5rem' : '1rem'};
			  `
			: css`
					right: ${defaultBtn ? '-3rem' : '1rem'};
			  `}
`;

SliderController.propTypes = {
	type: PropTypes.string.isRequired,
	defaultBtn: PropTypes.bool.isRequired,
	onClick: PropTypes.func
};

export default SliderController;
