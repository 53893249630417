import React from 'react';
import { arrayOf, shape, string, number, func } from 'prop-types';

// Import components
import Episode from './Episode';
import { Basic } from '../Basic';

export function Episodes({ episodes, selected, setSelected }) {
	const handleSetSelected = (index) => setSelected(index);

	return (
		<Basic
			itemResolutionWidth={6}
			itemResolutionHeight={9}
			itemsToShow={7}
			spaceBetweenItems={10}
			itemsList={episodes}
			verticalPadding={0}
			renderItemComponent={(props) => (
				<Episode
					setSelected={handleSetSelected}
					resources={props}
					isActive={selected === props.index}
					key={props.data.uuid}
				/>
			)}
		/>
	);
}

Episodes.propTypes = {
	episodes: arrayOf(
		shape({
			uuid: string
		})
	).isRequired,
	selected: number.isRequired,
	setSelected: func.isRequired
};
