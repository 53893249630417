import React from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import assets
import LogoRTL from 'assets/images/logo_rtl.png';
import LogoLTR from 'assets/images/logo_ltr.png';

// Import styles
import { LogoWrapper } from './styles';

// Import paths
import { APP_PATHS_KEY } from 'components/routes/helpers';

const { MAIN } = APP_PATHS_KEY;

const Logo = ({ isRtl = false }) => {
	const logoSrc = isRtl ? LogoRTL : LogoLTR;

	return (
		<LogoWrapper>
			<Link to={MAIN}>
				<LazyLoadImage width={112} src={logoSrc} effect="blur" delayTime={0} />
			</Link>
		</LogoWrapper>
	);
};

Logo.propTypes = {
	isRtl: bool
};

export default Logo;
