import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Import components
import { Loader, PlayerError, PlayerPip, Watermark } from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import PlayerControllers from 'components/views/vod/components/PlayerWrapper/player_controllers/PlayerControllers';
import TopHeadline from 'components/views/vod/components/PlayerWrapper/TopHeadline/TopHeadline';
import NextEpisode from 'components/views/vod/components/PlayerWrapper/next_episode/NextEpisode';
import Recommendations from 'components/views/vod/components/PlayerWrapper/recommendations/Recommendations';
import PlayerSkipIntro from 'components/views/vod/components/PlayerWrapper/player_skip_intro/PlayerSkipIntro';
import CastReceiverName from 'components/views/vod/components/CastReceiverName';

// Import styles
import { BgGradient, CastWrapper } from '../styles';

const ShakaPlayerContent = ({ isFocus, type }) => {
	const {
		buffering,
		isReady,
		error,
		isEpisode,
		isNextEpisodeAvailable,
		handlePlayPause,
		setFullScreen,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		isNextEpisode,
		showRecommendation,
		setContinueProgress,
		introData,
		useWatermark
	} = usePlayerContent({ type });

	useEffect(() => {
		setContinueProgress();
		// eslint-disable-next-line
	}, [isReady]);

	return (
		<>
			<BgGradient
				onDoubleClick={setFullScreen}
				onClick={handlePlayPause}
				isFocus={isFocus}
			/>

			{buffering && (
				<Loader background={false} position="absolute" zIndex={1} />
			)}

			<TopHeadline isFocus={isFocus} isError={error.isError} />

			<Watermark useWatermark={useWatermark} isError={error.isError} />

			<CastWrapper>
				<CastReceiverName />
			</CastWrapper>

			{isReady && (
				<>
					<PlayerSkipIntro
						currentTimeProgress={currentTimeProgress}
						updateTime={updateTime}
						introData={introData}
					/>
					<PlayerControllers
						isNextEpisodeAvailable={isNextEpisodeAvailable}
						isEpisode={isEpisode}
						isFocus={isFocus}
						duration={duration}
						updateTime={updateTime}
						currentTimeProgress={currentTimeProgress}
						isPreviewAvailable={isPreviewAvailable}
					/>
					{isNextEpisode && (
						<NextEpisode currentTimeProgress={currentTimeProgress} />
					)}
				</>
			)}

			{showRecommendation && <Recommendations />}

			<PlayerPip isReady={isReady} />

			{error.isError && <PlayerError error={error} />}
		</>
	);
};

ShakaPlayerContent.propTypes = {
	type: PropTypes.string.isRequired,
	isFocus: PropTypes.bool.isRequired
};

export default ShakaPlayerContent;
