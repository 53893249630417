import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import i18n from 'i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

// Import utilites
import { flex } from 'components/styles';

// Import Provider
import AuthenticationProvider from 'components/context/authentication/AuthenticationProvider';

// Import helpers
import { LOGIN_PAGE_IMAGE, NON_SUBSCRIBER_PAGE_IMAGE } from 'helpers/variables';
import { APP_PATHS_KEY, getNavigationPaths } from 'components/routes/helpers';

const { NON_SUBSCRIBER } = APP_PATHS_KEY;

const AuthenticationTemplate = ({ children }) => {
	const { language } = i18n;
	const { pathname } = useLocation();

	const nonSubscriberPath = getNavigationPaths(NON_SUBSCRIBER);

	const isNonSubscriberPage = pathname.includes(nonSubscriberPath);

	const backgroundUrl = isNonSubscriberPage
		? NON_SUBSCRIBER_PAGE_IMAGE[language] ?? NON_SUBSCRIBER_PAGE_IMAGE.ar
		: LOGIN_PAGE_IMAGE.DESKTOP;

	return (
		<AuthenticationProvider>
			<AuthenticationWrapper background={backgroundUrl}>
				<AuthenticationBox>{children}</AuthenticationBox>
			</AuthenticationWrapper>
		</AuthenticationProvider>
	);
};

const AuthenticationWrapper = styled.div`
	position: relative;
	flex: 1;
	${flex()}
	background: ${({ background }) => `url(${background}) center center/cover`};
	min-height: ${({ theme }) => `calc(100vh - ${theme.nav.height}rem)`};
`;

const AuthenticationBox = styled.div`
	color: white;
	width: 100%;
`;

AuthenticationTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)])
};

export default AuthenticationTemplate;
