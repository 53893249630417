import { useSelector } from 'react-redux';

// Import helpers
import { checkProperFavouriteProduct, filterProductGenres } from 'helpers';
import { getTitleImage } from 'components/views/vod/helpers/getTitleImage';

// Import utilities
import { useAuth, useProductTranslations } from 'components/utilities';

const useInfo = ({ currentItem = {} }) => {
	const { isAuth } = useAuth();

	const { data: genresList } = useSelector(({ genresList }) => genresList);

	const {
		product: {
			images,
			uuid,
			title,
			subtype,
			hide_title,
			short_desc,
			rating,
			year,
			context,
			genres,
			star_rating: starRating,
			country,
			previews
		}
	} = useProductTranslations({ data: currentItem });

	const duration = context?.duration;

	const titleImage = getTitleImage(images);

	const productGenres = genres || [];

	const productGenresList = filterProductGenres({ genresList, productGenres });

	const basicData = {
		rating,
		year,
		duration,
		genres: productGenresList,
		starRating,
		subtype,
		country
	};

	const videoAssetId = previews?.preview?.[0]?.videoAssetId;

	const showFavouriteButton = isAuth && checkProperFavouriteProduct(subtype);

	return {
		uuid,
		title,
		subtype,
		hide_title,
		short_desc,
		titleImage,
		videoAssetId,
		basicData,
		showFavouriteButton
	};
};

export default useInfo;
