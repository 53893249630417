import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const DottedLoaderWrapper = styled.div`
	width: 100%;
	height: 30rem;
	${flex()}

	@keyframes dot-keyframes {
		0% {
			opacity: 0.4;
			transform: scale(1, 1);
		}

		50% {
			opacity: 1;
			transform: scale(1.2, 1.2);
		}

		100% {
			opacity: 0.4;
			transform: scale(1, 1);
		}
	}
`;

const dots = () => css`
	animation: dot-keyframes 1.5s infinite ease-in-out;
	border-radius: 50%;
	height: 2rem;
	width: 2rem;
	background-color: ${({ theme }) => theme.loader.dotted.bg};
`;

const pseudoElements = () => css`
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
`;

export const DottedLoaderInner = styled.div`
	${dots()}
	margin: 0 0.5rem;
	position: relative;
	animation-delay: 1s;

	&:before {
		${pseudoElements()}
		left: 3rem;
		${dots()}
	}

	&:after {
		${pseudoElements()}
		right: 3rem;
		${dots()}
		animation-delay: 0.5s;
	}
`;
