import React from 'react';
import { string, oneOfType, array, number, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourite, deleteFavourite } from 'store/actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import assets
import RemoveFromFavourites from 'assets/icons/ic_remove_from_favourites.png';
import AddToFavourites from 'assets/icons/ic_add_to_favourites.png';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';
import { getWachlistButtonTitle } from './helpers';

// Import components
import { ButtonRounded, AuthModal } from 'components/elements';

const WatchlistButton = ({
	uuid,
	width,
	height,
	iconWidth,
	modifiers,
	isUserProfile
}) => {
	const dispatch = useDispatch();
	const favourites = useSelector(({ favourites }) => favourites.data);

	const isFavourite = checkIsFavourite(favourites, uuid);

	const handleButtonClick = () =>
		isFavourite
			? deleteFavourite(uuid)(dispatch)
			: addFavourite(uuid)(dispatch);

	const title = getWachlistButtonTitle({ isFavourite, isUserProfile });

	return (
		<AuthModal>
			<ButtonRounded
				type="button"
				onClick={handleButtonClick}
				width={width}
				height={height}
				title={title}
				modifiers={modifiers}
			>
				{isFavourite ? (
					<LazyLoadImage src={RemoveFromFavourites} width={iconWidth} />
				) : (
					<LazyLoadImage src={AddToFavourites} width={iconWidth} />
				)}
			</ButtonRounded>
		</AuthModal>
	);
};

WatchlistButton.defaultProps = {
	width: 80,
	height: 80,
	iconWidth: 50,
	isUserProfile: false
};

WatchlistButton.propTypes = {
	uuid: string.isRequired,
	width: number,
	height: number,
	iconWidth: number,
	modifiers: oneOfType([string, array]),
	isUserProfile: bool
};

export default WatchlistButton;
