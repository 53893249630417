import i18n from 'i18next';

// Import variables
import { appPaths } from './appPaths';

export const getNavigationPaths = (key) => {
	const { language } = i18n;
	const paths = appPaths[language] ?? appPaths.ar;
	return paths[key];
};

export const APP_PATHS_KEY = {
	LANDING_PAGE: 'landingPage',
	ABOUT: 'about',
	REGULATION: 'regulation',
	RODO: 'rodo',
	CONTACT: 'contact',
	SUPPORT: 'support',
	REGISTER: 'register',
	RESET_PASSWORD: 'resetPassword',
	EMAIL_CONFIRM: 'emailConfirm',
	LOGIN: 'login',
	PACKETS: 'packets',
	SINGLE_PACKET: 'singlePacket',
	HOME: 'home',
	PROGRAM_DETAILS: 'programDetails',
	CHANNELS: 'channels',
	EPG: 'epg',
	USER_DEVICES: 'userDevices',
	USER_GENERAL: 'userGeneral',
	USER_RECORDINGS: 'userRecordings',
	USER_PRODUCTS: 'userProducts',
	USER_SUBSCRIPTIONS: 'userSubscriptions',
	USER_FAVOURITES: 'userFavourites',
	USER_PAYMENTS: 'userPayments',
	USER_REGULATION: 'userRegulation',
	USER_WATCHLIST: 'userWatchlist',
	USER_ENTRY_TICKETS: 'userEntryTickets',
	USER_DELETING_ACCOUNT: 'userDeletingAccount',
	PLAYER: 'player',
	SECTION_DETAILS: 'sectionDetails',
	SECTION_CHANNELS_DETAILS: 'sectionChannelsDetails',
	SECTION_CATEGORY_DETAILS: 'sectionCategoryDetails',
	PRODUCT_DETAILS: 'productDetails',
	SECTION_MOVIES: 'sectionMovies',
	SECTION_SERIES: 'sectionSeries',
	SECTION_KIDS: 'sectionKids',
	TV: 'tv',
	VOD: 'vod',
	USER: 'user',
	SEARCH: 'search',
	TRANSACTION_FINALIZATION: 'transactionFinalization',
	PRIVACY_POLICY: 'privacyPolicy',
	FAQ: 'faq',
	MAIN: '/',
	NON_SUBSCRIBER: 'nonSubscriber',
	CODE_EVENT: 'codeEvent',
	ERROR_404: 'error404'
};
