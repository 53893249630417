import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';

// Import helpers
import { languagesOptions, hasSeveralLanguages } from 'helpers/languages';
import { getNewPath } from './helpers';

// Import variables
import { FONTS } from 'helpers/variables';

// Import styles
import { StyledSelect } from './styles';

const { Option } = Select;

const LanguageDropdown = () => {
	const { pathname, search } = useLocation();
	const { t, i18n } = useTranslation();

	if (!hasSeveralLanguages) return null;

	const handleChangeLanguage = (newLng) => {
		const newPath = getNewPath({ pathname, newLng });
		window.location.replace(`${newPath}${search}`);
	};

	const getDropdownContainer = (trigger) => trigger.parentNode;

	return (
		<StyledSelect
			value={i18n.language}
			style={{ width: 120 }}
			onChange={handleChangeLanguage}
			getPopupContainer={getDropdownContainer}
		>
			{languagesOptions.map(({ value, name }) => (
				<Option
					key={value}
					value={value}
					style={{
						fontFamily:
							name === process.env.REACT_APP_DEFAULT_LANGUAGE
								? FONTS.NOTO_KUFI_ARABIC
								: FONTS.NOTO_SANS_REGULAR
					}}
				>
					{t(`common_language_shortcut_${name}`)}
				</Option>
			))}
		</StyledSelect>
	);
};

export default LanguageDropdown;
