import React from 'react';

// Import components
import Nav from 'components/layout/nav/Nav';
import NavToolbar from '../nav/nav_toolbar/NavToolbar';
import Logo from './Logo';
import HiddenTags from './HiddenTags';

// Import styles
import { Wrapper } from './styles';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import hooks
import { useHeader } from './useHeader';

const Header = () => {
	const { areTitlesVisible, isRtl } = useHeader();

	return (
		<ToggleRPC>
			{({ toggle, on }) => (
				<Wrapper>
					<Logo isRtl={isRtl} />

					<Nav isMainNavigation on={on} toggle={toggle} />

					<NavToolbar toggle={toggle} />

					{areTitlesVisible && <HiddenTags />}
				</Wrapper>
			)}
		</ToggleRPC>
	);
};

export default Header;
