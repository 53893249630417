// ----------------- ACTIVE SUBSCRIPTIONS LIST -----------------
export const FETCH_ACTIVE_PAYMENT_METHODS_LOADING =
	'FETCH_ACTIVE_PAYMENT_METHODS_LOADING';

export const FETCH_ACTIVE_PAYMENT_METHODS_SUCCESS =
	'FETCH_ACTIVE_PAYMENT_METHODS_SUCCESS';

export const FETCH_ACTIVE_PAYMENT_METHODS_ERROR =
	'FETCH_ACTIVE_PAYMENT_METHODS_ERROR';

export const CLEAR_ACTIVE_PAYMENT_METHODS = 'CLEAR_ACTIVE_PAYMENT_METHODS';

// ----------------- LAST GATEWAY -----------------
export const FETCH_LAST_GATEWAY_TYPE_LOADING =
	'FETCH_LAST_GATEWAY_TYPE_LOADING';

export const FETCH_LAST_GATEWAY_TYPE_SUCCESS =
	'FETCH_LAST_GATEWAY_TYPE_SUCCESS';

export const FETCH_LAST_GATEWAY_TYPE_ERROR = 'FETCH_LAST_GATEWAY_TYPE_ERROR';

export const CLEAR_LAST_GATEWAY_TYPE = 'CLEAR_LAST_GATEWAY_TYPE';

// ----------------- ACTIVE SUBSCRIPTIONS -----------------
export const FETCH_ACTIVE_SUBSCRIPTIONS_LOADING =
	'FETCH_ACTIVE_SUBSCRIPTIONS_LOADING';
export const FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS =
	'FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS';
export const FETCH_ACTIVE_SUBSCRIPTIONS_ERROR =
	'FETCH_ACTIVE_SUBSCRIPTIONS_ERROR';
export const CLEAR_ACTIVE_SUBSCRIPTIONS_DATA =
	'CLEAR_ACTIVE_SUBSCRIPTIONS_DATA';

// ----------------- ASSIGNED SUBSCRIPTIONS -----------------
export const FETCH_ASSIGNED_SUBSCRIPTIONS_LOADING =
	'FETCH_ASSIGNED_SUBSCRIPTIONS_LOADING';
export const FETCH_ASSIGNED_SUBSCRIPTIONS_SUCCESS =
	'FETCH_ASSIGNED_SUBSCRIPTIONS_SUCCESS';
export const FETCH_ASSIGNED_SUBSCRIPTIONS_ERROR =
	'FETCH_ASSIGNED_SUBSCRIPTIONS_ERROR';
export const CLEAR_ASSIGNED_SUBSCRIPTIONS_DATA =
	'CLEAR_ASSIGNED_SUBSCRIPTIONS_DATA';

// ----------------- FETCH USER SUBSCRIPTIONS -----------------
export const FETCH_USER_SUBSCRIPTIONS_LOADING =
	'FETCH_USER_SUBSCRIPTIONS_LOADING';
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS =
	'FETCH_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_ERROR = 'FETCH_USER_SUBSCRIPTIONS_ERROR';
