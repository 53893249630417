import React from 'react';

// Import components
import { PlayerElements, Button } from 'components/elements';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

const { IconCast, IconCastConnected } = PlayerElements;

const CastButton = () => {
	const { isCasting, isCastAvailable, cast, isReady } = usePlayerTvContext();

	if (!isCastAvailable || !isReady) return null;

	return (
		<Button onClick={cast} modifiers={['icon', 'playerIconHover']}>
			{isCasting ? <IconCastConnected aria-hidden /> : <IconCast aria-hidden />}
		</Button>
	);
};

export default CastButton;
