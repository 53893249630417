import styled, { css } from 'styled-components';

// Import styles
import {
	borderGradient,
	customMinResponsive,
	fadeIn,
	rtl,
	transitionMain
} from 'components/styles';

// Import components
import { Heading } from 'components/elements';

// import assets
import NoImage from 'components/views/vod/images/placeholder.svg';

const { REACT_APP_API_URL } = process.env;

export const Wrapper = styled.div`
	cursor: pointer;
	position: absolute;
	left: 5rem;
	bottom: 12rem;
	z-index: 999;
	display: flex;
	animation: ${fadeIn(0)} 0.5s ease-in-out forwards;
	${transitionMain()}

	&:hover,
	&:focus {
		transform: scale(1.02);
	}

	${customMinResponsive(
		1100,
		css`
			${rtl`left: 6.5rem;`};
		`
	)}

	${customMinResponsive(
		1440,
		css`
			${rtl`left: 8rem;`};
		`
	)}

	${customMinResponsive(
		1750,
		css`
			${rtl`left: 10rem;`};
		`
	)}
`;

export const HeadingStyled = styled(Heading.First)`
	direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
	font-size: 3.8rem;
	font-weight: ${({ theme }) => theme.font.regular};
	color: ${({ theme }) => theme.player.nextEpisode.heading};
	margin-bottom: 1.1rem;
`;

export const Title = styled(Heading.Second)`
	direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
	font-size: 5.6rem;
	margin-bottom: 0.8rem;
	color: ${({ theme }) => theme.player.nextEpisode.title};
`;

export const TimerText = styled.p`
	direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
	font-size: 3.2rem;
	color: ${({ theme }) => theme.player.nextEpisode.timerText};
	font-weight: ${({ theme }) => theme.font.normal};
`;

export const Cover = styled.div`
	position: relative;
	width: 27.9rem;
	height: 21.8rem;
	${rtl`margin-right: 6rem;`};
	border-radius: 0.5rem;
	box-shadow: ${({ theme }) => theme.player.nextEpisode.cover.shadow};
	background-image: url('${REACT_APP_API_URL}/assets/${({ id }) =>
	id}/cover'), url('${NoImage}');
	background-size: cover;
	background-position: center;
  
	${({ theme }) =>
		css`
			${borderGradient({
				borderSize: 2,
				borderBg: theme.player.nextEpisode.cover.border
			})}
		`};
`;
