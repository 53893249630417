import React from 'react';
import styled from 'styled-components';

// Import translation
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as GearsIcon } from 'assets/icons/ic_gears.svg';
import Logo from 'assets/images/logo_big.png';

function PortalUnavailable() {
	const { t } = useTranslation();

	return (
		<Container>
			<ContainerItem>
				<PortalLogo src={Logo} />
				<ErrorText>{t('common_errors_service_unavailable')}</ErrorText>
				<ErrorIcon />
			</ContainerItem>
		</Container>
	);
}

const Container = styled.div`
	padding-top: 10vh;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ContainerItem = styled.div`
	max-width: 790px;
	width: 100%;
	padding: 0 15px;
`;

const PortalLogo = styled.img`
	width: 12rem;
	margin: 0 auto;
	display: block;
`;

const ErrorIcon = styled(GearsIcon)`
	display: block;
	margin: 0 auto;
	width: 150px;
`;

const ErrorText = styled.p`
	color: ${({ theme }) => theme.grayMedium};
	font-weight: ${({ theme }) => theme.font.normal};
	text-align: center;
	font-size: 2.5rem;
	margin: 55px 0 45px 0;
	letter-spacing: 0.27px;
	line-height: 120%;
`;

export default PortalUnavailable;
