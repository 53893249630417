import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

const Tag = styled.div`
	height: 4rem;
	width: 100%;
	${flex()}
	margin-bottom: 1.5rem;
	border-radius: ${({ theme }) =>
		theme.searchPage.searchItems.tag.roundSize}rem;
	background: ${({ theme }) => theme.searchPage.searchItems.tag.bg};
	color: ${({ theme }) => theme.searchPage.searchItems.tag.color};
	font-size: 1.62rem;
`;

export default Tag;
