import * as types from 'store/actions/types';
import servicesVod from 'services/services';
import { openNotification } from 'components/utilities/notification/notification';
import i18next from 'i18next';

// Import helpers
import { checkErrorStatus } from '../../helpers/error_status';

export async function fetchSectionContent(sectionId, dispatch) {
	try {
		dispatch({ type: types.SECTION_DETAILS_LOADING });

		const urlDetails = `/sections/${sectionId}`;

		const { data: details } = await servicesVod.get(urlDetails);

		dispatch({
			type: types.SECTION_DETAILS_SUCCESS,
			details
		});
	} catch (error) {
		dispatch({ type: types.SECTION_DETAILS_ERROR });

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('vod_root_error_title'),
				description: i18next.t('vod_root_error_description')
			});
	}
}

export function clearSectionContent(dispatch) {
	dispatch({ type: types.SECTION_DETAILS_CLEAR });
}
