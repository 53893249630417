import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isError: false,
	isLoaded: false,
	data: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* ACTIVE SUBSCRIPTIONS *******************
			case types.FETCH_ACTIVE_SUBSCRIPTIONS_LOADING:
				draft.isError = false;
				draft.isLoaded = false;
				return;

			case types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS:
				draft.data = action.payload;
				draft.isError = false;
				draft.isLoaded = true;
				return;

			case types.FETCH_ACTIVE_SUBSCRIPTIONS_ERROR:
				draft.data = [];
				draft.isError = true;
				draft.isLoaded = true;

				return;

			case types.CLEAR_ACTIVE_SUBSCRIPTIONS_DATA:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
