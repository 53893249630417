import services from 'services/services';

export const getProductSubtype = async (uuid, callback) => {
	try {
		const {
			data: { type: subtype }
		} = await services.get(`/products/${uuid}/subtype`);

		callback(subtype);
	} catch (err) {}
};
