import { css } from 'styled-components';

export const CONTAINER_SCREEN_PERCENT = 100;
export const CONTAINER_MAX_HEIGHT = 940;
export const CONTAINER_MIN_HEIGHT = 760;

export const CAROUSEL_START_TIMEOUT = 6000;
export const CAROUSEL_STOP_TIMEOUT = 3000;

/**
 * function gives min-height i max-height for component by its vh value in
 * proportion to container
 * @param {number} elementScreenPercent
 */
export function minMaxHeight(elementScreenPercent) {
	const maxHeight =
		(CONTAINER_MAX_HEIGHT * elementScreenPercent) / CONTAINER_SCREEN_PERCENT;

	const minHeight =
		(CONTAINER_MIN_HEIGHT * elementScreenPercent) / CONTAINER_SCREEN_PERCENT;

	return css`
		max-height: ${maxHeight}px;
		min-height: ${minHeight}px;
	`;
}
