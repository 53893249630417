import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import icons
import { ReactComponent as IconList } from 'assets/icons/ic_list.svg';

// Import helpers
import { createLinkParams } from 'helpers/index';
import { useProductTranslations } from 'components/utilities';

const PlayerTop = ({ channel, isSiedebarVisible, isFocus }) => {
	if (!channel) return null;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { product } = useProductTranslations({ data: channel });

	const { title: channelName, epgProgrammes, currentLiveIndex } = product;

	if (!epgProgrammes[currentLiveIndex]) return null;

	const { uuid: id, title } = epgProgrammes[currentLiveIndex];

	return (
		<Top isFocus={isFocus}>
			<LinkStyled to={createLinkParams({ id, hash: 'live' })}>
				{title && `${channelName} ${title ? `- ${title}` : ''}`}
			</LinkStyled>
			{isSiedebarVisible && <IconListStyled />}
		</Top>
	);
};

const Top = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 2.6rem 2rem 2rem;
	background-image: linear-gradient(
		to top,
		transparent,
		rgba(0, 0, 0, 0.8) 50%,
		#000
	);
	z-index: 999;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	transition: all 0.3s ease-in-out;
`;

const LinkStyled = styled(Link)`
	color: ${({ theme }) => theme.white};
	font-weight: ${({ theme }) => theme.font.light};
	font-size: 2.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		color: ${({ theme }) => theme.white};
	}
`;

const IconListStyled = styled(IconList)`
	height: 3rem;
	width: 3rem;

	g {
		fill: ${({ theme }) => theme.white};
	}
`;

PlayerTop.propTypes = {
	channel: PropTypes.object,
	isSiedebarVisible: PropTypes.bool.isRequired,
	isFocus: PropTypes.bool.isRequired
};

const mapStateToProps = ({
	selected_program: { channelID },
	channels: {
		data: { lives }
	}
}) => ({
	channel: lives.find(({ uuid }) => uuid === channelID)
});

export default connect(mapStateToProps)(PlayerTop);
