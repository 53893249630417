import React from 'react';
import { withRouter } from 'react-router-dom';

// Import styles
import { FooterStyled } from './styles';

// Import components
import FooterNav from './FooterNav';
import FooterInfo from './FooterInfo';
import FooterStore from './FooterStore';

const Footer = () => {
	return (
		<FooterStyled>
			<FooterStyled.Content>
				<FooterNav />
				<FooterInfo />
				<FooterStore />
			</FooterStyled.Content>
		</FooterStyled>
	);
};

export default withRouter(Footer);
