import React from 'react';
import { number, array, func, oneOfType, bool, string } from 'prop-types';
import Slider from 'react-slick';

// Import helpers
import { DIRECTION_WAY_TYPES } from 'helpers/variables';

// Components
import { CustomArrow } from './CustomArrow';
import { Root, SliderContainer, ItemContainer } from './style';
import { ErrorBoundary } from 'components/elements';

// Logic
import { useLogic } from './useLogic';

const { RIGHT, LEFT } = DIRECTION_WAY_TYPES;

export function Basic(props) {
	const {
		renderItemComponent,
		renderHeader,
		headerSliderGap,
		itemsToShow,
		backgroundImage = false,
		spaceBetweenItems,
		isVODRecommendation
	} = props;
	const {
		containerRef,
		containerHeight,
		checkedHeaderHeight,
		containerWidth,
		itemAndSliderHeight,
		sliderWidth,
		itemWidth,
		sliderRef,
		sliderItems
	} = useLogic(props);

	const renderSliderItem = (data, index) =>
		renderItemComponent({
			data,
			height: itemAndSliderHeight,
			width: itemWidth,
			index
		});

	return (
		<Root
			ref={containerRef}
			height={containerHeight}
			header={checkedHeaderHeight}
			gridGap={headerSliderGap}
			backgroundImage={backgroundImage}
		>
			{!!containerWidth && (
				<>
					{!!renderHeader && renderHeader()}
					<SliderContainer
						height={itemAndSliderHeight}
						width={sliderWidth}
						spaceBetweenItems={spaceBetweenItems}
						isVODRecommendation={isVODRecommendation}
					>
						<Slider
							infinite={false}
							dots={false}
							rows={1}
							slidesToScroll={itemsToShow}
							slidesToShow={itemsToShow}
							initialSlide={0}
							nextArrow={<CustomArrow direction={RIGHT} />}
							prevArrow={<CustomArrow direction={LEFT} />}
							ref={sliderRef}
						>
							{sliderItems.map((item, index) => (
								<div key={`${item.uuid || item}-${index}`}>
									<ItemContainer>
										<ErrorBoundary>
											{renderSliderItem(item, index)}
										</ErrorBoundary>
									</ItemContainer>
								</div>
							))}
						</Slider>
					</SliderContainer>
				</>
			)}
		</Root>
	);
}

Basic.propTypes = {
	itemResolutionWidth: number.isRequired,
	itemResolutionHeight: number.isRequired,
	itemsList: array.isRequired,
	spaceBetweenItems: number.isRequired,
	horizontalPadding: number,
	verticalPadding: number,
	headerHeight: number,
	itemsToShow: number.isRequired,
	headerSliderGap: number,
	renderItemComponent: func.isRequired,
	renderHeader: func,
	backgroundImage: oneOfType([string, bool]),
	isVODRecommendation: bool,
	isCarousel: bool,
	shuffle: bool
};

Basic.defaultProps = {
	horizontalPadding: 40,
	verticalPadding: 24,
	headerHeight: 90,
	headerSliderGap: 0,
	backgroundImage: false,
	isVODRecommendation: false,
	isCarousel: false,
	shuffle: false
};
