import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	authenticate: null,
	isLogged: false,
	isAuthenticated: false,
	isDeviceLimited: false,
	deviceExists: false,
	accepetedAgreements: false,
	subscriberLocalActive: false,
	error: false,
	isLoaded: false,
	products: [],
	packets: {
		data: [],
		isLoaded: false,
		isError: false
	},
	authError: {
		message: '',
		name: '',
		isError: false,
		errorCode: null,
		status: 200
	},
	devices: {
		limitMessage: '',
		data: [],
		isLoaded: false,
		errorDevice: false
	},
	signOutError: false,
	signOutLoading: false,
	isUserDetailsLoading: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Authorize user *******************
			case types.AUTH_USER_LOADING:
				return { ...INITIAL_STATE };

			case types.AUTH_USER_SUCCESS:
				draft.authenticate = action.payload;
				draft.isAuthenticated = action.payload.isAuthenticated;
				draft.isDeviceLimited = action.payload.isDeviceLimited;
				draft.deviceExists = action.payload.deviceExists;
				draft.accepetedAgreements = action.payload.accepetedAgreements;
				draft.devices.limitMessage = action.payload.limitMessage;
				draft.isLogged = action.payload.isLogged;
				return;

			case types.AUTH_USER_ERROR:
				draft.authError.isError = true;
				draft.authError.name = action.payload.data.name || '';
				draft.authError.message = action.payload.data.message || '';
				draft.authError.status = action.payload.status;
				return;

			case types.AUTH_USER_APP_CHECK_TOKEN_ERROR:
				draft.authError.isError = true;
				draft.authError.message = action.payload?.message;
				return;

			// ******************* User details *******************
			case types.AUTH_USER_DETAILS_LOADING:
				draft.isUserDetailsLoading = true;
				return;

			case types.AUTH_USER_DETAILS_SUCCESS:
				draft.authenticate = action.payload;
				draft.subscriberLocalActive = action.payload.subscriberLocalActive;
				draft.isLoaded = true;
				draft.isUserDetailsLoading = false;
				return;

			case types.AUTH_USER_DETAILS_ERROR:
				draft.error = true;
				draft.isUserDetailsLoading = false;
				return;

			// ******************* User packets *******************
			case types.FETCH_USER_PACKETS_SUCCESS:
				draft.packets.isLoaded = true;
				draft.packets.data = action.payload.packets;
				draft.products = action.payload.products.data;
				return;

			case types.FETCH_USER_PACKETS_ERROR:
				draft.packets.isError = true;
				return;

			// ******************* Fetch user agreements *******************
			case types.AUTH_UPDATE_AGREEMENTS_SUCCESS:
				draft.isLogged = true;
				return;

			case types.AUTH_UPDATE_AGREEMENTS_ERROR:
				draft.authError.status = action.payload.status;
				draft.authError.isError = true;
				draft.authError.errorCode = action.payload.errorCode;
				return;

			// ******************* Fetch user devices *******************
			case types.AUTH_FETCH_USER_DEVICES_LOADING:
				draft.devices.isLoaded = false;
				return;

			case types.AUTH_FETCH_USER_DEVICES_SUCCESS:
				draft.devices.isLoaded = true;
				draft.devices.data = action.payload;
				return;

			case types.AUTH_FETCH_USER_DEVICES_ERROR:
				draft.devices.errorDevice = true;
				draft.authError.status = action.payload.status;
				draft.authError.isError = true;
				draft.authError.errorCode = action.payload.errorCode;
				return;

			// ******************* Update user device *******************
			case types.AUTH_UPDATE_USER_DEVICES_SUCCES:
				draft.isDeviceLimited = !action.payload;
				draft.deviceExists = action.payload;
				draft.isLogged = draft.accepetedAgreements;
				return;

			case types.AUTH_UPDATE_USER_DEVICES_ERROR:
				draft.devices.errorDevice = true;
				draft.authError.status = action.payload.status;
				draft.authError.isError = true;
				draft.authError.name = action.payload.data.name || '';
				draft.authError.message = action.payload.data.message || '';
				return;

			// ******************* Update user device *******************
			case types.AUTH_USER_CLEAR:
				return { ...INITIAL_STATE };

			// ******************* Set unavailable channels *******************
			case types.SET_USER_UNAVAILABLE_CHANNELS_SUCCESS:
				draft.authenticate.hideUnavailable = action.payload;
				return;

			case types.SET_USER_UNAVAILABLE_CHANNELS_ERROR:
				draft.error = true;
				return;

			// ******************* Set user in local  *******************
			case types.SET_USER_IN_LOCAL:
				draft.authenticate.subscriber_local_active = action.payload;
				return;

			// ******************* Set unavailable channels *******************
			case types.ENABLE_USER_PARENTAL_CONTROL:
				draft.authenticate.parentalControlEnabled =
					action.payload.parentalControlEnabled;
				draft.authenticate.rating = action.payload.rating;
				return;

			// ******************* Sign out *******************
			case types.SIGN_OUT_LOADING:
				draft.signOutLoading = true;
				return;

			case types.SIGN_OUT:
				return { ...INITIAL_STATE };

			case types.SIGN_OUT_ERROR:
				draft.signOutLoading = false;
				draft.signOutError = true;
				return;

			default:
				return state;
		}
	});
