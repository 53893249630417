import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { NavLink as RouterNavLink, Link as RouterLink } from 'react-router-dom';

// Import utilties
import {
	customResponsive,
	customMinResponsive,
	rtl,
	flex
} from 'components/styles';

const LIST_MODIFIERS = {
	searchPage: () => css`
		padding-top: 0.8rem;
	`
};

const ITEM_MODIFIERS = {
	searchPage: () => css`
		padding: 0 1.6rem;
	`
};

const NAV_LINK_MODIFIERS = {
	searchPage: ({ theme }) => css`
		&:link,
		&:visited {
			box-sizing: content-box;
			height: 2.7rem;
			width: 6rem;
			${flex()};
			font-size: 1.8rem;
			font-weight: ${theme.font.regular};
		}

		&.active-nav {
			&:link,
			&:visited {
				border-bottom: 0.3rem solid
					${theme.searchPage.searchNav.activeItemBorder};
			}
		}
	`
};

const linkStyle = css`
	&:link,
	&:visited {
		position: relative;
		display: flex;
		align-items: center;
		padding: 1.5rem 1.3rem;
		color: ${({ theme }) => theme.nav.color};
		font-size: 1.6rem;
		font-weight: ${({ theme }) => theme.font.medium};
		transition: all 0.3s ease-in-out;
	}

	&.active-nav {
		&:link,
		&:visited {
			color: ${({ theme: { nav } }) => nav.menuActiveText};
		}
	}
	&:hover {
		color: ${({ theme: { nav } }) => nav.menuActiveText};
	}
`;

const List = styled.ul`
	list-style: none;
	display: flex;
  ${rtl`
    direction: ltr;
  `}

${({ isMainNavigation }) =>
	isMainNavigation &&
	css`
		width: 90%;
		display: flex;
		justify-content: center;
		margin: 0 auto;

		&:link,
		&:visited {
			padding: 1.5rem 2.8rem;
		}

		&.active-nav {
			&:link,
			&:visited {
				color: ${({ theme }) => theme.white};
			}
		}
	`}

	${({ dropdown }) =>
		dropdown &&
		css`
			${customResponsive(
				1200,
				css`
					display: none;
				`
			)}
		`}

	${applyStyleModifiers(LIST_MODIFIERS)};
`;

const Item = styled.li`
	display: flex;

	${applyStyleModifiers(ITEM_MODIFIERS)};
`;

const NavLink = styled(RouterNavLink)`
	${linkStyle}

  ${({ isMainNavigation }) =>
		isMainNavigation &&
		css`
			&:link,
			&:visited {
				padding: 1.5rem 1.6rem;
				width: max-content;

				${({ isRtl }) =>
					isRtl &&
					css`
						padding: 1.5rem 0.6rem;
					`};

				${customMinResponsive(
					1300,
					css`
						padding: 1.5rem 2rem;

						${({ isRtl }) =>
							isRtl &&
							css`
								padding: 1.5rem 1.4rem;
							`};
					`
				)}

				${customMinResponsive(
					1500,
					css`
						padding: 1.5rem 2.8rem;
					`
				)}
			}
		`}

	${({ inverse }) =>
		inverse &&
		css`
			&.active-nav {
				&:link,
				&:visited {
					color: ${({ theme }) => theme.white};
				}
			}
		`}

	${applyStyleModifiers(NAV_LINK_MODIFIERS)};
`;
const Link = styled(RouterLink)`
	${linkStyle}
`;

export { List, Item, NavLink, Link };
