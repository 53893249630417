import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers;
import {
	checkPosterUrl,
	createLinkParams,
	convertTime,
	convertDateFormat
} from 'helpers/index';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

// Import components
import { Masonry, Paragraph } from 'components/elements';

const MasonryCommon = ({
	element: {
		images,
		title,
		short_desc,
		uuid: id,
		slides,
		parent_uuid,
		context: { till, since }
	},
	index,
	adjustHeight
}) => {
	const { t } = useTranslation();
	const { lives } = useSelector(({ channels }) => channels.data);
	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = checkPosterUrl({ images, slides });

	const linkDetails = createLinkParams({ id, hash: 'program' });
	const channel = lives.find(({ uuid }) => uuid === parent_uuid);

	const currentDay = new Date().getTime();
	const startDay = new Date(since.replace(/-/g, '/')).getTime();
	const isUpcommingProgram =
		startDay > currentDay
			? `${convertDateFormat(since)} | `
			: t('common_general_today');

	return (
		<Masonry.Item height={adjustHeight(index)}>
			<Masonry.Wrapper>
				{isImgUrlValid && (
					<ImageOnLoadRPC>
						{({ setError }) => (
							<Masonry.Img onError={setError} src={imgUrl} alt={title} />
						)}
					</ImageOnLoadRPC>
				)}
			</Masonry.Wrapper>
			<Masonry.Link
				onClick={(e) => !channel && e.preventDefault()}
				to={linkDetails}
			>
				<Masonry.Mask>
					<Masonry.Content>
						<Masonry.Info>
							<Paragraph.Ellipsis>
								{channel && channel.title} | {isUpcommingProgram}{' '}
								{convertTime(since, till)}
							</Paragraph.Ellipsis>
							<Paragraph.Ellipsis>{title}</Paragraph.Ellipsis>
						</Masonry.Info>
						<Masonry.DescriptionBox>
							<Masonry.Description>{short_desc}</Masonry.Description>
						</Masonry.DescriptionBox>
					</Masonry.Content>
				</Masonry.Mask>
			</Masonry.Link>
		</Masonry.Item>
	);
};

MasonryCommon.propTypes = {
	element: PropTypes.shape({
		parent_uuid: PropTypes.string.isRequired,
		uuid: PropTypes.string.isRequired,
		images: PropTypes.object,
		title: PropTypes.string.isRequired,
		short_desc: PropTypes.string.isRequired,
		slides: PropTypes.string,
		context: PropTypes.shape({
			since: PropTypes.string,
			till: PropTypes.string
		}).isRequired
	}).isRequired,
	index: PropTypes.number.isRequired,
	adjustHeight: PropTypes.func.isRequired
};

export default MasonryCommon;
