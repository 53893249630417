import React from 'react';
import { useStore, useDispatch } from 'react-redux';
import { updateCurrentLives } from 'store/actions';

// Import variables
import { BROWSER_WAKE_UP_INTERVAL } from 'helpers/variables';

const useBrowserWakeRefreshPrograms = () => {
	const store = useStore();
	const dispatch = useDispatch();
	/*eslint-disable */
	React.useEffect(() => {
		const intervalTime = BROWSER_WAKE_UP_INTERVAL;
		let lastTime = new Date().getTime();

		const wake = setInterval(() => {
			const currentTime = new Date().getTime();
			if (currentTime > lastTime + intervalTime * 2) {
				// If browser wakes up
				// Dispatch an updateCurrentLives action in channels folder
				updateCurrentLives()(dispatch, store.getState);
			}
			lastTime = currentTime;
		}, intervalTime);

		return () => clearInterval(wake);
	}, []);
	/*eslint-enable */
};

export default useBrowserWakeRefreshPrograms;
