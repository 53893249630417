import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { AnchorStyled } from './styles';

const AnchorTransparent = ({ children, href, ...props }) => (
	<AnchorStyled href={href} {...props}>
		{children}
	</AnchorStyled>
);

AnchorTransparent.propTypes = {
	href: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	className: PropTypes.string
};

AnchorTransparent.defaultProps = {
	width: 238,
	height: 59
};

export default AnchorTransparent;
