import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isSuccess: false,
	isLoaded: false,
	isError: false,
	loading: false, //added to easier maintain button disabling
	voucher: '',
	errorMessage: '',
	options: undefined,
	paymentMethod: undefined
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Submit purchase *******************
			case types.PURCHASE_LOADING:
				draft.isSuccess = false;
				draft.isLoaded = false;
				draft.isError = false;
				draft.loading = true;
				return;

			case types.PURCHASE_SUCCESS:
				draft.options = action.payload.data;
				draft.paymentMethod = action.payload.paymentMethod;
				draft.isSuccess = true;
				draft.isLoaded = true;
				draft.loading = false;
				return;

			case types.PURCHASE_ERROR:
				draft.isError = true;
				draft.isLoaded = true;
				draft.errorMessage = action.payload.response.data.message;
				draft.loading = false;
				return;

			// ******************* Clear purchase *******************
			case types.PURCHASE_CLEAR:
				return INITIAL_STATE;

			case types.PURCHASE_CLEAR_ERROS:
				draft.isError = false;
				break;

			default:
				return state;
		}
	});
