export const fonts = {
	font: {
		light: 300,
		normal: 400,
		regular: 500,
		semiMedium: 600,
		medium: 700,
		bold: 900
	}
};
