import { useEffect, useRef, useReducer } from 'react';

// IMPORT TYPES, INITIAL_STATE, REDUCER
import {
	TYPES,
	initialState,
	sliderReducer,
	percent,
	getShownPercent
} from './helpers';

import { useContextProvider } from 'components/views/vod/components/PlayerWrapper/useContextProvider';

export function useSlider(duration, updateTime, currentTimeProgress) {
	const [state, dispatch] = useReducer(sliderReducer, initialState);
	const prev = useRef({});
	const containerRef = useRef(null);
	const pointRef = useRef(null);

	const { play, pause } = useContextProvider();

	// this function starts dragging action
	const mouseDown = (event) => {
		const { offsetLeft } = pointRef.current;
		const { offsetWidth } = containerRef.current;

		prev.current = {
			clientX: event.clientX,
			maxInLeft: offsetLeft,
			maxInRight: offsetWidth - offsetLeft
		};

		pause();

		dispatch({ type: TYPES.SET_DRAGGABLE, payload: true });
	};

	const mouseMove = (event) => {
		const { draggable, contWidth } = state;

		if (draggable) {
			const shownPercent = getShownPercent(event.clientX, contWidth, prev);

			updateTime((duration * shownPercent) / 100);

			// set shown percent
			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: shownPercent });
		}
	};

	const mouseUp = (event) => {
		const { draggable, contWidth } = state;
		if (draggable) {
			const shownPercent = getShownPercent(event.clientX, contWidth, prev);

			play();

			// set translateX
			dispatch({ type: TYPES.SET_TRANSLATE_X, payload: 0 });
			// set draggable
			dispatch({ type: TYPES.SET_DRAGGABLE, payload: false });
			// set shown percent
			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: shownPercent });
		}
	};

	const click = (event) => {
		const { left } = event.target.getBoundingClientRect();
		const { contWidth, draggable } = state;
		const newLeftPix = event.clientX - left;
		const newLeftPercent = percent(newLeftPix, contWidth);
		const { offsetWidth } = containerRef.current;

		prev.current = {
			clientX: event.clientX,
			maxInLeft: newLeftPix,
			maxInRight: offsetWidth - newLeftPix
		};

		if (draggable) {
			pause();
		}

		dispatch({ type: TYPES.SET_DRAGGABLE, payload: true });

		updateTime((duration * newLeftPercent) / 100);

		// set shown percent
		dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
	};

	const updateContainerData = () => {
		if (containerRef.current) {
			// set left percent
			const { offsetWidth } = containerRef.current;
			dispatch({ type: TYPES.SET_CONT_WIDTH, payload: offsetWidth });
		}
	};

	const onContainerMouseMove = ({ clientX }) => {
		const container = containerRef.current;
		const { left } = container.getBoundingClientRect();
		const cursorPosition = (clientX - left) / container.offsetWidth;
		// set cursor position
		dispatch({ type: TYPES.SET_CURSOR_POSITION, payload: cursorPosition });
	};

	const setPreviewVisible = (isVisible = false) => {
		dispatch({ type: TYPES.SET_PREVIEW_VISIBLE, payload: isVisible });
	};

	const setPointVisible = (isVisible = false) => {
		dispatch({ type: TYPES.SET_POINT_VISIBLE, payload: isVisible });
	};

	useEffect(() => {
		window.addEventListener('mousemove', mouseMove);
		window.addEventListener('mouseup', mouseUp);
		window.addEventListener('resize', updateContainerData);
		return () => {
			window.removeEventListener('mousemove', mouseMove);
			window.removeEventListener('mouseup', mouseUp);
			window.removeEventListener('resize', updateContainerData);
		};
	});

	useEffect(() => {
		updateContainerData();
	}, []);

	useEffect(() => {
		currentTimeProgress((currentTime) => {
			const newLeftPercent = percent(currentTime, duration);
			// set shown percent
			dispatch({ type: TYPES.SET_SHOWN_PERCENT, payload: newLeftPercent });
		});
		// eslint-disable-next-line
	}, []);

	return {
		isPointVisible: state.isPointVisible,
		mouseDown,
		click,
		containerRef,
		pointRef,
		onContainerMouseMove,
		setPreviewVisible,
		setPointVisible,
		...state
	};
}
