import styled from 'styled-components';

// Import utilities
import { flex } from 'components/styles';

export const Wrapper = styled.div`
	height: 6rem;
	width: 90%;
	left: 0;
	right: 0;
	margin: 0 auto;
	position: absolute;
	bottom: 4.5rem;
	z-index: 999;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	align-items: center;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: opacity 0.3s ease-in-out;
	direction: ltr;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 3.5rem;

	& button {
		height: 2.7rem;
		width: 2.7rem;
	}
`;

export const InnerContainer = styled.div`
	${flex()}
	column-gap: 3rem;
`;

export const ControlsContainer = styled.div`
	width: 40%;
	display: grid;
	align-items: center;
	justify-items: center;
	grid-template-columns: repeat(5, 1fr);
`;

export const SettingsContainer = styled.div`
	${flex({ jc: 'flex-start' })}
`;

export const Timer = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1.9rem;
	padding: 0 0 0.3rem;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme }) => theme.player.timer.color};
	visibility: ${({ showTime }) => (showTime ? 'visible' : 'hidden')};
`;
