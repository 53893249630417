// Import error codes
import { ERROR_CODE_API_TYPES } from 'helpers/error_code_api';

// Import translation
import i18n from 'i18next';

/**
 * Helper function that properly recoqnizes request exception error and
 * sets proper app errors based on it (both in formik and redux | UI).
 * @param {object} error - error from api
 * @param {object} resources - initial resources, that will be changed and returned
 * @param {function} setErrors - Formik function to set errors in form
 * @returns {object} resources - Returns modified resources object
 */
export const checkAuthorizeLoginCodeErrors = (error, resources, setErrors) => {
	const {
		response: {
			data: { errorCode }
		}
	} = error;

	// Check what type of exeption is returned and set proper type for reducer
	if (errorCode === ERROR_CODE_API_TYPES.SUBSCRIBER_LOGIN_CODE_EXPIRED) {
		resources.isCodeExpired = true;
		// Set Formik error
		setErrors({
			code: i18n.t('user_profile_devices_login_with_code_error')
		});
	} else if (
		errorCode === ERROR_CODE_API_TYPES.DEVICE_LIMIT_EXCEEDED_EXCEPTION
	) {
		resources.isDevicesExceeded = true;
	} else if (
		errorCode === ERROR_CODE_API_TYPES.DEVICE_CHANGES_LIMIT_EXCEEDED_EXCEPTION
	) {
		resources.isChangesExceeded = true;
		// Set Formik error
		setErrors({
			code: i18n.t(
				'user_profile_devices_login_with_code_form_errors_devices_changes_limit'
			)
		});
	} else {
		// set generic error
		setErrors({
			code: i18n.t('user_profile_devices_login_with_code_form.errors_generic')
		});
	}

	return resources;
};
