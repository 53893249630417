import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ar-sa';
import { format } from 'date-fns';

// Set local language
moment.locale();

export const setLocaleDate = (date, language) => {
	if (language) {
		return moment(date).locale(language);
	}

	return moment(date).locale('en-gb');
};

export const convertProgramTimeFromUTC = ({ since, till }) => {
	const [dateSince, timeSince] = since.split(/\s/g);
	const [dateTill, timeTill] = till.split(/\s/g);

	const zone = '.000Z';
	const formatDate = 'YYYY-MM-DD HH:mm:ss';

	const sinceUTC = new Date(`${dateSince}T${timeSince}${zone}`);
	const tillUTC = new Date(`${dateTill}T${timeTill}${zone}`);

	const programSince = format(sinceUTC, formatDate);
	const programTill = format(tillUTC, formatDate);

	return { since: programSince, till: programTill };
};
