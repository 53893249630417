import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import icons
import { ReactComponent as IconBasketSVG } from 'assets/icons/ic_basket.svg';
import { ReactComponent as IconLockSVG } from 'assets/icons/ic_lock_second.svg';

// Import styles
import { flex, borderGradientTransparent } from 'components/styles';

// API-URL
const { REACT_APP_API_URL } = process.env;

export const Wrapper = styled.div`
	position: relative;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	border-radius: ${({ theme }) => theme.vodDetails.episodes.card.roundSize}rem;
	padding: 0.3rem;
	${flex()}

	${({ isActive, theme }) =>
		isActive &&
		css`
			filter: ${theme.vodDetails.episodes.card.shadow};

			${borderGradientTransparent({
				width: 2,
				gradient: theme.vodDetails.episodes.card.border
			})}
		`}
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	background: url('${REACT_APP_API_URL}/assets/${({ id }) => id}/poster') black;
	background-size: cover;
	border-radius: inherit;
	${flex()};
`;

export const Info = styled.div`
	background-color: ${({ theme }) => theme.vodDetails.episodes.card.shadowBg};
	height: 100%;
	width: 100%;
	padding: 3rem;
	color: ${({ theme }) => theme.vodDetails.episodes.card.title};
	${flex()}
	flex-direction: column;
	text-align: center;
`;

export const EpisodeNumber = styled.span`
	font-size: 1.8rem;
`;

export const Title = styled.div`
	font-size: 1.4rem;
	font-weight: 300;
	color: ${({ theme }) => theme.vodDetails.episodes.card.description};
`;

export const IconBasket = styled(IconBasketSVG)`
	height: 6vh;
`;

export const IconLock = styled(IconLockSVG)`
	height: 6rem;
	width: 6rem;
`;

export const StyledLink = styled(Link)`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Button = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	width: 100%;
	height: 100%;
`;
