import React from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import firebase from 'firebase/compat/app';

// Import heleprs
import { TRACKING_GTM_MANAGER } from 'helpers/variables';
import { getTrackingOptions } from './helpers';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTM_ID
};

const options = {
	autoConfig: true,
	debug: false
};

const { PAGE_VIEW, PAGE_VIEW_PIXEL } = TRACKING_GTM_MANAGER;

const useTracking = () => {
	const { pathname } = useLocation();

	React.useEffect(() => {
		TagManager.initialize(tagManagerArgs);
		ReactPixel.init(process.env.REACT_APP_FB_PIXEL, {}, options);
	}, []);

	React.useEffect(() => {
		ReactPixel.pageView();
		ReactPixel.fbq('track', PAGE_VIEW_PIXEL);

		const options = getTrackingOptions(pathname);

		window.dataLayer.push({
			event: PAGE_VIEW,
			pathname,
			...options,
			eventProps: {}
		});

		firebase.analytics().logEvent(PAGE_VIEW, { name: pathname, ...options });
	}, [pathname]);
};

export default useTracking;
