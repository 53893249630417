import styled, { css } from 'styled-components';

const baseTagStyle = css`
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
`;

export const HiddenHeadingFirst = styled.h1`
	${baseTagStyle}
`;

export const HiddenHeadingSecond = styled.h2`
	${baseTagStyle}
`;

export const HiddenParagraph = styled.p`
	${baseTagStyle}
`;
