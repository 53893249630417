import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useVodProgress = ({ id, duration, isEpisode = false }) => {
	const [progress, setProgress] = useState(0);
	const { data, continueProgress } = useSelector(
		({ continue_watching }) => continue_watching
	);

	useEffect(() => {
		const movie = isEpisode
			? data.find(({ uuid }) => uuid === id)
			: continueProgress;

		if (movie?.ended) {
			setProgress(100);
		} else if (movie?.time >= 4) {
			const percent = Math.ceil((movie.time / duration) * 100);
			setProgress(percent);
		}
	}, [data, id, duration, continueProgress, isEpisode]);

	return progress;
};

export default useVodProgress;
