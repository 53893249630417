import {
	DIRECTION_TYPES,
	LANGUAGES_TYPES,
	LOCAL_STORAGE_LANGUAGE
} from 'helpers/variables';

export const getDirection = () => {
	const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

	const isRtl = language === LANGUAGES_TYPES.AR;

	const direction = isRtl ? DIRECTION_TYPES.RTL : DIRECTION_TYPES.LTR;

	return { isRtl, direction };
};
