import * as types from 'store/actions/types';
import services from 'services/services';

// Import translation
import i18n from 'i18next';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { PAYMENT_METHODS } from 'helpers/prices';
import { openNotification } from 'components/utilities/notification/notification';

const { SUBSCRIPTION_CHECKOUT } = PAYMENT_METHODS;

// ******************** SUBMIT PRODUCT PURCHASE ********************
export const submitProductPurchase = (
	{
		pin = '',
		productId,
		priceId,
		promotion = null,
		paymentMethod,
		user_payment_data_id = null,
		gateway_type = null,
		isSubscriptionUpdate,
		need_invoice = false,
		client = null,
		type
	},
	callbackSuccess
) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.PURCHASE_LOADING,
			payload: true
		});

		let url = `/payments/${type}/subscription/${productId}/${priceId}/subscribe`;

		const voucherCode = promotion?.voucherCode ?? '';
		const options = {
			data: { pin, code: voucherCode },
			need_invoice,
			client
		};

		if (user_payment_data_id) {
			options.user_payment_data_id = user_payment_data_id;
		}

		if (isSubscriptionUpdate) {
			options.update_subscription = true;
		}

		if (gateway_type) {
			options.data.gateway_type = gateway_type;
		}

		if (promotion?.id) {
			url += `?promotion_id=${promotion.id}`;
		}

		const { data } = await services.post(url, options);

		// Dispatch an action
		dispatch({
			type: types.PURCHASE_SUCCESS,
			payload: { data, paymentMethod }
		});
		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.PURCHASE_ERROR,
			payload: error
		});
	}
};

// ******************** SUBMIT PRODUCT PURCHASE BY CHECKOUT ********************
export const submitProductPurchaseByCheckout = (
	{ productId, priceId, card_token, promotion = null },
	callbackSuccess
) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.PURCHASE_LOADING,
			payload: true
		});

		let url = `/payments/checkout/subscription/${productId}/${priceId}/subscribe`;

		if (promotion?.id) {
			url += `?promotion_id=${promotion.id}`;
		}

		const options = {
			card_token
		};

		const { data } = await services.post(url, options);

		// Dispatch an action
		dispatch({
			type: types.PURCHASE_SUCCESS,
			payload: { data, paymentMethod: SUBSCRIPTION_CHECKOUT }
		});

		openNotification({
			title: i18n.t('payment_pay_with_credit_card_success'),
			type: 'success'
		});

		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.PURCHASE_ERROR,
			payload: error
		});
	}
};

export const clearPurchase = (dispatch) =>
	dispatch({ type: types.PURCHASE_CLEAR });

export const clearPurchaseErrors = (dispatch) =>
	dispatch({ type: types.PURCHASE_CLEAR_ERROS });
