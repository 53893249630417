import React, { useState } from 'react';

const useResize = () => {
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

	React.useEffect(() => {
		setViewportHeight(document.body.scrollHeight);
	}, []);

	return { viewportHeight };
};

export default useResize;
