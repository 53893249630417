import React from 'react';
import { number } from 'prop-types';

// Import styles
import { BaseStrap, LoadedStrap } from 'components/elements/atoms/Srap/styles';

const Strap = ({ percent = 100 }) => (
	<BaseStrap>
		<LoadedStrap percent={percent} />
	</BaseStrap>
);

Strap.propTypes = {
	percent: number
};

export default Strap;
