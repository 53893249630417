import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { LoaderWrapper, LoaderItem } from './styles';

const Loader = ({
	width = 15,
	height = 15,
	position = 'fixed',
	zIndex = 1200,
	wrapperHeight = '100%',
	top = 0,
	modifiers
}) => {
	return (
		<LoaderWrapper
			zIndex={zIndex}
			position={position}
			wrapperHeight={wrapperHeight}
			top={top}
			data-testid="loader"
			modifiers={modifiers}
		>
			<LoaderItem width={width} height={height} />
		</LoaderWrapper>
	);
};

Loader.propTypes = {
	position: PropTypes.string,
	zIndex: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	wrapperHeight: PropTypes.string,
	top: PropTypes.number,
	modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Loader;
