import styled from 'styled-components';

// Import styles
import { borderGradientTransparent, flex, rtl } from 'components/styles';

export const CodeFieldStyled = styled.div`
	position: relative;
	width: 14.9rem;
	height: 3.5rem;
	${flex()}
	${rtl`margin-right: 1rem;`};
	border-radius: ${({ theme }) => theme.user.device_login.field.roundSize}rem;
	border: none;
	padding-bottom: 0.2rem;
	${({ theme }) =>
		borderGradientTransparent({
			width: 1,
			gradient: theme.user.device_login.field.border
		})}
`;

export const StyledInput = styled.input`
	width: 90%;
	text-align: center;
	color: ${({ theme, error }) =>
		error
			? theme.user.device_login.input.colorError
			: theme.user.device_login.input.color};
	font-size: 1.6rem;
	background: none;
	outline: none;
	border: 0;
	border-bottom: 1px solid
		${({ theme }) => theme.user.device_login.input.border};
`;
