import React from 'react';
import ReactDOM from 'react-dom';

import 'babel-polyfill';
import 'whatwg-fetch';

// Import translations
import './i18n';

// Import vendors
import './vendors/vendor_styles';

// Import root component
import Root from './Root';

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}

ReactDOM.render(<Root />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/firebase-messaging-sw.js')
		.then(function(registration) {
			console.log('Registration successful, scope is:', registration.scope);
		})
		.catch(function(err) {
			console.log('Service worker registration failed, error:', err);
		});
}
