import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

// Import styles
import { Root, BottomChecker, Item, ButtonContainer } from './styles';

export function PlayerStickyButtons({ children }) {
	const bottomCheckerRef = useRef(null);
	const [bottom, setBottom] = useState(0);

	const calcButtonsPosition = () => {
		const rect = bottomCheckerRef.current?.getBoundingClientRect();
		const top = rect?.top;

		const difference = window.innerHeight - 1;

		if (top > difference) {
			setBottom(top - difference);
		} else {
			setBottom(0);
		}
	};

	const throttleCalcButtonsPosition = throttle(calcButtonsPosition, 20);

	useEffect(() => {
		bottomCheckerRef?.current && calcButtonsPosition();
	}, [bottomCheckerRef]);

	useEffect(() => {
		if (bottomCheckerRef?.current) {
			window.addEventListener('scroll', throttleCalcButtonsPosition);
			window.addEventListener('resize', throttleCalcButtonsPosition);
		}

		return () => {
			window.removeEventListener('scroll', throttleCalcButtonsPosition);
			window.removeEventListener('resize', throttleCalcButtonsPosition);
		};
	});

	return (
		<Root>
			<ButtonContainer>
				<Item bottom={bottom} id="tv-player-buttons-container">
					{children}
				</Item>
				<BottomChecker ref={bottomCheckerRef} />
			</ButtonContainer>
		</Root>
	);
}
