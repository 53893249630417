import { useRef, useEffect, useState, useMemo } from 'react';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';

// Import hooks
import { useSectionFocus } from 'components/utilities';

const useBannerLogic = ({ id }) => {
	const containerRef = useRef(null);
	const [bannerHeight, setBannerHeight] = useState(0);

	const { isRtl } = useSelector(({ direction }) => direction);

	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	// multiplication container width * 20.84% to get banner height
	// depending on the browser window width
	const handleSetBannerHeight = () => {
		if (containerRef.current) {
			const height = (containerRef.current.offsetWidth * 20.84) / 100;
			setBannerHeight(height);
		}
	};

	const throttleHandleResize = throttle(handleSetBannerHeight, 1000);

	useEffect(() => {
		handleSetBannerHeight();
	}, [containerRef]);

	useEffect(() => {
		window.addEventListener('resize', throttleHandleResize);

		return () => {
			window.removeEventListener('resize', throttleHandleResize);
		};
	});

	const settings = useMemo(
		() => ({
			dots: false,
			infinite: true,
			speed: 500,
			autoplaySpeed: 6000,
			swipe: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			adaptiveHeight: true,
			rtl: isRtl
		}),
		[isRtl]
	);

	return {
		containerRef,
		bannerHeight,
		settings,
		saveSectionId,
		restorationRef
	};
};

export default useBannerLogic;
