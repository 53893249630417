import React from 'react';
import { string } from 'prop-types';

// Import helpers
import { phoneCodes } from 'helpers/phone_codes';

// Import components
import { DynamicIcon } from 'components/elements';

// Import styles
import { CountryFlagWrapper } from './styles';

const CountryFlag = ({ countryCode }) => {
	const { name } = phoneCodes.find(({ iso2 }) => iso2 === countryCode);

	return (
		<CountryFlagWrapper>
			<DynamicIcon icon={name} isCountryIcon />
		</CountryFlagWrapper>
	);
};

CountryFlag.propTypes = {
	countryCode: string.isRequired
};

export default CountryFlag;
