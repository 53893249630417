import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PaymentField = ({
	type = 'text',
	label,
	passwordClassName,
	field,
	form: { touched, errors },
	placeholder,
	disabled = false,
	isFocus = false
}) => {
	const inputRef = React.createRef(null);

	React.useEffect(() => {
		// Set focus
		isFocus && inputRef.current.focus();
		// eslint-disable-next-line
	}, [isFocus]);

	return (
		<FormFieldStyled>
			<Label>{label}</Label>
			<Input
				ref={inputRef}
				className={`${passwordClassName && passwordClassName} ${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				{...field}
				onChange={(event) =>
					event.target.value.length < 5 && field.onChange(field.name)(event)
				}
			/>
		</FormFieldStyled>
	);
};

const FormFieldStyled = styled.div`
	margin-top: 2.8rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Label = styled.label`
	color: ${({ theme }) => theme.payment.paymentOptions.paymentField.labelColor};
	font-size: 1.5rem;
	margin-bottom: 2.4rem;
	letter-spacing: 0.3px;
`;

const Input = styled.input`
	width: 16rem;
	border: 1px solid transparent;
	border-bottom: 1px solid
		${({ theme }) => theme.payment.paymentOptions.paymentField.borderColor};
	text-align: center;
	background: transparent;
	color: ${({ theme }) => theme.payment.paymentOptions.paymentField.color};
	font-size: 7rem;
	padding-bottom: 2.5rem;
	height: 3.5rem;
	letter-spacing: 3.5px;

	&:focus {
		outline: none;
	}

	&.errors {
		border-bottom: 1px solid
			${({ theme }) =>
				theme.payment.paymentOptions.paymentField.errorBorderColor};
	}

	&.mask {
		font-family: 'text-security-disc';
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) =>
			theme.payment.paymentOptions.paymentField.placeholderColor};
	}
`;

PaymentField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	passwordClassName: PropTypes.string,
	isFocus: PropTypes.bool,
	disabled: PropTypes.bool
};

export default PaymentField;
