import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Import variables
import { DIRECTION_TYPES } from 'helpers/variables';
import { CAROUSEL_START_TIMEOUT, CAROUSEL_STOP_TIMEOUT } from './helpers';

// Import helpers
import { shuffledArray } from 'helpers';

// Import hooks
import { useSectionFocus } from 'components/utilities';

export function useCarousel({ itemsList, shuffle, id }) {
	const timeoutRef = useRef({});

	const { direction } = useSelector((state) => state.direction);
	const isRtl = direction === DIRECTION_TYPES.RTL;

	const movieListLength = itemsList.length - 1;
	const currentIndexInitial = isRtl ? movieListLength : 0;
	const [currentIndex, setCurrentIndex] = useState(currentIndexInitial);
	const [stopped, setStopped] = useState(false);
	const [sliderItems, setSliderItems] = useState([]);

	const { saveSectionId, restorationRef } = useSectionFocus({ id });

	const nextElement = () => {
		let nextIndex = isRtl ? currentIndex - 1 : currentIndex + 1;
		if (movieListLength !== 0) {
			if (isRtl) {
				if (nextIndex <= 0) nextIndex = movieListLength;
				setCurrentIndex(nextIndex);
			} else {
				if (nextIndex === movieListLength) nextIndex = 0;
				setCurrentIndex(nextIndex);
			}
		}
	};

	const changeCurrentIndex = (index) => {
		clearTimeout(timeoutRef.current);
		setCurrentIndex(index);
	};

	const stopStartCarousel = () => {
		if (stopped) {
			timeoutRef.current = setTimeout(() => {
				nextElement();
			}, CAROUSEL_STOP_TIMEOUT);
		} else {
			clearTimeout(timeoutRef.current);
		}
		setStopped(!stopped);
	};

	const startCarousel = () => {
		timeoutRef.current = setTimeout(() => {
			nextElement();
			setStopped(false);
		}, CAROUSEL_START_TIMEOUT);
	};

	useEffect(() => {
		const sliderItems = shuffle ? shuffledArray(itemsList) : itemsList;

		setSliderItems(sliderItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		timeoutRef.current = setTimeout(() => {
			nextElement();
			setStopped(false);
		}, CAROUSEL_START_TIMEOUT);

		return () => clearTimeout(timeoutRef.current);
		// eslint-disable-next-line
	}, [currentIndex]);

	return {
		currentIndex,
		changeCurrentIndex,
		stopStartCarousel,
		startCarousel,
		isRtl,
		sliderItems,
		saveSectionId,
		restorationRef
	};
}
