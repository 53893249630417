import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Heading } from 'components/elements';

export const BenefitsWrapper = styled.div`
	${flex({ fd: 'column' })};
	width: 100%;
	padding: ${({ isUserProfile }) => (isUserProfile ? '0' : '0 5rem')};
`;

export const BenefitsHeading = styled(Heading.First)`
	font-size: 1.9rem;
	margin-top: 0;
	margin-bottom: 7rem;
	font-weight: ${({ theme }) => theme.font.semiMedium};
	color: ${({ theme }) => theme.user.subscriptions.benefitsHeading};
`;

export const BenefitsGrid = styled.div`
	direction: ${({ direction }) => direction};
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	gap: 4rem 6rem;
	grid-auto-rows: ${({ isUserProfile }) => (isUserProfile ? 15 : 10)}rem;
`;

export const Benefit = styled.div`
	${flex({ jc: 'space-between', fd: 'column' })};
`;

export const BenefitText = styled.p`
	font-weight: ${({ theme }) => theme.font.semiMedium};
	font-size: 1.3rem;
	line-height: 1.8rem;
	text-align: center;
	color: ${({ theme }) => theme.user.subscriptions.defaultTextColor};
	min-height: 3.6rem;
	${({ isNonSubscriber }) =>
		isNonSubscriber &&
		css`
			margin-top: 2rem;
		`}
`;
