import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { ERROR_CODE_API_TYPES } from 'helpers/error_code_api';

const { MISSING_EMAIL } = ERROR_CODE_API_TYPES;

const INITIAL_STATE = {
	agreements: [],
	options: [],
	init: { loading: false, data: {} },
	hasInvoiceCredits: false,
	isEmailValid: true,
	isLoaded: false,
	isError: false,
	errorMessage: '',
	memorizedAgreements: null
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch payment options *******************
			case types.FETCH_PAYMENT_TYPES_LOADING:
				return {
					...INITIAL_STATE,
					memorizedAgreements: draft.memorizedAgreements
				};

			case types.FETCH_PAYMENT_TYPES_SUCCESS:
				draft.options = action.payload;
				return;

			case types.FETCH_PAYMENT_TYPES_ERROR:
				draft.isError = true;
				draft.isLoaded = true;
				draft.errorMessage = action.payload.response.data.message;
				return;

			// ******************* Fetch payment init *******************

			case types.FETCH_PAYMENT_INIT_LOADING:
				draft.init.loading = true;
				return;

			case types.FETCH_PAYMENT_INIT_SUCCESS:
				draft.init.loading = false;
				draft.init.data = action.payload;
				return;

			case types.FETCH_PAYMENT_INIT_ERROR:
				draft.init.loading = false;
				const errorCode = action.payload.response.data.errorCode;

				draft.isError = true;
				draft.isLoaded = true;
				draft.errorMessage = action.payload.response.data.message;
				draft.isEmailValid = errorCode === MISSING_EMAIL ? false : true;
				return;

			// ******************* Fetch payment agreements *******************
			case types.FETCH_PAYMENT_AGREEMENTS_SUCCESS:
				draft.agreements = action.payload
					.sort((a, b) => (a.rank > b.rank ? 1 : -1))
					.map((item) => ({
						...item,
						checked: false
					}));

				draft.isLoaded = true;
				return;

			case types.FETCH_PAYMENT_AGREEMENTS_ERROR:
				draft.isError = true;
				draft.isLoaded = true;
				draft.errorMessage = action.payload.response.data.message;
				return;

			// ******************* CHECK_INVOICE_CREDITS *******************
			case types.CHECK_INVOICE_CREDITS:
				draft.hasInvoiceCredits = action.payload;
				break;

			// ******************* Clear payment *******************
			case types.PAYMENT_CLEAR:
				return {
					...INITIAL_STATE,
					memorizedAgreements: draft.memorizedAgreements
				};

			// ******************* set memorized agreements *******************
			case types.SET_MEMORIZED_AGREEMENTS:
				draft.memorizedAgreements = action.payload;
				return;

			// ******************* clear memorized agreements *******************
			case types.CLEAR_MEMORIZED_AGREEMENTS:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
