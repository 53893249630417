import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Import images
import pageNotFoundBg from 'assets/images/404_page_not_found_background.png';

// Import styles
import {
	Wrapper,
	Title,
	BackgroundCover,
	Box,
	DescriptionWrapper,
	Description,
	DescriptionSecond,
	Grid,
	GridItem
} from './styles';

// Import components
import {
	ButtonTransparent,
	DynamicIcon,
	HelmetTags
} from 'components/elements';

// Import helpers
import { APP_SUB_PATHS_KEY } from 'helpers/navigationSubPaths';
import { getDocumentNavLink } from 'helpers';

const { CONTACT } = APP_SUB_PATHS_KEY;

const PageNotFound = () => {
	const { t } = useTranslation();

	const contactPath = getDocumentNavLink(CONTACT);

	return (
		<Wrapper>
			<HelmetTags metaTitle="common_not_found_page_meat_title" />
			<BackgroundCover src={pageNotFoundBg} />
			<Box>
				<Title>{t('common_not_found_page_title')}</Title>

				<DynamicIcon icon="ic_sad_smile" />

				<DescriptionWrapper>
					<Description>{t('common_not_found_page_description')}</Description>
				</DescriptionWrapper>

				<Grid>
					<GridItem>
						<DescriptionSecond>
							{ReactHtmlParser(t('common_not_found_page_back_title'))}
						</DescriptionSecond>

						<ButtonTransparent
							type="link"
							to="/"
							modifiers="secondary"
							width={202}
							height={44}
						>
							{t('common_not_found_page_back_btn')}
						</ButtonTransparent>
					</GridItem>

					<GridItem>
						<DescriptionSecond>
							{ReactHtmlParser(t('common_not_found_page_contact_title'))}
						</DescriptionSecond>

						<ButtonTransparent
							type="link"
							to={contactPath}
							width={202}
							height={44}
							opacity={0.7}
						>
							{t('common_not_found_page_contact_btn')}
						</ButtonTransparent>
					</GridItem>
				</Grid>
			</Box>
		</Wrapper>
	);
};

export default PageNotFound;
