import React from 'react';
import { string, func, number, oneOfType, array } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { PreviewButton } from 'components/views/vod/components/buttons/PreviewButton';
import PlayerPreview from './PlayerPreview';
import { ErrorBoundary } from 'components/elements';

const Preview = React.memo(
	({ uuid, stopStartCarousel, startCarousel, videoAssetId, modifiers }) => {
		const { t } = useTranslation();

		return (
			<ToggleRPC>
				{({ on, toggle }) =>
					!on ? (
						<PreviewButton watchPreview={toggle} modifiers={modifiers} />
					) : (
						<ErrorBoundary text={t('player_error_playlist_data_error')}>
							<PlayerPreview
								startCarousel={startCarousel}
								stopStartCarousel={stopStartCarousel}
								uuid={uuid}
								toggle={toggle}
								videoAssetId={videoAssetId}
							/>
						</ErrorBoundary>
					)
				}
			</ToggleRPC>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

Preview.defaultProps = {
	stopStartCarousel: () => {},
	startCarousel: () => {}
};

Preview.propTypes = {
	uuid: string.isRequired,
	stopStartCarousel: func.isRequired,
	startCarousel: func.isRequired,
	videoAssetId: number.isRequired,
	modifiers: oneOfType([string, array])
};

export default Preview;
