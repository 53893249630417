import React from 'react';

// Import components
import { DottedLoaderWrapper, DottedLoaderInner } from './styles';

const DottedLoader = () => {
	return (
		<DottedLoaderWrapper>
			<DottedLoaderInner />
		</DottedLoaderWrapper>
	);
};

export default DottedLoader;
