import React from 'react';
import { string, oneOf } from 'prop-types';

// Import styled components
import {
	HiddenHeadingFirst,
	HiddenHeadingSecond,
	HiddenParagraph
} from './styles';

// Import variables
import { H1, H2, P } from './helpers';

const HiddenTag = ({ component = H1, text }) => {
	switch (component) {
		case H2:
			return <HiddenHeadingSecond>{text}</HiddenHeadingSecond>;

		case P:
			return <HiddenParagraph>{text}</HiddenParagraph>;

		default:
			return <HiddenHeadingFirst>{text}</HiddenHeadingFirst>;
	}
};

HiddenTag.propTypes = {
	component: oneOf([H1, H2, P]),
	text: string.isRequired
};

export default HiddenTag;
