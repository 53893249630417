import React from 'react';
import { string, number, oneOfType, array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as PlayIcon } from 'assets/icons/ic_vod_play.svg';

// Import helpers
import { createLinkParams } from 'helpers';

// Import components
import { ButtonRounded } from 'components/elements';

export function DetailsButton({
	uuid,
	width,
	height,
	subtype,
	title,
	modifiers,
	saveSectionId
}) {
	const { t } = useTranslation();

	const linkRedirect = createLinkParams({ id: uuid, type: subtype, title });

	return (
		<ButtonRounded
			type="link"
			width={width}
			height={height}
			to={linkRedirect}
			onClick={saveSectionId}
			title={t('vod_buttons_play')}
			modifiers={modifiers}
		>
			<PlayIcon />
		</ButtonRounded>
	);
}

DetailsButton.defaultProps = {
	width: 80,
	height: 80,
	subtype: '',
	title: ''
};

DetailsButton.propTypes = {
	uuid: string.isRequired,
	width: number,
	height: number,
	subtype: string,
	title: string,
	modifiers: oneOfType([string, array]),
	saveSectionId: func
};
