import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import redux actions
import { setCouponActivationCode } from 'store/actions';

// Import helpers
import { CODE_PARAMETER } from 'helpers/variables';
import { getSearchParametr } from 'helpers';
import { APP_PATHS_KEY, getNavigationPaths } from 'components/routes/helpers';

const { USER_ENTRY_TICKETS } = APP_PATHS_KEY;

const useCouponActivationUrl = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const code = getSearchParametr(window.location.search, CODE_PARAMETER);

	const { urlCode } = useSelector(({ coupon_activation }) => coupon_activation);

	const userEntryTicketsPath = getNavigationPaths(USER_ENTRY_TICKETS);

	useEffect(() => {
		code && setCouponActivationCode(code)(dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		urlCode && history.push(userEntryTicketsPath);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlCode]);
};

export default useCouponActivationUrl;
